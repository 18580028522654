import { REQUIRED_BLOCKS } from '@configs/required-blocks';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { types } from './mutations';

const MODULE_NAME = 'cmsBlock';

const TYPE_CMS_BLOCK = 'cmsBlock';
const TYPE_PAGE_BUILDER_BLOCK = 'pageBuilderBlock';

const MISSING_REQUIRED_BLOCKS_ERROR_MESSAGE = 'Not all required blocks have been loaded.';

const sendErrorToSentry = (err, blocks, $errorHandler, blockType = TYPE_CMS_BLOCK) => {
    $errorHandler.captureStoreError(
        MODULE_NAME,
        err,
        {
            [ERROR_ACTION_TAG_NAME]: `cmsBlocks/${blockType}`,
        },
        {
            blocks,
            blockType,
        }
    );
};

const checkAllRequiredBlocksHaveBeenLoaded = (
    loadedBlocks,
    blocksToLoad,
    $errorHandler,
    blockType = TYPE_CMS_BLOCK
) => {
    const loadedBlocksIds = loadedBlocks.flatMap(({ identifier }) => identifier);

    const missingRequiredBlocks = blocksToLoad.filter(
        id => REQUIRED_BLOCKS.includes(id) && !loadedBlocksIds.includes(id)
    );

    if (missingRequiredBlocks.length) {
        sendErrorToSentry(
            new Error(MISSING_REQUIRED_BLOCKS_ERROR_MESSAGE),
            missingRequiredBlocks,
            $errorHandler,
            blockType
        );
    }
};

const getBlocks = async (
    dispatch,
    state,
    blocksRequested,
    { $services, $errorHandler },
    blockType = TYPE_CMS_BLOCK
) => {
    let loadedBlocks = [];

    const blocksToLoad = [blocksRequested]
        .flat()
        .filter(id => Object.keys(state[id] || {}).length === 0);

    if (blocksToLoad.length === 0) {
        return true;
    }

    const requiredBlocksToLoad = blocksToLoad.filter(id => REQUIRED_BLOCKS.includes(id));

    try {
        const method = blockType === TYPE_CMS_BLOCK ? 'getData' : 'getPageBuilderData';

        loadedBlocks = await $services.cmsBlocks[method](blocksToLoad);
    } catch (err) {
        sendErrorToSentry(err, blocksToLoad, $errorHandler, blockType);
    }

    if (loadedBlocks?.length) {
        dispatch('setCmsBlocks', loadedBlocks);

        checkAllRequiredBlocksHaveBeenLoaded(loadedBlocks, blocksToLoad, $errorHandler, blockType);

        return true;
    }

    if (requiredBlocksToLoad.length) {
        sendErrorToSentry(
            new Error(MISSING_REQUIRED_BLOCKS_ERROR_MESSAGE),
            requiredBlocksToLoad,
            $errorHandler,
            blockType
        );
    }

    return false;
};

export default {
    setCmsBlocks({ commit }, cmsBlocks) {
        commit(types.SET_CMS_BLOCKS, cmsBlocks);
    },

    async getCmsBlocks({ dispatch, state }, { cmsBlocksToLoad }) {
        return getBlocks(dispatch, state, cmsBlocksToLoad, this.app, TYPE_CMS_BLOCK);
    },

    async getPageBuilderBlocks({ dispatch, state }, { pageBuilderBlocksToLoad }) {
        return getBlocks(
            dispatch,
            state,
            pageBuilderBlocksToLoad,
            this.app,
            TYPE_PAGE_BUILDER_BLOCK
        );
    },
};
