import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.gdpr.${action}`,
});

export default (megatronClient, $errorHandler) => ({
    async getGdprAgreementsData(viewId, customerEmail) {
        const ERROR_TAGS = getErrorTags('getGdprAgreementsData');

        try {
            const { data, error, status } = await megatronClient.get(
                customerEmail ? 'v1/gdprAgreementsForEmail' : 'v1/gdprAgreements',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        viewId,
                        ...(customerEmail && { customerEmail }),
                    },
                }
            );

            if (!error) {
                return data?.gdprAgreements?.agreements || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    viewId,
                    customerEmail,
                    data,
                    error,
                    status,
                }
            );

            return [];
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                viewId,
                customerEmail,
            });

            return [];
        }
    },

    async createGdprAgreementDecision(agreementsData) {
        const ERROR_TAGS = getErrorTags('createGdprAgreementDecision');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/createGdprAgreementDecision',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: agreementsData,
                }
            );

            if (!error) {
                return data?.createGdprAgreementDecision?.success || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    agreementsData,
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                agreementsData,
            });

            return false;
        }
    },

    async getGdprCustomerAgreements() {
        const response = (agreements, error) => ({
            agreements: agreements || [],
            error: error || null,
        });
        const ERROR_TAGS = getErrorTags('getGdprCustomerAgreements');

        try {
            const { data, error, status } = await megatronClient.get('v1/gdprCustomerAgreements', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return response(data?.gdprCustomerAgreements);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return response([], error);
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return response([], err);
        }
    },
});
