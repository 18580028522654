import { COLOR_ATTRIBUTE_NAME } from '@configs/product-attribute-names';

import {
    getLoyaltyClubPrice,
    getLoyaltyClubDiscount,
    getLoyaltyClubPoints,
} from '@assets/loyalty-club';
import { getDoubleSize } from '@assets/double-size';

const getMainSizeLabel = (variant, sizeValueLabel) =>
    variant ? getDoubleSize(variant) || sizeValueLabel : sizeValueLabel;

export const formatDiscount = value => (value > 0 ? `-${value}%` : `+${value * -1}%`);

export const transformCartItemConfigurableOptions = item => {
    const { configurable_options: configurableOptions, product } = item;
    let chosenSize = '';
    let sizeValues = {};
    const sizeAttrIndex = 0;
    const sizeAttr = configurableOptions[sizeAttrIndex];

    if (sizeAttr) {
        const {
            id: sizeAttrCode,
            option_label: sizeAttrLabel,
            value_id: sizeValueCode,
            value_label: sizeValueLabel,
            system_label: systemValueLabel,
        } = sizeAttr;

        const variant = product.variants?.[systemValueLabel];

        sizeValues = {
            attribute: {
                code: sizeAttrCode,
                label: sizeAttrLabel,
            },
            value: {
                code: sizeValueCode,
                label: getMainSizeLabel(variant, sizeValueLabel),
            },
        };

        chosenSize = systemValueLabel;
    }

    return {
        chosenSize,
        sizeValues,
    };
};

export const transformCartItem = (item, statusesData, locale, currency, pointPrice) => {
    const { configurable_options: configurableOptions, offer_id: offerId } = item;

    if (!item.product) {
        item.product = {};
    }

    const {
        id,
        skuVariant,
        product: {
            analytics,
            badges,
            color,
            fason,
            isActive,
            isOneSize,
            model,
            seriesName,
            sexName,
            family,
            brandName,
            brandUrl,
            categories,
            sku,
            name,
            url_key,
            url = '',
            images,
            sizeType,
            sizeTypeCode,
            variants = {},
            attributes = {},
            values,
            purpose,
            style,
            relatedIndexes,
            hasPromoSticker,
            promoSticker,
            imageSizeTable,
            productBadgeConfig,
        },
        prices,
        ext_stock_count: stockCount,
        quantity,
        discount_loyalty_club_status: loyaltyClubStatus,
    } = item;

    const { url: src = '', label: alt = '' } = item.product.image ?? {};

    const {
        discount_percent: cartDiscountPercent,
        final_price: finalPriceTotal,
        formatted_final_price: finalPriceFormattedTotal,
        final_item_price: finalPrice,
        item_original_price: regularPrice,
        formatted_final_item_price: finalPriceFormatted,
        formatted_item_original_price: regularPriceFormatted,
        discount_percent_by_special_price: discountPercent,
        original_price: regularPriceTotal,
        formatted_original_price: regularPriceTotalFormatted,
        omnibus_price: omnibusPrice = 0,
        formatted_omnibus_price: omnibusPriceFormatted,
        omnibus_discount_amount: omnibusDiscountPercent,
        item_price: specialPrice,
        formatted_item_price: specialPriceFormatted,
    } = prices;

    const transformed = {
        analytics,
        badges,
        brandName,
        brandUrl,
        color,
        fason,
        isActive,
        isOneSize,
        model,
        seriesName,
        id,
        sku,
        offerId,
        skuVariant,
        name,
        quantity,
        categories,
        variants,
        attributes,
        url_key,
        image: {
            src,
            alt,
        },
        images,
        currency,
        discount: {
            special: discountPercent,
            regular: cartDiscountPercent,
            omnibus: omnibusDiscountPercent,
        },
        price: {
            discount: discountPercent === 0 ? null : `${discountPercent}%`,
            cartDiscount: cartDiscountPercent === 0 ? null : `${cartDiscountPercent}%`,
            omnibusDiscount:
                omnibusDiscountPercent <= 0 || !omnibusPrice ? null : `${omnibusDiscountPercent}%`,
            regular: {
                amount: regularPrice,
                formatted: regularPriceFormatted,
            },
            regularTotal: {
                amount: regularPriceTotal,
                formatted: regularPriceTotalFormatted,
            },
            promotional: {
                amount: finalPrice,
                formatted: finalPriceFormatted,
            },
            promotionalTotal: {
                amount: finalPriceTotal,
                formatted: finalPriceFormattedTotal,
            },
            special: {
                amount: specialPrice,
                formatted: specialPriceFormatted,
            },
            omnibus: {
                amount: omnibusPrice,
                formatted: omnibusPrice ? omnibusPriceFormatted : '',
            },
        },
        loyaltyClubData: {
            clubDiscountValueFormatted: getLoyaltyClubDiscount(
                statusesData,
                regularPriceTotal,
                locale,
                currency
            ),
            clubPrice: getLoyaltyClubPrice(statusesData, regularPrice, locale, currency),
            clubPriceTotal: getLoyaltyClubPrice(statusesData, regularPriceTotal, locale, currency),
            clubPoints: getLoyaltyClubPoints(finalPriceTotal, pointPrice),
        },
        loyaltyClubStatus,
        relatedIndexes,
        hasPromoSticker,
        promoSticker,
        imageSizeTable,
        productBadgeConfig,
    };

    const { sizeValues, chosenSize } = transformCartItemConfigurableOptions(item);

    const itemValues = {
        size: sizeValues,
    };

    if (values && Object.keys(values).length) {
        const colorValues = values[COLOR_ATTRIBUTE_NAME];

        if (colorValues) {
            itemValues.color = colorValues;
        }
    }

    return {
        ...transformed,
        path: url || '',
        chosenSize,
        brand: brandName,
        brand_url: brandUrl || '',
        sizeType,
        sizeTypeCode,
        sexName,
        purpose,
        style,
        family,
        isSimpleProduct: !configurableOptions,
        values: itemValues,
        stockCount,
    };
};
