import { ADDRESS } from '@configs/partner-information';
import { MEGATRON_SEARCH_GET_STORE_OPERATION_NAME } from '@configs/megatron-client';

const normalizer = async response => {
    const { data, error } = response;

    if (error || !data) {
        return response;
    }

    if (data.internal) {
        response.data.details.address = ADDRESS;
    }

    return response;
};

export const getStores = {
    operationName: MEGATRON_SEARCH_GET_STORE_OPERATION_NAME,
    normalizer,
};
