import { GOOGLE_ANALYTICS_CLIENT_ID_COOKIE_NAME } from '@configs/storage';

import {
    SEARCH_SERVICE_ENGINE_COOKIE_NAME,
    SEARCH_SERVICE_ENGINE_COOKIE_NAME_ALL,
    USE_SYNERISE_ENGINE_COOKIE_NAME,
} from '@search/assets/ab-tests';

import { isAbTestOn } from '@search/utils/ab-tests';

import { DEFAULT_LOCALE } from '@analytics-types/Analytics';

export const SYNERISE = 'SYNERISE';
export const GOOGLE = 'GOOGLE';
export const JESSICA = 'JESSICA';

export const SYNERISE_SEARCH_TYPE_FULL_TEXT = 'full-text-search';
export const SYNERISE_SEARCH_TYPE_AUTOCOMPLETE = 'autocomplete';

const AVAILABLE_VARIANTS = [SYNERISE, GOOGLE, JESSICA];

export const getSearchServiceQueryUrl = (
    searchQueryUrl,
    engine = SYNERISE,
    sortingVariant = null,
    imgUrl = null
) => {
    const engineInfo = engine ? `&engine=${engine}` : '';
    const sorting = sortingVariant ? `&sortingVariant=${sortingVariant}` : '';
    const visualSearchImg = imgUrl ? `&imgUrl=${imgUrl}` : '';

    return `?${searchQueryUrl.split('?')[1]}${engineInfo}${sorting}${visualSearchImg}`;
};

export const getEngineVariant = abTests => {
    if (!abTests) {
        return JESSICA;
    }

    const engineVariantPl = abTests.getVariant(SEARCH_SERVICE_ENGINE_COOKIE_NAME);
    const engineVariantAll = abTests.getVariant(SEARCH_SERVICE_ENGINE_COOKIE_NAME_ALL);

    const currenVariant = engineVariantPl || engineVariantAll;

    const variant = currenVariant?.split('_')[0].toUpperCase();

    const optionalEngine = isAbTestOn(abTests, USE_SYNERISE_ENGINE_COOKIE_NAME)
        ? SYNERISE
        : JESSICA;

    return AVAILABLE_VARIANTS.includes(variant) ? variant : optionalEngine;
};

export const getGoogleClientId = $cookies => {
    const gaCookie = $cookies.get(GOOGLE_ANALYTICS_CLIENT_ID_COOKIE_NAME);

    if (!gaCookie || typeof gaCookie !== 'string') {
        return undefined;
    }

    return gaCookie?.split('.').slice('-2').join('.');
};

const addCategoryTranslation = (item, translations) => {
    const { id, children = null } = item;

    item.translations[DEFAULT_LOCALE] = translations[id];

    if (children) {
        item.children = children.map(child => addCategoryTranslation(child, translations));
    }

    return item;
};

export const addCategoriesTranslations = (
    navigation,
    { categories, breadcrumbs, treeItems } = {}
) => {
    if (!categories || !Object.keys(categories)) {
        return;
    }

    const {
        bread_crumbs: { items } = { items: [] },
        categories: navCategories = [],
        tree: { items: branches } = { branches: [] },
    } = navigation;

    navigation.bread_crumbs.items = items.map(breadcrumb =>
        addCategoryTranslation(breadcrumb, breadcrumbs)
    );

    navigation.categories = navCategories.map(category =>
        addCategoryTranslation(category, categories)
    );

    navigation.tree.items = branches.map(branch => addCategoryTranslation(branch, treeItems));

    return navigation;
};
