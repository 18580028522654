import { getStaticFileUrlWithHash } from '@modules/static-folders-hashes/get-static-file-url-with-hash';

export default ({ env: { precacheVersion, staticPath, staticHashParameterName } }, inject) => {
    const staticFoldersHashes = JSON.parse('{"folders":{"fonts/Lato":"b84cd9","fonts/Averta":"02d15e"},"files":{}}');

    inject('staticFoldersHashes', staticFoldersHashes);

    inject(
        'getStaticFileUrlWithHash',
        getStaticFileUrlWithHash(
            staticHashParameterName,
            staticPath,
            staticFoldersHashes.folders,
            precacheVersion
        )
    );
};
