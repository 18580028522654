export const delay = timeoutMs =>
    // eslint-disable-next-line no-promise-executor-return
    new Promise(resolve => setTimeout(resolve, timeoutMs));

export const forEach = async (array, callback) => {
    for (let index = 0; index < array.length; index += 1) {
        // eslint-disable-next-line no-await-in-loop
        await callback(array[index], index, array);
    }
};

export const map = (array, callback) => Promise.all(array.map(item => callback(item)));

export const flatMap = async (array, callback) => {
    const result = await map(array, callback);

    return result.flat(1);
};

export const find = async (array, callback) => {
    for (let index = 0; index < array.length; index += 1) {
        // eslint-disable-next-line no-await-in-loop
        if (await callback(array[index], index, array)) {
            return array[index];
        }
    }
};
