export const types = {
    SET_PRODUCTS: 'SET_PRODUCTS',
    SET_LOADED: 'SET_LOADED',
};

export default {
    [types.SET_PRODUCTS](state, products) {
        state.products = products;
    },

    [types.SET_LOADED](state, loaded) {
        state.isLoaded = loaded;
    },
};
