import { normalizePadData } from '@normalizers/models/pad';
import normalizeCart from '@normalizers/models/cart';

import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF } from '@configs/megatron-client';

import {
    FORBIDDEN,
    NO_SUCH_ENTITY,
    USER_BAD_INPUT,
    INTERNAL_SERVER_ERROR,
} from '@types/GraphQLErrorCodes';

import { ApolloError } from '@cherokee-assets/apolloError';
import { GraphQLError } from '@cherokee-assets/graphQlError';

const toApolloError = ({ code, errors }) => {
    const graphQLErrors = errors.map(
        error => new GraphQLError(error, error, { code, category: error })
    );

    return new ApolloError({ graphQLErrors, errorMessage: 'Error' });
};

const toMagentoError = response => {
    const { status, data: { errors = [], errorMessage = '' } = {} } =
        response.originalResponse || {};

    switch (status) {
        case 400:
            return toApolloError({ code: USER_BAD_INPUT, errors });
        case 403:
            return toApolloError({ code: FORBIDDEN, errors });
        case 404:
            return toApolloError({ code: NO_SUCH_ENTITY, errors });
        case 409:
        case 422:
            return toApolloError({ code: errors[0], errors });
        case 500:
            return toApolloError({ code: INTERNAL_SERVER_ERROR, errors: [errorMessage] });
        default:
            throw new Error(`Unknown status ${response.originalResponse.status}`);
    }
};
const handleWriteResponse = response => {
    switch (response.originalResponse.status) {
        case 200:
            return response.data;
        default:
            throw toMagentoError(response);
    }
};

const handleReadResponse = response => {
    return response.data;
};

const handleGetCartReadResponse = response => {
    switch (response.originalResponse.status) {
        case 200:
            return response.data;
        case 403:
        case 404:
            return null;
        default:
            throw toMagentoError(response);
    }
};

const transformPrice = money => {
    if (money) {
        return {
            currency: money.currency,
            value: money.value ? Number(money.value) : null,
            extFormat: money.formattedAmount,
            __typename: 'Money',
        };
    }

    return null;
};
const transformOption = option => ({
    id: option.id,
    option_label: option.optionLabel,
    value_id: option.valueId,
    value_label: option.valueLabel,
    system_label: option.systemLabel,
    __typename: 'SelectedConfigurableOption',
});
const transformItem = item => ({
    discount_loyalty_club_status: item.loyaltyClubStatus,
    id: item.id,
    offer_id: item.offerId,
    quantity: item.quantity,
    sku: item.sku,
    product: {
        name: item.product.name,
        image: {
            label: item.product.imageLabel,
            url: item.product.imageUrl,
            __typename: 'ProductImage',
        },
        sku: item.product.sku,
        url_key: item.product.urlKey,
        __typename: 'ConfigurableProduct',
    },
    prices: {
        discount_percent: item.prices.discountPercent,
        discount_percent_by_special_price: item.prices.discountPercentBySpecialPrice,
        // prices
        final_item_price: transformPrice(item.prices.finalItemPrice)?.value,
        final_price: transformPrice(item.prices.finalPrice)?.value,
        formatted_final_item_price: transformPrice(item.prices.finalItemPrice)?.extFormat,
        formatted_final_price: transformPrice(item.prices.finalPrice)?.extFormat,
        formatted_item_original_price: transformPrice(item.prices.itemOriginalPrice)?.extFormat,
        formatted_item_price: transformPrice(item.prices.itemPrice)?.extFormat,
        formatted_item_special_price: transformPrice(item.prices.itemSpecialPrice)?.extFormat,
        formatted_original_price: transformPrice(item.prices.originalPrice)?.extFormat,
        formatted_price: transformPrice(item.prices.price)?.extFormat,
        item_original_price: transformPrice(item.prices.itemOriginalPrice)?.value,
        item_price: transformPrice(item.prices.itemPrice)?.value,
        item_special_price: transformPrice(item.prices.itemSpecialPrice)?.value,
        original_price: transformPrice(item.prices.originalPrice)?.value,
        price: transformPrice(item.prices.price)?.value,
        omnibus_price: transformPrice(item.prices.omnibusPrice)?.value,
        formatted_omnibus_price: transformPrice(item.prices.omnibusPrice)?.extFormat,
        omnibus_discount_amount: item.prices.omnibusDiscountPercent,
        __typename: 'ExtCartItemPrices',
    },
    availability: {
        is_available: item.isAvailable,
        error: null,
        __typename: 'CartItemAvailability',
    },
    configurable_options: item.options.map(transformOption),
    __typename: 'MarketplaceStoreCartItem',
});
const methodById = (methods, id) => methods.find(method => method.id === id);
const toMethodOption = (option, selectedMethod) => ({
    id: option.id,
    name: option.name,
    description: option.description,
    logo: option.logo,
    fee: option.fee,
    old_fee: option.oldFee,
    is_selected: selectedMethod.optionIds.map(({ id }) => id).includes(option.id),
    rule_description: option.ruleDescription,
    rule_link: option.ruleLink,
    link_title: option.ruleLinkTitle,
    __typename: 'MarketplaceStoreCartShippingOption',
});
const sumOptionsFee = (options, selectedOptions) => {
    if (options?.length && selectedOptions?.length) {
        return options
            .filter(({ id }) => selectedOptions.includes(id))
            .reduce((acc, { fee }) => acc + fee, 0);
    }

    return null;
};
const toSelectedShippingMethod = (methods, selectedMethod) => {
    if (selectedMethod === null) {
        return null;
    }

    const method = methodById(methods, selectedMethod.id);

    if (!method) {
        return {
            active: false,
            id: '',
            name: '',
            method_code: '',
            displayName: '',
            price: null,
            module_map: false,
            options: [],
            logo: '',
            additional_data: null,
        };
    }

    const optionsFee = sumOptionsFee(method?.shippingOptions, selectedMethod.optionIds);
    const deliveryPrice =
        optionsFee || selectedMethod.price
            ? Number(selectedMethod.price || 0) + optionsFee || 0
            : null;

    return {
        active: true,
        id: selectedMethod.id,
        name: method.methodCode,
        method_code: method.methodCode,
        displayName: method.title,
        price: deliveryPrice,
        module_map: method.isDefaultPickupPointMethod,
        options: method.shippingOptions?.map(option => toMethodOption(option, selectedMethod)),
        logo: method.logoUrl,
        additional_data: { point: selectedMethod.pointId },
    };
};
const transformStoreCart = (storeCart, padData) => ({
    items: storeCart.items.map(transformItem),
    __typename: 'MarketplaceStoreCart',
    store: {
        id: storeCart.store.id,
        name: storeCart.store.name,
        type: storeCart.store.type,
        internal: storeCart.store.internal,
        details: {
            address: {
                country: storeCart.store.details?.address?.country,
                city: storeCart.store.details?.address?.city,
                zipCode: storeCart.store.details?.address?.zipCode,
                street1: storeCart.store.details?.address?.street1,
                street2: storeCart.store.details?.address?.street2,
                __typename: 'MarketplaceStoreDetailsAddress',
            },
            grade: storeCart.store.details?.grade,
            taxNumber: storeCart.store.details?.taxNumber,
            returnPolicy: storeCart.store.details?.returnPolicy,
            privacyPolicy: storeCart.store.details?.privacyPolicy,
            __typename: 'MarketplaceStoreDetails',
        },
        __typename: 'MarketplaceStore',
    },
    selected_shipping_method: toSelectedShippingMethod(
        padData.deliveryMethods,
        storeCart.selectedDeliveryMethod
    ),
});
const toDiscount = discount => {
    if (discount) {
        return {
            amount: transformPrice(discount.amount),
            label: discount.appliedCode ? [discount.appliedCode] : null,
            is_separate: discount.loyaltyClubDiscount,
            __typename: 'ExtDiscount',
        };
    }

    return null;
};
const toDuty = duty => {
    if (duty) {
        const price = transformPrice(duty);

        return {
            label: 'Additional import duty',
            amount: price,
            formatted_amount: price.extFormat,
            __typename: 'CartDuty',
        };
    }

    return null;
};
const toSelectedPaymentMethod = (methods, selectedMethod) => {
    const method = methods.find(({ code }) => code === selectedMethod?.code);

    return {
        code: selectedMethod?.code || '',
        title: method?.title || '',
        __typename: 'SelectedPaymentMethod',
    };
};
const toMagentoCart = async (cart, withPayment) => {
    const padData = normalizePadData(cart.pad);
    const magentoCart = {
        mfc_points: cart.summary.loyaltyClubPoints,
        prices: {
            discount_percent: null, // nie używane w modivo-pwa na tym poziomie
            formatted_grand_total:
                cart.summary.basePriceWithDiscountAndDeliveryCost.formattedAmount,
            formatted_subtotal_including_tax: cart.summary.basePrice.formattedAmount,
            formatted_subtotal_with_discount_excluding_tax:
                cart.summary.basePriceWithDiscount.formattedAmount,
            discount: toDiscount(cart.discount),
            discounts: cart.discount ? [toDiscount(cart.discount)] : null,
            duty: toDuty(cart.summary.duty),
            grand_total: transformPrice(cart.summary.basePriceWithDiscountAndDeliveryCost),
            subtotal_including_tax: transformPrice(cart.summary.basePrice),
            subtotal_with_discount_excluding_tax: transformPrice(
                cart.summary.basePriceWithDiscount
            ),
            __typename: 'ExtCartPrices',
        },
        __typename: 'ExtCart',
        store_carts: cart.storeCarts.map(storeCart => transformStoreCart(storeCart, padData)),
    };

    if (withPayment) {
        magentoCart.selected_payment_method = toSelectedPaymentMethod(
            padData.paymentMethods,
            cart.selectedPaymentMethod
        );
    }

    const extCart = {
        extCart: magentoCart,
        applied_coupons: cart.discount?.appliedCode
            ? [{ code: cart.discount?.appliedCode, __typename: 'AppliedCoupon' }]
            : null,
        __typename: 'Cart',
        electronicInvoice: {
            isCheckboxEnabled: cart.electronicInvoice?.enabled,
            isCheckboxSelected: cart.electronicInvoice?.selected,
            __typename: 'ElectronicInvoice',
        },
        pad: padData,
    };

    return normalizeCart(extCart);
};
const bffToMagentoCart = bffCart => toMagentoCart(bffCart, true);
const toBFFAddress = address => ({
    addressHouseNumber: address.address_house_number,
    addressIsActive: address.address_is_active,
    addressLocalNumber: address.address_local_number,
    addressTitle: address.address_title,
    buyAs: address.buy_as,
    city: address.city,
    company: address.company,
    countryCode: address.country_code,
    customerAddressType: address.customer_address_type,
    firstname: address.firstname,
    lastname: address.lastname,
    postcode: address.postcode,
    saveInAddressBook: address.save_in_address_book,
    street: address.street[0],
    telephone: address.telephone,
    vatId: address.vat_id,
});

const getVerificationHeader = verificationCode => ({
    headers: { 'verification-code': verificationCode },
});

const createBffService = megatronClient => {
    return {
        async createCart() {
            return megatronClient.post('v1/rest/checkout/cart', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
            });
        },

        async getCart({ cartId }) {
            return megatronClient.get(`v1/rest/checkout/cart/${cartId}`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
            });
        },

        async addItems({ cartId, cartItems }) {
            const items = cartItems.map(item => ({
                parentSku: item.parent_sku,
                sku: item.data.sku,
                offerId: item.data.offer_id,
                storeId: item.data.store_id,
                quantity: item.data.quantity,
            }));

            return megatronClient.post(`v1/rest/checkout/cart/${cartId}/item`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                payload: { items },
                context: {
                    hasCfChallenge: true,
                },
                withCredentials: true,
            });
        },

        async updateItems({ cartId, cartItems }) {
            const items = cartItems.map(item => ({
                itemId: item.cart_item_id,
                quantity: item.quantity,
            }));

            return megatronClient.put(`v1/rest/checkout/cart/${cartId}/item`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                payload: { items },
            });
        },

        async removeItem({ cartId, cartItemId }) {
            return megatronClient.delete(`v1/rest/checkout/cart/${cartId}/item/${cartItemId}`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
            });
        },

        async getPadData(cartId) {
            const { data } = await megatronClient.get(
                `v1/rest/checkout/cart/${cartId}/payment-delivery-methods`,
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                }
            );

            return normalizePadData(data);
        },
        async mergeCarts(sourceCartId, destinationCartId) {
            return megatronClient.post(`v1/rest/checkout/cart/${sourceCartId}/merge-with`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                payload: { destinationCartId },
            });
        },
        async applyPromocode(cartId, couponCode) {
            return megatronClient.put(`v1/rest/checkout/cart/${cartId}/promo-code/${couponCode}`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
            });
        },

        async removeCouponFromCart(cartId) {
            return megatronClient.delete(`v1/rest/checkout/cart/${cartId}/promo-code`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
            });
        },

        async setPaymentMethodOnCart(cartId, method) {
            return megatronClient.put(`v1/rest/checkout/cart/${cartId}/payment-method`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                payload: { code: method.code },
            });
        },

        async removePaymentMethodOnCart(cartId) {
            return megatronClient.delete(`v1/rest/checkout/cart/${cartId}/payment-method`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
            });
        },

        async setDeliveryMethods({ cartId, storesMethods }) {
            return megatronClient.post(`v1/rest/checkout/cart/${cartId}/delivery-method`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                payload: {
                    storeMethods: storesMethods.map(sm => ({
                        storeId: sm.storeId,
                        deliveryMethod: {
                            id: sm.methodId,
                            optionIds: sm.optionIds,
                        },
                    })),
                },
            });
        },

        async removeDeliveryMethods({ cartId, storesIds }) {
            return megatronClient.delete(
                `v1/rest/checkout/cart/${cartId}/delivery-method/store/${storesIds.join(',')}`,
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                }
            );
        },

        async setDeliveryMethodPoint({ cartId, storeId, point }) {
            return megatronClient.put(
                `v1/rest/checkout/cart/${cartId}/delivery-method/store/${storeId}/point`,
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                    payload: { pointId: point },
                }
            );
        },

        async removeDeliveryMethodPoint({ cartId, storeId }) {
            return megatronClient.delete(
                `v1/rest/checkout/cart/${cartId}/delivery-method/store/${storeId}/point`,
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                }
            );
        },

        async setElectronicInvoiceAgreement({ cartId }) {
            return megatronClient.put(`v1/rest/checkout/cart/${cartId}/electronic-invoice`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
            });
        },

        async resetElectronicInvoiceAgreement({ cartId }) {
            return megatronClient.delete(`v1/rest/checkout/cart/${cartId}/electronic-invoice`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
            });
        },

        async setGuestEmailOnCart({ cartId, email }) {
            return megatronClient.put(`v1/rest/checkout/cart/${cartId}/guest-email`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                payload: { email },
            });
        },

        async setBillingAddressOnCart({ cartId, address }) {
            return megatronClient.put(`v1/rest/checkout/cart/${cartId}/billing-address`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                payload: toBFFAddress(address),
            });
        },

        async setShippingAddressOnCart({ cartId, address }) {
            return megatronClient.put(`v1/rest/checkout/cart/${cartId}/shipping-address`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                payload: toBFFAddress(address),
            });
        },

        async getPickupPlaces({ cartId }) {
            return megatronClient.get(`v1/rest/checkout/cart/${cartId}/pickup-places`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
            });
        },

        async getCartItemsStocks({ cartId }) {
            return megatronClient.get(`v1/rest/checkout/cart/${cartId}/item-stocks`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
            });
        },

        async createOrder({ cartId, verificationCode }) {
            return megatronClient.put(`v1/rest/checkout/cart/${cartId}/order`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                ...((verificationCode && getVerificationHeader(verificationCode)) || {}),
            });
        },

        async getPhoneVerificationCode({ cartId }) {
            return megatronClient.post(`v1/rest/checkout/cart/${cartId}/phone-verification-code`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT_BFF,
                context: {
                    hasCfChallenge: true,
                },
                withCredentials: true,
            });
        },
    };
};

const toMarketplaceStorePickupPlaces = pickupPlaces => ({
    marketplace_stores: pickupPlaces?.storePickupPoints?.map(storePickupPoints => ({
        store: { id: storePickupPoints.store.id },
        pickup_point: storePickupPoints.pickupPoints.map(({ id, localizedNames }) => ({
            id,
            name: id,
            localized_infos: localizedNames.map(({ locale, name }) => ({
                locale,
                name,
            })),
        })),
    })),
});

export default megatronClient => {
    const bffService = createBffService(megatronClient);

    return {
        async createEmptyCart() {
            return bffService
                .createCart()
                .then(handleWriteResponse)
                .then(({ cartId }) => cartId);
        },

        async addMarketplaceProductsToCart({ cartId, cartItems }) {
            return bffService
                .addItems({ cartId, cartItems })
                .then(handleWriteResponse)
                .then(bffToMagentoCart)
                .then(cart => ({ cart }));
        },

        async removeItemFromCart({ cartId, cartItemId }) {
            return bffService
                .removeItem({ cartId, cartItemId })
                .then(handleWriteResponse)
                .then(bffToMagentoCart)
                .then(cart => ({ cart }));
        },

        async getCart(payload) {
            const { cartId, withPaymentAndShippingMethods = false } = payload;

            const bffCart = await bffService.getCart({ cartId }).then(handleGetCartReadResponse);

            let cart = null;

            if (bffCart) {
                cart = await toMagentoCart(bffCart, withPaymentAndShippingMethods);
            }

            return cart;
        },

        async mergeGuestCartWithCustomerCart(sourceCartId, destinationCartId) {
            return bffService
                .mergeCarts(sourceCartId, destinationCartId)
                .then(handleWriteResponse)
                .then(bffToMagentoCart);
        },

        async setShippingMethodOnSellerCart(cartId, methods) {
            const storesMethods = methods.map(m => ({
                storeId: m.store_id,
                methodId: m.method_id,
                optionIds: m.option_ids,
            }));

            return bffService
                .setDeliveryMethods({ cartId, storesMethods })
                .then(handleWriteResponse)
                .then(bffToMagentoCart);
        },

        async resetShippingMethodOnSellerCart(cartId, methods) {
            const storesIds = methods.flatMap(({ store_id }) => store_id);

            return bffService
                .removeDeliveryMethods({ cartId, storesIds })
                .then(handleWriteResponse)
                .then(bffToMagentoCart);
        },

        async setPointsToShipping(cartId, storeCarts) {
            // eslint-disable-next-line no-restricted-syntax
            for await (const { store_id, point } of storeCarts) {
                const result = await bffService.setDeliveryMethodPoint({
                    cartId,
                    storeId: store_id,
                    point,
                });

                handleWriteResponse(result);
            }

            return { result: storeCarts.map(({ store_id }) => ({ store_id })) };
        },

        async removePointFromShipping(cartId, storeCarts) {
            await Promise.all(
                storeCarts
                    .map(({ store_id }) =>
                        bffService.removeDeliveryMethodPoint({ cartId, storeId: store_id })
                    )
                    .map(p => p.then(handleWriteResponse))
            );

            return { result: storeCarts.map(({ store_id }) => ({ store_id })) };
        },

        async setPaymentMethodOnCart(cartId, method) {
            return bffService
                .setPaymentMethodOnCart(cartId, method)
                .then(handleWriteResponse)
                .then(bffToMagentoCart);
        },

        async resetPaymentMethodOnCart(cartId) {
            return bffService
                .removePaymentMethodOnCart(cartId)
                .then(handleWriteResponse)
                .then(bffToMagentoCart);
        },

        async applyCouponToCartWithCouponInfo(cartId, couponCode) {
            const response = await bffService.applyPromocode(cartId, couponCode);

            switch (response.originalResponse.status) {
                case 200:
                    return { cart: await bffToMagentoCart(response.data) };

                case 400:
                    return {
                        coupon_application_info: {
                            message: response.error.data?.message,
                            coupon_info: {
                                already_used: response.error.data?.promoCodeInfo?.alreadyUsed,
                                expiration_date: response.error.data?.promoCodeInfo?.expirationDate,
                                rule_conditions: response.error.data?.promoCodeInfo?.ruleConditions,
                                general_rejection_reason:
                                    response.error.data?.promoCodeInfo?.generalRejectionReason,
                            },
                        },
                    };
                default:
                    throw toMagentoError(response);
            }
        },

        async removeCouponFromCart(cartId) {
            return bffService
                .removeCouponFromCart(cartId)
                .then(handleWriteResponse)
                .then(bffToMagentoCart);
        },

        async updateCartItem({ cartId, cartItems }) {
            return bffService
                .updateItems({ cartId, cartItems })
                .then(handleWriteResponse)
                .then(bffToMagentoCart);
        },

        async setShippingAddressOnCart(cartId, shippingAddress) {
            return bffService
                .setShippingAddressOnCart({ cartId, address: shippingAddress.address })
                .then(handleWriteResponse)
                .then(bffToMagentoCart);
        },

        async setBillingAddressOnCart(cartId, billingAddress) {
            return bffService
                .setBillingAddressOnCart({ cartId, address: billingAddress.address })
                .then(handleWriteResponse)
                .then(bffToMagentoCart);
        },

        async setGuestEmailOnCart(cartId, email) {
            return bffService
                .setGuestEmailOnCart({ cartId, email })
                .then(handleWriteResponse)
                .then(bffToMagentoCart);
        },

        async setElectronicInvoiceAgreement(cartId, electronicInvoiceAgreement) {
            if (electronicInvoiceAgreement) {
                await bffService
                    .setElectronicInvoiceAgreement({ cartId })
                    .then(handleWriteResponse);
            } else {
                await bffService
                    .resetElectronicInvoiceAgreement({ cartId })
                    .then(handleWriteResponse);
            }

            return true;
        },

        async getMarketplaceStorePickupPlacesByCart(cartId) {
            return bffService
                .getPickupPlaces({ cartId })
                .then(handleReadResponse)
                .then(toMarketplaceStorePickupPlaces);
        },

        async getCartItemsVariants(cartId) {
            const itemsStocks = await bffService
                .getCartItemsStocks({ cartId })
                .then(handleReadResponse);

            const variants = itemsStocks?.items.map(item => ({
                id: item.id,
                configurable_options: [{}],
                product: {
                    sku: item.parentSku,
                    variants: item.configurableOptions.map(({ systemLabel }) => ({
                        attributes: [{ system_label: systemLabel }],
                        product: {
                            sku: item.sku,
                            ext_stock_count: item.stockCount,
                        },
                    })),
                },
            }));

            return variants || [];
        },

        async getUpdatedCartItemsStock(cartId) {
            const itemsStocks = await bffService
                .getCartItemsStocks({ cartId })
                .then(handleReadResponse);

            return {
                totalQuantity: 99,
                items:
                    itemsStocks?.items.map(item => ({
                        id: item.id,
                        ext_stock_count: item.stockCount,
                    })) || [],
            };
        },

        async placeOrder(cartId, verificationCode) {
            const result = await bffService
                .createOrder({ cartId, verificationCode })
                .then(handleWriteResponse);

            return {
                order: {
                    order_number: result.orderId,
                },
                order_hash: result.orderHash,
            };
        },

        async getPhoneVerificationCode(cartId) {
            return bffService.getPhoneVerificationCode({ cartId }).then(handleWriteResponse);
        },

        getPadData: bffService.getPadData,
    };
};
