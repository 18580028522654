<template>
    <div class="observer">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Observer',

    props: {
        options: {
            type: Object,
            default: () => ({}),
        },

        observeOnce: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            observer: null,
        };
    },

    mounted() {
        this.initObserver();
    },

    beforeDestroy() {
        this.disconnectObserver();
    },

    methods: {
        initObserver() {
            this.observer = new IntersectionObserver(([entry]) => {
                if (!entry) {
                    return;
                }

                this.$emit('intersect', entry.isIntersecting);

                if (entry.isIntersecting && this.observeOnce) {
                    this.observer.disconnect();
                    this.observer = null;
                }
            }, this.options);

            this.$nextTick(() => {
                if (this.observer) {
                    this.observer.observe(this.$el);
                }
            });
        },

        disconnectObserver() {
            if (this.observer) {
                this.observer.disconnect();
            }
        },

        // eslint-disable-next-line vue/no-unused-properties
        async resetObserver() {
            this.disconnectObserver();
            await this.$nextTick();
            this.initObserver();
        },
    },
};
</script>
