import Navigation from '@header/models/Navigation/Navigation';
import { MODIVO_KIDS_CATEGORY_ID } from '@header/configs/contexts';

export default {
    categories: state => {
        return state.categories.map(category => {
            const {
                static_block_suffix: staticBlockSuffix,
                id,
                display_name: displayName,
                path,
                children,
            } = category;

            const { slug = '', code = -1 } = category.pim || {};

            const isModivoKidsCategory = code === MODIVO_KIDS_CATEGORY_ID;

            return new Navigation({
                staticBlockSuffix,
                id,
                displayName,
                slug,
                path,
                children,
                removeCmsBanners: isModivoKidsCategory,
                withDisclosures: isModivoKidsCategory || state.useDisclosures,
                pimCode: code,
            }).normalize();
        });
    },
};
