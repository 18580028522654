import MERCHANT_SERVICES_NAMESPACE from '@merchant/async-services/namespace';

export const NAME = `${MERCHANT_SERVICES_NAMESPACE}/catalog`;

export const GET_DATA = 'GET_DATA';

export const ASYNC_CATALOG_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        GET_DATA,
    },
};
