export const ERROR = 'error';
export const SUCCESS = 'success';
export const WARNING = 'warning';
export const INFO = 'info';

export default {
    ERROR,
    SUCCESS,
    WARNING,
    INFO,
};
