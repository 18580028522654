export const USER_CHOICE_ACCEPTED = 'accepted';
export const USER_CHOICE_DISMISSED = 'dismissed';

export const BEFORE_INSTALL_PROMPT_EVENT_NAME = 'beforeinstallprompt';
export const APP_INSTALLED_EVENT_NAME = 'appinstalled';

export const EVENTS = {
    BEFORE_INSTALL_PROMPT_EVENT_NAME,
    APP_INSTALLED_EVENT_NAME,
};
