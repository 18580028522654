<template>
    <div class="authorization-layout">
        <HeaderSimpleWithBackButton />

        <main>
            <nuxt />
        </main>

        <Messages />

        <ContainerContent :align-left="!isMobile">
            <FooterBottom v-if="!isWebViewMobile" />
        </ContainerContent>

        <ChatWrapper />

        <GlobalModals :is-authorization-layout="true" />

        <SmartBannerWrapper />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import WebView from '@mixins/WebView';
import BaseLayout from '@layouts/mixins/BaseLayout';
import WithCustomer from '@layouts/mixins/WithCustomer';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import HeaderSimpleWithBackButton from '@molecules/HeaderSimpleWithBackButton/HeaderSimpleWithBackButton';
import Messages from '@molecules/Messages/Messages';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';
import FooterBottom from '@molecules/FooterBottom/FooterBottom';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import GlobalModals from '@organisms/GlobalModals/GlobalModals';

export default {
    name: 'AuthorizationLayout',

    components: {
        SmartBannerWrapper,
        ChatWrapper,
        HeaderSimpleWithBackButton,
        Messages,
        GlobalModals,
        FooterBottom,
        ContainerContent,
    },

    mixins: [BaseLayout, WithCustomer, WebView],

    computed: {
        ...mapState(['isMobile']),
    },
};
</script>

<style scoped lang="scss">
.authorization-layout {
    @apply bg-ui-swap-gray100 min-h-screen;

    @apply flex flex-col;
}
</style>
