import {
    DEFAULT_IMAGE_FORMAT,
    ADDITIONAL_IMAGE_FORMATS,
    IMAGE_TYPES,
    IMAGE_FORMAT_SVG_EXTENSION,
} from '@types/Image';

export default url => ({
    getImage(
        path,
        slug,
        alt,
        imageType = IMAGE_TYPES.product.name,
        format = DEFAULT_IMAGE_FORMAT,
        sourceImageFormats = ADDITIONAL_IMAGE_FORMATS,
        sourceImageTypes = []
    ) {
        let defaultSourceImageTypes = [imageType];

        if (sourceImageTypes.length > 0) {
            defaultSourceImageTypes = sourceImageTypes;
        }

        const sources = sourceImageFormats.reduce((items, { extension: sourceImageExtension }) => {
            items[sourceImageExtension] = defaultSourceImageTypes.map(sourceImageType => {
                return {
                    src: this.getUrl(path, slug, sourceImageType, sourceImageExtension),
                    width: IMAGE_TYPES[sourceImageType].width,
                };
            });

            return items;
        }, {});

        return {
            url: this.getUrl(path, slug, imageType, format.extension),
            width: IMAGE_TYPES[imageType].width,
            height: IMAGE_TYPES[imageType].height,
            alt,
            sources,
        };
    },

    getUrl(path, slug, imageType, format) {
        if (format === IMAGE_FORMAT_SVG_EXTENSION) {
            return `${url}/${imageType}(${path.replace('.svg', '')})/${slug}.${format}`;
        }

        return `${url}/${imageType}(${path},${format})/${slug}.${format}`;
    },
});
