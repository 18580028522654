<template>
    <WithClickOutsideDetection :handler="isAutocompleteOpen ? 'closeAutocomplete' : null">
        <div class="header-search">
            <HeaderSearchInput class="header-search-input" />

            <HeaderAutocomplete v-if="isAutocompleteOpen && !isMobile" />
            <Scrim
                v-show="isAutocompleteOpen && !isMobile"
                class="backdrop"
                @click="closeAutocomplete()"
            />
        </div>
    </WithClickOutsideDetection>
</template>

<script>
import { mapState } from 'vuex';

import { AUTOCOMPLETE_MODAL } from '@header/configs/modals';

import WithClickOutsideDetection from '@molecules/WithClickOutsideDetection/WithClickOutsideDetection';

import HeaderSearchInput from '@header-atoms/HeaderSearchInput/HeaderSearchInput';

import { Scrim } from '@modivo-ui/components/Scrim/v1';

export default {
    name: 'HeaderSearch',

    components: {
        HeaderSearchInput,
        WithClickOutsideDetection,
        HeaderAutocomplete: () => ({
            component: import(
                /* webpackChunkName: "header-autocomplete" */
                '@header-organisms/HeaderAutocomplete/HeaderAutocomplete'
            ),
        }),
        Scrim,
    },

    computed: {
        ...mapState(['isMobile']),

        isAutocompleteOpen() {
            return this.$modals.isOpen(AUTOCOMPLETE_MODAL);
        },
    },

    watch: {
        $route() {
            this.closeAutocomplete();
        },
    },

    methods: {
        closeAutocomplete() {
            this.$modals.close(AUTOCOMPLETE_MODAL);
        },
    },
};
</script>

<style lang="scss" scoped>
.header-search {
    @apply relative;

    .header-search-input {
        @apply z-5;
    }

    .backdrop {
        @apply z-2 fixed;
    }
}
</style>
