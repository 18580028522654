import {
    BREADCRUMB_CLICK,
    BREADCRUMB_GO_BACK_CLICK,
    BREADCRUMB_OPEN_CONTEXTMENU,
} from '@analytics-types/Events';

export default {
    methods: {
        sendAnalyticsEvent(name) {
            if (name === this.HOMEPAGE_NAME) {
                return;
            }

            const oldBreadcrumbs = Object.values(this.breadcrumbs).map(
                ({ name: breadcrumbName }) => breadcrumbName
            );

            const nameIndex = oldBreadcrumbs.indexOf(name);

            const newBreadcrumbs = oldBreadcrumbs.slice(0, nameIndex + 1);

            this.$analytics.emit(BREADCRUMB_CLICK, {
                newBreadcrumbs,
                oldBreadcrumbs,
                page: this.$route.name,
            });
        },

        sendAnalyticsGoBackEvent() {
            this.$analytics.emit(BREADCRUMB_GO_BACK_CLICK, {
                page: this.$route.name,
            });
        },

        sendAnalyticsContextMenuEvent(url) {
            this.$analytics.emit(BREADCRUMB_OPEN_CONTEXTMENU, { url });
        },
    },
};
