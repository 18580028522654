import { registerMediaQueryWatcher } from '@assets/media-query-watcher';

import { CORE_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { lg as BREAKPOINT_LG } from '@theme/resources/variables/responsiveBreakpoints.json';

const MOBILE_BREAKPOINT = `(max-width: ${parseInt(BREAKPOINT_LG, 10) - 1}px)`;

export default ({ app, store }) => {
    window.onNuxtReady(() => {
        try {
            registerMediaQueryWatcher(
                isMobile => store.dispatch('setIsMobile', isMobile),
                MOBILE_BREAKPOINT
            );
        } catch (err) {
            app.$errorHandler.captureDomainError(CORE_ERROR_DOMAIN, err, {
                [ERROR_ACTION_TAG_NAME]: 'plugin.is-mobile-watcher',
            });
        }
    });
};
