<template>
    <BaseTooltip
        :is-mobile="isMobile"
        :show-tooltip-on-mobile="false"
        position="bottom-left"
        padding="8px"
        class="minicart-tooltip"
        tooltip-content-class="minicart-tooltip-content"
    >
        <HeaderActionButton
            :path="cartPath"
            :icon="Bag"
            :label="$t('Cart')"
            :digit="cartItemsCount"
        />

        <template #tooltipContent="{ isVisible }">
            <MinicartContent v-if="isVisible" :items-count="cartItemsCount" />
        </template>
    </BaseTooltip>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { CHECKOUT_CART_PAGE } from '@router/paths';

import BaseTooltip from '@atoms/BaseTooltip/BaseTooltip';
import Loader from '@atoms/Loader/Loader';
import HeaderActionButton from '@header-atoms/HeaderActionButton/HeaderActionButton';

import { Bag } from '@modivo-ui/icons/v2/action';

const { mapGetters: mapCartGetters } = createNamespacedHelpers('cart');

export default {
    name: 'MinicartTooltip',

    components: {
        BaseTooltip,
        HeaderActionButton,
        MinicartContent: () => ({
            component: import(
                /* webpackChunkName: "minicart-content" */
                '@molecules/MinicartContent/MinicartContent'
            ),
            delay: 0,
            loading: Loader,
        }),
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapCartGetters(['cartItemsCount']),
    },

    beforeCreate() {
        this.cartPath = `/${CHECKOUT_CART_PAGE}`;
        this.Bag = Bag;
    },
};
</script>

<style lang="scss" scoped>
// todo update design - Checkout?
.minicart-tooltip {
    &:deep() {
        .minicart-tooltip-content {
            width: theme('customVariables.minicart.tooltipContentWidth');
        }

        .cart-tooltip {
            width: theme('customVariables.minicart.tooltipWidth');
        }

        .empty-cart {
            .paragraph {
                @apply font-semibold text-dark text-center;
            }

            .empty-cart-svg {
                @apply my-0 mx-auto;
            }
        }

        .delivery-cost {
            @apply flex justify-between my-2;

            .paragraph:last-of-type {
                @apply text-tertiary text-right;
                max-width: theme('customVariables.minicart.shippingTextMaxWidth');
            }
        }

        .minicart-summary {
            @apply py-3;
        }

        .minicart-total {
            @apply flex justify-between;

            .price {
                @apply flex text-r font-semibold leading-r text-tertiary;

                .minicart-total-price {
                    .price {
                        @apply text-m;
                    }
                }
            }
        }

        .minicart-buttons {
            @apply flex flex-col;

            .checkout-link {
                @apply flex w-full;

                &:hover {
                    @apply no-underline;
                }

                &.checkout {
                    @apply text-light;
                }

                &.cart {
                    @apply text-primary justify-center;
                    transition: 0.5s;

                    &:hover {
                        .base-button {
                            @apply text-tertiary;
                        }
                    }
                }

                .base-button {
                    @apply w-full;

                    .btn-text {
                        @apply m-auto;
                    }
                }
            }
        }
    }
}
</style>
