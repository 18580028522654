export const CORE_ERROR_DOMAIN = 'core';
export const PRODUCT_ERROR_DOMAIN = 'product';
export const PAYMENTS_ERROR_DOMAIN = 'payments';
export const SEARCH_ERROR_DOMAIN = 'search';
export const SEARCH_SERVICE_ERROR_DOMAIN = 'searchService';
export const CLIENT_IDENTITY_ERROR_DOMAIN = 'client-identity';
export const CHECKOUT_ERROR_DOMAIN = 'checkout';

export default [
    CORE_ERROR_DOMAIN,
    PRODUCT_ERROR_DOMAIN,
    PAYMENTS_ERROR_DOMAIN,
    SEARCH_ERROR_DOMAIN,
    SEARCH_SERVICE_ERROR_DOMAIN,
    CLIENT_IDENTITY_ERROR_DOMAIN,
    CHECKOUT_ERROR_DOMAIN,
];
