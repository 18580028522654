<template>
    <div v-if="content" v-html="content" />
</template>

<script>
export default {
    name: 'StaticBlock',

    props: {
        script: {
            type: String,
            default: '',
        },

        content: {
            type: String,
            required: true,
        },
    },

    mounted() {
        if (!this.script) {
            return;
        }

        // eslint-disable-next-line no-eval
        window.eval?.(this.script);
    },
};
</script>
