<template>
    <div class="breadcrumbs">
        <ul class="breadcrumb-list">
            <li class="breadcrumb">
                <BaseLink
                    :path="fullHomepagePath"
                    :label="HOMEPAGE_NAME"
                    :title="HOMEPAGE_NAME"
                    class="text-link"
                />
            </li>

            <li
                v-for="({ path, name }, index) in breadcrumbs"
                :key="name + index"
                class="breadcrumb"
            >
                <BaseLink
                    v-if="isBreadcrumbActive(path, index)"
                    :path="path"
                    :label="name"
                    :title="name"
                    class="text-link"
                    @click.native="sendAnalyticsEvent(name)"
                    @click.native.middle="sendAnalyticsEvent(name)"
                    @contextmenu.native="sendAnalyticsContextMenuEvent(path)"
                />
                <span v-else-if="!isDisabledLastElement" class="breadcrumb-name">
                    {{ name }}
                </span>
            </li>
        </ul>
        <ButtonWithIcon
            class="mobile-breadcrumb"
            :theme="THEME_PURE"
            :to="mobileBreadcrumbPath"
            @click.native="sendAnalyticsEvent(mobileBreadcrumbName)"
        >
            <template #left>
                <SvgIcon width="16px" height="16px" class="icon">
                    <ArrowLeft />
                </SvgIcon>
            </template>
            {{ mobileBreadcrumbName }}
        </ButtonWithIcon>
    </div>
</template>

<script>
import { createPagePath } from '@assets/path';

import { THEME_PURE } from '@types/Button';

import BreadcrumbsAnalytics from '@mixins/BreadcrumbsAnalytics';

import ArrowLeft from '@static/icons/16px/arrow1-left.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseLink from '@atoms/BaseLink/BaseLink';

import ButtonWithIcon from '@molecules/ButtonWithIcon/ButtonWithIcon';

export default {
    name: 'Breadcrumbs',

    components: {
        BaseLink,
        ButtonWithIcon,
        SvgIcon,
        ArrowLeft,
    },

    mixins: [BreadcrumbsAnalytics],

    props: {
        breadcrumbs: {
            type: Array,
            default: () => [],
        },

        isDisabledLastElement: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        fullHomepagePath() {
            return createPagePath('');
        },

        lastIndex() {
            return this.breadcrumbs.length - 1;
        },

        mobileBreadcrumb() {
            const lastIndex = this.isDisabledLastElement ? this.lastIndex : this.lastIndex - 1;

            return this.breadcrumbs[lastIndex] || null;
        },

        mobileBreadcrumbPath() {
            return this.mobileBreadcrumb?.path || this.fullHomepagePath;
        },

        mobileBreadcrumbName() {
            return this.mobileBreadcrumb?.name || this.HOMEPAGE_NAME;
        },
    },

    beforeCreate() {
        this.THEME_PURE = THEME_PURE;
        this.HOMEPAGE_NAME = this.$t('Home page');
    },

    methods: {
        isBreadcrumbActive(path, index) {
            if (this.isDisabledLastElement) {
                return !!path;
            }

            return path && index !== this.lastIndex;
        },
    },
};
</script>

<style lang="scss" scoped>
$mobile-height: 44px;
$icon-size: 15px;

.breadcrumbs {
    @apply w-full;

    .breadcrumb-list {
        @apply hidden;
    }

    .breadcrumb {
        @apply flex items-center leading-s text-s whitespace-nowrap;

        &:not(:first-of-type) {
            &::before {
                @apply block w-3 h-3 mx-1 font-semibold text-center;
                font-size: $icon-size;
                line-height: $icon-size;
                content: '\203A';
            }

            &:last-of-type {
                @apply text-gray2 min-w-0 flex;
            }
        }
    }

    .breadcrumb-name {
        @apply truncate block;
    }

    .mobile-breadcrumb {
        @apply px-0 justify-start text-gray1 font-book text-s leading-s;
        height: $mobile-height;
    }

    .icon {
        @apply mr-2;
    }

    @screen md {
        .breadcrumb-list {
            @apply flex;
        }

        .mobile-breadcrumb {
            @apply hidden;
        }

        .breadcrumb {
            @apply leading-r text-r;
        }
    }
}
</style>
