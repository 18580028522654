export const TYPE_BUTTON = 'button';
export const TYPE_SUBMIT = 'submit';
export const TYPE_RESET = 'reset';

export const THEME_PRIMARY = 'primary';
export const THEME_SECONDARY = 'secondary';
export const THEME_SECONDARY_DASHED = 'secondary-dashed';
export const THEME_TERTIARY = 'tertiary';
export const THEME_INITIAL = 'initial';
export const THEME_PURE = 'pure';
export const THEME_WHITE = 'white';
export const THEME_TRANSPARENT_WHITE = 'transparent-white';
export const THEME_TRANSPARENT_WHITE_DASHED = 'transparent-white-dashed';
export const THEME_THIN_DARK = 'thin-dark';
export const THEME_THIN_GRAY = 'thin-gray';
export const THEME_THIN_LIGHT = 'thin-light';
export const THEME_DARK = 'dark';
export const THEME_LIGHT = 'light';
export const THEME_TAG = 'tag';
export const THEME_EOB = 'eob';

export const SIZE_BIG = 'big';
export const SIZE_NORMAL = 'normal';
export const SIZE_SMALL = 'small';

export const THEMES_THIN = {
    THEME_THIN_DARK,
    THEME_THIN_GRAY,
    THEME_THIN_LIGHT,
};

export const TYPES = { TYPE_BUTTON, TYPE_SUBMIT, TYPE_RESET };

export const THEMES = {
    THEME_PRIMARY,
    THEME_SECONDARY,
    THEME_SECONDARY_DASHED,
    THEME_TERTIARY,
    THEME_INITIAL,
    THEME_PURE,
    THEME_WHITE,
    THEME_TRANSPARENT_WHITE,
    THEME_TRANSPARENT_WHITE_DASHED,
    THEME_DARK,
    THEME_LIGHT,
    THEME_THIN_DARK,
    THEME_THIN_GRAY,
    THEME_THIN_LIGHT,
    THEME_TAG,
    THEME_EOB,
};

export const SIZES = { SIZE_BIG, SIZE_NORMAL, SIZE_SMALL };
