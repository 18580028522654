import { QUERY_WORD_SEPARATOR } from '@search/configs/query-keys/filters';

export const normalizeQuery = (searchQuery = '') =>
    searchQuery
        .replace(/\s+|%20+|%+|&+|\*+|\?+|>+|<+|,+|\++/g, QUERY_WORD_SEPARATOR)
        .split(QUERY_WORD_SEPARATOR)
        .filter(value => value)
        .join(QUERY_WORD_SEPARATOR);

export const queryToPhrase = (searchQuery = '') => searchQuery.replace(/_/g, ' ').trim();
