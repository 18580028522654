import {
    CUSTOMER_PAGE,
    CUSTOMER_DETAILS_PAGE,
    CUSTOMER_ADDRESSES_PAGE,
    CUSTOMER_ACCOUNT_ORDERS_PAGE,
    CUSTOMER_ACCOUNT_AGREEMENTS_PAGE,
    CUSTOMER_ACCOUNT_RETURNS_PAGE,
    CUSTOMER_ACCOUNT_CARDS_PAGE,
} from '@router/paths';
import { RMA_PATH, RMA_RETURNS_PATH } from '@rma/routing/paths';

import {
    CUSTOMER_ACCOUNT_RETURNS_NAME,
    CUSTOMER_PAGE_NAME,
    CUSTOMER_DETAILS_NAME,
    CUSTOMER_ADDRESSES_NAME,
    CUSTOMER_ACCOUNT_ORDERS_NAME,
    CUSTOMER_ACCOUNT_AGREEMENTS_NAME,
    CUSTOMER_ACCOUNT_CARDS_NAME,
} from '@router/names';
import { RMA_LIST_NAME } from '@rma/routing/names';

import { Tiles, User, Settings, Pin, Package, Wallet } from '@modivo-ui/icons/v2/other';
import { Return } from '@modivo-ui/icons/v2/delivery';

const RMA_LIST_ACCOUNT_LINK_NAME = 'My returns';

export const RMA_LIST_ACCOUNT_LINK = {
    key: CUSTOMER_ACCOUNT_RETURNS_NAME,
    path: `/${CUSTOMER_PAGE}/${CUSTOMER_ACCOUNT_RETURNS_PAGE}`,
    name: RMA_LIST_ACCOUNT_LINK_NAME,
};

export const NEW_RMA_LIST_ACCOUNT_LINK = {
    key: RMA_LIST_NAME,
    path: `/${RMA_PATH}/${RMA_RETURNS_PATH}`,
    name: RMA_LIST_ACCOUNT_LINK_NAME,
    order: 5,
    icon: Return,
};

export const RMA_STATIC_PAGE_ACCOUNT_LINK = {
    key: 'returns-and-exchanges',
    path: 'returns-and-exchanges',
    name: RMA_LIST_ACCOUNT_LINK_NAME,
    useTranslatePath: true,
};

export const BASE_ACCOUNT_LINKS = [
    {
        key: CUSTOMER_PAGE_NAME,
        path: `/${CUSTOMER_PAGE}`,
        name: 'Customer account',
        highlight: false,
        order: 0,
        icon: Tiles,
    },
    {
        key: CUSTOMER_DETAILS_NAME,
        path: `/${CUSTOMER_PAGE}/${CUSTOMER_DETAILS_PAGE}`,
        name: 'My data',
        highlight: false,
        order: 1,
        icon: User,
    },
    {
        key: CUSTOMER_ADDRESSES_NAME,
        path: `/${CUSTOMER_PAGE}/${CUSTOMER_ADDRESSES_PAGE}`,
        name: 'My addresses',
        highlight: false,
        order: 2,
        icon: Pin,
    },
    {
        key: CUSTOMER_ACCOUNT_CARDS_NAME,
        path: `/${CUSTOMER_PAGE}/${CUSTOMER_ACCOUNT_CARDS_PAGE}`,
        name: 'My cards',
        highlight: false,
        order: 3,
        icon: Wallet,
    },
    {
        key: CUSTOMER_ACCOUNT_ORDERS_NAME,
        path: `/${CUSTOMER_PAGE}/${CUSTOMER_ACCOUNT_ORDERS_PAGE}`,
        name: 'My orders',
        highlight: false,
        order: 4,
        icon: Package,
    },
    {
        key: CUSTOMER_ACCOUNT_AGREEMENTS_NAME,
        path: `/${CUSTOMER_PAGE}/${CUSTOMER_ACCOUNT_AGREEMENTS_PAGE}`,
        name: 'Formal agreements',
        highlight: false,
        order: 6,
        icon: Settings,
    },
];
