var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-content",class:{
        _full: _vm.fullWidth,
        _fullonmobile: _vm.fullWidthOnMobile,
        _nogutters: _vm.noGutters,
        _nopaddings: _vm.noPaddings,
        _alignleft: _vm.alignLeft,
        _cmspadding: _vm.cmsPadding,
    }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }