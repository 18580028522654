import ConfigProvider from '@configs/x3/config-provider';

export default async ({ $config, nuxtState: stateProvidedByServer }, inject) => {
    const { brand } = $config;

    const {
        configs: { brandConfig, domainConfigs },
    } = stateProvidedByServer || {};

    const configProvider = new ConfigProvider(brand, brandConfig, $config, domainConfigs);

    inject('configProvider', configProvider);

    // backward compatibility
    inject('getLocaleConfigByKey', key => configProvider.getConfig(key));
};
