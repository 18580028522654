import { BADGE_EDGE_VARIANTS } from '@modivo-ui/components/BadgeEdge/v1';

export const TYPE_SPECIAL_ACTION = 'special-action';
export const TYPE_PROMO_ACTION = 'promo-action';

export const TYPE_NEW = 'nowosc';

export const TYPE_OUTLET = 'outlet';

export const TYPE_DISCOUNT = 'discount';
export const TYPE_OCCASION = 'occasion';
export const TYPE_HOT_DEAL = 'hot-deal';
export const TYPE_ONLY_HERE = 'only-here';
export const TYPE_ECO_FRIENDLY = 'eco-friendly';
export const TYPE_GOOD_PRICE = 'good-price';

export const TYPES_FOR_INTERNAL_OFFERS_ONLY = [
    TYPE_HOT_DEAL,
    TYPE_SPECIAL_ACTION,
    TYPE_PROMO_ACTION,
];

export const TYPES_ORDER = [
    TYPE_OUTLET,
    TYPE_HOT_DEAL,
    TYPE_SPECIAL_ACTION,
    TYPE_PROMO_ACTION,
    TYPE_DISCOUNT,
    TYPE_OCCASION,
    TYPE_NEW,
    TYPE_ECO_FRIENDLY,
    TYPE_ONLY_HERE,
];

export const DISCOUNT_TYPES = [TYPE_DISCOUNT, TYPE_OCCASION];

export const PRICING_DISCOUNT_LABEL_TYPE = 'discount';
export const PRICING_SPECIAL_OFFER_LABEL_TYPE = 'special_offer';
export const PRICING_GOOD_PRICE_LABEL_TYPE = 'good_price';

export const BADGE_EDGE_VARIANTS_MAP = {
    [TYPE_HOT_DEAL]: BADGE_EDGE_VARIANTS.BASIC,
    [TYPE_SPECIAL_ACTION]: BADGE_EDGE_VARIANTS.BASIC,
    [TYPE_PROMO_ACTION]: BADGE_EDGE_VARIANTS.BASIC,
    [TYPE_DISCOUNT]: BADGE_EDGE_VARIANTS.DISCOUNT,
    [TYPE_OCCASION]: BADGE_EDGE_VARIANTS.DISCOUNT,
    [TYPE_ECO_FRIENDLY]: BADGE_EDGE_VARIANTS.BASIC,
    [TYPE_ONLY_HERE]: BADGE_EDGE_VARIANTS.BASIC,
    [TYPE_NEW]: BADGE_EDGE_VARIANTS.NEW,
    [TYPE_OUTLET]: BADGE_EDGE_VARIANTS.OUTLET,
    [TYPE_GOOD_PRICE]: BADGE_EDGE_VARIANTS.BASIC,
};
