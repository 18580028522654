const TIME_COMPARE_RESOLUTION = 'minute';

const timeDiff = (time1, time2) => time1.valueOf() - time2.valueOf();

const isDateBetween = (start, end, current) => {
    return timeDiff(current, start) >= 0 && timeDiff(end, current) >= 0;
};

export const createDateHelper = () => {
    let luxon = null;
    let luxonPromise = null;

    return {
        async init() {
            if (luxon) {
                return;
            }

            if (!luxonPromise) {
                luxonPromise = import(/* webpackChunkName: "luxon" */ 'luxon');
            }

            luxon = await luxonPromise;
        },

        async createDateFromStringInTimezone(dateString, dateFormat, timezone) {
            await this.init();

            return luxon.DateTime.fromFormat(dateString, dateFormat, {
                zone: timezone,
            });
        },

        async createCurrentDateInTimezone(timezone) {
            await this.init();

            return luxon.DateTime.now().setZone(timezone);
        },

        isCurrentDateBetweenPassed(start, end, current) {
            return isDateBetween(start, end, current.startOf(TIME_COMPARE_RESOLUTION));
        },

        timeDiffCurrentToEnd(end, current) {
            return timeDiff(end, current.startOf(TIME_COMPARE_RESOLUTION));
        },

        async isValid(dateString, dateFormat) {
            await this.init();

            if (dateString === '') {
                return false;
            }

            return luxon.DateTime.fromFormat(dateString, dateFormat).isValid;
        },

        toISO(date) {
            return date.toISO({ suppressMilliseconds: true });
        },

        setTimezone(date, timezone) {
            return date.setZone(timezone);
        },
    };
};

export default (_, inject) => {
    inject('dateHelper', createDateHelper());
};
