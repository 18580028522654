import {
    ADDRESS_IS_ACTIVE,
    ADDRESS_TITLE,
    BILLING_ADDRESS_TYPE,
    BUY_AS,
    BUY_AS_COMPANY,
    BUY_AS_PRIVATE_PERSON,
    CITY,
    COMPANY,
    COUNTRY_CODE,
    CUSTOMER_ADDRESS_ACTIVE,
    CUSTOMER_ADDRESS_NOT_ACTIVE,
    CUSTOMER_ADDRESS_TYPE,
    DEFAULT_BILLING_ADDRESS,
    DEFAULT_SHIPPING_ADDRESS,
    FIRSTNAME,
    HOUSE_NUMBER,
    ID,
    LASTNAME,
    LOCAL_NUMBER,
    POSTCODE,
    SHIPPING_ADDRESS_TYPE,
    STREET,
    TELEPHONE,
    VAT_ID,
} from '@configs/mage-customer-attributes';
import { STORE_CONTEXT } from '@configs/context';

export const stringifyAddress = ({ city, zipCode, street1, street2 }) => {
    const streetParts = [street1, street2].filter(street => !!street);

    return [...streetParts, `${zipCode} ${city}`].join(', ');
};

export const mapAddressFromAvatar = (
    {
        phone,
        zipCode,
        city,
        street,
        houseNumber,
        isActive,
        isCompanyOrder,
        isBillingAddress,
        isDefaultBillingAddress,
        isDefaultDeliveryAddress,
        flatNumber,
        vatNumber,
        companyName,
        firstName,
        lastName,
        title,
        clientAddressId,
        ...rest
    },
    countryCode
) => ({
    ...rest,
    clientAddressId,
    [ID]: clientAddressId,
    [TELEPHONE]: phone,
    [POSTCODE]: zipCode,
    [CITY]: city,
    [STREET]: [street],
    [HOUSE_NUMBER]: houseNumber,
    [ADDRESS_IS_ACTIVE]: isActive ? CUSTOMER_ADDRESS_ACTIVE : CUSTOMER_ADDRESS_NOT_ACTIVE,
    [BUY_AS]: isCompanyOrder ? BUY_AS_COMPANY : BUY_AS_PRIVATE_PERSON,
    [CUSTOMER_ADDRESS_TYPE]: isBillingAddress ? BILLING_ADDRESS_TYPE : SHIPPING_ADDRESS_TYPE,
    [DEFAULT_BILLING_ADDRESS]: isDefaultBillingAddress,
    [DEFAULT_SHIPPING_ADDRESS]: isDefaultDeliveryAddress,
    [LOCAL_NUMBER]: flatNumber,
    [VAT_ID]: vatNumber,
    [COMPANY]: companyName,
    [FIRSTNAME]: firstName,
    [LASTNAME]: lastName,
    [ADDRESS_TITLE]: title,
    [COUNTRY_CODE]: countryCode,
});

export const mapAddressForAvatar = (
    {
        [HOUSE_NUMBER]: houseNumber,
        [ADDRESS_IS_ACTIVE]: addressIsActive,
        [LOCAL_NUMBER]: flatNumber,
        [ADDRESS_TITLE]: title,
        [BUY_AS]: buyAs,
        [CITY]: city,
        [COMPANY]: companyName,
        [CUSTOMER_ADDRESS_TYPE]: customerAddressType,
        [DEFAULT_BILLING_ADDRESS]: isDefaultBillingAddress = false,
        [DEFAULT_SHIPPING_ADDRESS]: isDefaultDeliveryAddress = false,
        [FIRSTNAME]: firstName,
        [LASTNAME]: lastName,
        [POSTCODE]: zipCode,
        [STREET]: street,
        [TELEPHONE]: phone,
        [VAT_ID]: vatNumber,
        clientAddressId = undefined,
    },
    websiteContext
) => ({
    isActive: CUSTOMER_ADDRESS_ACTIVE === addressIsActive,
    isCompanyOrder: BUY_AS_COMPANY === buyAs,
    isBillingAddress: BILLING_ADDRESS_TYPE === customerAddressType,
    isDeliveryAddress: SHIPPING_ADDRESS_TYPE === customerAddressType,
    storeContext: STORE_CONTEXT,
    title,
    city,
    street,
    websiteContext,
    phone,
    zipCode,
    houseNumber,
    isDefaultBillingAddress,
    isDefaultDeliveryAddress,
    flatNumber,
    vatNumber,
    companyName,
    firstName,
    lastName,
    clientAddressId,
});
