const { namespace } = require('./index');

function tProduct(translationKey, variables) {
    return this.t(`${namespace}.${translationKey}`, variables);
}

export default function ({ app }, inject) {
    app.i18n.tProduct = tProduct.bind(app.i18n);

    app.$tProduct = app.i18n.tProduct.bind(app.i18n);

    inject('tProduct', app.$tProduct);
}
