import { CUSTOMER_ACCOUNT_ORDERS_NAME, CUSTOMER_ACCOUNT_ORDER_VIEW_NAME } from '@router/names';

import { PRODUCT_PAGE_NAME } from '@product/routing/names';

export default ({ app, store }, inject) => {
    inject('absoluteUrl', path => {
        return `${store.state.config.baseUrl}${path}`;
    });

    inject('createPagePath', routePageConfig => {
        return routePageConfig;
    });

    inject('createProductPath', (urlKey, query = {}) => {
        const {
            route: { fullPath },
        } = app.router.resolve({
            name: PRODUCT_PAGE_NAME,
            params: {
                urlKey,
            },
            query,
        });

        return fullPath;
    });

    inject('createCustomerOrdersPath', () => {
        const {
            route: { path },
        } = app.router.resolve({
            name: CUSTOMER_ACCOUNT_ORDERS_NAME,
        });

        return path;
    });

    inject('createCustomerOrderViewPath', orderId => {
        const {
            route: { path },
        } = app.router.resolve({
            name: CUSTOMER_ACCOUNT_ORDER_VIEW_NAME,
            params: {
                orderId,
            },
        });

        return path;
    });
};
