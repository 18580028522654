import { formatPrice } from '@core-assets/price';

export const getLoyaltyClubDiscount = (statusesData, regularPrice, locale, currency) => {
    if (statusesData.length === 0) {
        return {};
    }

    return statusesData.reduce(
        (statuses, { code, discount_rate }) => ({
            ...statuses,
            [code]: formatPrice(parseFloat(regularPrice * (discount_rate / 100)), locale, currency),
        }),
        {}
    );
};

export const getLoyaltyClubPrice = (statusesData, regularPrice, locale, currency) => {
    if (statusesData.length === 0) {
        return {};
    }

    return statusesData.reduce((statuses, { code, discount_rate }) => {
        const price = parseFloat(regularPrice * (1 - discount_rate / 100));

        return {
            ...statuses,
            [code]: { amount: price, formatted: formatPrice(price, locale, currency) },
        };
    }, {});
};

export const getLoyaltyClubPoints = (finalPrice, pointPrice) => {
    return Math.floor(finalPrice / pointPrice);
};
