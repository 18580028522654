import mainMutations, {
    CMS_BLOCKS,
    IS_STRICT_PHONE_VALIDATION_ENABLED_FLAG,
    LOYALTY_CLUB_SETTINGS,
    LOYALTY_CLUB_STATUSES_DATA,
    MARKETING_BAR_CONFIG,
    NAVIGATION,
    PAGE_BUILDER_BLOCKS,
} from './mutations';

import mainActions from './actions';

import mainGetters from './getters';

export const state = () => ({
    isMobile: false,
    asyncModulesRegistered: [],
    isMobileEvaluated: false,
    isTabletOrDesktop: false,
    isTabletOrDesktopEvaluated: false,
    isDesktop: false,
    isDesktopEvaluated: false,
    dataLoaded: {
        [CMS_BLOCKS]: false,
        [PAGE_BUILDER_BLOCKS]: false,
        [IS_STRICT_PHONE_VALIDATION_ENABLED_FLAG]: false,
        [LOYALTY_CLUB_SETTINGS]: false,
        [LOYALTY_CLUB_STATUSES_DATA]: false,
        [NAVIGATION]: false,
        [MARKETING_BAR_CONFIG]: false,
    },
    isChatWidgetLoading: false,
    isChatWidgetLoaded: false,
    performance: [],
    routeRenderStart: {},
    stanleyPrefix: null,
    isPrintableContentOnly: false,
});

export const mutations = mainMutations;
export const actions = mainActions;
export const getters = mainGetters;
