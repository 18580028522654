<template>
    <ChatButton v-if="isChatWidgetAllowed" />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'ChatWrapper',

    components: {
        ChatButton: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "chat-button" */ '@molecules/ChatButton/ChatButton'
            ),
        }),
    },

    computed: {
        ...mapConfigGetters(['isZowieChatEnabled', 'zowieChatEnabledPages', 'isLiveChatEnabled']),

        isZowieChatAllowed() {
            return this.isZowieChatEnabled && this.zowieChatEnabledPages.includes(this.$route.name);
        },

        isChatWidgetAllowed() {
            return this.isLiveChatEnabled || this.isZowieChatAllowed;
        },
    },
};
</script>
