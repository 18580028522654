const ONLINE = 'online';
const COD = 'cod';

export const PAYMENT_TYPES = {
    ONLINE,
    COD,
};

export default {
    PAYMENT_TYPES,
};
