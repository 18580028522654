import { MEGATRON_CUSTOMER_GET_CUSTOMER_ORDER_EXT_DATA_OPERATION_NAME } from '@configs/megatron-client';

import normalizeCustomerOrderInfoExt from '@normalizers/models/customerOrderInfoExt';

const normalizer = response => {
    const {
        data: { customerOrderExt },
        error,
    } = response;

    if (error || !customerOrderExt) {
        return response;
    }

    response.data.customerOrderExt = normalizeCustomerOrderInfoExt(customerOrderExt);

    return response;
};

export const getCustomerOrderExtData = {
    operationName: MEGATRON_CUSTOMER_GET_CUSTOMER_ORDER_EXT_DATA_OPERATION_NAME,
    normalizer,
};
