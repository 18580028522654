export const types = {
    SET_STATUSES_DATA: 'SET_STATUSES_DATA',
    SET_LOYALTY_CLUB_SETTINGS: 'SET_LOYALTY_CLUB_SETTINGS',
};

export default {
    [types.SET_STATUSES_DATA](state, statusesData) {
        state.statusesData = statusesData;
    },
    [types.SET_LOYALTY_CLUB_SETTINGS](state, loyaltyClubSettings) {
        state.loyaltyClubSettings = loyaltyClubSettings;
    },
};
