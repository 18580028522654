export const hotDealAttributeModel = {
    label_color: {
        type: String,
    },
    label_text: {
        type: String,
    },
    label_text_color: {
        type: String,
    },
    kp_label_color: {
        type: String,
    },
    kp_label_text: {
        type: String,
    },
    kp_label_text_color: {
        type: String,
    },
    start_date: {
        type: Date,
        keepRaw: true,
    },
    end_date: {
        type: Date,
        keepRaw: true,
    },
    copy_code: {
        type: String,
        isOptional: true,
    },
    discount: {
        type: Number,
    },
};
