import { NAME as SEOMATIC_SERVICE_NAME } from '@async-services/seomatic/meta';
import { NAME as AD_TECH_SERVICE_NAME } from '@async-services/adTech/meta';
import { NAME as SEO_LINK_SERVICE_NAME } from '@async-services/seoLinkService/meta';

export default {
    [SEOMATIC_SERVICE_NAME]: () =>
        import(/* webpackChunkName: "async-service-seomatic" */ '@async-services/seomatic/service'),
    [AD_TECH_SERVICE_NAME]: () =>
        import(/* webpackChunkName: "async-service-ad-tech" */ '@async-services/adTech/service'),
    [SEO_LINK_SERVICE_NAME]: () =>
        import(
            /* webpackChunkName: "async-service-ad-tech" */ '@async-services/seoLinkService/service'
        ),
};
