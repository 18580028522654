import { getProducts } from '@normalizers/megatron/product-search/get-products';
import { getProduct } from '@normalizers/megatron/product-search/get-product';
import { getSponsoredProducts } from '@normalizers/megatron/product-search/get-sponsored-products';
import { getStores } from '@normalizers/megatron/product-search/get-stores';

import { getAutocomplete } from '@normalizers/megatron/search/get-autocomplete';
import { getProductsCatalog } from '@normalizers/megatron/search/get-products-catalog';
import { getSearch } from '@normalizers/megatron/search/get-search';
import { getFilters } from '@normalizers/megatron/search/get-filters';
import { getCatalogColorVariants } from '@normalizers/megatron/search/get-catalog-color-variants';

import { getProductReviews } from '@normalizers/megatron/reviews/get-product-reviews';

import { getCustomerOrderExtData } from '@normalizers/megatron/customer/get-customer-order-ext-data';

import { getSuccessPageData } from '@normalizers/megatron/checkout/get-success-page-data';
import { getRepaymentOrderDetails } from '@normalizers/megatron/checkout/get-repayment-order-details';

export const megatronNormalizersRegistry = [
    getAutocomplete,
    getStores,
    getProducts,
    getProductsCatalog,
    getCatalogColorVariants,
    getProduct,
    getSearch,
    getSponsoredProducts,
    getFilters,
    getProductReviews,
    getCustomerOrderExtData,
    getSuccessPageData,
    getRepaymentOrderDetails,
].reduce((config, { operationName, normalizer }) => {
    config[operationName] = normalizer;

    return config;
}, {});
