import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

export default megatronClient => ({
    async getStatusesData() {
        const { data } = await megatronClient.get('v1/statusesData', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
        });

        return data?.statusesWithDiscountRates || null;
    },
    async getLoyaltyClubSettings() {
        const { data } = await megatronClient.get('v1/loyaltyClubSettings', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
        });

        return data?.loyaltyClubSettings || null;
    },
});
