<template>
    <div class="footer-bottom-links">
        <ul
            v-for="(section, index) in menuSections"
            :key="`${section.sectionName}-${index}`"
            class="links-wrapper"
        >
            <FooterLinkBottom
                v-for="({ path, label, isEmitAction }, key) in section.links"
                :key="`${label}-${key}`"
                :path="path"
                :label="label"
                :is-emit-action="isEmitAction"
                class="footer-link"
            />
        </ul>
    </div>
</template>

<script>
import FooterLinkBottom from '@atoms/FooterLinkBottom/FooterLinkBottom';

export default {
    name: 'FooterBottomLinks',

    components: {
        FooterLinkBottom,
    },

    props: {
        menuSections: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-bottom-links {
    .links-wrapper {
        @apply flex items-center;
        @apply justify-center;
        @apply flex-wrap;
        @apply gap-y-ui-2;
    }

    .footer-link {
        @apply flex items-center;
        @apply font-ui-body-s;
        @apply whitespace-nowrap;
        @apply no-underline;

        &:not(:last-child) {
            &:after {
                @apply content-['·'] mx-ui-2;
            }
        }
    }

    @screen lg {
        .links-wrapper {
            @apply gap-y-ui-0;
        }
    }
}
</style>
