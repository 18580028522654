export const HTTP_CLIENT_ENDPOINT_NAME_AUTH = 'auth';
export const HTTP_CLIENT_ENDPOINT_NAME_AVATAR = 'avatar';
export const HTTP_CLIENT_ENDPOINT_NAME_ADS = 'ads';
export const HTTP_CLIENT_ENDPOINT_NAME_ADS_DATA = 'adsData';
export const HTTP_CLIENT_ENDPOINT_NAME_PARTNER = 'partner';
export const HTTP_CLIENT_ENDPOINT_NAME_SMAUG = 'smaug';
export const HTTP_CLIENT_ENDPOINT_NAME_SEARCH_SERVICE = 'searchService';
export const HTTP_CLIENT_ENDPOINT_NAME_SEOMATIC = 'seomatic';
export const HTTP_CLIENT_ENDPOINT_NAME_SEO_LINK_SERVICE = 'seoLinksService';
export const HTTP_CLIENT_ENDPOINT_NAME_SEARCH = 'search';
