import { STORAGE_TYPE_SESSION } from '@configs/storage';

export const TIME_TO_SHOW_TOOLTIP_WITH_LOGIN = 20000;

export const isShowLimitExceeded = ({ storage, limit, isShownStorageName, counterStorageName }) => {
    const isShownInSession = storage.getItem(isShownStorageName, STORAGE_TYPE_SESSION);

    const counter = storage.getItem(counterStorageName);

    return !!isShownInSession || counter >= limit;
};

export const setTooltipDataInStorage = ({ storage, isShownStorageName, counterStorageName }) => {
    const newsletterTooltipCounter = storage.getItem(counterStorageName);

    storage.setItem(counterStorageName, newsletterTooltipCounter + 1);

    storage.setItem(isShownStorageName, true, null, STORAGE_TYPE_SESSION);
};
