import consentsMutations from './mutations';
import consentsActions from './actions';

export const state = () => ({
    categories: [],
    providers: [],
    isModalOpen: false,
    isTrustedPartnersModalOpen: false,
    isModalCookieSettingsOpen: false,
    areActiveConsentsEmpty: false,
    isConsentsModalConfigured: false,
});

export const mutations = consentsMutations;
export const actions = consentsActions;
