<template>
    <div class="mobile-app-links">
        <a
            v-for="({ path, logo, company }, index) in apps"
            :key="`${company}-${index}`"
            :href="path"
            class="logo"
            target="_blank"
        >
            <Icon :icon="logo" width="130" height="40" />
        </a>
    </div>
</template>

<script>
import GooglePlay from '@static/pwa/static/download-app/google-play.svg?inline';
import AppStore from '@static/pwa/static/download-app/app-store.svg?inline';
import AppGallery from '@static/pwa/static/download-app/app-gallery.svg?inline';

import { Icon } from '@modivo-ui/components/Icon/v1';

export default {
    name: 'MobileAppLinks',

    components: {
        Icon,
    },

    props: {
        playUrl: {
            type: String,
            default: '',
        },

        appStoreUrl: {
            type: String,
            default: '',
        },

        appGalleryUrl: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isAndroid: false,
            isIOS: false,
        };
    },

    computed: {
        apps() {
            const { isAndroid, checkIfSystemActive } = this;

            return [
                {
                    logo: GooglePlay,
                    company: this.$t('Google Play'),
                    path: this.playUrl,
                    isActive: checkIfSystemActive(isAndroid),
                },
                {
                    logo: AppStore,
                    company: this.$t('App Store'),
                    path: this.appStoreUrl,
                    isActive: checkIfSystemActive(this.isIOS),
                },
                {
                    logo: AppGallery,
                    company: this.$t('AppGallery'),
                    path: this.appGalleryUrl,
                    isActive: checkIfSystemActive(isAndroid),
                },
            ].filter(({ isActive, logo }) => isActive && !!logo);
        },
    },

    mounted() {
        this.isAndroid = this.$ua?.isAndroid;
        this.isIOS = this.$ua?.isIOS;
    },

    methods: {
        checkIfSystemActive(system) {
            return !this.isAndroid && !this.isIOS ? true : system;
        },
    },
};
</script>

<style lang="scss" scoped>
.mobile-app-links {
    .logo {
        @apply block;
    }
}
</style>
