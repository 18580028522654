import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

export default megatronClient => ({
    async getData(cmsBlocksIds) {
        const { data } = await megatronClient.get('v1/cmsBlocks', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                cmsBlocksIds,
            },
        });

        return data?.cmsBlocks?.items || [];
    },

    async getPageBuilderData(cmsBlocksIds) {
        const { data } = await megatronClient.get('v1/pageBuilderBlocks', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                cmsBlocksIds,
            },
        });

        return data?.pageBuilderBlocks?.items || [];
    },
});
