import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

export default megatronClient => ({
    async getStoreData(cmsBlocksIds, pageBuilderBlocksIds, mainCategoryId) {
        const { data } = await megatronClient.get('v1/storeData', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                cmsBlocksIds,
                pageBuilderBlocksIds,
                mainCategoryId,
            },
        });

        return data || {};
    },
});
