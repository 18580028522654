<template>
    <div class="step-progress-bar">
        <ul class="steps">
            <li
                v-for="(step, index) in steps"
                :key="`step-${index}`"
                :class="[
                    'step',
                    {
                        active: isActive(index),
                        valid: isValid(index),
                    },
                ]"
            >
                <span v-if="isValid(index)" class="circle icon">
                    <SvgIcon width="24px" height="24px" class="icon-svg">
                        <Check2 />
                    </SvgIcon>
                </span>
                <span v-else class="circle counter">{{ index + 1 }}</span>
                <div class="label">
                    <BaseLink
                        v-if="step.path"
                        :path="step.path"
                        :label="step.name"
                        :title="step.name"
                        class="link"
                    />
                    <span v-else>{{ step.name }}</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import Check2 from '@static/icons/16px/check2.svg?inline';
import BaseLink from '@atoms/BaseLink/BaseLink';

export default {
    name: 'StepProgressBar',
    components: {
        SvgIcon,
        Check2,
        BaseLink,
    },
    props: {
        steps: {
            type: Array,
            required: true,
        },
        currentStep: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        isValid(index) {
            return index < this.currentStep - 1;
        },
        isActive(index) {
            return index === this.currentStep - 1;
        },
    },
};
</script>

<style lang="scss" scoped>
.step-progress-bar {
    @apply mt-3 w-full;

    .icon-svg {
        @apply text-light;
    }

    .steps {
        @apply flex flex-row flex-nowrap flex-auto;

        .step {
            @apply relative flex flex-col flex-1 items-center;

            &::after {
                @apply block absolute w-full bg-gray4 h-1p z-hidden top-18 -right-1/2;
                content: '';
            }

            &:last-of-type {
                &::after {
                    @apply hidden h-0;
                }
            }

            .circle {
                @apply flex flex-row justify-center items-center w-5 h-5;
                border-radius: 100%;
            }

            .label {
                @apply flex flex-row text-xs text-gray3 mt-2 text-center;

                .link {
                    @apply text-xs mt-0 text-gray3;
                }
            }

            .counter {
                @apply bg-light text-gray3 text-center border-1
                    border-gray3 leading-l shadow-16 font-semibold;
            }

            &.valid {
                .icon {
                    @apply bg-primary border-0 text-light shadow-16 font-semibold;
                }
            }

            &.active {
                .label {
                    @apply font-semibold text-gray1;
                }

                .counter {
                    @apply bg-primary border-0 text-light shadow-16 font-semibold;
                }
            }
        }
    }
}
</style>
