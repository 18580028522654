import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

export default megatronClient => ({
    async getNavCategories(mainCategoryId) {
        const { data } = await megatronClient.get('v1/navigationCategories', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                mainCategoryId,
            },
        });

        return data?.categoryList || [];
    },
});
