import { AB_TEST } from '@analytics-types/Events';

import { getCookiesWithSearchAbtest } from './get-cookies-with-search-abtest';

export const sendAnalyticsForSearchAbtest = ({ $cookies, $abTests, $analytics }) => {
    getCookiesWithSearchAbtest($cookies).forEach(cookieName => {
        const abTestData = {
            testName: cookieName,
            variantName: $abTests.getVariant(cookieName) || '',
        };

        if (abTestData.variantName) {
            $analytics.emit(AB_TEST, abTestData);
        }
    });
};
