import MediaQueryDisplayModeWatcher from '@assets/media-query-display-mode-watcher';

import { CORE_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

export default ({ app, store }) => {
    try {
        MediaQueryDisplayModeWatcher(displayMode =>
            store.dispatch('pwa/setDisplayMode', displayMode)
        );
    } catch (err) {
        app.$errorHandler.captureDomainError(CORE_ERROR_DOMAIN, err, {
            [ERROR_ACTION_TAG_NAME]: 'plugin.display-mode-watcher.client',
        });
    }
};
