import { LOCAL_WISHLIST_KEY, LOCAL_WISHLIST_PRICES_KEY } from '@configs/storage';

export default $storage => ({
    get() {
        return $storage.getItem(LOCAL_WISHLIST_KEY) || [];
    },

    set(wishlist = []) {
        return $storage.setItem(LOCAL_WISHLIST_KEY, wishlist);
    },

    clear() {
        $storage.removeItem(LOCAL_WISHLIST_PRICES_KEY);

        return $storage.removeItem(LOCAL_WISHLIST_KEY);
    },

    addWishlistPrice({ sku, price } = {}) {
        if (!sku || !price) {
            return;
        }

        const allPrices = this.getWishlistPrices();

        $storage.setItem(LOCAL_WISHLIST_PRICES_KEY, {
            ...allPrices,
            [sku]: price,
        });
    },

    getWishlistPrices() {
        return $storage.getItem(LOCAL_WISHLIST_PRICES_KEY) || {};
    },

    removeWishlistPriceBySku(sku) {
        if (!sku) {
            return;
        }

        const prices = this.getWishlistPrices();

        delete prices[sku];

        $storage.setItem(LOCAL_WISHLIST_PRICES_KEY, prices);
    },
});
