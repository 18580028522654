export const SAFE_SIZE_LENGTH = 'flatlenght_safesize';
export const SAFE_SIZE_MAX_SIZE = 'maximum_shoe_size';

export const MANUFACTURER_TABLE_ATTRIBUTE_KEY = 'tabela_producent';
export const MANUFACTURER_TABLE_VALUE_KEY = 'tabela_rozmiarow';
export const MANUFACTURER_TABLE_SIZE = 'rozmiar';
export const MANUFACTURER_TABLE_INSOLE_LENGTH = 'dlugosc_wkladki_wewnatrz_buta_cm';
export const MANUFACTURER_TABLE_MAX_FOOT_LENGTH = 'max_dlugosc_stopy_kupujacego_cm';

export const CATEGORY_FILTER_ATTRIBUTE_KEY = 'kategorie_filtr_b';
export const DIMENSIONS = 'wymiary';
export const PRODUCT_CARD_SIZE_ATTRIBUTE = 'rozmiar_karta_produktu';
export const MAPPED_SIZE_ATTRIBUTE = 'rozmiar_filtr';
export const DIMENSION_PREFIX = 'wymiar_';

export const GLOVE_LENGTH = 'dlugosc_rekawiczki_cm_variant';
export const GLOVE_WIDTH = 'szerokosc_rekawiczki_cm_variant';
export const MIDDLE_FINGER_LENGTH = 'dlugosc_palca_srodkowego_variant';
export const SCARF_HEIGHT = 'wysokosc_szala_variant';
export const BELT_LENGTH = 'calkowita_dlugosc_paska_variant';
export const SCARF_LENGTH = 'calkowita_dlugosc_szala_variant';
export const FIRST_HOLE_LENGTH = 'dlugosc_do_pierwszej_dziurki_variant';
export const LAST_HOLE_LENGTH = 'dlugosc_do_ostatniej_dziurki_variant';

export const SAFE_SIZE_TABLE_KEYS = [SAFE_SIZE_LENGTH, SAFE_SIZE_MAX_SIZE];
export const MANUAL_TABLE_KEYS = [
    GLOVE_LENGTH,
    GLOVE_WIDTH,
    MIDDLE_FINGER_LENGTH,
    SCARF_HEIGHT,
    BELT_LENGTH,
    SCARF_LENGTH,
    FIRST_HOLE_LENGTH,
    LAST_HOLE_LENGTH,
];

export const MANUFACTURER_TABLE_ATTRIBUTES_KEYS = [
    MANUFACTURER_TABLE_MAX_FOOT_LENGTH,
    MANUFACTURER_TABLE_INSOLE_LENGTH,
];

export const SAFE_SIZE_TABLE_TYPE = 'SAFE_SIZE_TABLE_TYPE';
export const MANUAL_TABLE_TYPE = 'MANUAL_TABLE_TYPE';
export const MANUFACTURER_TABLE_TYPE = 'MANUFACTURER_TABLE_TYPE';
export const NO_TABLE_TYPE = 'NO_TABLE_TYPE';

export const SIZE_TABLE_TYPES = [
    SAFE_SIZE_TABLE_TYPE,
    MANUAL_TABLE_TYPE,
    MANUFACTURER_TABLE_TYPE,
    NO_TABLE_TYPE,
];

export const MANUFACTURER_TABLE_LABELS = {
    [MANUFACTURER_TABLE_MAX_FOOT_LENGTH]: 'Max. foot length',
    [MANUFACTURER_TABLE_INSOLE_LENGTH]: 'Insole length',
};
