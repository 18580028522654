import { PWA_INSTALLED_KEY } from '@configs/storage';

import { PWA_BANNER_INSTALLED, PWA_BANNER_UNINSTALLED } from '@analytics-types/Events';
import { APP_INSTALLED_EVENT_NAME, USER_CHOICE_ACCEPTED } from '@types/PWA';

export default class PWAEventsHandler {
    constructor(store, $storage, $analytics) {
        this.store = store;
        this.$storage = $storage;
        this.$analytics = $analytics;

        this.beforeInstallPromptEventWatcher = this.store.watch(
            state => state.pwa.beforeInstallPromptEvent,
            event => {
                if (event) {
                    this.beforeInstallPromptHandler();
                    this.beforeInstallPromptEventWatcher?.();
                }
            },
            {
                immediate: true,
            }
        );

        this.appInstalledHandlerWrapper = this.appInstalledHandler.bind(this);

        window.addEventListener(APP_INSTALLED_EVENT_NAME, this.appInstalledHandlerWrapper);
    }

    beforeInstallPromptHandler() {
        const wasPWAInstalledBefore = !!this.$storage.getItem(PWA_INSTALLED_KEY);

        if (wasPWAInstalledBefore) {
            this.$storage.removeItem(PWA_INSTALLED_KEY);
            this.$analytics.emit(PWA_BANNER_UNINSTALLED);
        }
    }

    appInstalledHandler() {
        this.removeListeners();

        this.$analytics.emit(PWA_BANNER_INSTALLED, {
            userChoice: USER_CHOICE_ACCEPTED,
        });
        this.$storage.setItem(PWA_INSTALLED_KEY, 1);
        this.store.dispatch('pwa/setIsInstallationPending', false);
        this.store.dispatch('pwa/setWasInstallationSuccessful', true);
    }

    removeListeners() {
        window.removeEventListener(APP_INSTALLED_EVENT_NAME, this.appInstalledHandlerWrapper);
    }
}
