import mutations from './mutations';
import actions from './actions';

const state = () => ({
    base64: '',
    searchId: '',
    fileName: '',
});

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
