import { MEGATRON_SEARCH_GET_CATALOG_COLOR_VARIANTS_OPERATION_NAME } from '@configs/megatron-client';

import normalizeProduct from '@normalizers/models/catalogProduct';

const normalizer = async (response, config, helpers) => {
    const { data, error } = response;

    if (error || !data?.items?.length) {
        return response;
    }

    response.data.items = await Promise.all(
        data.items.map(product =>
            normalizeProduct({ ...product, attributes: data.attributes }, helpers, config)
        )
    );

    return response;
};

export const getCatalogColorVariants = {
    operationName: MEGATRON_SEARCH_GET_CATALOG_COLOR_VARIANTS_OPERATION_NAME,
    normalizer,
};
