import SEARCH_SERVICES_NAMESPACE from '@search/async-services/namespace';

export const NAME = `${SEARCH_SERVICES_NAMESPACE}/attributes`;

export const GET_ATTRIBUTE_AGGREGATION = 'GET_ATTRIBUTE_AGGREGATION';

export const ASYNC_ATTRIBUTES_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        GET_ATTRIBUTE_AGGREGATION,
    },
};
