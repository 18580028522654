// @TODO: remove polyfill after https://modivo.atlassian.net/browse/CORE-2839
const isSupported = typeof Array.prototype.at !== 'undefined';

if (!isSupported) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.at = function (index) {
        const relativeIndex = Number(index) || 0;

        const trueIndex = relativeIndex >= 0 ? relativeIndex : this.length + relativeIndex;

        if (trueIndex < 0 || trueIndex > this.length) {
            return undefined;
        }

        return this[trueIndex];
    };
}
