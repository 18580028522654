import ShippingMethod from '@models/ShippingMethod/ShippingMethod';

export default ({ store_orders, ...customerOrderRest }) => ({
    ...customerOrderRest,
    sellerOrders: store_orders.map(sellerOrder => ({
        ...sellerOrder,
        selected_shipping_method: new ShippingMethod(
            sellerOrder.selected_shipping_method
        ).getModel(),
    })),
});
