<template>
    <div ref="lazyImagesContainer" class="with-lazy-images">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'WithLazyImages',
    props: {
        imgAttrSelector: {
            type: String,
            required: true,
        },
        imgSrcAttr: {
            type: String,
            required: true,
        },
        loadCondition: {
            type: Boolean,
            required: true,
        },
    },

    watch: {
        loadCondition(condition) {
            if (condition) {
                this.loadImages();
            }
        },
    },

    mounted() {
        if (this.loadCondition) {
            this.loadImages();
        }
    },

    methods: {
        loadImages() {
            const innerImages = this.getInnerImageElements();

            innerImages.forEach(image => {
                const srcToLoad = image.getAttribute(this.imgSrcAttr);

                if (srcToLoad) {
                    const dummyImg = document.createElement('img');

                    dummyImg.onload = () => {
                        image.src = srcToLoad;
                        image.removeAttribute(this.imgSrcAttr);
                    };

                    dummyImg.src = srcToLoad;
                }
            });
        },
        getInnerImageElements() {
            return Array.prototype.slice.call(
                this.$refs.lazyImagesContainer.querySelectorAll(`[${this.imgAttrSelector}]`)
            );
        },
    },
};
</script>
