export const TYPE_CUSTOM = 'custom';

export const WEB_PDP_1 = 'web-pdp-1';
export const WEB_PDP_2 = 'web-pdp-2';
export const WEB_PDP_UNAVBL_1 = 'web-pdp-unavbl-1';
export const WEB_PRECART_1 = 'web-precart-1';
export const WEB_CART_1 = 'web-cart-1';
export const WEB_PDP_SIMILAR = 'web-pdp-similar';
export const WEB_PDP_PRODUCT_NOTIFICATION = 'web-pdp-product-notification';
export const WEB_CATEGORY_1 = 'web-category-1';
export const WEB_SEARCH_RESULTS_1 = 'web-search-results-1';
export const WEB_ERROR_PAGE_1 = 'web-error-page-1';
export const WEB_EMPTY_CART_1 = 'web-empty-cart-1';

export const WEB_CART_SPONSORED = 'web-cart-sponsored';
export const WEB_HOMEPAGE_SPONSORED = 'web-homepage-sponsored';
export const WEB_PDP_SPONSORED = 'web-pdp-sponsored';

export const SYNERISE_RECOMMENDATION_TYPES = {
    TYPE_CUSTOM,
};

export const SYNERISE_RECOMMENDATION_PLACEMENTS = {
    WEB_PDP_1,
    WEB_PDP_2,
    WEB_PDP_UNAVBL_1,
    WEB_CART_1,
    WEB_PRECART_1,
    WEB_PDP_SIMILAR,
    WEB_PDP_PRODUCT_NOTIFICATION,
    WEB_CATEGORY_1,
    WEB_SEARCH_RESULTS_1,
    WEB_ERROR_PAGE_1,
    WEB_EMPTY_CART_1,
};

export const SYNERISE_RECOMMENDATION_PRODUCT_PLACEMENTS = [WEB_PDP_UNAVBL_1, WEB_PRECART_1];
export const SYNERISE_RECOMMENDATION_CART_PLACEMENTS = [WEB_CART_1];
export const SYNERISE_RECOMMENDATION_CATEGORY_PLACEMENTS = [WEB_CATEGORY_1];
export const SYNERISE_RECOMMENDATION_SEARCH_RESULTS_PLACEMENTS = [WEB_SEARCH_RESULTS_1];
export const SYNERISE_RECOMMENDATION_ERROR_PAGE_PLACEMENTS = [WEB_ERROR_PAGE_1];

export const SYNERISE_RECOMMENDATION_TYPES_WITH_NO_UUID = [];

export const SYNERISE_RECOMMENDATION_TYPES_WITH_PRODUCT_SKU = [
    WEB_PDP_1,
    WEB_PDP_2,
    WEB_PDP_UNAVBL_1,
    WEB_CART_1,
    WEB_PRECART_1,
    WEB_PDP_SIMILAR,
    WEB_PDP_PRODUCT_NOTIFICATION,
];
