import {
    AB_TEST_COOKIE_VALUE_SEPARATOR,
    AB_TEST_COOKIE_VARIANT_SEPARATOR,
    AB_TEST_SEARCH_COOKIE_SUFFIX,
} from '@search/assets/ab-tests';

import { getAbtestValueFromCookies } from './get-abtest-value-from-cookies';

export const getCookiesWithSearchAbtest = $cookies => {
    if (!$cookies) {
        return [];
    }

    const abTestsCookieValue = getAbtestValueFromCookies($cookies);

    if (!abTestsCookieValue) {
        return [];
    }

    return abTestsCookieValue.split(AB_TEST_COOKIE_VALUE_SEPARATOR).reduce((acc, part) => {
        const variant = part.split(AB_TEST_COOKIE_VARIANT_SEPARATOR)[0];

        if (variant.endsWith(AB_TEST_SEARCH_COOKIE_SUFFIX)) {
            acc.push(variant);
        }

        return acc;
    }, []);
};
