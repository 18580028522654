import avatarMutations from './mutations';
import avatarActions from './actions';
import avatarGetters from './getters';

export const state = () => ({
    isConnectingClient: false,
    clientAddresses: [],
    accountVerificationStatus: null,
});

export const mutations = avatarMutations;
export const actions = avatarActions;
export const getters = avatarGetters;
