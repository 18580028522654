const { namespace } = require('./index');

function tSearch(searchModuleTranslation, variables) {
    return this.t(`${namespace}.${searchModuleTranslation}`, variables);
}

export default function ({ app }, inject) {
    app.i18n.tSearch = tSearch.bind(app.i18n);

    app.$tSearch = app.i18n.tSearch.bind(app.i18n);

    inject('tSearch', app.$tSearch);
}
