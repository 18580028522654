<template>
    <div class="messages">
        <BaseAlertRow
            v-for="message in messages"
            :id="message.id"
            :key="`alert-new-${message.id}`"
            :type="message.type"
            :action-handler="message.action.handler"
            :action-url="message.action.url"
            :action-text="message.action.label"
            class="messages-alert"
        >
            {{ message.text }}
        </BaseAlertRow>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BaseAlertRow from '@atoms/BaseAlertRow/BaseAlertRow';

const { mapState: mapStateMessages, mapActions: mapActionsMessages } = createNamespacedHelpers(
    'messages'
);

export default {
    name: 'Messages',
    components: {
        BaseAlertRow,
    },
    computed: {
        ...mapStateMessages(['messages']),
    },
    methods: {
        ...mapActionsMessages(['removeMessage']),
    },
};
</script>

<style lang="scss" scoped>
.messages {
    @apply fixed w-full z-10 left-0 text-center bottom-0;
}

.messages-alert {
    @apply mt-3;
}

@screen lg {
    .messages {
        @apply flex items-center flex-col w-auto left-1/2 bottom-0 mb-5;
        transform: translateX(-50%);
    }

    .messages-alert {
        @apply shrink-0;
        min-width: theme('customVariables.messages.minWidthDesktop');
    }
}
</style>
