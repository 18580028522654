import { PRODUCT_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { PSB_SYNERISE_CONFIG_ID } from '@configs/synerise';

import { ERROR_ACTION_TAG_NAME, ERROR_STORE_MODULE_TAG_NAME } from '@types/Errors';

import { types } from './mutations';

const getPageBuilderBlockProps = pageBuilderBlock => {
    const content = JSON.parse(pageBuilderBlock.content_json)[0];

    return content?.slots.default[0].props || {};
};

const MODULE_NAME = 'synerise';

const actions = {
    async loadSyneriseConfig({ rootGetters, commit }) {
        const configBlock = rootGetters['cmsBlock/staticBlockById'](PSB_SYNERISE_CONFIG_ID);

        if (!configBlock?.content_json) {
            return false;
        }

        try {
            const syneriseConfig = getPageBuilderBlockProps(configBlock);

            const { apiKey, trackerKey, trackerDomain, scriptUrl, ...placements } = syneriseConfig;

            const POSSIBLE_PREFIXES = ['Mod', 'Eob'];

            Object.entries(placements).forEach(([key, value]) => {
                POSSIBLE_PREFIXES.forEach(prefix => {
                    if (new RegExp(`web${prefix}.*`).test(key) && value) {
                        const keyWithoutBrandPrefix = key.split(prefix).join('');

                        if (placements[keyWithoutBrandPrefix]) {
                            return;
                        }

                        placements[keyWithoutBrandPrefix] = value;
                    }
                });
            });

            const mappedPrefixedPlacements = Object.fromEntries(
                Object.entries(placements).filter(([key]) =>
                    POSSIBLE_PREFIXES.every(prefix => !new RegExp(`web${prefix}.*`).test(key))
                )
            );

            const placementsMapped = Object.fromEntries(
                Object.entries(mappedPrefixedPlacements).map(([key, value]) => {
                    const keyMapped = key
                        .split('')
                        .map(letter => {
                            if (letter === letter.toUpperCase() || letter.match(/[0-9]/)) {
                                return `-${letter.toLowerCase()}`;
                            }

                            return letter;
                        })
                        .join('');

                    return [keyMapped, value];
                })
            );

            commit(types.SET_SYNERISE_CONFIG, {
                apiKey,
                trackerKey,
                trackerDomain,
                scriptUrl,
                ...placementsMapped,
            });

            return true;
        } catch (err) {
            this.app.$errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'loadSyneriseConfig',
                    [ERROR_STORE_MODULE_TAG_NAME]: MODULE_NAME,
                },
                { configBlock }
            );

            return false;
        }
    },

    setSyneriseConfig({ rootState }) {
        const { syneriseConfig = {} } = rootState.synerise;

        const { apiKey, trackerKey, trackerDomain, scriptUrl, ...placements } = syneriseConfig;

        this.app.$services.recommendations.setApiKey(apiKey);
        this.app.$services.recommendations.setPlacements(placements);
    },

    setSyneriseLoaded({ commit }, isLoaded) {
        commit(types.SET_SYNERISE_LOADED, isLoaded);
    },
};

export default actions;
