import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5687d992 = () => interopDefault(import('../modules/search/src/pages/Brands.vue' /* webpackChunkName: "pages/brands" */))
const _07cb14a6 = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "pages/contact" */))
const _c30480ba = () => interopDefault(import('../modules/merchant/src/pages/partner/MerchantPartner.vue' /* webpackChunkName: "pages/merchant/partner" */))
const _4dcff81e = () => interopDefault(import('../modules/merchant/src/pages/partner/MerchantPartnerInfo.vue' /* webpackChunkName: "pages/merchant/partner/info" */))
const _b31f13d2 = () => interopDefault(import('../modules/merchant/src/pages/partner/MerchantPartnerReturnForm.vue' /* webpackChunkName: "pages/merchant/partner/return" */))
const _eeec135a = () => interopDefault(import('../pages/CheckoutRepayment.vue' /* webpackChunkName: "pages/checkout-repayment" */))
const _29f935d2 = () => interopDefault(import('../modules/merchant/src/pages/PartnerCatalog.vue' /* webpackChunkName: "pages/merchant/partner/products" */))
const _65f7a83a = () => interopDefault(import('../pages/CheckoutSuccess.vue' /* webpackChunkName: "pages/checkout-success" */))
const _55723e10 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "pages/reset-password" */))
const _024713ba = () => interopDefault(import('../pages/Newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _1d4161d8 = () => interopDefault(import('../pages/SuccessAddReturn.vue' /* webpackChunkName: "pages/success-add-return" */))
const _21753dfa = () => interopDefault(import('../pages/FailedAddReturn.vue' /* webpackChunkName: "pages/failed-add-return" */))
const _4c3d510d = () => interopDefault(import('../pages/CheckoutAccount.vue' /* webpackChunkName: "pages/checkout/account" */))
const _cd5e0704 = () => interopDefault(import('../pages/Preferences.vue' /* webpackChunkName: "pages/Preferences" */))
const _552d25ba = () => interopDefault(import('../pages/PackageTracking.vue' /* webpackChunkName: "pages/package-tracking" */))
const _32b5025b = () => interopDefault(import('../pages/LoginAndRegister.vue' /* webpackChunkName: "pages/LoginAndRegister" */))
const _0a0a5758 = () => interopDefault(import('../pages/Order.vue' /* webpackChunkName: "pages/order" */))
const _2e3edff0 = () => interopDefault(import('../pages/CheckoutCart.vue' /* webpackChunkName: "pages/checkout/cart" */))
const _fdbe4762 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/cms" */))
const _7082a9f6 = () => interopDefault(import('../pages/NotSupported.vue' /* webpackChunkName: "pages/NotSupported" */))
const _3d9decd0 = () => interopDefault(import('../pages/MobileTokenExpired.vue' /* webpackChunkName: "pages/MobileTokenExpired" */))
const _1bde35a2 = () => interopDefault(import('../pages/LoyaltyClub.vue' /* webpackChunkName: "pages/loyalty-club" */))
const _36a47f56 = () => interopDefault(import('../pages/Livestream.vue' /* webpackChunkName: "pages/livestream" */))
const _a735bc92 = () => interopDefault(import('../pages/AddReturn.vue' /* webpackChunkName: "pages/add-return" */))
const _4d906924 = () => interopDefault(import('../modules/search/src/pages/Search.vue' /* webpackChunkName: "pages/search" */))
const _347d7cc3 = () => interopDefault(import('../modules/search/src/pages/Catalog.vue' /* webpackChunkName: "pages/category" */))
const _d4b67ea2 = () => interopDefault(import('../pages/WebLinks.vue' /* webpackChunkName: "pages/WebLinks" */))
const _18918e62 = () => interopDefault(import('../modules/product/src/pages/Product.vue' /* webpackChunkName: "pages/product" */))
const _018946a0 = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _d32524a2 = () => interopDefault(import('../pages/Wishlist.vue' /* webpackChunkName: "pages/wishlist" */))
const _4492f970 = () => interopDefault(import('../pages/Customer.vue' /* webpackChunkName: "pages/customer" */))
const _0bc3d78b = () => interopDefault(import('../pages/customer/Index.vue' /* webpackChunkName: "pages/customer/index" */))
const _c032b68a = () => interopDefault(import('../pages/customer/Details.vue' /* webpackChunkName: "pages/customer/details" */))
const _3bd1c9db = () => interopDefault(import('../pages/customer/Addresses.vue' /* webpackChunkName: "pages/customer/addresses" */))
const _35bc0182 = () => interopDefault(import('../pages/customer/orders/Index.vue' /* webpackChunkName: "pages/customer/orders" */))
const _508e2f1f = () => interopDefault(import('../pages/customer/cards/Index.vue' /* webpackChunkName: "pages/customer/cards" */))
const _62b0f6b6 = () => interopDefault(import('../pages/customer/orders/OrderId.vue' /* webpackChunkName: "pages/customer/orders/orderId" */))
const _c0d00580 = () => interopDefault(import('../pages/customer/Agreements.vue' /* webpackChunkName: "pages/customer/agreements" */))
const _7cfc9988 = () => interopDefault(import('../pages/customer/Returns.vue' /* webpackChunkName: "pages/customer/returns" */))
const _dafa7272 = () => interopDefault(import('../pages/customer/Return.vue' /* webpackChunkName: "pages/customer/return" */))
const _098c104c = () => interopDefault(import('../pages/customer/SuccessReturn.vue' /* webpackChunkName: "pages/customer/success-return" */))
const _446b53a4 = () => interopDefault(import('../pages/customer/FailedReturn.vue' /* webpackChunkName: "pages/customer/failed-return" */))
const _57faf87e = () => interopDefault(import('../pages/MainCategory.vue' /* webpackChunkName: "pages/main-category" */))
const _7e513656 = () => interopDefault(import('../pages/Live.vue' /* webpackChunkName: "pages/live" */))
const _7570a250 = () => interopDefault(import('../modules/rma/src/pages/Rma.vue' /* webpackChunkName: "pages/rma" */))
const _52a3caa2 = () => interopDefault(import('../modules/rma/src/pages/RmaProcess.vue' /* webpackChunkName: "pages/rma/process" */))
const _33c659cc = () => interopDefault(import('../modules/rma/src/pages/process/RmaCreate.vue' /* webpackChunkName: "pages/rma/create" */))
const _7310db83 = () => interopDefault(import('../modules/rma/src/pages/RmaReturns.vue' /* webpackChunkName: "pages/rma/returns" */))
const _74092342 = () => interopDefault(import('../modules/rma/src/pages/returns/RmaList.vue' /* webpackChunkName: "pages/rma/list" */))
const _8d26ed64 = () => interopDefault(import('../modules/rma/src/pages/returns/RmaDetails.vue' /* webpackChunkName: "pages/rma/details" */))
const _5bd156bc = () => interopDefault(import('../pages/SmsUnsubscribe.vue' /* webpackChunkName: "pages/sms-unsubscribe" */))
const _581337e1 = () => interopDefault(import('../modules/page-builder/PageBuilder.vue' /* webpackChunkName: "pages/page-builder" */))
const _0ce04b58 = () => interopDefault(import('../pages/Homepage.vue' /* webpackChunkName: "pages/homepage" */))
const _02718e88 = () => interopDefault(import('../modules/redirector/NotFound.vue' /* webpackChunkName: "pages/not-found" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/:brands(marki|brands|marci|znacky|markak|markes|marken|marques|prekiu_zenklai|marche|brandovi|brendi|brandid|zimoli|znamke)/:category?",
    component: _5687d992,
    name: "brands"
  }, {
    path: "/:contact(kontakt|contact|kapcsolat|kontakti|epikoinonia|kontaktai|contatti)",
    component: _07cb14a6,
    name: "contact"
  }, {
    path: "/:partner(partner|synergati)/:partnerSlug",
    component: _c30480ba,
    children: [{
      path: "/",
      component: _4dcff81e,
      name: "partner/info/merchant"
    }, {
      path: ":return(zwrot|reso|vratenie|epistrofes)",
      component: _b31f13d2,
      name: "partner/return/merchant"
    }]
  }, {
    path: "/checkout/repayment/:orderHash",
    component: _eeec135a,
    name: "checkout-repayment"
  }, {
    path: "/partner/:partnerId/products",
    component: _29f935d2,
    name: "partner/products"
  }, {
    path: "/checkout/success/:urlKey?",
    component: _65f7a83a,
    name: "checkout-success"
  }, {
    path: "/reset-password/:state",
    component: _55723e10,
    name: "reset-password"
  }, {
    path: "/newsletter/:urlKey",
    component: _024713ba,
    name: "newsletter"
  }, {
    path: "/success-add-return",
    component: _1d4161d8,
    name: "success-add-return"
  }, {
    path: "/failed-add-return",
    component: _21753dfa,
    name: "failed-add-return"
  }, {
    path: "/checkout/account",
    component: _4c3d510d,
    name: "checkout-account"
  }, {
    path: "/preferences-form",
    component: _cd5e0704,
    name: "preferences-form"
  }, {
    path: "/package-tracking",
    component: _552d25ba,
    name: "package-tracking"
  }, {
    path: "/forgot-password",
    component: _32b5025b,
    name: "forgot-password"
  }, {
    path: "/order/:urlKey?",
    component: _0a0a5758,
    name: "order"
  }, {
    path: "/checkout/cart",
    component: _2e3edff0,
    name: "checkout-cart"
  }, {
    path: "/b/:identifier",
    component: _fdbe4762,
    name: "cms"
  }, {
    path: "/not-supported",
    component: _7082a9f6,
    name: "not-supported"
  }, {
    path: "/token-expired",
    component: _3d9decd0,
    name: "token-expired"
  }, {
    path: "/fashion-club",
    component: _1bde35a2,
    name: "fashion-club"
  }, {
    path: "/supervision",
    component: _36a47f56,
    name: "livestream"
  }, {
    path: "/add-return",
    component: _a735bc92,
    name: "add-return"
  }, {
    path: "/s/:url(.*)",
    component: _4d906924,
    name: "search"
  }, {
    path: "/c/:url(.*)",
    component: _347d7cc3,
    name: "category"
  }, {
    path: "/web-links",
    component: _d4b67ea2,
    name: "web-links"
  }, {
    path: "/p/:urlKey",
    component: _18918e62,
    name: "product"
  }, {
    path: "/checkout",
    component: _018946a0,
    name: "checkout"
  }, {
    path: "/register",
    component: _32b5025b,
    name: "register"
  }, {
    path: "/wishlist",
    component: _d32524a2,
    name: "wishlist"
  }, {
    path: "/customer",
    component: _4492f970,
    children: [{
      path: "/",
      component: _0bc3d78b,
      name: "customer"
    }, {
      path: "details",
      component: _c032b68a,
      name: "customer/details"
    }, {
      path: "addresses",
      component: _3bd1c9db,
      name: "customer/addresses"
    }, {
      path: "orders",
      component: _35bc0182,
      name: "customer/orders"
    }, {
      path: "cards",
      component: _508e2f1f,
      name: "customer/cards"
    }, {
      path: "orders/:orderId",
      component: _62b0f6b6,
      name: "customer/orders/order-view"
    }, {
      path: "agreements",
      component: _c0d00580,
      name: "customer/agreements"
    }, {
      path: "returns",
      component: _7cfc9988,
      name: "customer/returns"
    }, {
      path: "return",
      component: _dafa7272,
      name: "customer/add-return"
    }, {
      path: "success-return",
      component: _098c104c,
      name: "customer/success-return"
    }, {
      path: "failed-return",
      component: _446b53a4,
      name: "customer/failed-return"
    }]
  }, {
    path: "/m/:slug",
    component: _57faf87e,
    name: "main-category"
  }, {
    path: "/login",
    component: _32b5025b,
    name: "login"
  }, {
    path: "/live",
    component: _7e513656,
    name: "live"
  }, {
    path: "/rma",
    component: _7570a250,
    children: [{
      path: "",
      component: _52a3caa2,
      children: [{
        path: "create/:orderNumber?",
        component: _33c659cc,
        name: "rma/create"
      }]
    }, {
      path: "returns",
      component: _7310db83,
      children: [{
        path: "",
        component: _74092342,
        name: "rma/list"
      }, {
        path: ":returnUuid",
        component: _8d26ed64,
        name: "rma/details"
      }]
    }]
  }, {
    path: "/us",
    component: _5bd156bc,
    name: "sms-unsubscribe"
  }, {
    path: "/pb",
    component: _581337e1,
    name: "pb"
  }, {
    path: "/",
    component: _0ce04b58,
    name: "homepage"
  }, {
    path: "*",
    component: _02718e88,
    name: "not-found"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
