export const getStaticFileUrlWithHash = (
    staticHashParameterName,
    staticPath,
    staticFoldersHashes,
    precacheVersion
) => {
    return (filePath, folder) => {
        const version = `?${staticHashParameterName}=${precacheVersion}__${
            staticFoldersHashes[folder] || ''
        }`;

        return `${staticPath}/${filePath}${version}`;
    };
};
