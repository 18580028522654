<template>
    <div class="main">
        <ContainerContent v-if="isWebViewMobile" :cms-padding="true">
            <DefaultLayout />
        </ContainerContent>
        <CmsLayout v-else />
    </div>
</template>

<script>
import BaseLayout from '@layouts/mixins/BaseLayout';
import WithCustomer from '@layouts/mixins/WithCustomer';
import CmsLayout from '@layouts/cms';
import DefaultLayout from '@layouts/default';
import WebView from '@mixins/WebView';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'ContactLayout',

    components: {
        ContainerContent,
        CmsLayout,
        DefaultLayout,
    },

    mixins: [BaseLayout, WithCustomer, WebView],
};
</script>
