import {
    AB_TEST_COOKIE_VARIANT_SEPARATOR,
    AB_TEST_SEARCH_COOKIE_SUFFIX,
    X_SEARCH_ABTEST_HEADER_NAME,
} from '@search/assets/ab-tests';

export const getSearchAbtestHeader = $abTests => {
    if (!$abTests) {
        return {};
    }

    const { abTests, query } = $abTests;
    const allTests = { ...abTests, ...query };

    const sbeTests = Object.entries(allTests).filter(([key]) => {
        return key.endsWith(AB_TEST_SEARCH_COOKIE_SUFFIX);
    });

    if (!sbeTests.length) {
        return {};
    }

    return {
        [X_SEARCH_ABTEST_HEADER_NAME]: sbeTests
            .join('|')
            .replaceAll(',', AB_TEST_COOKIE_VARIANT_SEPARATOR),
    };
};
