<script>
import {
    FOOTER_DOWNLOAD_APP,
    FOOTER_DOWNLOAD_APP_GALLERY,
    SUCCESS_PAGE_DOWNLOAD_APP,
    SUCCESS_PAGE_DOWNLOAD_APP_GALLERY,
} from '@localeConfig/keys';

export default {
    name: 'MobileAppsLinksProvider',

    render() {
        return this.$scopedSlots.default({
            footerAppUrl: this.$configProvider.getConfig(FOOTER_DOWNLOAD_APP),
            footerAppGalleryUrl: this.$configProvider.getConfig(FOOTER_DOWNLOAD_APP_GALLERY),
            successPageAppUrl: this.$configProvider.getConfig(SUCCESS_PAGE_DOWNLOAD_APP),
            successPageAppGalleryUrl: this.$configProvider.getConfig(
                SUCCESS_PAGE_DOWNLOAD_APP_GALLERY
            ),
        });
    },
};
</script>
