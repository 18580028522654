import { CATALOG_PAGE } from '@search/routing/paths';

import { createPagePath } from '@assets/path';

export default class Navigation {
    constructor(navigationItem) {
        const { children, removeCmsBanners } = navigationItem;

        this.navigationItem = navigationItem;
        this.children = children;
        this.removeCmsBanners = removeCmsBanners;
    }

    getNormalizedChildren() {
        return this.children.map(child => ({
            hasChildren: child.children?.length > 0,
            path: Navigation.getCategoryPath(child),
            name: child.display_name,
            content: this.getNormalizedContent(child.children),
            specialTextColor: Navigation.getSpecialTextColor(child),
            cmsBlock: this.removeCmsBanners ? {} : child.cms_block,
            id: child.id,
            withDisclosures: this.navigationItem.withDisclosures,
        }));
    }

    getNormalizedContent(categories) {
        return categories.map(category => {
            const categoryLinks = this.getNavigationLinks(category);

            if (category.pim) {
                categoryLinks.sort((a, b) => a.name.localeCompare(b.name));
            }

            return {
                id: category.id,
                title: category.display_name,
                categoryLinks,
                showAll: Navigation.getShowAll(category) || null,
            };
        });
    }

    getNavigationLinks(category) {
        if (category.children.length === 1 && category.children[0].hasChildren) {
            return this.getNavigationLinks(category.children[0]);
        }

        return category.children.reduce((acc, curr) => {
            if (curr.display_name && curr.children?.length === 0) {
                acc.push({
                    name: curr.display_name,
                    path: Navigation.getCategoryPath(curr),
                });
            }

            if (curr.children?.length) {
                curr.children.forEach(child =>
                    acc.push({
                        name: child.display_name,
                        path: Navigation.getCategoryPath(child),
                    })
                );
            }

            return acc;
        }, []);
    }

    static getShowAll(category) {
        const first = category.children[0];

        if (category.children.length === 1 && (!!first.custom_url_path || !!first.pim?.slug)) {
            return {
                name: first.display_name,
                path: Navigation.getCategoryPath(first),
            };
        }

        const showAll = category.children.find(
            child => !!child.children.length && (child.custom_url_path || child.pim?.slug)
        );

        if (!showAll) {
            return;
        }

        return {
            name: showAll.display_name,
            path: Navigation.getCategoryPath(showAll),
        };
    }

    static getSpecialTextColor(category) {
        return {
            ...(category.special_text_color ? { color: category.special_text_color } : undefined),
        };
    }

    static getCategoryPath(category) {
        const { custom_url_path, pim } = category;

        if (custom_url_path) {
            return custom_url_path;
        }

        const { slug = null } = pim || {};

        return slug ? createPagePath(slug, CATALOG_PAGE) : '';
    }

    static getLimitedContent(content, hasCmsBlocks = false) {
        let fullColumns = 0;

        const columnsLimit = hasCmsBlocks ? 3 : 5;

        const limitedContent = content.slice(0, columnsLimit);

        limitedContent.forEach(section => {
            section.categoryLinks = section.categoryLinks.filter(({ path }) => path);

            const limitedLinks = section.categoryLinks.slice(0, 20);

            if (fullColumns + Math.ceil(limitedLinks.length / 10) <= columnsLimit) {
                section.categoryLinks = limitedLinks;
                fullColumns += limitedLinks.length > 10 ? 2 : 1;
            } else if (fullColumns + 1 <= columnsLimit) {
                section.categoryLinks = limitedLinks.slice(0, 10);
            } else {
                section.categoryLinks = [];
            }
        });

        return limitedContent.filter(section => !!section.categoryLinks.length);
    }

    getLimitedChildren() {
        const children = this.getNormalizedChildren();

        children.forEach(child => {
            if (child.content.length) {
                const hasCmsBlocks = !!child.cmsBlock?.content;

                child.content = Navigation.getLimitedContent(child.content, hasCmsBlocks);
            }
        });

        return children;
    }

    normalize() {
        return {
            ...this.navigationItem,
            children: this.children?.length ? this.getLimitedChildren() : [],
            hasChildren: !!this.children?.length,
        };
    }
}
