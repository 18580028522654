export default {
    getErrorCodes(
        graphQLError,
        onlyFirstError = true,
        withErrorCategory = false,
        fromTryCatchStatement = true
    ) {
        const emptyValue = onlyFirstError ? null : [];

        try {
            const graphQLErrors = fromTryCatchStatement
                ? graphQLError.graphQLErrors || []
                : graphQLError;

            if (graphQLErrors.length) {
                if (onlyFirstError) {
                    return graphQLErrors[0].extensions.code;
                }

                return graphQLErrors.map(err => {
                    if (withErrorCategory) {
                        const { code, category } = err.extensions;

                        return {
                            code,
                            category: category || '',
                        };
                    }

                    return err.extensions.code;
                });
            }
        } catch (err) {
            return emptyValue;
        }

        return emptyValue;
    },
    getErrorMessages(graphQLError, onlyFirstError = true) {
        const emptyValue = onlyFirstError ? null : [];

        try {
            const { graphQLErrors = [] } = graphQLError;

            if (graphQLErrors.length) {
                return onlyFirstError
                    ? graphQLErrors[0].message
                    : graphQLErrors.map(({ message }) => message);
            }
        } catch (err) {
            return emptyValue;
        }

        return emptyValue;
    },
};
