import MERCHANT_SERVICES_NAMESPACE from '@merchant/async-services/namespace';

export const NAME = `${MERCHANT_SERVICES_NAMESPACE}/partner`;

export const GET_PARTNER_DATA = 'GET_PARTNER_DATA';
export const GET_PARTNER_SLUG = 'GET_PARTNER_SLUG';
export const GET_PARTNER_NAME = 'GET_PARTNER_NAME';

export const ASYNC_PARTNER_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        GET_PARTNER_DATA,
        GET_PARTNER_SLUG,
        GET_PARTNER_NAME,
    },
};
