import {
    DEFAULT_DATE_FORMAT,
    FORMAT_DDMMYYYY_DOT,
    FORMAT_DDMM_DOT,
    FORMAT_DDMMYYYY_SLASH,
} from '@types/DateFormat';

export const HOUR_IN_MILISECONDS = 3600 * 1000;
export const MINUTE_IN_MILISECONDS = 60 * 1000;
export const DAY_IN_MILISECONDS = 24 * HOUR_IN_MILISECONDS;
export const FRIDAY_DAY_NUMBER = 5;
export const MONDAY_DAY_NUMBER = 1;

const separatorMap = {
    [FORMAT_DDMMYYYY_DOT]: '.',
    [FORMAT_DDMM_DOT]: '.',
    [FORMAT_DDMMYYYY_SLASH]: '/',
    default: '-',
};

export const formatDate = (
    date,
    format = DEFAULT_DATE_FORMAT,
    reversed = false,
    exactTime = false
) => {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    const separator = separatorMap[format] || separatorMap.default;

    const dataToJoin = [day, month];

    if (format !== FORMAT_DDMM_DOT) {
        dataToJoin.push(date.getFullYear());
    }

    if (reversed) {
        dataToJoin.reverse();
    }

    let formattedDate = dataToJoin.join(separator);

    if (exactTime) {
        const hour = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        const hourMinutesSeconds = `${hour}:${minutes}:${seconds}`;

        formattedDate += ` ${hourMinutesSeconds}`;
    }

    return formattedDate;
};

export const normalizeDateString = dateToNormalize => {
    if (typeof dateToNormalize !== 'string') {
        return dateToNormalize;
    }

    return dateToNormalize
        .replace(/-/g, '/')
        .replace(/T/g, ' ')
        .replace(/(\+[0-9]{2}:[0-9]{2}$|(\.0{3})?Z$)/g, '');
};

export const formatDateFromString = (
    dateString,
    format = DEFAULT_DATE_FORMAT,
    reversed = false,
    exactTime = false
) => formatDate(new Date(normalizeDateString(dateString)), format, reversed, exactTime);

export const getNextWorkingDays = (date, workingDaysNumberToGet = 2) => {
    const today = date.setHours(0, 0, 0, 0);
    const todayTime = new Date(today).getTime();

    const workingDays = [];
    let nextDay = new Date(todayTime + DAY_IN_MILISECONDS);

    while (workingDays.length < workingDaysNumberToGet) {
        const dayNumber = nextDay.getDay();

        // working days check
        if (dayNumber >= MONDAY_DAY_NUMBER && dayNumber <= FRIDAY_DAY_NUMBER) {
            workingDays.push(nextDay);
        }

        nextDay = new Date(nextDay.getTime() + DAY_IN_MILISECONDS);
    }

    return workingDays;
};

export const startDateLocaleString = (startDate, formatLocale) => {
    const digitFormat = '2-digit';

    const time = startDate.toLocaleTimeString(formatLocale, {
        hour: digitFormat,
        minute: digitFormat,
    });

    const date = startDate.toLocaleDateString(formatLocale);

    return {
        time,
        date,
    };
};

export const isNotExpired = date => {
    const today = Date.now();

    return date >= today;
};

export const getTodayFormattedDate = () => {
    return formatDate(new Date());
};

export const getFormattedtDateInXDays = days => {
    const daysInMilliseconds = days * DAY_IN_MILISECONDS;

    return formatDate(new Date(new Date().getTime() + daysInMilliseconds));
};

export const addHours = (date, hours) => {
    return new Date(date.getTime() + hours * HOUR_IN_MILISECONDS);
};

export const getDatesBetween = (start, end) => {
    const dates = [];
    let currentDate = start;

    while (currentDate <= end) {
        dates.push(currentDate);
        currentDate = addHours(currentDate, 24);
    }

    return dates;
};
