<template>
    <button :type="type" class="button-icon" v-bind="$attrs" v-on="$listeners">
        <slot />
    </button>
</template>

<script>
export default {
    name: 'ButtonIcon',

    props: {
        /**
         * Type of <button /> element.
         */
        type: {
            type: String,
            required: false,
            default: 'button',
        },
    },
};
</script>

<style lang="scss" scoped>
.button-icon {
    @apply flex justify-center items-center no-underline outline-none cursor-pointer bg-transparent;
    @apply rounded-8;

    &:focus,
    &:hover {
        &:deep() .svg-icon {
            stroke: theme('colors.primary');
        }
    }

    &:active {
        @apply bg-light text-dark;

        &:deep() .svg-icon {
            stroke: theme('colors.primary');
        }

        &:focus,
        &:hover {
            @apply bg-light text-primary;

            &:deep() .svg-icon {
                stroke: theme('colors.primary');
            }
        }
    }

    &:focus {
        outline: none;
    }

    &[disabled] {
        @apply pointer-events-none opacity-40;
    }
}
</style>
