import {
    BRAND_FILTER_CODE,
    BRAND_GROUP_CODE,
    OCCASION_OPTION_CODE,
    OCCASION_FILTER_CODE,
    HOT_DEAL_ACTION_CODE,
    NEW_OPTION_CODE,
    PREMIUM_OPTION_CODE,
    PRODUCT_BADGE_FILTER_CODE,
    SPECIAL_ACTION_CODE,
    TOGGLE,
} from '@search/types/Filters';

import { isFilterActive } from '@search/assets/filters';
import { isObject } from '@assets/object';

export default function normalizeFilters(filters, { locale, storeViewTimezone }) {
    if (!isObject(filters) || filters === null) {
        return {};
    }

    const { [SPECIAL_ACTION_CODE]: specialAction, [HOT_DEAL_ACTION_CODE]: hotDeal } = filters || {};

    [specialAction, hotDeal].forEach(filter => {
        if (!filter) {
            return;
        }

        const filterOptions = (filter.options || [])
            .filter(({ values: { start_date: startDate, end_date: endDate } = {} }) => {
                if (!startDate || !endDate) {
                    return;
                }

                return isFilterActive(storeViewTimezone, startDate[locale], endDate[locale]);
            })
            .map(option => {
                const {
                    label,
                    values: { label_text: labelText },
                } = option;

                if (!labelText) {
                    return option;
                }

                return {
                    ...option,
                    label: labelText[locale] || label,
                };
            });

        if (filterOptions.length) {
            filters[filter.code] = {
                ...filter,
                options: filterOptions,
            };
        } else {
            delete filters[filter.code];
        }
    });

    const productBadgeFilter = Object.values(filters).find(
        ({ code }) => code === PRODUCT_BADGE_FILTER_CODE
    );

    const newOption = productBadgeFilter?.options.find(({ code }) => code === NEW_OPTION_CODE);

    if (newOption) {
        filters[PRODUCT_BADGE_FILTER_CODE] = {
            ...productBadgeFilter,
            options: [newOption],
            selected: newOption.selected,
        };
    } else {
        filters[PRODUCT_BADGE_FILTER_CODE] = {
            ...productBadgeFilter,
            options: [],
            selected: false,
        };
    }

    const premiumOption = productBadgeFilter?.options.find(
        ({ code }) => code === PREMIUM_OPTION_CODE
    );

    let premiumFilter;

    if (premiumOption) {
        premiumFilter = {
            url: productBadgeFilter.url,
            label: productBadgeFilter.label,
            type: TOGGLE,
            code: PREMIUM_OPTION_CODE,
            option: premiumOption,
            selected: premiumOption.selected,
        };
    }

    const occasionOption = productBadgeFilter?.options.find(
        ({ code }) => code === OCCASION_OPTION_CODE
    );

    if (occasionOption) {
        filters[OCCASION_FILTER_CODE] = {
            url: productBadgeFilter.url,
            label: productBadgeFilter.label,
            type: TOGGLE,
            code: OCCASION_FILTER_CODE,
            option: occasionOption,
            selected: occasionOption.selected,
        };
    }

    const brandFilter = filters[BRAND_FILTER_CODE];
    const brandGroupFilters = [premiumFilter, brandFilter].filter(Boolean);

    return Object.fromEntries(
        Object.entries(filters).map(([key, value]) => {
            if (key === BRAND_FILTER_CODE) {
                return [
                    BRAND_GROUP_CODE,
                    {
                        filters: brandGroupFilters,
                        type: 'group',
                        code: BRAND_GROUP_CODE,
                        label: brandFilter.label,
                    },
                ];
            }

            return [key, value];
        })
    );
}
