<script>
import LoaderGif from '@assets/img/loader.gif';

export default {
    name: 'Loader',
    props: {
        height: {
            type: String,
            default: '100px',
        },
    },
    render(h) {
        return h(
            'div',
            {
                class: {
                    wrapper: true,
                },
                style: {
                    height: this.height,
                },
            },
            [
                h('img', {
                    attrs: {
                        // eslint-disable-next-line max-len
                        class: 'loader',
                        alt: '',
                        src: LoaderGif,
                    },
                }),
            ]
        );
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    @apply relative w-full;
}

.loader {
    @apply absolute top-1/2 left-1/2 max-w-full h-auto;
    transform: translateX(-50%) translateY(-50%);
}
</style>
