import catalogServiceConfig from './catalog/config';
import attributesServiceConfig from './attributes/config';

export default function ({ $asyncServices }) {
    [catalogServiceConfig, attributesServiceConfig].forEach(
        ({ name: serviceName, load: serviceLoadFn }) => {
            $asyncServices.registerService(serviceName, serviceLoadFn);
        }
    );
}
