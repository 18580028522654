<template>
    <div>
        <div class="viewport-content" :class="{ 'single-input-view': isSingleInputAuthTest }">
            <template v-if="!isWebViewMobile">
                <HeaderSimpleWrapper class="header-wrapper" />
            </template>
            <main>
                <nuxt />
            </main>
        </div>
        <Messages />
        <BaseFooter v-if="!isWebViewMobile" />
        <ChatWrapper />
        <GlobalModals :is-authorization-layout="true" />
        <SmartBannerWrapper />
    </div>
</template>

<script>
import { isSingleInputAuthTestOn } from '@configs/abtest-single-input-auth';

import WebView from '@mixins/WebView';
import BaseLayout from '@layouts/mixins/BaseLayout';
import WithCustomer from '@layouts/mixins/WithCustomer';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import HeaderSimpleWrapper from '@molecules/HeaderSimpleWrapper/HeaderSimpleWrapper';
import Messages from '@molecules/Messages/Messages';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import BaseFooter from '@organisms/BaseFooter/BaseFooter';
import GlobalModals from '@organisms/GlobalModals/GlobalModals';

export default {
    name: 'AuthorizationLayout',

    components: {
        SmartBannerWrapper,
        ChatWrapper,
        HeaderSimpleWrapper,
        Messages,
        BaseFooter,
        GlobalModals,
    },

    mixins: [BaseLayout, WithCustomer, WebView],

    computed: {
        isSingleInputAuthTest() {
            return isSingleInputAuthTestOn(this.$abTests);
        },
    },
};
</script>

<style scoped lang="scss">
.viewport-content {
    @apply min-h-screen flex flex-col;

    &.single-input-view {
        .header-wrapper {
            &:deep(.right-wrapper) {
                @apply hidden;
            }

            &:deep(.wrapper) {
                @apply items-start;
            }
        }
    }
}
</style>
