import { snakeCaseToCamelCase } from '@assets/string-utility';

export const isObject = obj =>
    typeof obj === 'object' && obj !== null && !Array.isArray(obj) && !(obj instanceof RegExp);

export const mapObjectKeys = (object, mapFunction) =>
    Object.fromEntries(
        Object.entries(object).map(([key, value]) => {
            return [mapFunction(key), isObject(value) ? mapObjectKeys(value, mapFunction) : value];
        })
    );

export const snakeCaseToCamelCaseObjectKeys = object => mapObjectKeys(object, snakeCaseToCamelCase);

export const areObjectsEqual = (object1, object2) => {
    const object1Keys = Object.keys(object1);
    const object2Keys = Object.keys(object2);

    if (object1Keys.length !== object2Keys.length) {
        return false;
    }

    const keys = Array.from(new Set([...object1Keys, ...object2Keys]));

    return keys.every(key => {
        const newValue = object1[key];
        const oldValue = object2[key];

        return (
            typeof newValue !== 'undefined' &&
            typeof oldValue !== 'undefined' &&
            newValue === oldValue
        );
    });
};

export function isEmptyObject(obj) {
    return (
        isObject(obj) &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
    );
}

export const setNestedProperty = (obj, [first, ...rest], value) => ({
    ...(obj || {}),
    [first]: rest.length ? setNestedProperty(obj?.[first], rest, value) : value,
});
