import Exception from '@exceptions/Exception';

export default class CmsPageException extends Exception {
    name = 'CmsPageException';

    constructor(identifier, error) {
        super(`Getting CMS page with identifier ${identifier} error.`);

        this.identifier = identifier;
        this.error = error;
    }
}
