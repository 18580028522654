import { GATEWAY_PAYU_CARD_CODE } from '@configs/checkout-payment-methods';

function normalizeAdditionalInfo(methodCode, additionalInfo) {
    if (!additionalInfo || !Object.keys(additionalInfo)?.length) {
        return null;
    }

    const { __typename: omit, ...data } = additionalInfo;

    if (methodCode === GATEWAY_PAYU_CARD_CODE) {
        const { sdkUrl = '', posId = '', embeddedFormEnabled = false } =
            additionalInfo?.payUGatewayCard || {};

        return { sdkUrl, posId, embeddedFormEnabled };
    }

    return data;
}

export function normalizePadPaymentMethod(method) {
    const {
        id = '',
        code = '',
        title = '',
        description = '',
        type = '',
        disallowedForCompany = false,
        disallowedForPrivatePerson = false,
        additionalInfo = null,
    } = method;

    return {
        id,
        code,
        title,
        description,
        type,
        disallowedForCompany,
        disallowedForPrivatePerson,
        additionalInfo: normalizeAdditionalInfo(code, additionalInfo),
    };
}

function normalizeDeliveryMethodOption(option) {
    const {
        id = '',
        name = '',
        description = '',
        logo = '',
        fee = 0,
        oldFee = 0,
        ruleDescription = '',
        ruleLink = '',
        ruleLinkTitle = '',
    } = option;

    return {
        id,
        name,
        description,
        logo,
        fee,
        oldFee,
        ruleDescription,
        ruleLink,
        ruleLinkTitle,
    };
}

function normalizeDeliveryMethod(method) {
    const {
        code = '',
        moduleMap = false,
        id = '',
        logo = '',
        description = '',
        name = '',
        displayName = '',
        options = [],
    } = method;

    const methodCode = code || name;

    return {
        id,
        isDefaultPickupPointMethod: moduleMap,
        logoUrl: logo,
        description,
        title: displayName,
        carrierCode: methodCode,
        methodCode,
        shippingOptions: options.map(normalizeDeliveryMethodOption),
    };
}

function normalizePadOption(padOption) {
    const {
        deliveryMethodId = '',
        paymentMethodId = '',
        price = 0,
        maximumAmountOfProducts = null,
    } = padOption;

    return {
        deliveryMethodId,
        paymentMethodId,
        price,
        maximumAmountOfProducts,
    };
}

function normalizePadStoreOption(storeOption) {
    const { storeId = '', options = [] } = storeOption;

    return {
        storeId,
        options: options.map(normalizePadOption),
    };
}

export function normalizePadData(padData) {
    const { storesOptions = [], deliveryMethods = [], paymentMethods = [] } = padData || {};

    return {
        deliveryMethods: deliveryMethods.map(normalizeDeliveryMethod),
        paymentMethods: paymentMethods.map(normalizePadPaymentMethod),
        storesOptions: storesOptions.map(normalizePadStoreOption),
    };
}
