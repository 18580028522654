import { types } from './mutations';

let initValidationPromise = null;

export default {
    async initializeValidation({ state, commit }) {
        if (!initValidationPromise) {
            initValidationPromise = this.app.$validation();
        }

        const { rules } = await initValidationPromise;

        if (!state.validationRules.length) {
            commit(types.SET_VALIDATION_RULES, rules);
        }
    },

    setStrictPhoneValidationEnabled({ commit }, isStrictPhoneValidationEnabled) {
        commit(types.SET_STRICT_PHONE_VALIDATION_ENABLED, isStrictPhoneValidationEnabled);
    },

    async getStrictPhoneValidationEnabled({ dispatch }) {
        // eslint-disable-next-line max-len
        const isStrictPhoneValidation = await this.app.$services.validate.getIsStrictPhoneValidationEnabled();

        if (!isStrictPhoneValidation) {
            return false;
        }

        dispatch('setStrictPhoneValidationEnabled', isStrictPhoneValidation);

        return true;
    },
};
