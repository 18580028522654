import DataLayerProduct from '@models/Analytics/DataLayerProduct';

export default class DataLayerProducts {
    constructor({
        carts = null,
        products = [],
        productsReviewsTotalCount = {},
        routePath = '',
        selectedPaymentMethod = null,
        selectedPaymentType = '',
    }) {
        this.carts = carts;
        this.products = products;
        this.productsReviewsTotalCount = productsReviewsTotalCount;
        this.routePath = routePath;
        this.selectedPaymentMethod = selectedPaymentMethod;
        this.selectedPaymentType = selectedPaymentType;
    }

    build() {
        const { carts, products, selectedPaymentMethod, selectedPaymentType } = this;

        let productsList;

        if (carts) {
            productsList = carts
                .flatMap(({ items, selected_shipping_method: method }) =>
                    items.map(item => ({ item, selectedShippingMethodCode: method?.carrierCode }))
                )
                .map(({ item, selectedShippingMethodCode }, index) =>
                    this.buildProduct(
                        item,
                        index + 1,
                        selectedShippingMethodCode,
                        selectedPaymentMethod,
                        selectedPaymentType
                    )
                );
        } else {
            productsList = products.map((item, index) => this.buildProduct(item, index + 1));
        }

        return productsList;
    }

    buildProduct(item, position, shippingMethod, selectedPaymentMethod, selectedPaymentType) {
        const {
            quantity,
            sku = '',
            skuVariant = '',
            prices: { final_item_price: discountedPrice } = {},
            offer_id: offerId,
        } = item;

        const productReviewsTotalCount = {
            totalCount: this.productsReviewsTotalCount[sku],
        };

        const product = item.product || item;

        const productData = new DataLayerProduct({
            product,
            reviews: productReviewsTotalCount,
            position: item.position || position,
            quantity,
            variantId: skuVariant,
            shippingMethod,
            discountedPrice,
            selectedPaymentMethod,
            selectedPaymentType,
            offerId,
        }).build();

        if (productData) {
            return {
                ...productData,
                list: this.routePath,
            };
        }

        return null;
    }
}
