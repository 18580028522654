import {
    WEB_CART_1,
    WEB_CATEGORY_1,
    WEB_EMPTY_CART_1,
    WEB_ERROR_PAGE_1,
    WEB_PDP_1,
    WEB_PDP_2,
    WEB_PDP_SIMILAR,
    WEB_PDP_UNAVBL_1,
    WEB_PRECART_1,
    WEB_SEARCH_RESULTS_1,
    WEB_PDP_PRODUCT_NOTIFICATION,
} from '@types/Synerise';

export const createDefaultSyneriseConfig = () => ({
    apiKey: '',
    trackerKey: '',
    trackerDomain: '',
    [WEB_PDP_1]: '',
    [WEB_PDP_2]: '',
    [WEB_PDP_UNAVBL_1]: '',
    [WEB_PRECART_1]: '',
    [WEB_CART_1]: '',
    [WEB_PDP_SIMILAR]: '',
    [WEB_PDP_PRODUCT_NOTIFICATION]: '',
    [WEB_CATEGORY_1]: '',
    [WEB_SEARCH_RESULTS_1]: '',
    [WEB_ERROR_PAGE_1]: '',
    [WEB_EMPTY_CART_1]: '',
});

const state = () => ({
    syneriseConfig: {
        ...createDefaultSyneriseConfig(),
    },
    syneriseLoaded: false,
});

export default state;
