import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { BRAND_EOBUWIE } from '@configs/x3/brands';

import { types } from './mutations';

const MODULE_NAME = 'navigation';

export default {
    async getNavCategoriesData({ dispatch, state }) {
        const { defaultCategoryId } = state;
        let defaultCategory = [];

        try {
            defaultCategory = await this.app.$services.navigation.getNavCategories(
                defaultCategoryId
            );
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'navigation.getNavCategories',
                },
                {
                    defaultCategoryId,
                    action: 'navigation.getNavCategories',
                }
            );
        }

        if (defaultCategory && defaultCategory.length) {
            const mainCategoriesWrapper = defaultCategory[0];

            this.app.$navigationContext.initNavigationContext(mainCategoriesWrapper);
            dispatch('setNavigationCategories', mainCategoriesWrapper);

            return true;
        }

        this.app.$errorHandler.captureStoreMessage(
            MODULE_NAME,
            'Error getting navigation categories',
            {
                [ERROR_ACTION_TAG_NAME]: 'setNavigationCategories',
            },
            {
                defaultCategory,
            }
        );

        return false;
    },

    setNavigationCategories({ commit }, defaultCategory) {
        const { children: rootCategoryChildren } = defaultCategory;

        if (!this.app.$navigationContext.isMenuContextual) {
            commit(types.SET_NAVIGATION_CATEGORIES, [defaultCategory]);

            return;
        }

        commit(types.SET_NAVIGATION_CATEGORIES, rootCategoryChildren);
    },

    async prepareNavigationData({ commit }) {
        const useDisclosures = this.app.$configProvider.getConfig('brand') === BRAND_EOBUWIE;

        commit(types.SET_USE_DISCLOSURES, useDisclosures);
    },
};
