import { createNamespacedHelpers } from 'vuex';
import { MOBILE_APP_STORE_CODES } from '@configs/defaults/store-codes';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    computed: {
        ...mapConfigGetters(['storeCode']),

        showMobileAppInfoForStoreCodes() {
            return MOBILE_APP_STORE_CODES.some(code => code === this.storeCode);
        },
    },
};
