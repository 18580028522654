<template>
    <div class="header-actions">
        <AccountTooltip />

        <WishlistTooltip />

        <MinicartTooltip />

        <LoginTooltip
            v-if="shouldShowLoginTooltip"
            class="login-tooltip"
            @close="closeLoginTooltip()"
            @show-login-modal="showLoginModal(true)"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { MODAL_CUSTOMER_ACCOUNT_NAME, MODAL_TYPE } from '@configs/modals';

import {
    LOGIN_TOOLTIP_VIEW,
    LOGIN_TOOLTIP_CLICK,
    LOGIN_TOOLTIP_CLOSE,
} from '@analytics-types/Events';
import { CATEGORIES } from '@analytics-types/Categories';
import { LOGIN } from '@types/CustomerModal';

import AccountTooltip from '@header-molecules/AccountTooltip/AccountTooltip';
import WishlistTooltip from '@header-molecules/WishlistTooltip/WishlistTooltip';
import MinicartTooltip from '@header-molecules/MinicartTooltip/MinicartTooltip';

const {
    mapGetters: mapCustomerGetters,
    mapState: mapCustomerState,
    mapActions: mapCustomerActions,
} = createNamespacedHelpers('customer');
const { mapGetters: mapCartGetters } = createNamespacedHelpers('cart');

export default {
    name: 'HeaderTooltips',

    components: {
        AccountTooltip,
        MinicartTooltip,
        WishlistTooltip,
        LoginTooltip: () => ({
            component: import(
                /* webpackChunkName: "login-tooltip" */
                '@molecules/LoginTooltip/LoginTooltip'
            ),
        }),
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapCustomerGetters(['isLoggedIn', 'wishlistCount']),
        ...mapCartGetters(['cartItemsCount']),
        ...mapCustomerState([
            'loginTooltipVisibility',
            'initLoginTooltip',
            'fetchingCustomerInProgress',
        ]),

        shouldShowLoginTooltip() {
            return (
                this.loginTooltipVisibility &&
                !this.isLoggedIn &&
                !!(this.wishlistCount || this.cartItemsCount)
            );
        },

        isLoginTooltipUnderWishlist() {
            return !!(this.wishlistCount && !this.cartItemsCount);
        },

        gaEventCategoryForLoginTooltip() {
            const { WISHLIST_TOOLTIP, CART_TOOLTIP } = CATEGORIES;

            return this.isLoginTooltipUnderWishlist ? WISHLIST_TOOLTIP : CART_TOOLTIP;
        },
    },

    watch: {
        initLoginTooltip(shouldInit) {
            if (!shouldInit) {
                return;
            }

            this.initLoginTooltipWithDelay();
        },

        shouldShowLoginTooltip(shouldShow) {
            if (!shouldShow) {
                return;
            }

            this.$analytics.emit(LOGIN_TOOLTIP_VIEW, {
                eventCategory: this.gaEventCategoryForLoginTooltip,
            });
        },
    },

    methods: {
        ...mapCustomerActions(['setLoginTooltipVisibility', 'initLoginTooltipWithDelay']),

        async showLoginModal(fromLoginTooltip = false) {
            await this.$modals.open(MODAL_CUSTOMER_ACCOUNT_NAME, {
                [MODAL_TYPE]: LOGIN,
            });

            if (fromLoginTooltip) {
                this.$analytics.emit(LOGIN_TOOLTIP_CLICK, {
                    eventCategory: this.gaEventCategoryForLoginTooltip,
                });
            }
        },

        closeLoginTooltip() {
            this.setLoginTooltipVisibility(false);

            this.$analytics.emit(LOGIN_TOOLTIP_CLOSE, {
                eventCategory: this.gaEventCategoryForLoginTooltip,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.header-actions {
    @apply flex items-center relative;

    .login-tooltip {
        @apply absolute z-3 right-[-10px] top-[60px];

        &::before {
            @apply hidden;
        }
    }

    @screen ui-desktopPlus {
        @apply gap-x-ui-6;
    }
}
</style>
