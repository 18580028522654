import { CorrelationService } from '@eobuwie/correlation-context';

// eslint-disable-next-line quotes
const options = JSON.parse(`{
  "serviceName": "modivo-pwa"
}`);

export default (context, inject) => {
    const name = `${options.serviceName}-${
        process.server ? 'server' : 'client'
    }`;
    const correlation = new CorrelationService(name);

    inject('correlation', correlation);

    if (process.server) {
        Object.entries(correlation.getHeaders() || {}).forEach(
            ([headerName, headerValue]) => {
                context.res.setHeader(headerName, headerValue);
            }
        );
    }
};
