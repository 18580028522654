const { resolve } = require('path');

const { GENERAL_TRANSLATIONS_KEY } = require('../../../../server/trans/config');

const MERCHANT_I18N_NAMESPACE = '__merchant';

module.exports = {
    namespace: MERCHANT_I18N_NAMESPACE,

    generateFilePaths: lang => ({
        [GENERAL_TRANSLATIONS_KEY]: resolve(__dirname, `./translations/${lang}.json`),
    }),
};
