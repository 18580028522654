import { MOBILE_TOKEN_EXPIRED_PAGE_NAME } from '@router/names';

import { shouldRefreshToken, isExpired } from '@assets/auth';

const refreshAccessToken = (store, refreshToken) =>
    store.dispatch('customer/refreshAuthToken', refreshToken);

const buildAuthorizationHeader = accessToken => ({
    authorization: `Bearer ${accessToken}`,
});

export const createAuthorizationHeader = async ({ store, $mobileApp, $router }) => {
    const {
        accessToken,
        refreshToken,
        accessTokenExpirationISO,
        refreshTokenExpirationISO,
    } = store.state.customer;

    if ($mobileApp.isWebView) {
        if (accessToken && !isExpired(accessTokenExpirationISO)) {
            return buildAuthorizationHeader(accessToken);
        }

        const currentRouteName = $router.currentRoute.name;

        if (
            $mobileApp.hasAuthorizationTokensFromMobileApp &&
            currentRouteName !== MOBILE_TOKEN_EXPIRED_PAGE_NAME
        ) {
            await $mobileApp.refreshMobileTokens();

            const { accessToken: refreshedAccessToken } = store.state.customer;

            return refreshedAccessToken ? buildAuthorizationHeader(refreshedAccessToken) : {};
        }

        return {};
    }

    if (!accessToken && !refreshToken) {
        return {};
    }

    if (accessToken && !isExpired(accessTokenExpirationISO)) {
        return buildAuthorizationHeader(accessToken);
    }

    if (
        shouldRefreshToken(
            accessToken,
            accessTokenExpirationISO,
            refreshToken,
            refreshTokenExpirationISO
        ) &&
        process.client
    ) {
        await refreshAccessToken(store, refreshToken);

        const { accessToken: refreshedAccessToken } = store.state.customer;

        return refreshedAccessToken ? buildAuthorizationHeader(refreshedAccessToken) : {};
    }

    return {};
};

export const handleRefreshTokensAfterUnauthorizedResponse = async ({
    store,
    $mobileApp,
    $router,
}) => {
    if (!process.client) {
        return null;
    }

    if ($mobileApp.isWebView) {
        const currentRouteName = $router.currentRoute.name;

        if (
            $mobileApp.hasAuthorizationTokensFromMobileApp &&
            currentRouteName !== MOBILE_TOKEN_EXPIRED_PAGE_NAME
        ) {
            const isSuccess = await $mobileApp.refreshMobileTokens();

            if (!isSuccess) {
                return null;
            }

            const { accessToken: refreshedAccessToken } = store.state.customer;

            return refreshedAccessToken;
        }

        return null;
    }

    const { refreshToken } = store.state.customer;

    if (!refreshToken) {
        return null;
    }

    await refreshAccessToken(store, refreshToken);

    const { accessToken: refreshedAccessToken } = store.state.customer;

    return refreshedAccessToken;
};
