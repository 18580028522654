import { MERCHANT_DOMAIN_TAG_VALUE } from '@merchant/errors/config';

import { createErrorHandlerWithDomainContext } from '@errors/plugin/create-domain-error-handler';

export default function ({ $errorHandler }, inject) {
    inject(
        'merchantErrorHandler',
        createErrorHandlerWithDomainContext($errorHandler, MERCHANT_DOMAIN_TAG_VALUE)
    );
}
