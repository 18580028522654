export const NAME = 'seoLinkService';

export const GET_SEO_LINKS_DATA = 'GET_SEO_LINKS_DATA';

export const ASYNC_SEO_LINK_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        GET_SEO_LINKS_DATA,
    },
};
