import loyaltyClubMutations from './mutations';
import loyaltyClubActions from './actions';
import loyaltyClubGetters from './getters';

export const state = () => ({
    statusesData: [],
    loyaltyClubSettings: {},
});

export const mutations = loyaltyClubMutations;
export const actions = loyaltyClubActions;
export const getters = loyaltyClubGetters;
