export const ATTRIBUTE_TYPE_TEXT = 'text';
export const ATTRIBUTE_TYPE_NUMBER = 'number';
export const ATTRIBUTE_TYPE_BOOLEAN = 'boolean';
export const ATTRIBUTE_TYPE_PRICE_COLLECTION = 'price_collection';
export const ATTRIBUTE_TYPE_ASSETS_COLLECTION = 'assets_collection';
export const ATTRIBUTE_TYPE_SIMPLE_SELECT = 'simpleselect';
export const ATTRIBUTE_TYPE_MULTI_SELECT = 'multiselect';
export const ATTRIBUTE_TYPE_REFERENCE_ENTITY = 'reference_entity';
export const ATTRIBUTE_TYPE_REFERENCE_ENTITY_COLLECTION = 'reference_entity_collection';
export const ATTRIBUTE_TYPE_METRIC = 'metric';

export default {
    ATTRIBUTE_TYPE_TEXT,
    ATTRIBUTE_TYPE_BOOLEAN,
    ATTRIBUTE_TYPE_ASSETS_COLLECTION,
    ATTRIBUTE_TYPE_PRICE_COLLECTION,
    ATTRIBUTE_TYPE_SIMPLE_SELECT,
    ATTRIBUTE_TYPE_MULTI_SELECT,
    ATTRIBUTE_TYPE_REFERENCE_ENTITY,
    ATTRIBUTE_TYPE_REFERENCE_ENTITY_COLLECTION,
    ATTRIBUTE_TYPE_METRIC,
};
