<template>
    <div
        class="main"
        :class="{
            home: isHomePage,
            'printable-content-only': isPrintableContentOnly,
        }"
    >
        <MarketingBarDataProvider v-if="!isWebViewMobile" />
        <HeaderWrapper v-if="!isWebViewMobile" />
        <main>
            <nuxt />
        </main>
        <template v-if="!isWebViewMobile">
            <BaseFooter />
            <MobileAppBanner />
            <ChatWrapper />
        </template>
        <Messages />
        <GlobalModals />
        <SmartBannerWrapper />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { HOME_PAGE } from '@router/paths';

import WebView from '@mixins/WebView';
import BaseLayout from '@layouts/mixins/BaseLayout';
import WithCustomer from '@layouts/mixins/WithCustomer';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import Messages from '@molecules/Messages/Messages';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import BaseFooter from '@organisms/BaseFooter/BaseFooter';
import MobileAppBanner from '@organisms/MobileAppBanner/MobileAppBanner';
import GlobalModals from '@organisms/GlobalModals/GlobalModals';

import HeaderWrapper from '@header-organisms/HeaderWrapper/HeaderWrapper';

import MarketingBarDataProvider from '@marketing-bar-components/organisms/MarketingBarDataProvider/MarketingBarDataProvider';

export default {
    name: 'DefaultLayout',

    components: {
        MarketingBarDataProvider,
        ChatWrapper,
        Messages,
        BaseFooter,
        MobileAppBanner,
        HeaderWrapper,
        GlobalModals,
        SmartBannerWrapper,
    },

    mixins: [BaseLayout, WithCustomer, WebView],

    computed: {
        ...mapState(['isPrintableContentOnly']),

        isHomePage() {
            return this.$route.path === HOME_PAGE;
        },
    },
};
</script>

<style lang="scss" scoped>
.home {
    &:deep(.base-header) {
        @apply border-none;
    }
}

@screen lg {
    .home {
        &:deep(.header-menu) {
            .body-text {
                @apply hidden;
            }
        }
    }
}

.main {
    &.printable-content-only {
        @screen print {
            & > *:not(main) {
                @apply hidden;
            }
        }
    }
}
</style>
