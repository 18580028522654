<template>
    <div class="nav-i" @mouseenter="setHovered()">
        <component
            :is="linkComponent"
            :title="category.name"
            :path="category.path"
            :data-test-id="NAVIGATION_ITEM_LINK"
            :class="{ hovered: isActive }"
            class="nav-a"
        >
            <span :style="category.specialTextColor">{{ category.name }}</span>
        </component>

        <NavigationContent
            v-if="shouldShowContent"
            :class="{ active: isActive }"
            :categories="category.content"
            :cms-block="category.cmsBlock"
            :is-hovered="isHovered"
            class="nav-c"
        />
    </div>
</template>

<script>
import { NAVIGATION_ITEM_LINK } from '@types/AutomaticTestIDs';

import { isHttpLink } from '@assets/link';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

import NavigationContent from '@header-molecules/NavigationContent/NavigationContent';

export default {
    name: 'NavigationItem',

    components: {
        BaseLink,
        BaseLinkStatic,
        NavigationContent,
    },

    props: {
        category: {
            type: Object,
            required: true,
        },

        isActive: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        return {
            NAVIGATION_ITEM_LINK,
        };
    },

    data() {
        return {
            isHovered: false,
        };
    },

    computed: {
        shouldShowContent() {
            return this.category.hasChildren && (this.isActive || this.isHovered);
        },

        isHttpLink() {
            return isHttpLink(this.category.path);
        },

        linkComponent() {
            if (!this.category.path) {
                return 'div';
            }

            return this.isHttpLink ? BaseLinkStatic : BaseLink;
        },
    },

    methods: {
        setHovered() {
            this.isHovered = true;

            if (this.category.hasChildren) {
                this.$emit('hover');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-i {
    @apply h-full;

    .nav-a {
        @apply font-ui-body-m text-ui-text-primary-default h-full flex items-center;
        @apply relative cursor-pointer whitespace-nowrap;

        &.hovered,
        &:hover {
            @apply no-underline;

            &:after {
                @apply absolute bottom-[-1px] w-full h-ui-1 z-ui-1 content-[''];
                @apply bg-ui-container-primary-default;
            }
        }
    }

    .nav-c {
        @apply w-full absolute left-ui-0 right-ui-0;
        @apply bg-ui-container-default-default rounded-b-ui-4 invisible z-7 mt-[1px];

        &.active {
            @apply visible;
        }
    }
}
</style>
