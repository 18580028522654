<template>
    <div v-if="loading" class="progress-bar" :class="{ failed }" :style="style" />
</template>

<script>
import { LOADER_COLOR } from '@configs/x3/config-keys';

export default {
    data() {
        return {
            loading: false,
            failed: false,
            percent: 0,
            loadingColor: '#222222',
        };
    },

    computed: {
        style() {
            return {
                width: `${this.percent}%`,
                backgroundColor: this.failed ? 'red' : this.loadingColor,
            };
        },
    },

    beforeDestroy() {
        this.clear();
    },

    methods: {
        clear() {
            clearInterval(this.timer);
            clearTimeout(this.throttle);
            clearTimeout(this.hide);
            this.timer = null;
        },
        // eslint-disable-next-line vue/no-unused-properties
        start() {
            this.failed = false;
            this.loadingColor = this.$configProvider.getConfig(LOADER_COLOR);
            this.clear();
            this.throttle = setTimeout(() => this.startTimer(), 200);
        },

        // eslint-disable-next-line vue/no-unused-properties
        finish() {
            this.percent = 100;
            this.clear();
            this.hide = setTimeout(() => {
                this.show = false;
                this.$nextTick(() => {
                    this.percent = 0;
                });
            }, 500);
        },

        // eslint-disable-next-line vue/no-unused-properties
        fail() {
            this.failed = true;
        },

        startTimer() {
            this.loading = true;

            this.timer = setInterval(() => {
                this.increase(2);
            }, 100);
        },

        increase(num) {
            this.percent = Math.min(100, Math.floor(this.percent + num));
        },
    },
};
</script>

<style scoped lang="scss">
.progress-bar {
    @apply fixed top-ui-0 left-ui-0 w-full h-ui-2 z-9999;
    transition: width 0.1s, opacity 0.4s;
}
</style>
