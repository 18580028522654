import { REDIRECT_HTTP_CODE } from '@configs/redirect';

import Redirector from '@modules/redirector/Redirector';

export default async function ({ store, route: { path: currentUrl }, redirect }) {
    const locale = store.getters['config/locale'];

    const staticRedirects = Redirector.getRedirectsForLocale(locale);
    const staticRedirect = staticRedirects?.[decodeURI(currentUrl)];

    if (staticRedirect) {
        return redirect(REDIRECT_HTTP_CODE, staticRedirect);
    }
}
