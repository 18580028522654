export const types = {
    SET_IS_CONNECTING_CLIENT: 'SET_IS_CONNECTING_CLIENT',
    SET_CLIENT_ADDRESSES: 'SET_CLIENT_ADDRESSES',
    SET_ACCOUNT_VERIFICATION_STATUS: 'SET_ACCOUNT_VERIFICATION_STATUS',
};

export default {
    [types.SET_IS_CONNECTING_CLIENT](state, isConnecting) {
        state.isConnectingClient = isConnecting;
    },

    [types.SET_CLIENT_ADDRESSES](state, addresses) {
        state.clientAddresses = addresses || [];
    },

    [types.SET_ACCOUNT_VERIFICATION_STATUS](state, status) {
        state.accountVerificationStatus = status;
    },
};
