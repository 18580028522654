import { isAllowed } from '@modules/page-builder/domain-whitelist';

export default function (context) {
    if (process.server) {
        const {
            req: { headers },
            $config: { imaginatorUrl },
        } = context;

        const additionalHeaders = {
            'Content-Security-Policy': 'upgrade-insecure-requests',
            'X-Content-Type-Options': 'nosniff',
            'Referrer-Policy': 'no-referrer-when-downgrade',
            'Permissions-Policy':
                // eslint-disable-next-line max-len
                'accelerometer=(), camera=(), gyroscope=(), magnetometer=(), microphone=(), payment=(self "https://google.com" "https://pay.google.com"), usb=(), midi=()',
        };

        const refererHeader = headers?.referer || '';
        const linkHeader = headers?.link || '';

        if (!isAllowed(refererHeader)) {
            headers['X-Frame-Options'] = 'SAMEORIGIN';
        }

        const linkHeaders = linkHeader ? [linkHeader] : [];

        if (imaginatorUrl) {
            linkHeaders.push(`<${imaginatorUrl}>; rel=preconnect`);
        }

        context.res.set('link', linkHeaders);
        context.res.set(additionalHeaders);
    }
}
