import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG } from '@configs/megatron-client';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { PAYMENTS_ERROR_DOMAIN } from '@errors/feature-domain-names';

export default (megatronClient, $errorHandler) => ({
    async availableCards(customerUuid) {
        try {
            return await megatronClient.get(`v1/customers/${customerUuid}/payu-cards`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG,
                context: {
                    useAuthorization: false,
                },
            });
        } catch (err) {
            $errorHandler.captureDomainError(
                PAYMENTS_ERROR_DOMAIN,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'services.payments.payu.payu-cards',
                },
                {
                    payload: {
                        customerUuid,
                    },
                }
            );
        }
    },

    async deleteCard(customerUuid, token) {
        try {
            return await megatronClient.delete(`v1/customers/${customerUuid}/payu-cards/${token}`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG,
                context: {
                    useAuthorization: false,
                },
            });
        } catch (err) {
            $errorHandler.captureDomainError(
                PAYMENTS_ERROR_DOMAIN,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'services.payments.payu.payu-cards.delete',
                },
                {
                    payload: {
                        customerUuid,
                    },
                }
            );
        }
    },
});
