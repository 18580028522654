import getRecaptchaToken, { initRecaptcha } from '@eobuwie/recaptcha';

import { CORE_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

export default ({ $config, $errorHandler }, inject) => {
    const { reCaptchaSiteKey } = $config.google;

    const getToken = formName => {
        try {
            return getRecaptchaToken(formName, reCaptchaSiteKey);
        } catch (err) {
            $errorHandler.captureDomainError(CORE_ERROR_DOMAIN, err, {
                [ERROR_ACTION_TAG_NAME]: 'recaptcha.getToken',
            });

            return '';
        }
    };

    const init = () => {
        try {
            initRecaptcha(reCaptchaSiteKey);
        } catch (err) {
            $errorHandler.captureDomainError(CORE_ERROR_DOMAIN, err, {
                [ERROR_ACTION_TAG_NAME]: 'recaptcha.init',
            });
        }
    };

    inject('recaptcha', { getToken, init });
};
