import navigationMutations from './mutations';
import navigationActions from './actions';
import navigationGetters from './getters';

export const state = () => ({
    defaultCategoryId: '2',
    categories: [],
    useDisclosures: false,
});

export const mutations = navigationMutations;
export const actions = navigationActions;
export const getters = navigationGetters;

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
