<template>
    <li class="footer-link-bottom">
        <HtmlLinkHandler #default="{ clickHandler }" @click="setIsModalCookieSettingsOpen(true)">
            <component
                :is="linkComponent"
                :path="path"
                :title="label"
                class="link"
                :data-click-action="linkWithEmitAction"
                @click.native="clickHandler($event)"
            >
                {{ label }}
            </component>
        </HtmlLinkHandler>
    </li>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { isHttpLink } from '@assets/link';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

import HtmlLinkHandler from '@core-components/molecules/HtmlLinkHandler/HtmlLinkHandler';

const { mapActions: mapConsentsActions } = createNamespacedHelpers('consents');

export default {
    name: 'FooterLinkBottom',

    components: {
        BaseLink,
        BaseLinkStatic,
        HtmlLinkHandler,
    },

    props: {
        path: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            default: '',
        },

        isEmitAction: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        linkComponent() {
            return isHttpLink(this.path) ? BaseLinkStatic : BaseLink;
        },

        linkWithEmitAction() {
            return this.isEmitAction ? 'emit' : null;
        },
    },

    methods: {
        ...mapConsentsActions(['setIsModalCookieSettingsOpen']),
    },
};
</script>

<style lang="scss" scoped>
.footer-link-bottom {
    .link {
        @apply font-ui-body-s;
    }
}
</style>
