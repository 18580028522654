import RequestUserPermission from '@modules/notifications/RequestUserPermission';
import { EVENT_SYNERISE_LOADED } from '@types/Events';
import { TRY_GET_USER_PERMISSION_TIMEOUT } from '@configs/web-push-notifications';

export default ({ app: { $errorHandler, $analytics, $cookies, $storage } }) => {
    const requestUserPermission = new RequestUserPermission({
        $errorHandler,
        $analytics,
        $cookies,
        $storage,
    });

    const tryGetUserPermissionAfterTimeout = () => {
        setTimeout(async () => {
            await requestUserPermission.tryGetUserPermission();
        }, TRY_GET_USER_PERMISSION_TIMEOUT);
    };

    if (typeof window.SR === 'undefined') {
        window.addEventListener(EVENT_SYNERISE_LOADED, async () => {
            tryGetUserPermissionAfterTimeout();
        });
    } else {
        tryGetUserPermissionAfterTimeout();
    }
};
