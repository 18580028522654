export const PSB_FOOTER_CONFIGURATION = 'psb_footer_configuration';

export const PSB_FOOTER_COOKIES = 'psb_footer_cookies';
export const PSB_FOOTER_COOKIES_SCROLL = 'psb_footer_cookies_scroll';
export const PSB_FOOTER_COOKIES_POLICY = 'psb_footer_cookies_policy';
export const PSB_FOOTER_COOKIES_PERSONAL_DATA = 'psb_footer_cookies_personal_data';
export const PSB_FOOTER_COOKIES_ALERT = 'psb_footer_cookies_alert';

export const PSB_FOOTER_SIMPLE_TOP = 'psb_footer_simple_top';
export const PSB_FOOTER_SIMPLE_BOTTOM_RIGHT = 'psb_footer_simple_bottom_right';

export const MAX_COLUMNS_TO_DISPLAY = 3;

export const COOKIES_CMS_BLOCKS_IDS = [
    PSB_FOOTER_COOKIES,
    PSB_FOOTER_COOKIES_SCROLL,
    PSB_FOOTER_COOKIES_POLICY,
    PSB_FOOTER_COOKIES_PERSONAL_DATA,
    PSB_FOOTER_COOKIES_ALERT,
];

export const FOOTER_SIMPLE_CMS_BLOCKS_IDS = [PSB_FOOTER_SIMPLE_TOP, PSB_FOOTER_SIMPLE_BOTTOM_RIGHT];
