import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { USER_BAD_INPUT } from '@types/GraphQLErrorCodes';

import {
    getFirstErrorCode,
    getFirstErrorMessage,
} from '@assets/megatron-client/graphql-errors-helper';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.newsletter-sms.${action}`,
});

export default ({ megatronClient, $errorHandler }) => ({
    async unsubscribePhoneByToken(token) {
        const response = (success, message) => ({
            success: success || false,
            message: message || 'An error occurred during the unsubscription process.',
        });
        const ERROR_TAGS = getErrorTags('unsubscribePhoneByToken');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/unsubscribePhoneByToken',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        token,
                    },
                }
            );

            if (!error) {
                const { success, message } = data?.unsubscribePhoneByToken || {};

                return response(success, message);
            }

            const firstErrorCode = getFirstErrorCode(error.data.errors);

            if (firstErrorCode !== USER_BAD_INPUT) {
                $errorHandler.captureError(
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    ERROR_TAGS,
                    {
                        token,
                        data,
                        error,
                        status,
                    }
                );
            }

            return response(false, getFirstErrorMessage(error.data.errors));
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                token,
            });

            return response();
        }
    },
});
