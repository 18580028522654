import { DEFAULT_LOCALE } from '@analytics-types/Analytics';

import { LOCALE_WITH_EUR_PRICE, UA_LOCALE } from '@configs/duty';

export const getSelectLocales = locale => {
    const selectLocales = [DEFAULT_LOCALE];

    if (locale === UA_LOCALE) {
        selectLocales.push(LOCALE_WITH_EUR_PRICE);
    }

    return selectLocales;
};

export const getLocaleISOCode = (locales, localeCode) => {
    const localeObj = locales.find(localesItem => localesItem.code === localeCode);

    return localeObj?.iso;
};

export const getCountryCodeFromLocale = locale => {
    return (!!locale?.length && locale.slice(3, 5).toLowerCase()) || '';
};
