import {
    DOUBLE_SIZE_TYPE,
    PRODUCT_CARD_SIZE_TYPE_ATTRIBUTE_TEST_B,
    MAPPED_SIZE_TYPE,
} from '@configs/product-attribute-names';

export const getDoubleSize = productVariant => {
    const variantValues = productVariant.values;
    const attributeValue = variantValues[PRODUCT_CARD_SIZE_TYPE_ATTRIBUTE_TEST_B];

    const sizeTypeValueCode = attributeValue?.value.values.code_size_attribute.code;

    if (sizeTypeValueCode === MAPPED_SIZE_TYPE) {
        return variantValues[MAPPED_SIZE_TYPE]?.value.label || '';
    }

    if (sizeTypeValueCode === DOUBLE_SIZE_TYPE || !attributeValue) {
        return variantValues[DOUBLE_SIZE_TYPE]?.value.label || '';
    }

    return '';
};
