import { MEGATRON_REVIEWS_GET_PRODUCT_REVIEWS_OPERATION_NAME } from '@configs/megatron-client';

const normalizer = async response => {
    const { data, error } = response;

    if (error || !data) {
        return response;
    }

    response.data.reviews = {
        averageRatings: [
            {
                code: 'Value',
                value: data.product.averageGrade,
            },
        ],
        ratingCodes: ['Value'],
        ratings: [
            {
                code: 'Value',
                values: Object.entries(data.product.gradesDistribution).map(([vote, count]) => ({
                    count,
                    vote,
                })),
            },
        ],
        items: data.reviews.items.map(item => {
            const createdAt = item.createdAt.split('T')[0];
            const { name } = item.author;
            const content = item.body || '';
            const value = item.grade;
            const originalContent = item.originalBody || '';
            const originalLanguage = item.originalLanguage || '';

            return {
                content,
                createdAt,
                name,
                originalContent,
                originalLanguage,
                ratings: [{ code: 'Value', value }],
            };
        }),
        totalCountAll: data.product.reviewsCount,
    };

    return response;
};

export const getProductReviews = {
    operationName: MEGATRON_REVIEWS_GET_PRODUCT_REVIEWS_OPERATION_NAME,
    normalizer,
};
