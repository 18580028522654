<template>
    <p class="paragraph">
        <!--
            @slot Content slot
        -->
        <slot />
    </p>
</template>

<script>
export default {
    name: 'BaseParagraph',
};
</script>

<style lang="scss" scoped>
.paragraph {
    @apply text-r text-left font-book leading-r text-text;

    &-link {
        @apply text-secondary underline;
    }
}
</style>
