import NavigationContext from '@models/NavigationContext/NavigationContext';

export default async (
    { $cookies, $errorHandler, $configProvider, nuxtState: stateProvidedByServer },
    inject
) => {
    const {
        navigationContext: { contexts, currentContext },
    } = stateProvidedByServer || {};

    const navigationContext = new NavigationContext({
        $cookies,
        $errorHandler,
        $configProvider,
        contexts,
        currentContext,
    });

    inject('navigationContext', navigationContext);
};
