import { isRumTestOn } from '@assets/rum';

const makeAvg = (all, count) => {
    return all && count ? all / count : null;
};

export default ({ $abTests, $config, app }) => {
    const { $lux, router } = app;
    const { imaginatorUrl } = $config;

    if (!isRumTestOn($abTests)) {
        return;
    }

    let shouldSend = false;

    const imageTiming = {
        shortest: null,
        longest: null,
        all: null,
        count: null,
    };

    const clearImageTiming = () => {
        imageTiming.shortest = null;
        imageTiming.longest = null;
        imageTiming.all = null;
        imageTiming.count = null;
    };

    const perfObserver = list => {
        list.getEntries().forEach(entry => {
            if (entry.initiatorType !== 'img' || !entry.name.includes(imaginatorUrl)) {
                return;
            }

            const duration = Math.round(entry.duration);

            imageTiming.count += 1;
            imageTiming.all += duration;

            if (imageTiming.shortest === null || duration < imageTiming.shortest) {
                imageTiming.shortest = duration;
            }

            if (imageTiming.longest === null || duration > imageTiming.longest) {
                imageTiming.longest = duration;
            }
        });
    };

    const observer = new PerformanceObserver(perfObserver);

    observer.observe({ entryTypes: ['resource'] });

    router.beforeEach((to, from, next) => {
        if (to.path !== from.path) {
            if (!shouldSend) {
                $lux.init();
                shouldSend = true;
            } else {
                $lux.addData('img_count', imageTiming.count);
                $lux.addData('img_avg', makeAvg(imageTiming.all, imageTiming.count));
                $lux.addData('img_shortest', imageTiming.shortest);
                $lux.addData('img_longest', imageTiming.longest);
                $lux.send();
                $lux.init();
                clearImageTiming();
            }
        }

        next();
    });
};
