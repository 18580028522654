import {
    COOKIES_MODAL_COOKIE_NAME,
    WEB_PUSH_SUBSCRIBE_STATUS,
    WEB_PUSH_SUBSCRIBED,
    WEB_PUSH_UNSUBSCRIBED,
    HIDE_MODAL_CONSENTS_KEY,
} from '@configs/storage';

import { USER_WEB_PUSH_PERMISSION } from '@analytics-types/Events';
import {
    NOTIFICATION_PERMISSION_GRANTED,
    NOTIFICATION_NOT_SUPPORTED,
    NOTIFICATION_PERMISSION_DENIED,
    NOTIFICATION_PERMISSION_DEFAULT,
} from '@types/Notifications';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { EVENT_COOKIES_MODAL_CLOSED } from '@types/Events';

import cookies from '@services/cookies';

export default class RequestUserPermission {
    constructor({ $errorHandler, $analytics, $cookies, $storage }) {
        if (!($errorHandler && $analytics && $cookies && $storage)) {
            throw new Error('RequestUserPermission parameters are missing.');
        }

        this.$errorHandler = $errorHandler;
        this.$analytics = $analytics;
        this.$cookies = $cookies;
        this.$storage = $storage;
        this.cookiesModalCookieName = cookies.createCookieWithPrefix(COOKIES_MODAL_COOKIE_NAME);
        this.syneriseClient = () => window.SR || {};
    }

    async tryGetUserPermission() {
        try {
            if (
                !('serviceWorker' in navigator) ||
                !('PushManager' in window) ||
                !('Notification' in window)
            ) {
                this.emitAnalyticsEvent(NOTIFICATION_NOT_SUPPORTED, NOTIFICATION_NOT_SUPPORTED);

                return;
            }

            const localWebPushSubscribeStatus = this.getLocalWebPushSubscribeStatus();
            const browserWebPushPermission = window.Notification.permission;

            if (browserWebPushPermission === NOTIFICATION_PERMISSION_GRANTED) {
                if (this.syneriseClient().webPush?.checkSubscribeRequestNeed()) {
                    this.subscribe();
                }

                if (this.getLocalWebPushSubscribeStatus() !== WEB_PUSH_SUBSCRIBED) {
                    this.setLocalSubscribeStatus(WEB_PUSH_SUBSCRIBED);
                }
            } else if (
                browserWebPushPermission === NOTIFICATION_PERMISSION_DENIED &&
                localWebPushSubscribeStatus !== WEB_PUSH_UNSUBSCRIBED
            ) {
                this.unsubscribe();
            } else if (browserWebPushPermission === NOTIFICATION_PERMISSION_DEFAULT) {
                if (
                    this.$cookies.get(this.cookiesModalCookieName) ||
                    this.$storage.getItem(HIDE_MODAL_CONSENTS_KEY)
                ) {
                    await this.requestPermission();
                } else {
                    window.addEventListener(EVENT_COOKIES_MODAL_CLOSED, async () => {
                        await this.requestPermission();
                    });
                }
            }
        } catch (err) {
            this.$errorHandler.captureError(err, {
                [ERROR_ACTION_TAG_NAME]: 'RequestUserPermission.tryGetUserPermission',
            });
        }
    }

    async requestPermission() {
        const response = await window.Notification.requestPermission();

        if (
            this.syneriseClient().webPush?.isWebPushAppReady() &&
            response === NOTIFICATION_PERMISSION_GRANTED
        ) {
            this.subscribe();
        } else if (response === NOTIFICATION_PERMISSION_DENIED) {
            this.unsubscribe();
        }

        this.emitAnalyticsEvent('request', 'request');
        this.emitAnalyticsEvent('response', response);
    }

    subscribe() {
        this.syneriseClient().webPush?.subscribe();
        this.setLocalSubscribeStatus(WEB_PUSH_SUBSCRIBED);
    }

    unsubscribe() {
        this.syneriseClient().webPush?.unsubscribe();
        this.setLocalSubscribeStatus(WEB_PUSH_UNSUBSCRIBED);
    }

    getLocalWebPushSubscribeStatus() {
        return this.$storage.getItem(WEB_PUSH_SUBSCRIBE_STATUS);
    }

    setLocalSubscribeStatus(status) {
        this.$storage.setItem(WEB_PUSH_SUBSCRIBE_STATUS, status);
    }

    emitAnalyticsEvent(name = '', result = '') {
        this.$analytics.emit(USER_WEB_PUSH_PERMISSION, {
            name,
            result,
        });
    }
}
