<template>
    <div class="main">
        <MarketingBarDataProvider />
        <HeaderSimpleWrapperV2 :with-free-delivery-and-refund-info="false">
            <template #user-dashboard>
                <HeaderActions />
            </template>
        </HeaderSimpleWrapperV2>
        <main>
            <nuxt />
        </main>
        <Messages />
        <FooterSimpleV2 :footer-links="FOOTER_LINKS" />
        <GlobalModals />
        <SmartBannerWrapper />
    </div>
</template>

<script>
import { HOME_PAGE } from '@router/paths';

import BaseLayout from '@layouts/mixins/BaseLayout';

import HeaderSimpleWrapperV2 from '@molecules/HeaderSimpleWrapper/HeaderSimpleWrapperV2';
import Messages from '@molecules/Messages/Messages';
import FooterSimpleV2 from '@molecules/FooterSimple/FooterSimpleV2';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import GlobalModals from '@organisms/GlobalModals/GlobalModals';
import HeaderActions from '@header-organisms/HeaderActions/HeaderActions';

import MarketingBarDataProvider from '@marketing-bar-components/organisms/MarketingBarDataProvider/MarketingBarDataProvider';

export default {
    name: 'CheckoutCartLayoutV3',

    components: {
        SmartBannerWrapper,
        HeaderActions,
        HeaderSimpleWrapperV2,
        Messages,
        FooterSimpleV2,
        GlobalModals,
        MarketingBarDataProvider,
    },

    mixins: [BaseLayout],

    beforeCreate() {
        this.FOOTER_LINKS = {
            goBackTitle: this.$t('Back to shopping'),
            goBackPath: `${HOME_PAGE}`,
        };
    },
};
</script>
