import { CHECKOUT_SUCCESS_PAGE_NAME } from '@router/names';

import { PRODUCT_PAGE_NAME } from '@product/routing/names';

export const CHECKOUT_SUCCESS_PAGE_WIDGET_SCRIPT_SRC =
    '//integrations.fitanalytics.com/shop/modivo/orderconfirmation.js';
export const PRODUCT_PAGE_WIDGET_SCRIPT_SRC = '//integrations.fitanalytics.com/shop/modivo/pdp.js';

export const PAGES_WITH_WIDGET = [PRODUCT_PAGE_NAME, CHECKOUT_SUCCESS_PAGE_NAME];

export const FIT_ANALYTICS_AB_TEST_NAME = 'mod_all_pdp_fit_analytics';
