import {
    LOGIN_PAGE_NAME,
    RESET_PASSWORD_PAGE_NAME,
    WISHLIST_PAGE_NAME,
    CHECKOUT_ACCOUNT_PAGE_NAME,
    CHECKOUT_CART_PAGE_NAME,
    CHECKOUT_PAGE_NAME,
    CHECKOUT_REPAYMENT_NAME,
    CUSTOMER_ACCOUNT_AGREEMENTS_NAME,
    CUSTOMER_ACCOUNT_ORDER_VIEW_NAME,
    CUSTOMER_ACCOUNT_ORDERS_NAME,
    CUSTOMER_ACCOUNT_RETURN_NAME,
    CUSTOMER_ACCOUNT_RETURNS_NAME,
    CUSTOMER_ADDRESSES_NAME,
    CUSTOMER_DETAILS_NAME,
    CUSTOMER_PAGE_NAME,
    CHECKOUT_SUCCESS_PAGE_NAME,
} from '@router/names';

export const PSB_MOBILE_APP_BANNER = 'psb_mobile_app_banner';
export const MOBILE_APP_BANNER_KEY = 'mobile-app-banner-key';
export const BANNER_SHOW_DELAY = 10000;
export const BANNER_EXPIRE_TIME = 60 * 60 * 24 * 30 * 1000;
export const ROUTES_TO_NOT_DISPLAY = [
    LOGIN_PAGE_NAME,
    RESET_PASSWORD_PAGE_NAME,
    WISHLIST_PAGE_NAME,
    CHECKOUT_PAGE_NAME,
    CHECKOUT_CART_PAGE_NAME,
    CHECKOUT_ACCOUNT_PAGE_NAME,
    CHECKOUT_SUCCESS_PAGE_NAME,
    CHECKOUT_REPAYMENT_NAME,
    CUSTOMER_PAGE_NAME,
    CUSTOMER_DETAILS_NAME,
    CUSTOMER_ADDRESSES_NAME,
    CUSTOMER_ACCOUNT_ORDERS_NAME,
    CUSTOMER_ACCOUNT_ORDER_VIEW_NAME,
    CUSTOMER_ACCOUNT_AGREEMENTS_NAME,
    CUSTOMER_ACCOUNT_RETURNS_NAME,
    CUSTOMER_ACCOUNT_RETURN_NAME,
];

export default {
    PSB_MOBILE_APP_BANNER,
    MOBILE_APP_BANNER_KEY,
    ROUTES_TO_NOT_DISPLAY,
    BANNER_SHOW_DELAY,
    BANNER_EXPIRE_TIME,
};
