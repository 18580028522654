import {
    PRIVATE_PERSON_CUSTOMER_TYPE,
    COMPANY_CUSTOMER_TYPE,
} from '@analytics-modules/checkout/types/Analytics';

import { PRIVATE_PERSON_CUSTOMER, COMPANY_CUSTOMER } from '@types/Customer';

import DataLayerProducts from '@models/Analytics/DataLayerProducts';
import DataLayerProduct from '@models/Analytics/DataLayerProduct';

import { isCodPayment } from '@assets/pad';

const getAnalyticsCustomerTypeFromCustomerType = customerType => {
    switch (customerType) {
        case PRIVATE_PERSON_CUSTOMER:
            return PRIVATE_PERSON_CUSTOMER_TYPE;
        case COMPANY_CUSTOMER:
            return COMPANY_CUSTOMER_TYPE;
        default:
            return '';
    }
};

export const buildViewCartEventPayload = ({
    currency,
    grandTotalNumber,
    carts,
    productsReviewsTotalCount,
    elapsedTimeMs,
    route,
}) => {
    const products = new DataLayerProducts({
        carts,
        productsReviewsTotalCount,
    }).build();

    return {
        currency,
        grandTotalNumber,
        products,
        elapsedTimeMs,
        route,
    };
};

export const buildBeginCheckoutPayload = ({
    currency,
    discountCode,
    grandTotalNumber,
    carts,
    selectedPaymentMethodCode,
    selectedPaymentType,
    customerType,
    autocomplete,
    elapsedTimeMs,
    route,
}) => {
    const dataLayerProducts = new DataLayerProducts({
        carts,
        selectedPaymentType,
        selectedPaymentMethod: selectedPaymentMethodCode,
    }).build();

    return {
        currency,
        discountCode,
        grandTotalNumber,
        products: dataLayerProducts,
        customerType: getAnalyticsCustomerTypeFromCustomerType(customerType),
        isPrefilled: autocomplete,
        isCodPayment: isCodPayment(selectedPaymentType),
        elapsedTimeMs,
        route,
    };
};

export const buildAddShippingInfoPayload = ({
    currency,
    discountCode,
    grandTotalNumber,
    selectedPaymentType,
    selectedPaymentMethodCode,
    carts,
    storeId,
    autocomplete,
    route,
}) => {
    const storeCart = carts.find(({ store }) => store.id === storeId);

    const products = new DataLayerProducts({
        carts: [storeCart],
        selectedPaymentMethod: selectedPaymentMethodCode,
        selectedPaymentType,
    }).build();

    const shippingMethods = carts
        .map(({ selected_shipping_method: method }) => method?.carrierCode)
        .filter(code => code);

    return {
        currency,
        discountCode,
        grandTotalNumber,
        products,
        isPrefilled: autocomplete,
        isCodPayment: isCodPayment(selectedPaymentType),
        shippingMethods,
        route,
    };
};

export const buildAddPaymentInfoPayload = ({
    currency,
    discountCode,
    grandTotalNumber,
    carts,
    selectedPaymentType,
    selectedPaymentMethodCode,
    autocomplete,
    route,
}) => {
    const products = new DataLayerProducts({
        carts,
        selectedPaymentMethod: selectedPaymentMethodCode,
        selectedPaymentType,
    }).build();

    return {
        currency,
        discountCode,
        grandTotalNumber,
        products,
        isPrefilled: autocomplete,
        isCodPayment: isCodPayment(selectedPaymentType),
        paymentMethod: selectedPaymentMethodCode,
        route,
    };
};

export const buildRemoveFromCartPayload = ({
    currency,
    carts,
    cartItem,
    quantity = 0,
    productReviewTotalCount,
    elapsedTimeMs,
    selectedPaymentType,
    selectedPaymentMethodCode,
    route,
}) => {
    const {
        product: cartItemProduct,
        quantity: cartItemQuantity,
        offer_id: offerId,
        price: { promotional: { amount: promotionalPrice } = {} } = {},
        prices: { final_item_price: finalItemPrice } = {},
        skuVariant,
    } = cartItem;

    const cartItemIndex = carts
        .flatMap(({ items }) => items)
        .map(item => item.sku)
        .indexOf(skuVariant);

    const {
        selected_shipping_method: { carrierCode: selectedShippingMethodCode = '' },
    } = carts.find(({ items }) => items.find(item => item.skuVariant === skuVariant)) || {};

    const product = new DataLayerProduct({
        product: cartItemProduct,
        reviews: { totalCount: productReviewTotalCount },
        position: cartItemIndex + 1,
        quantity: quantity || cartItemQuantity,
        discountedPrice: promotionalPrice || finalItemPrice,
        variantId: skuVariant,
        offerId,
        selectedPaymentType,
        selectedPaymentMethod: selectedPaymentMethodCode,
        shippingMethod: selectedShippingMethodCode,
    }).build();

    return {
        currency,
        product,
        elapsedTimeMs,
        route,
    };
};
