import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { PSB_MODAL_CONSENTS_INFO } from '@configs/modal-consents';
import {
    COOKIES_MODAL_COOKIE_NAME,
    COOKIES_POLICY_COOKIE_NAME,
    COOKIES_PERSONAL_DATA_COOKIE_NAME,
} from '@configs/storage';

import cookies from '@services/cookies';

import { types } from './mutations';

const MODULE_NAME = 'consents';

export default {
    setConsentData({ commit }, consentData) {
        commit(types.SET_CONSENT_DATA, consentData);
    },

    async getConsentData({ dispatch }) {
        let consentData = {};

        try {
            consentData = await this.app.$services.consents.getConsentData();
        } catch (err) {
            this.app.$errorHandler.captureStoreError(MODULE_NAME, err, {
                [ERROR_ACTION_TAG_NAME]: 'consents.getConsentData',
            });

            if (process.client) {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t('Fetching GDPR data error occured'),
                    },
                    { root: true }
                );
            }

            consentData = {};
        }

        if (Object.keys(consentData).length) {
            dispatch('setConsentData', consentData);
        }
    },

    async getRequiredConsentsStaticBlocks({ dispatch }) {
        const cmsBlocksToLoad = [PSB_MODAL_CONSENTS_INFO];

        await dispatch('cmsBlock/getCmsBlocks', { cmsBlocksToLoad }, { root: true });
    },

    setIsModalOpen({ commit }, isModalOpen) {
        commit(types.SET_IS_MODAL_OPEN, isModalOpen);
    },

    setIsTrustedPartnersModalOpen({ commit }, isTrustedPartnersModalOpen) {
        commit(types.SET_IS_TRUSTED_PARTNERS_MODAL_OPEN, isTrustedPartnersModalOpen);
    },

    setIsModalCookieSettingsOpen({ commit }, isModalCookieSettingsOpen) {
        commit(types.SET_IS_MODAL_COOKIE_SETTINGS_OPEN, isModalCookieSettingsOpen);
    },

    setAreActiveConsentsEmpty({ commit }, areEmpty) {
        commit(types.SET_ARE_ACTIVE_CONSENTS_EMPTY, areEmpty);
    },

    async fetchConfigForConsentsModal({ state, dispatch, commit, rootGetters }) {
        await Promise.all([
            dispatch('getRequiredConsentsStaticBlocks'),
            dispatch('getConsentData'),
        ]);

        const staticBlockById = rootGetters['cmsBlock/staticBlockById'];
        const { categories, providers } = state;
        const providersAndCategoriesExist = categories?.length && providers?.length;
        const isConfigured = !!(
            staticBlockById(PSB_MODAL_CONSENTS_INFO) && providersAndCategoriesExist
        );

        commit(types.SET_IS_CONSENTS_MODAL_CONFIGURED, isConfigured);

        if (!isConfigured) {
            return;
        }

        const OLD_CONSENTS_COOKIES = [
            COOKIES_MODAL_COOKIE_NAME,
            COOKIES_POLICY_COOKIE_NAME,
            COOKIES_PERSONAL_DATA_COOKIE_NAME,
        ];

        OLD_CONSENTS_COOKIES.forEach(cookieName => {
            this.app.$cookies.remove(cookies.createCookieWithPrefix(cookieName));
        });
    },
};
