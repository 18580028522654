<template>
    <form autocomplete="off" class="header-search-input" @submit.prevent="searchSubmit()">
        <SearchComponent
            id="search-input-field-header-id"
            ref="searchInput"
            v-model="headerSearchValue"
            name="input-field-search-name"
            :placeholder="$t('Search brand, product, style')"
            :has-camera-icon="isVisualSearchTestOn"
            @cameraIconClick="openFileUploadModal()"
            @input="handleAutocompleteRequest()"
            @click.native="openAutocomplete()"
            @focus="focusHandler()"
        />

        <ButtonIcon
            v-if="isMobile"
            :variant="BUTTON_VARIANT"
            class="back"
            @click.native="closeAutocomplete()"
        >
            <ChevronLeft width="24px" height="24px" />
        </ButtonIcon>
    </form>
</template>

<script>
import debounce from 'lodash.debounce';
import { createNamespacedHelpers, mapState } from 'vuex';

import { AUTOCOMPLETE_MODAL } from '@header/configs/modals';
import { AUTOCOMPLETE_SUB_CONTEXT } from '@header/configs/contexts';

import { queryToPhrase } from '@search/assets/search';
import { SEARCH_RESULTS_PAGE_NAME } from '@search/routing/names';

import { ChevronLeft } from '@modivo-ui/icons/v2/navigation';
import { SearchComponent } from '@modivo-ui/components/SearchComponent/v1';
import { ButtonIcon, BUTTON_ICON_VARIANTS } from '@modivo-ui/components/ButtonIcon/v1';

import { COOKIE_SEARCH_BY_CATEGORY, VISUAL_SEARCH_BY_IMG } from '@search/assets/ab-tests';
import { isAbTestOn } from '@search/utils/ab-tests';

import { MODAL_SIMILAR_PRODUCTS_VS } from '@configs/modals';

import { VISUAL_SEARCH_INPUT_BUTTON_CLICK } from '@analytics-modules/catalog/types/Events';

import { MODULE_NAME as CATALOG_MODULE_NAME } from '@analytics-modules/catalog/meta';

const AUTOCOMPLETE_DEBOUNCE_TIME = 500;

const {
    mapActions: mapAutocompleteActions,
    mapState: mapAutocompleteState,
} = createNamespacedHelpers('header/autocomplete');

export default {
    name: 'HeaderSearchInput',

    components: {
        SearchComponent,
        ButtonIcon,
        ChevronLeft,
    },

    props: {
        focusOnMount: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            headerSearchValue: '',
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapAutocompleteState(['autocompleteVisited', 'suggestions']),

        isSearchQueryValid() {
            return this.headerSearchValue?.length > 1;
        },

        isSearchByCategoryTestOn() {
            return isAbTestOn(this.$abTests, COOKIE_SEARCH_BY_CATEGORY);
        },

        isVisualSearchTestOn() {
            return isAbTestOn(this.$abTests, VISUAL_SEARCH_BY_IMG);
        },
    },

    watch: {
        $route() {
            const query = this.$route.query.q?.slice(0, 250) || '';

            this.headerSearchValue =
                this.$route.name === SEARCH_RESULTS_PAGE_NAME ? queryToPhrase(query) : '';

            if (!this.isSearchQueryValid) {
                this.clearResults();
                this.setQuery('');
            }
        },
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_ICON_VARIANTS.TERTIARY;
    },

    beforeMount() {
        this.handleAutocompleteRequest = debounce(function () {
            this.trimInputValue();
            this.getAutocomplete();
        }, AUTOCOMPLETE_DEBOUNCE_TIME);
    },

    async mounted() {
        this.headerSearchValue = queryToPhrase(this.$route.query.q?.slice(0, 250) || '');
        this.setQuery(this.headerSearchValue);

        await this.$nextTick();

        if (this.focusOnMount) {
            this.$refs.searchInput?.focus?.();
        }
    },

    beforeDestroy() {
        this.handleAutocompleteRequest.cancel();
    },

    methods: {
        ...mapAutocompleteActions([
            'getAutocompleteData',
            'setQuery',
            'clearResults',
            'setAutocompleteVisited',
            'openAutocomplete',
            'getSearchUrl',
        ]),

        async getAutocomplete() {
            this.setQuery(this.headerSearchValue);

            if (!this.isSearchQueryValid) {
                this.clearResults();

                return;
            }

            await this.getAutocompleteData(this.headerSearchValue);
        },

        closeAutocomplete() {
            this.$modals.close(AUTOCOMPLETE_MODAL);
        },

        async searchSubmit() {
            this.trimInputValue();

            const query = this.headerSearchValue;

            const url = await this.getSearchUrl({
                query,
                categorySlug: this.isSearchByCategoryTestOn
                    ? this.$navigationContext.getContextSlug(AUTOCOMPLETE_SUB_CONTEXT)
                    : null,
            });

            if (!url) {
                return;
            }

            this.$router.push({ path: url });
        },

        focusHandler() {
            if (this.suggestions?.length === 0 && this.isSearchQueryValid) {
                this.trimInputValue();
                this.getAutocomplete();
            }
        },

        openFileUploadModal() {
            this.$modals.open(MODAL_SIMILAR_PRODUCTS_VS);

            this.$analytics.moduleEmit(CATALOG_MODULE_NAME, VISUAL_SEARCH_INPUT_BUTTON_CLICK);
        },

        trimInputValue() {
            if (this.headerSearchValue.length > 250) {
                this.headerSearchValue = this.headerSearchValue.slice(0, 250);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.header-search-input {
    @apply relative;

    :deep(.search-component .input) {
        @apply pl-ui-9;
    }

    :deep(.search-component .search-icon) {
        @apply hidden;

        @screen lg {
            @apply block;
        }
    }

    .back {
        @apply absolute left-ui-0 top-ui-0 z-1;
    }
}
</style>
