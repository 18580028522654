const { namespace } = require('./index');

function tMerchant(searchModuleTranslation, variables) {
    return this.t(`${namespace}.${searchModuleTranslation}`, variables);
}

export default function ({ app }, inject) {
    app.i18n.tMerchant = tMerchant.bind(app.i18n);

    app.$tMerchant = app.i18n.tMerchant.bind(app.i18n);

    inject('tMerchant', app.$tMerchant);
}
