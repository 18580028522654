import { WEB_PDP_1, WEB_PDP_2 } from '@types/Synerise';

export const SYNERISE_QUERY_NAMES = ['getSyneriseRecommendations'];

export const PRODUCT_PLACEMENT_PAGE_NAME = 'product';
export const CART_PLACEMENT_PAGE_NAME = 'cart';
export const EMPTY_CART_PLACEMENT_PAGE_NAME = 'empty_cart';
export const HOME_PAGE_PLACEMENT_PAGE_NAME = 'homepage';
export const CATEGORY_PLACEMENT_PAGE_NAME = 'category';
export const SEARCH_RESULTS_PLACEMENT_PAGE_NAME = 'search';
export const ERROR_PAGE_PLACEMENT_PAGE_NAME = 'error';

export const PSB_SYNERISE_CAMPAIGNS_PDP_1_MAP = 'psb_synerise_campaigns_pdp_1_map';
export const PSB_SYNERISE_CAMPAIGNS_PDP_2_MAP = 'psb_synerise_campaigns_pdp_2_map';
export const PSB_SYNERISE_CONFIG_ID = 'psb_synerise_config';

export const PLACEMENT_CATEGORY_MAP = {
    [WEB_PDP_1]: PSB_SYNERISE_CAMPAIGNS_PDP_1_MAP,
    [WEB_PDP_2]: PSB_SYNERISE_CAMPAIGNS_PDP_2_MAP,
};
