import Transparent from '@static/img/colors/transparent.png';
import Multicolor from '@static/img/colors/multicolor.png';

export const WHITE_COLOR_CODE = 'bialy';
export const EMPTY_COLOR_CODE = 'empty';
export const TRANSPARENT_COLOR_CODE = 'przezroczysty';

export const LIGHT_COLOR_CODES = [
    'szary',
    'zolty',
    'zloty',
    'kolorowy',
    'bezowy',
    'srebrny',
    'bialy',
    'przezroczysty',
    'empty',
    'biale_zloto',
    'rozowe_zlocenie',
    'rozowe_zloto',
    'srebrne_rodowanie',
    'zolte_zloto',
    'zolte_zlocenie',
    'ecru',
];

export const COLORS = {
    empty: '#e8e8e8',
    biale_zloto: '#cbcbcb',
    bialy: '#ffffff',
    ecru: '#f5f5dc',
    bezowy: '#decea5',
    zolte_zlocenie: '#e0cd67',
    zolty: '#fcd303',
    zolte_zloto: '#e2bf36',
    rozowe_zlocenie: '#cda3a9',
    rozowe_zloto: '#b88078',
    pomaranczowy: '#df8849',
    koralowy: '#ff8675',
    czerwony: '#ca2b2b',
    bordowy: '#880720',
    rozowy: '#c983a8',
    fioletowy: '#765b8b',
    blekitny: '#8fcfef',
    niebieski: '#466ea0',
    granatowy: '#3d4266',
    turkusowy: '#50acb4',
    morski: '#497283',
    zielony: '#60906d',
    oliwkowy: '#a2a247',
    khaki: '#968053',
    brazowy: '#734526',
    szary: '#d8d3d9',
    srebrne_rodowanie: '#a2a2a1',
    czarne_rodowanie: '#3a3a3a',
    czarny: '#211A23',
    zloty:
        // eslint-disable-next-line max-len
        'linear-gradient(66deg, #cdb34f  0.14%, #fce074 49.78%, #cdb34f 99.71%) center / 100% 100% no-repeat',
    srebrny:
        // eslint-disable-next-line max-len
        'linear-gradient(66deg, #bebebe  0.14%, #e4e4e4 49.78%, #bebebe 99.71%) center / 100% 100% no-repeat',
    miedziany:
        // eslint-disable-next-line max-len
        'linear-gradient(66deg, #a0442b  0.14%, #d4775e 49.78%, #a0442b 99.71%) center / 100% 100% no-repeat',
    przezroczysty: `url(${Transparent}) center / 100% 100% no-repeat`,
    kolorowy: `url(${Multicolor}) center / 100% 100% no-repeat`,
    // below to rm
    zloto_bicolour:
        // eslint-disable-next-line max-len
        'linear-gradient(45deg, #e5c768 0%, #c4b77d 50%, #adb1b6 100%) center / 100% 100% no-repeat',
};

export const COLOR_ORDER_MAP = new Map([
    ['przezroczysty', 10],
    ['empty', 20],
    ['bialy', 30],
    ['ecru', 40],
    ['bezowy', 50],
    ['zolte_zlocenie', 60],
    ['zolty', 70],
    ['zolte_zloto', 80],
    ['rozowe_zlocenie', 90],
    ['rozowe_zloto', 100],
    ['pomaranczowy', 110],
    ['koralowy', 120],
    ['czerwony', 130],
    ['bordowy', 140],
    ['rozowy', 150],
    ['fioletowy', 160],
    ['blekitny', 170],
    ['niebieski', 180],
    ['granatowy', 190],
    ['turkusowy', 200],
    ['morski', 210],
    ['zielony', 220],
    ['oliwkowy', 230],
    ['khaki', 240],
    ['brazowy', 250],
    ['szary', 260],
    ['biale_zloto', 270],
    ['srebrne_rodowanie', 280],
    ['czarne_rodowanie', 290],
    ['czarny', 300],
    ['zloto_bicolour', 310],
    ['zloty', 320],
    ['srebrny', 330],
    ['miedziany', 340],
    ['kolorowy', 350],
]);

export default {
    COLORS,
    LIGHT_COLOR_CODES,
    WHITE_COLOR_CODE,
    EMPTY_COLOR_CODE,
    TRANSPARENT_COLOR_CODE,
};
