import { PAYMENT_TYPES } from '@types/PaymentTypes';

const ONLINE_RADIO_BUTTON_LABEL = 'Pay now';
const COD_RADIO_BUTTON_LABEL = 'Pay on delivery';

const ONLINE_RADIO_BUTTON_ADDITIONAL_TEXT = 'Fast and safe';
const COD_RADIO_BUTTON_ADDITIONAL_TEXT = 'Possible restrictions on products and delivery methods';

const ONLINE_RADIO_BUTTON_ADDITIONAL_TEXT_IF_DISABLED = 'The seller does not offer online payment';
const COD_RADIO_BUTTON_ADDITIONAL_TEXT_IF_DISABLED =
    'The seller does not offer payment on delivery';

const ONLINE_REMOVE_MODAL_TITLE = 'Products below cannot be paid for on delivery';
const COD_REMOVE_MODAL_TITLE = 'Products below cannot be paid for online';

const ONLINE_METHOD_NOT_AVAILABLE_TEXT = 'This method is not available for online payments.';
const COD_METHOD_NOT_AVAILABLE_TEXT = 'This method is not available for payment on delivery.';

export const PAYMENT_TYPE_OPTIONS = {
    [PAYMENT_TYPES.ONLINE]: {
        label: ONLINE_RADIO_BUTTON_LABEL,
        additionalText: ONLINE_RADIO_BUTTON_ADDITIONAL_TEXT,
        additionalTextIfDisabled: ONLINE_RADIO_BUTTON_ADDITIONAL_TEXT_IF_DISABLED,
        removeModalTitle: ONLINE_REMOVE_MODAL_TITLE,
        methodNotAvailableText: ONLINE_METHOD_NOT_AVAILABLE_TEXT,
    },
    [PAYMENT_TYPES.COD]: {
        label: COD_RADIO_BUTTON_LABEL,
        additionalText: COD_RADIO_BUTTON_ADDITIONAL_TEXT,
        additionalTextIfDisabled: COD_RADIO_BUTTON_ADDITIONAL_TEXT_IF_DISABLED,
        removeModalTitle: COD_REMOVE_MODAL_TITLE,
        methodNotAvailableText: COD_METHOD_NOT_AVAILABLE_TEXT,
    },
};
