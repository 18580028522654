<template>
    <a :href="path" :title="title" class="text-link" :target="target">
        <slot>
            <span v-if="label">{{ label }}</span>
        </slot>
    </a>
</template>

<script>
export default {
    name: 'BaseLinkStatic',

    props: {
        path: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        target: {
            type: String,
            default: '',
        },
    },
};
</script>
