import { MEGATRON_SEARCH_GET_SEARCH_OPERATION_NAME } from '@configs/megatron-client';

import normalizeProduct from '@normalizers/models/catalogProduct';
import normalizeFilters from '@normalizers/models/filters';

const normalizer = async (response, config, helpers) => {
    const { data, error } = response;

    if (error || !data) {
        return response;
    }

    response.data.products = await Promise.all(
        data.products.map(product => {
            const { bread_crumbs: { items = [] } = {} } = data.navigation;

            return normalizeProduct(
                {
                    ...product,
                    attributes: data.attributes,
                    categories: [items],
                },
                helpers,
                config
            );
        })
    );

    if (data?.filters) {
        response.data.filters = normalizeFilters(data.filters, config);
    }

    return response;
};

export const getSearch = {
    operationName: MEGATRON_SEARCH_GET_SEARCH_OPERATION_NAME,
    normalizer,
};
