import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG } from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { PAYMENTS_ERROR_DOMAIN } from '@errors/feature-domain-names';

export default (megatronClient, $errorHandler) => ({
    async createKlarnaSession(cartId) {
        const payload = {
            cartId,
        };

        try {
            const response = await megatronClient.post('v2/payments/session', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG,
                payload,
                context: {
                    useAuthorization: true,
                },
            });

            if (response.status !== 201) {
                throw new Error('Error in Klarna session creation');
            }

            return response.data?.clientToken || null;
        } catch (error) {
            $errorHandler.captureDomainError(
                PAYMENTS_ERROR_DOMAIN,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'services.payments.klarna.create-session',
                },
                {
                    payload,
                }
            );
        }
    },
    async extraMerchantData(customerUuid) {
        try {
            return await megatronClient.get(
                `v1/klarna/customer/${customerUuid}/extra-merchant-data`,
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG,
                    context: {
                        useAuthorization: false,
                    },
                }
            );
        } catch (error) {
            $errorHandler.captureDomainError(
                PAYMENTS_ERROR_DOMAIN,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'services.payments.klarna.extra-merchant-data',
                },
                {
                    payload: {
                        customerUuid,
                    },
                }
            );
        }
    },
});
