export const types = {
    SET_BEFORE_INSTALL_PROMPT_EVENT: 'SET_BEFORE_INSTALL_PROMPT_EVENT',
    SET_IS_INSTALLATION_PENDING: 'SET_IS_INSTALLATION_PENDING',
    SET_WAS_INSTALLATION_SUCCESSFUL: 'SET_WAS_INSTALLATION_SUCCESSFUL',
    SET_DISPLAY_MODE: 'SET_DISPLAY_MODE',
};

export default {
    [types.SET_BEFORE_INSTALL_PROMPT_EVENT](state, beforeInstallPromptEvent) {
        state.beforeInstallPromptEvent = beforeInstallPromptEvent;
    },
    [types.SET_IS_INSTALLATION_PENDING](state, isInstallationPending) {
        state.isInstallationPending = isInstallationPending;
    },
    [types.SET_WAS_INSTALLATION_SUCCESSFUL](state, wasInstallationSuccessful) {
        state.wasInstallationSuccessful = wasInstallationSuccessful;
    },
    [types.SET_DISPLAY_MODE](state, displayMode) {
        state.displayMode = displayMode;
    },
};
