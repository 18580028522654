export const getFirstOfferFromVariant = ({ offers }) => offers?.[0] || null;

export const hasOnlyInternalFirstOffers = (variants = {}) => {
    const offers = Object.values(variants)
        .map(getFirstOfferFromVariant)
        .filter(offer => offer);

    if (!offers.length) {
        return false;
    }

    return offers.every(({ store: { internal } = {} }) => internal === true);
};

export const getUniqueSellers = variants => {
    if (Object.keys(variants).length === 0) {
        return [];
    }

    const offers = Object.values(variants)
        .map(getFirstOfferFromVariant)
        .filter(offer => offer);

    const storesMap = offers.reduce((acc, offer) => {
        const { store } = offer;

        if (!store) {
            return acc;
        }

        acc[store.id] = store;

        return acc;
    }, {});

    return Object.values(storesMap);
};

export const getLowestOfferPriceInfo = offers => {
    const offersFiltered = offers.filter(offer => offer);

    if (offersFiltered.length === 0) {
        return {
            isLowestPrice: false,
            hasDiscount: false,
            price: '',
            finalPrice: '',
        };
    }

    const offerPricesMap = new Map(
        offersFiltered.map(offer => {
            const {
                final_price: { amount: finalAmount },
            } = offer;

            return [finalAmount, offer];
        })
    );

    const offerPrices = new Set(offerPricesMap.keys());

    const isLowestPrice = offerPrices.size > 1;

    const lowestOffer = offerPricesMap.get(Math.min(...offerPrices));

    const {
        price: { amount: regularAmount, formatted: price },
        final_price: { amount: finalAmount, formatted: finalPrice },
    } = lowestOffer;

    const hasDiscount = regularAmount > finalAmount;

    return {
        price,
        finalPrice,
        isLowestPrice,
        hasDiscount,
    };
};

export const isLowestOfferInternal = variants => {
    const allOffers = Object.values(variants)
        .flatMap(({ offers = [] }) => offers)
        .filter(offer => offer);

    if (!allOffers.length) {
        return false;
    }

    const lowestOffer = allOffers.reduce(
        (acc, { final_price: { amount: price }, store: { internal: isInternal } }) => {
            const { price: lowestPrice } = acc;

            if ((price === lowestPrice && isInternal) || price < lowestPrice) {
                return {
                    price,
                    isInternal,
                };
            }

            return acc;
        },
        {
            price: Number.POSITIVE_INFINITY,
            isInternal: true,
        }
    );

    return lowestOffer.isInternal;
};

export const hasOnlyExternalFirstOffers = (variants = {}) => {
    const offers = Object.values(variants)
        .map(getFirstOfferFromVariant)
        .filter(offer => offer);

    if (!offers.length) {
        return false;
    }

    return offers.every(({ store: { internal } = {} }) => internal === false);
};

export const isCheapestOfferStoreInternal = (variants = {}) => {
    const offers = Object.values(variants)
        .map(getFirstOfferFromVariant)
        .filter(offer => offer)
        .sort(
            ({ final_price: { amount: amount1 } }, { final_price: { amount: amount2 } }) =>
                amount1 - amount2
        );

    if (!offers.length) {
        return true;
    }

    const { amount: cheapestAmount } = offers[0].final_price;

    return offers.some(
        ({ store: { internal }, final_price: { amount } }) => internal && amount === cheapestAmount
    );
};
