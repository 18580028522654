export default {
    isAsyncModuleRegistered: state => moduleName =>
        state.asyncModulesRegistered.includes(moduleName),

    isConfigValid: ({ dataLoaded }) => {
        const {
            navigation,
            cmsBlocks,
            marketingBarConfig,
            loyaltyClubStatusesData,
            loyaltyClubSettings,
        } = dataLoaded;

        return (
            navigation &&
            cmsBlocks &&
            marketingBarConfig &&
            loyaltyClubStatusesData &&
            loyaltyClubSettings
        );
    },
};
