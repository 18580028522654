export const capitalizeFirstLetter = text => {
    if (typeof text !== 'string') {
        return text;
    }

    return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};

export const snakeCaseToCamelCase = snakeCaseString => {
    const [firstPart, ...parts] = snakeCaseString.split('_');

    return `${firstPart}${parts.map(capitalizeFirstLetter).join('')}`;
};

export const replaceABTestBankTransfer = (content, replacedString, newString) => {
    return content.replaceAll(replacedString, newString);
};

export const snakeCase = string => {
    const parts = string?.split(/[.\-\s_]/).map(part => part.toLowerCase()) || [];

    return parts.join('_');
};

export const splitTextByTokens = (text, tokens) => {
    if (tokens.length === 0) {
        return [text];
    }

    const token = tokens.shift();
    const parts = text.split(token).map(part => splitTextByTokens(part, Array.from(tokens)));

    const partsWithToken = parts.reduce((acc, part, index) => {
        if (index === 0) {
            return [part];
        }

        acc.push(token, part);

        return acc;
    }, []);

    return partsWithToken.flat(Number.POSITIVE_INFINITY);
};
