<template>
    <div class="navigation-bar">
        <nav class="navigation">
            <NavigationItem
                v-for="category in navigationCategories"
                :key="category.id"
                :category="category"
                :is-active="currentlyHoveredCategory === category.id && isDesktopMenuOpen"
                @hover="handleMouseenter(category.id)"
                @mouseleave.native="handleMouseleave()"
            />
        </nav>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { DESKTOP_MENU } from '@header/configs/modals';

import NavigationItem from '@header-atoms/NavigationItem/NavigationItem';

const CURRENTLY_HOVERED_CATEGORY_TIMEOUT_VALUE = 300;
const LEAVE_CATEGORY_TIMEOUT_VALUE = CURRENTLY_HOVERED_CATEGORY_TIMEOUT_VALUE;
const UNSELECTED_CATEGORY_ID_VALUE = -1;

const { mapGetters: mapNavigationGetters } = createNamespacedHelpers('header/navigation');

export default {
    name: 'NavigationBar',

    components: {
        NavigationItem,
    },

    data() {
        return {
            currentlyHoveredCategory: -1,
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapNavigationGetters(['categories']),

        isDesktopMenuOpen() {
            return this.$modals.isOpen(DESKTOP_MENU);
        },

        navigationCategories() {
            if (!this.$navigationContext.isMenuContextual) {
                return this.categories[0]?.children || [];
            }

            const contextId = this.$navigationContext.getContextId();

            return this.categories.find(({ id }) => id === contextId)?.children || [];
        },
    },

    watch: {
        isMobile() {
            this.resetAllHoverConfiguration();
        },

        $route() {
            this.$modals.close(DESKTOP_MENU);
        },
    },

    methods: {
        handleMouseenter(categoryId) {
            const timeoutCallback = () => {
                this.currentlyHoveredCategory = categoryId;
                this.$modals.open(DESKTOP_MENU);
            };

            if (this.currentlyHoveredCategory !== UNSELECTED_CATEGORY_ID_VALUE) {
                this.clearLeaveCategoryTimeout();
                this.clearCurrentlyHoveredCategoryTimeout();
            }

            this.currentlyHoveredCategoryTimeout = setTimeout(
                timeoutCallback,
                CURRENTLY_HOVERED_CATEGORY_TIMEOUT_VALUE
            );
        },

        handleMouseleave() {
            this.clearCurrentlyHoveredCategoryTimeout();

            this.leaveCategoryTimeout = setTimeout(() => {
                this.currentlyHoveredCategory = UNSELECTED_CATEGORY_ID_VALUE;
                this.$modals.close(DESKTOP_MENU);
            }, LEAVE_CATEGORY_TIMEOUT_VALUE);
        },

        resetAllHoverConfiguration() {
            this.clearCurrentlyHoveredCategoryTimeout();

            this.clearLeaveCategoryTimeout();

            this.currentlyHoveredCategory = UNSELECTED_CATEGORY_ID_VALUE;
        },

        clearCurrentlyHoveredCategoryTimeout() {
            clearTimeout(this.currentlyHoveredCategoryTimeout);

            this.currentlyHoveredCategoryTimeout = null;
        },

        clearLeaveCategoryTimeout() {
            clearTimeout(this.leaveCategoryTimeout);

            this.leaveCategoryTimeout = null;
        },
    },
};
</script>

<style scoped lang="scss">
.navigation-bar {
    .navigation {
        @apply flex items-center gap-x-ui-5 h-full;
    }

    @screen lg {
        @apply px-ui-4 w-max-ui-content mx-auto h-ui-12;
    }

    @media (min-width: 1280px) {
        .navigation {
            @apply gap-x-ui-7;
        }
    }
}
</style>
