export const ACCOUNT_PAGE = 'account_page';
export const CHECKOUT_PAGE_LOGGED_IN = 'checkout_page_logged_in';
export const CHECKOUT_PAGE_GUEST = 'checkout_page_guest';
export const CHECKOUT_PAGE_GUEST_NEW_ACCOUNT = 'checkout_page_guest_new_account';
export const CUSTOMER_REGISTRATION_PAGE = 'customer_registration_page';
export const NEWSLETTER_REGISTRATION = 'newsletter_registration';
export const CHECKOUT_PAGE = 'checkout_page';
export const CONTACT_PAGE = 'contact_page';
export const BLIK_PAYMENT_AGREEMENT_CODE = 'blik_payment';
export const LOYALTY_CLUB = 'loyalty_club';
export const PAYU_PAYMENT_AGREEMENT_CODE = 'payu_payment';
export const PRODUCT_ALERT_INFO = 'product_stock_alert';
export const CUSTOMER_REGISTRATION_THROUGH_SOCIAL_POPUP =
    'customer_registration_through_social_popup';
export const WISHLIST = 'wishlist';

export const INPUT_TYPE_EMPTY = 'empty';
export const INPUT_TYPE_CHECKBOX = 'checkbox';
export const INPUT_TYPE_RADIO = 'radio';
export const INPUT_TYPE_SELECT = 'select';

export const IS_SUBSCRIBED_CODE = 'is_subscribed';
export const SMS_COMMUNICATION = 'sms_communication';
export const TERMS = 'terms';

export const SOURCE_TYPE = 'web';
