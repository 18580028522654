export const localeToHreflang = locale => {
    const [lang = '', region] = locale?.toLowerCase().split('_') || [];

    return !region || lang === region ? lang : `${lang}-${region}`;
};

export const toHreflangLink = (locale = '', href = '') => ({
    rel: 'alternate',
    hreflang: localeToHreflang(locale),
    href,
});

export const metaLinksWithSlug = ({ locales = [], createPath, callback }) => {
    return locales
        .map(({ locale, base_url }) => {
            if (!callback) {
                return null;
            }

            const slug = callback(locale);

            if (!slug) {
                return null;
            }

            return toHreflangLink(
                locale,
                `https://${base_url}${createPath ? createPath(slug) : slug}`
            );
        })
        .filter(localeLink => localeLink);
};
