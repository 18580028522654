<template>
    <div>
        <MarketingBarDataProvider />
        <HeaderWrapper />
        <main>
            <ContainerContent>
                <div class="error">
                    <BaseHeading tag="h1" theme="h5">
                        {{ $t('Oops... this page no longer exists') }}
                    </BaseHeading>
                    <BaseParagraph>
                        {{ $t('It appears to have been moved or removed.') }}
                    </BaseParagraph>
                    <BaseParagraph>
                        {{
                            $t(
                                // eslint-disable-next-line max-len
                                'Check out our proposals below and maybe you will find what you are looking for!'
                            )
                        }}
                    </BaseParagraph>
                    <BaseButton to="/">
                        {{ $t('Go to homepage') }}
                    </BaseButton>
                </div>
            </ContainerContent>

            <RecommendedProductsSimpleSliderWrapperNew
                :recommendation-type="WEB_ERROR_PAGE_1"
                :placement-page-name="ERROR_PAGE_PLACEMENT_PAGE_NAME"
            />
        </main>
        <Messages />
        <BaseFooter />
        <ChatWrapper />
    </div>
</template>

<script>
import { ERROR_PAGE_PLACEMENT_PAGE_NAME } from '@configs/synerise';

import { WEB_ERROR_PAGE_1 } from '@types/Synerise';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BaseButton from '@atoms/BaseButton/BaseButton';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import Messages from '@molecules/Messages/Messages';

import BaseFooter from '@organisms/BaseFooter/BaseFooter';
import RecommendedProductsSimpleSliderWrapperNew from '@organisms/RecommendedProductsSimpleSliderWrapperNew/RecommendedProductsSimpleSliderWrapperNew';
import HeaderWrapper from '@header-organisms/HeaderWrapper/HeaderWrapper';

import MarketingBarDataProvider from '@marketing-bar-components/organisms/MarketingBarDataProvider/MarketingBarDataProvider';

export default {
    name: 'ErrorPage404',

    components: {
        RecommendedProductsSimpleSliderWrapperNew,
        ChatWrapper,
        BaseFooter,
        ContainerContent,
        BaseHeading,
        BaseParagraph,
        BaseButton,
        HeaderWrapper,
        Messages,
        MarketingBarDataProvider,
    },

    beforeCreate() {
        this.WEB_ERROR_PAGE_1 = WEB_ERROR_PAGE_1;
        this.ERROR_PAGE_PLACEMENT_PAGE_NAME = ERROR_PAGE_PLACEMENT_PAGE_NAME;
    },
};
</script>

<style lang="scss" scoped>
.error {
    @apply text-center pt-4 pb-5 mb-4;

    &:deep() {
        ._heading-h5 {
            @apply pb-4;
        }

        .paragraph {
            @apply text-center;
        }

        .base-button {
            @apply mt-5;
        }
    }

    @screen lg {
        @apply pt-10 pb-7;

        ._heading-h5 {
            @apply text-xxl leading-xxl;
        }
    }
}
</style>
