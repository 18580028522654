export const NAVIGATION = 'navigation';
export const CMS_BLOCKS = 'cmsBlocks';
export const PAGE_BUILDER_BLOCKS = 'pageBuilderBlocks';
export const MARKETING_BAR_CONFIG = 'marketingBarConfig';
export const LOYALTY_CLUB_STATUSES_DATA = 'loyaltyClubStatusesData';
export const LOYALTY_CLUB_SETTINGS = 'loyaltyClubSettings';
export const IS_STRICT_PHONE_VALIDATION_ENABLED_FLAG = 'isStrictPhoneValidationEnabledFlag';

export const types = {
    SET_DATA_LOADED: 'SET_DATA_LOADED',
    SET_IS_MOBILE: 'SET_IS_MOBILE',
    SET_IS_MOBILE_EVALUATED: 'SET_IS_MOBILE_EVALUATED',
    SET_IS_TABLET_OR_DESKTOP: 'SET_IS_TABLET_OR_DESKTOP',
    SET_IS_TABLET_OR_DESKTOP_EVALUATED: 'SET_IS_TABLET_OR_DESKTOP_EVALUATED',
    SET_IS_DESKTOP: 'SET_IS_DESKTOP',
    SET_IS_DESKTOP_EVALUATED: 'SET_IS_DESKTOP_EVALUATED',
    SET_IS_CHAT_WIDGET_LOADING: 'SET_IS_CHAT_WIDGET_LOADING',
    SET_IS_CHAT_WIDGET_LOADED: 'SET_IS_CHAT_WIDGET_LOADED',
    SET_ASYNC_MODULE_REGISTERED: 'SET_ASYNC_MODULE_REGISTERED',
    ADD_PERFORMANCE_ENTRY: 'ADD_PERFORMANCE_ENTRY',
    SET_STANLEY_PREFIX: 'SET_STANLEY_PREFIX',
    SET_PRINTABLE_CONTENT_ONLY: 'SET_PRINTABLE_CONTENT_ONLY',
    SET_ROUTE_RENDER_START: 'SET_ROUTE_RENDER_START',
};

export default {
    [types.SET_DATA_LOADED](state, { dataLoadedName, isValid }) {
        if (typeof state.dataLoaded[dataLoadedName] === 'undefined') {
            return;
        }

        state.dataLoaded = {
            ...state.dataLoaded,
            [dataLoadedName]: isValid,
        };
    },

    [types.SET_IS_MOBILE](state, isMobile) {
        state.isMobile = isMobile;
    },

    [types.SET_IS_MOBILE_EVALUATED](state, isMobileEvaluated) {
        state.isMobileEvaluated = isMobileEvaluated;
    },

    [types.SET_IS_TABLET_OR_DESKTOP](state, isTabletOrDesktop) {
        state.isTabletOrDesktop = isTabletOrDesktop;
    },

    [types.SET_IS_TABLET_OR_DESKTOP_EVALUATED](state, isTabletOrDesktopEvaluated) {
        state.isTabletOrDesktopEvaluated = isTabletOrDesktopEvaluated;
    },

    [types.SET_IS_DESKTOP](state, isDesktop) {
        state.isDesktop = isDesktop;
    },

    [types.SET_IS_DESKTOP_EVALUATED](state, isDesktopEvaluated) {
        state.isDesktopEvaluated = isDesktopEvaluated;
    },

    [types.SET_IS_CHAT_WIDGET_LOADING](state, isChatWidgetLoading) {
        state.isChatWidgetLoading = isChatWidgetLoading;
    },

    [types.SET_IS_CHAT_WIDGET_LOADED](state, isChatWidgetLoaded) {
        state.isChatWidgetLoaded = isChatWidgetLoaded;
    },

    [types.SET_ASYNC_MODULE_REGISTERED](state, moduleName) {
        state.asyncModulesRegistered.push(moduleName);
        state.asyncModulesRegistered = [...new Set(state.asyncModulesRegistered)];
    },

    [types.ADD_PERFORMANCE_ENTRY](state, entry) {
        state.performance.push(entry);
    },

    [types.SET_STANLEY_PREFIX](state, stanleyPrefix) {
        state.stanleyPrefix = stanleyPrefix;
    },

    [types.SET_PRINTABLE_CONTENT_ONLY](state, isPrintableContentOnly) {
        state.isPrintableContentOnly = isPrintableContentOnly;
    },

    [types.SET_ROUTE_RENDER_START](state, entry) {
        state.routeRenderStart = entry;
    },
};
