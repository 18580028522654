const { IS_DEV } = require('./assets/is-dev.cjs');

export default (ctx, inject) => {
    const clientLogger = {
        info: () => {
            if (IS_DEV) {
                console.warn('[Logger] Logs are not available on the client side.');
            }
        },
        warn: () => {
            if (IS_DEV) {
                console.warn('[Logger] Logs are not available on the client side.');
            }
        },
        error: () => {
            if (IS_DEV) {
                console.warn('[Logger] Logs are not available on the client side.');
            }
        },
        fatal: () => {
            if (IS_DEV) {
                console.warn('[Logger] Logs are not available on the client side.');
            }
        },
    };

    inject('logger', clientLogger);
};
