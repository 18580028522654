<template>
    <div class="social-icons">
        <div v-if="socialMediaLinksMap.length" class="icons">
            <ButtonSocialMedia
                v-for="({ link, icon }, linkId) in socialMediaLinksMap"
                :key="linkId"
                :to="link"
                target="_blank"
                class="icon"
            >
                <template #icon>
                    <Icon :icon="icon" />
                </template>
            </ButtonSocialMedia>
        </div>
    </div>
</template>

<script>
import { Instagram, Facebook, Youtube, TikTok, Pinterest } from '@modivo-ui/icons/v2/social-media';

import { ButtonSocialMedia } from '@modivo-ui/components/ButtonSocialMedia/v1';
import { Icon } from '@modivo-ui/components/Icon/v1';

export default {
    name: 'SocialIcons',

    components: {
        ButtonSocialMedia,
        Icon,
    },

    props: {
        instagramUrl: {
            type: String,
            default: '',
        },

        facebookUrl: {
            type: String,
            default: '',
        },

        youtubeUrl: {
            type: String,
            default: '',
        },

        tiktokUrl: {
            type: String,
            default: '',
        },

        pinterestUrl: {
            type: String,
            default: '',
        },
    },

    computed: {
        socialMediaLinksMap() {
            return [
                {
                    link: this.instagramUrl,
                    icon: Instagram,
                },
                {
                    link: this.youtubeUrl,
                    icon: Youtube,
                },
                {
                    link: this.facebookUrl,
                    icon: Facebook,
                },
                {
                    link: this.tiktokUrl,
                    icon: TikTok,
                },
                {
                    link: this.pinterestUrl,
                    icon: Pinterest,
                },
            ].filter(({ link }) => link);
        },
    },
};
</script>

<style lang="scss" scoped>
.social-icons {
    @apply text-center;

    .icons {
        @apply flex justify-center;
        @apply flex-wrap;
        @apply gap-ui-4;
    }

    @screen lg {
        @apply text-left;

        .icons {
            @apply justify-start;
        }
    }
}
</style>
