import { getChosenSizeLabel, getVariantKey } from '@assets/product';
import { checkIfOn } from '@assets/product-badge';
import { calculateAverageFromRatings } from '@assets/reviews';

const BELONG_TYPES = {
    MIX: 'mix',
    INTERNAL: 'internal',
    EXTERNAL: 'external',
};

const FIELD_REGULAR_PRICE = 'price';
const FIELD_FINAL_PRICE = 'final_price';
const FIELD_OMNIBUS_PRICE = 'omnibus_price';

const OWN_BRANDS = [
    'Americanos',
    'Creole',
    'Eva Longoria',
    'Eva Minge',
    'Rage Age',
    'Simple',
    'Sprandi',
    'Togoshi',
];

const BRAND_TYPE = {
    OWN_BRAND: 'own brand',
    FOREIGN_BRAND: 'foreign brand',
};

const EMPTY_VALUE = 'brak';
const ONE_SIZE = 'one size';
const MULTI_SIZE = 'multi size';
const SINGLE_OFFER = 'single offer';
const MULTI_OFFER = 'multi offer';

const priceRange = prices => {
    const min = Math.min(...prices);
    const max = Math.max(...prices);

    return `${min}-${max}`;
};

const getPriceFromOffer = (offer, priceField) => offer?.[priceField]?.amount;

const mapOffersToPrices = (offers, priceField) =>
    offers?.map(offer => getPriceFromOffer(offer, priceField)) || [];

const getPriceFromOffers = (offers, priceField) => {
    const prices = mapOffersToPrices(offers, priceField).filter(Boolean);

    switch (prices.length) {
        case 0:
            return '';
        case 1:
            return prices[0];
        default:
            return priceRange(prices);
    }
};

export default class DataLayerProduct {
    constructor({
        product = {},
        reviews = {},
        quantity = '',
        offerId = '',
        variantId = '',
        position,
        shippingMethod,
        discountedPrice,
        selectedPaymentMethod = '',
        selectedPaymentType = '',
    }) {
        this.product = product;
        this.reviews = reviews;
        this.quantity = quantity;
        this.offerId = offerId;
        this.variantId = variantId;
        this.chosenSize = getVariantKey(product.variants, variantId);
        this.chosenSizeLabel = getChosenSizeLabel(product, this.chosenSize);
        this.position = position;
        this.shippingMethod = shippingMethod;
        this.discountedPrice = discountedPrice;
        this.selectedPaymentMethod = selectedPaymentMethod;
        this.selectedPaymentType = selectedPaymentType;
    }

    getChosenSizeId() {
        const {
            chosenSize,
            product: {
                analytics: { sku },
            },
        } = this;

        return chosenSize ? `${sku}-${chosenSize.replaceAll('_', '.')}` : `${sku}`;
    }

    getCategoriesString() {
        const { categories, localeDefault } = this.product.analytics;
        const [firstCategories] = categories || [];

        if (
            !Array.isArray(firstCategories) ||
            firstCategories.some(category => !category.translations[localeDefault]?.label)
        ) {
            return '';
        }

        return firstCategories
            .map(category => category.translations[localeDefault].label)
            .slice(1)
            .join('/');
    }

    variantsInStock() {
        return Object.values(this.product.analytics.variants).reduce(
            (all, { stock_quantity: stockQuantity, size }) => {
                const variantQuantity = parseInt(stockQuantity, 10);

                if (variantQuantity > 0) {
                    all.totalCount += variantQuantity;
                    all.sizes.push(size);
                }

                return all;
            },
            {
                totalCount: 0,
                sizes: [],
            }
        );
    }

    allProductVariants() {
        return Object.values(this.product.analytics.variants)
            .map(({ size }) => size)
            .join(',');
    }

    checkHasMultiOffer() {
        const { variants } = this.product.analytics;

        return Object.values(variants).some(({ offers }) => offers?.length > 1);
    }

    getProductOfferType() {
        const { isOneSize } = this.product;

        const sizeType = isOneSize ? ONE_SIZE : MULTI_SIZE;

        const hasMultiOffer = this.checkHasMultiOffer();

        const offerType = hasMultiOffer ? MULTI_OFFER : SINGLE_OFFER;

        return `${sizeType} | ${offerType}`;
    }

    getTotalCount() {
        return this.reviews?.totalCountAll ?? '';
    }

    getAverageRating() {
        return calculateAverageFromRatings(this.reviews?.averageRatings);
    }

    getPremiumValue() {
        return this.product.analytics.isPremium ? 'Premium' : 'NonPremium';
    }

    getIsInStockValue() {
        return this.variantsInStock().totalCount > 0 ? 'In stock' : 'Out of stock';
    }

    getBadges() {
        const allBadges = this.product.analytics.productBadgeConfig;
        const productBadges = allBadges.map(badge => checkIfOn(badge, allBadges));

        const badges = Object.values(this.product.analytics.badges).map(({ label }) => label);

        if (productBadges.length) {
            badges.push(...productBadges);
        }

        return badges.join('/');
    }

    getShippingMethod() {
        return this.shippingMethod ? `shipping_method:${this.shippingMethod}` : '';
    }

    getOffers() {
        const { variants = [] } = this.product.analytics;

        const { offerId } = this;

        return Object.values(variants)
            .flatMap(({ offers }) => offers)
            .filter(offer => offer && (!offerId || offer.id === offerId));
    }

    static getOfferType(offers) {
        const internalValues = [offers]
            .flat()
            .map(({ store: { internal } }) =>
                internal ? BELONG_TYPES.INTERNAL : BELONG_TYPES.EXTERNAL
            );
        const hasMixedTypes =
            internalValues.includes(BELONG_TYPES.INTERNAL) &&
            internalValues.includes(BELONG_TYPES.EXTERNAL);

        return `${hasMixedTypes ? BELONG_TYPES.MIX : internalValues[0] || ''}`;
    }

    static getBelongsType(offers) {
        return `belongs_type:${this.getOfferType(offers)}`;
    }

    static getBrandType(offers, brandName) {
        const isOwnBrand = OWN_BRANDS.includes(brandName);

        if (!offers?.length) {
            return '';
        }

        return `${this.getOfferType(offers)}:${
            isOwnBrand ? BRAND_TYPE.OWN_BRAND : BRAND_TYPE.FOREIGN_BRAND
        }`;
    }

    static getMerchant(offers) {
        const merchants = [...new Set([offers].flat().map(({ store: { name } }) => name))].join(
            ','
        );

        return `merchant:${merchants}`;
    }

    getRegularPrice(offers) {
        if (!offers?.length) {
            const defaultAmount = this.product.analytics.price.regular.amount;

            return this.chosenSizeLabel ? defaultAmount : `${defaultAmount}-${defaultAmount}`;
        }

        return getPriceFromOffers(offers, FIELD_REGULAR_PRICE);
    }

    getPromotionalPrice(offers) {
        if (!offers?.length) {
            const defaultAmount = this.product.analytics.price.promotional.amount;

            return this.chosenSizeLabel ? defaultAmount : `${defaultAmount}-${defaultAmount}`;
        }

        return getPriceFromOffers(offers, FIELD_FINAL_PRICE);
    }

    getOmnibusPrice(offers) {
        if (!offers?.length) {
            const defaultAmount = this.product.analytics.price?.omnibus?.amount || '';

            return this.chosenSizeLabel ? defaultAmount : `${defaultAmount}-${defaultAmount}`;
        }

        return getPriceFromOffers(offers, FIELD_OMNIBUS_PRICE);
    }

    getStickerType() {
        if (!this.product.hasPromoSticker) {
            return '';
        }

        const { isHotDeal, formattedMarketingLabelContent, code } = this.product.promoSticker;

        const type = isHotDeal ? 'hot deal' : 'standard';

        return `typ: ${type} | ${formattedMarketingLabelContent} | ${code || ''}#is_on:1`;
    }

    getDiscountedPrice(offers) {
        const prices = mapOffersToPrices(offers, FIELD_FINAL_PRICE);

        return prices.length
            ? Math.min(...prices)
            : this.discountedPrice || this.product.analytics.price.promotional.amount;
    }

    getMetric1(offers) {
        const prices = mapOffersToPrices(offers, FIELD_REGULAR_PRICE);

        return prices.length ? Math.min(...prices) : this.product.analytics.price.regular.amount;
    }

    getMetric2(offers) {
        const prices = mapOffersToPrices(offers, FIELD_FINAL_PRICE);

        return prices.length
            ? Math.min(...prices)
            : this.product.analytics.price.promotional.amount;
    }

    static productTypes = {
        SPONSORED: 'sponsored',
        NORMAL: 'normal',
        RECO: 'reco',
    };

    static buildResult(data) {
        const {
            id = '',
            name = '',
            price = '',
            brand = '',
            category = '',
            colorVariant = '',
            quantity = '',
            fason = '',
            premiumValue = '',
            reviewsTotalCount = '',
            sizesInStockCount = '',
            isInStockValue = '',
            regularPrice = '',
            omnibusPrice = '',
            chosenSizeId = '',
            chosenSize = '',
            brandName = '',
            model = '',
            style = '',
            sexName = '',
            purpose = '',
            seriesName = '',
            variantsInStockSizes = '',
            badges = '',
            variantsInStockTotalCount = '',
            belongsType = '',
            brandType = '',
            shippingMethods = '',
            merchant = '',
            promotionalPrice = '',
            discountedPrice = '',
            selectedPaymentMethod = '',
            stickerType = '',
            allProductVariants = '',
            productOfferType = '',
            metric1 = '',
            metric2 = '',
            metric3 = '',
            position,
            productType = '',
            reviews = `${EMPTY_VALUE}|${EMPTY_VALUE}`,
            selectedPaymentType,
            recommendationCampaignName = '',
            isOneSize = false,
            hasMultiOffer = false,
            mainImageType = '',
            index,
        } = data;

        const result = {
            id,
            name,
            price: chosenSize ? discountedPrice : price,
            brand,
            category,
            variant: colorVariant,
            quantity,
            index,
            dimension4: fason,
            dimension5: premiumValue,
            dimension13: reviewsTotalCount,
            dimension14: sizesInStockCount,
            dimension16: isInStockValue,
            dimension26: regularPrice,
            dimension30: chosenSizeId,
            dimension31: chosenSize,
            dimension32: brandName, // @todo replace with other attribute
            dimension33: model,
            dimension34: style,
            dimension35: sexName,
            dimension36: purpose,
            dimension37: seriesName,
            dimension38: '', // @todo fill ex. ozdoby, np. 'cekiny'
            dimension39: '', // @todo fill ex. projektant, np. 'Gigi Hadid'
            dimension40: variantsInStockSizes,
            dimension41: badges,
            dimension42: variantsInStockTotalCount,
            // eslint-disable-next-line max-len
            dimension43: '', // @todo fill materiał np. 'zamsz' (mateiral_odziez ale co dla innego typu produktów?)
            dimension44: '', // @todo fill kolekcja, np 'S.Oliver Black Label'
            dimension64: reviews,
            dimension69: allProductVariants,
            dimension95: productOfferType,
            dimension98: omnibusPrice,
            dimension101: belongsType,
            dimension102: shippingMethods,
            dimension103: merchant,
            dimension104: promotionalPrice,
            dimension105: discountedPrice,
            dimension108: selectedPaymentMethod,
            dimension109: brandType,
            dimension110: selectedPaymentType,
            dimension112: mainImageType,
            dimension130: productType,
            dimension131: stickerType,
            dimension134: recommendationCampaignName,
            metric1,
            metric2,
            metric3,
            multiSize: !isOneSize,
            multiOffer: hasMultiOffer,
        };

        if (position) {
            result.position = position;
        }

        return result;
    }

    static buildSimpleResult(product) {
        const {
            sku: id,
            name,
            price,
            brandName,
            quantity,
            fason = '',
            index = 0,
            categories = [],
        } = product || {};

        return {
            id,
            name,
            price: price?.promotional?.amount || '',
            brand: brandName,
            category: categories[0]?.split('/').slice(1).join('/') || '',
            index: index + 1 || 1,
            quantity,
            fason,
        };
    }

    build() {
        if (!this.product.analytics) {
            return DataLayerProduct.buildSimpleResult(this.product);
        }

        const {
            brandName,
            color,
            fason,
            model,
            name,
            seriesName,
            sexName,
            sku,
            style,
            price,
            purpose,
            isSponsored,
            mainImageType,
        } = this.product.analytics;

        const variantsInStock = this.variantsInStock();
        const offers = this.getOffers();
        const discountedPrice = this.getDiscountedPrice(offers);

        const reviewsTotalCount = this.getTotalCount();
        const averageRating = this.getAverageRating();

        const reviews = `${averageRating || ''}|${reviewsTotalCount || EMPTY_VALUE}`;
        const productName = name || this.product.name;

        const data = {
            id: sku,
            name: productName,
            price: price.promotional.amount,
            brand: brandName,
            category: this.getCategoriesString(),
            colorVariant: color,
            quantity: this.quantity,
            fason,
            index: this.product.index + 1 || 1,
            premiumValue: this.getPremiumValue(),
            reviewsTotalCount,
            sizesInStockCount: variantsInStock.sizes.length,
            isInStockValue: this.getIsInStockValue(),
            regularPrice: this.getRegularPrice(offers),
            omnibusPrice: this.getOmnibusPrice(offers),
            chosenSizeId: this.getChosenSizeId(),
            chosenSize: this.chosenSize,
            brandName,
            model,
            style: style.join(','),
            sexName,
            purpose: purpose.join(','),
            seriesName,
            variantsInStockSizes: variantsInStock.sizes.join(','),
            badges: this.getBadges(),
            variantsInStockTotalCount: variantsInStock.totalCount,
            belongsType: DataLayerProduct.getBelongsType(offers),
            brandType: DataLayerProduct.getBrandType(offers, brandName),
            shippingMethods: this.getShippingMethod(),
            selectedPaymentMethod: this.selectedPaymentMethod,
            merchant: DataLayerProduct.getMerchant(offers),
            promotionalPrice: this.getPromotionalPrice(offers),
            stickerType: this.getStickerType(),
            allProductVariants: this.allProductVariants(),
            productOfferType: this.getProductOfferType(),
            discountedPrice,
            metric1: this.getMetric1(offers),
            metric2: this.getMetric2(offers),
            metric3: discountedPrice,
            position: this.position,
            productType: isSponsored
                ? DataLayerProduct.productTypes.SPONSORED
                : DataLayerProduct.productTypes.NORMAL,
            reviews,
            selectedPaymentType: this.selectedPaymentType,
            recommendationCampaignName: this.product.recommendationCampaignName,
            isOneSize: this.product.isOneSize,
            hasMultiOffer: this.checkHasMultiOffer(),
            mainImageType,
        };

        return DataLayerProduct.buildResult(data);
    }
}
