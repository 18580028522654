<template>
    <Observer
        v-if="displayContainer"
        :options="CONTAINER_OBSERVER_OPTIONS"
        :observe-once="true"
        class="recommended-products-simple-slider-wrapper-new"
        :class="{
            'is-loading': !isLoaded,
            'with-heading-and-subheading': heading && subheading,
            'is-inside-container': isInsideContainer,
        }"
        @intersect="displayContent($event)"
    >
        <template v-if="isDisplayed">
            <Loader v-if="!isLoaded" />
            <RecommendedProductsDataProvider
                :recommendation-type="recommendationType"
                :category-breadcrumbs="categoryBreadcrumbs"
                :product-sku="productSku"
                :custom-campaign-id="customCampaignId"
                :image-url="imageUrl"
                :categories-to-exclude-in-visual-search="categoriesToExcludeInVisualSearch"
                :is-visual-recommendation="isVisualRecommendation"
                :gender="gender"
                :size="size"
                @loaded="onLoadedProducts($event)"
                @error="handleError()"
            >
                <div v-if="isLoaded">
                    <component
                        :is="presentationComponent"
                        :products="productsLoaded"
                        :items-count="itemsCount"
                        :heading="heading"
                        :subheading="subheading"
                        :link="link"
                        :action-field="recommendationType"
                        @product-click="onProductClick($event)"
                        @see-more-click="emitRecommendationEvent(RECOMMENDATIONS_SEE_MORE)"
                        @scroll="emitRecommendationEvent(RECOMMENDATIONS_SCROLL)"
                        @wishlist-button-click="onAddToWishlistClick($event)"
                    >
                        <template #after-price="{ product }">
                            <slot name="after-price" :product="product" />
                        </template>
                    </component>
                </div>
            </RecommendedProductsDataProvider>
        </template>
    </Observer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { PRODUCT_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { ITEMS_COUNT } from '@configs/simple-slider';
import { PRODUCT_SLIDER_SLIDE_ATTRIBUTE } from '@configs/product';
import {
    DEFAULT_DEBOUNCE_TIME,
    HEADING_SLOT_INDEX,
    SUBHEADING_SLOT_INDEX,
    LINK_SLOT_INDEX,
    VISUAL_SEARCH_CAMPAIGN,
} from '@configs/recommendations';
import { VIEW_TIME_TO_SEND } from '@configs/analytics-events';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { SYNERISE_RECOMMENDATION_PLACEMENTS } from '@types/Synerise';

import {
    RECOMMENDATIONS_SEE_MORE,
    RECOMMENDATIONS_BAD_RESPONSE,
    RECOMMENDATIONS_SCROLL,
    RECOMMENDATIONS_PRODUCT_CLICKED,
    RECOMMENDATIONS_ADD_TO_WISHLIST,
    RECOMMENDATIONS_VIEW,
} from '@analytics-types/Events';

import { debounceAggregate } from '@assets/debounce-aggregate';

import SyneriseDataLayerProduct from '@models/Analytics/SyneriseDataLayerProduct';

import { checkIfExistsInValuesMap } from '@assets/props';

import Loader from '@atoms/Loader/Loader';
import Observer from '@atoms/Observer/Observer';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'RecommendedProductsSimpleSliderWrapperNew',

    components: {
        Loader,
        Observer,
        RecommendedProductsDataProvider: () => ({
            component: import(
                /* webpackChunkName: "recommended-products-data-provider" */
                '@molecules/RecommendedProductsDataProvider/RecommendedProductsDataProvider'
            ),
        }),
    },

    props: {
        recommendationType: {
            type: String,
            required: true,
            validator: checkIfExistsInValuesMap(SYNERISE_RECOMMENDATION_PLACEMENTS),
        },

        placementPageName: {
            type: String,
            required: true,
        },

        categoryBreadcrumbs: {
            type: Array,
            default: () => [],
        },

        productSku: {
            type: [String, Array],
            default: '',
        },

        itemsCount: {
            type: Number,
            default: 6,
            validator: value => ITEMS_COUNT.includes(value),
        },

        customCampaignId: {
            type: String,
            default: '',
        },

        isPageEventEmittedPromise: {
            type: Promise,
            default() {
                return Promise.resolve();
            },
        },

        imageUrl: {
            type: String,
            default: '',
        },

        gender: {
            type: String,
            default: '',
        },

        size: {
            type: String,
            default: '',
        },

        categoriesToExcludeInVisualSearch: {
            type: Array,
            default: () => [],
        },

        isInsideContainer: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isDisplayed: false,
            presentationComponent: null,
            displayContainer: true,
            slideObserver: null,
            productsLoaded: [],
            sliderExtras: {},
        };
    },

    computed: {
        ...mapConfigGetters(['currency', 'locale']),

        heading() {
            return this.getSliderExtrasData(HEADING_SLOT_INDEX);
        },

        subheading() {
            return this.getSliderExtrasData(SUBHEADING_SLOT_INDEX);
        },

        link() {
            return this.getSliderExtrasData(LINK_SLOT_INDEX);
        },

        isLoaded() {
            return this.productsLoaded.length && this.presentationComponent;
        },

        eventData() {
            return {
                currentScreenName: this.placementPageName,
                campaignID: this.customCampaignId || this.campaignId,
                campaignHash: this.sliderExtras?.correlationId || '',
            };
        },

        campaignId() {
            if (this.isVisualRecommendation) {
                return VISUAL_SEARCH_CAMPAIGN;
            }

            return this.$services.recommendations.getCampaignIdByType(
                this.recommendationType,
                this.locale
            );
        },

        isVisualRecommendation() {
            return (
                this.$services.recommendations.checkIfPlacementHasVisualSearch(
                    this.recommendationType,
                    this.locale
                ) && !!this.imageUrl
            );
        },
    },

    watch: {
        async isLoaded(value) {
            if (!value) {
                return;
            }

            const productMap = new Map();

            this.slideObserver = new IntersectionObserver(
                (entries, observer) => {
                    entries.forEach(({ target, isIntersecting }) => {
                        const index = parseInt(
                            target.getAttribute(PRODUCT_SLIDER_SLIDE_ATTRIBUTE),
                            10
                        );

                        if (isIntersecting) {
                            if (productMap.has(index)) {
                                return;
                            }

                            const timeout = setTimeout(async () => {
                                observer.unobserve(target);
                                productMap.delete(index);

                                const product = this.productsLoaded[index];

                                this.debouncedOnRecommendationView({ product, index });
                            }, VIEW_TIME_TO_SEND);

                            productMap.set(index, timeout);
                        } else {
                            clearTimeout(productMap.get(index));
                            productMap.delete(index);
                        }
                    });
                },
                {
                    threshold: 0.5,
                }
            );

            await this.$nextTick();

            const slides = this.$el.querySelectorAll(`[${PRODUCT_SLIDER_SLIDE_ATTRIBUTE}]`);

            slides.forEach(slide => {
                this.slideObserver.observe(slide);
            });
        },
    },

    beforeCreate() {
        this.CONTAINER_OBSERVER_OPTIONS = {
            root: null,
            threshold: 0,
            rootMargin: '0px 0px 200px 0px',
        };

        this.debouncedOnRecommendationView = debounceAggregate(products => {
            this.sendRecommendationViewsToAnalytics(products);
        }, DEFAULT_DEBOUNCE_TIME);

        this.RECOMMENDATIONS_SEE_MORE = RECOMMENDATIONS_SEE_MORE;
        this.RECOMMENDATIONS_SCROLL = RECOMMENDATIONS_SCROLL;
    },

    beforeDestroy() {
        this.slideObserver?.disconnect();
        this.slideObserver = null;
    },

    methods: {
        displayContent(intersect) {
            if (intersect) {
                this.isDisplayed = true;

                this.loadProductsSimpleSliderComponent();
            }
        },

        loadProductsSimpleSliderComponent() {
            import(
                /* webpackChunkName: "products-simple-slider-wrapper" */
                '@molecules/ProductsSimpleSliderWrapper/ProductsSimpleSliderWrapper'
            )
                .then(component => {
                    this.presentationComponent = component.default;
                })
                .catch(err => {
                    this.handleError();
                    this.$errorHandler.captureDomainError(PRODUCT_ERROR_DOMAIN, err, {
                        [ERROR_ACTION_TAG_NAME]: 'lazyload',
                    });
                });
        },

        handleError() {
            this.displayContainer = false;
            this.isDisplayed = false;
            this.$emit('error');
            this.emitBadResponseEvent();
        },

        async emitBadResponseEvent() {
            await this.isPageEventEmittedPromise;

            this.emitRecommendationEvent(RECOMMENDATIONS_BAD_RESPONSE);
        },

        onLoadedProducts({ products, extras }) {
            this.sliderExtras = extras;
            this.productsLoaded = products;

            if (!products.length) {
                this.displayContainer = false;
                this.emitBadResponseEvent();
            }
        },

        onProductClick({ product, index }) {
            this.emitRecommendationEventWithProduct(
                product,
                index,
                RECOMMENDATIONS_PRODUCT_CLICKED
            );
        },

        onAddToWishlistClick({ product, index, isAdded }) {
            if (isAdded) {
                return;
            }

            this.emitRecommendationEventWithProduct(
                product,
                index,
                RECOMMENDATIONS_ADD_TO_WISHLIST
            );
        },

        sendRecommendationViewsToAnalytics(products) {
            const productsToSend = products
                .flat()
                .map(({ product, index }) => this.buildSyneriseDataLayerProduct(product, index));

            this.$analytics.emit(RECOMMENDATIONS_VIEW, {
                ...this.eventData,
                recommendationProducts: productsToSend,
                currency: this.currency,
            });
        },

        getSliderExtrasData(slotIndex) {
            const name = this.sliderExtras?.slots?.[slotIndex]?.name || '';

            return name === '-' ? '' : name;
        },

        emitRecommendationEvent(eventName) {
            this.$analytics.emit(eventName, {
                ...this.eventData,
                itemListName: this.recommendationType,
            });
        },

        buildSyneriseDataLayerProduct(product, index) {
            return new SyneriseDataLayerProduct({
                product,
                position: index,
                recommendationType: this.recommendationType,
                pageType: this.$route.name,
            }).build();
        },

        emitRecommendationEventWithProduct(product, index, eventName) {
            this.$analytics.emit(eventName, {
                currency: this.currency,
                value: product.price.promotional.amount,
                recommendationProducts: [this.buildSyneriseDataLayerProduct(product, index)],
                actionFieldList: this.recommendationType,
                ...this.eventData,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$heading-height-mobile: 62px;
$heading-height-lg: 48px;

$subheading-height-mobile: 26px + $tailwindcss-spacing-3;
$subheading-height-md: 22px + $tailwindcss-spacing-3;

$min-height-mobile: 339px + $tailwindcss-spacing-2 + $heading-height-mobile;
$min-height-lg-min: 353px + $tailwindcss-spacing-2 + $heading-height-mobile;
$min-height-container: 475px + $tailwindcss-customSizes-12p + $heading-height-lg;

$min-height-with-heading-and-subheading-mobile: $min-height-mobile + $subheading-height-mobile +
    $tailwindcss-spacing-2;
$min-height-with-heading-and-subheading-md: $min-height-lg-min + $subheading-height-md +
    $tailwindcss-customSizes-12p;
$min-height-with-heading-and-subheading-container: $min-height-container + $subheading-height-md +
    $tailwindcss-customSizes-12p;

$multiplier: math.div(100px, 22px);

@mixin min-height-lg-min($min-height, $multiplier) {
    min-height: calc(#{$min-height} + ((100vw - 1024px) / #{$multiplier}));
}

.recommended-products-simple-slider-wrapper-new {
    min-height: $min-height-mobile;

    &.with-heading-and-subheading {
        min-height: $min-height-with-heading-and-subheading-mobile;
    }

    &.is-loading {
        @apply flex items-center bg-gray4;
    }

    @screen md {
        @include min-height-lg-min($min-height-lg-min, $multiplier);

        &.with-heading-and-subheading {
            @include min-height-lg-min($min-height-with-heading-and-subheading-md, $multiplier);
        }
    }

    @screen container {
        min-height: $min-height-container;

        &.with-heading-and-subheading {
            min-height: $min-height-with-heading-and-subheading-container;
        }
    }

    &.is-inside-container {
        &:deep(.slider-container-content) {
            @apply px-0;
        }
    }
}
</style>
