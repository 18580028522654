<template>
    <div class="static-html-wrapper">
        <slot v-if="!!$slots.default" />
    </div>
</template>

<script>
export default {
    name: 'StaticHtmlLinkHandler',
    data() {
        return {
            links: [],
        };
    },
    watch: {
        '$slots.default': 'contentUpdated',
    },
    mounted() {
        this.$nextTick(this.addListeners);
    },
    beforeDestroy() {
        this.removeListeners();
    },
    methods: {
        navigate(event) {
            this.$emit('navigate', event);
            const { currentTarget: target } = event;
            const href = target.getAttribute('href');
            const isLocalLink = href && href[0] === '/';

            if (isLocalLink) {
                event.preventDefault();
                this.$router.push(href);
            }
        },

        // eslint-disable-next-line vue/no-unused-properties
        contentUpdated() {
            this.removeListeners();
            this.$nextTick(() => {
                this.addListeners();
            });
        },
        addListeners() {
            this.links = this.$el.getElementsByTagName('a');

            for (let i = 0; i < this.links.length; i += 1) {
                this.links[i].addEventListener('click', this.navigate, false);
            }
        },
        removeListeners() {
            for (let i = 0; i < this.links.length; i += 1) {
                this.links[i].removeEventListener('click', this.navigate, false);
            }

            this.links = [];
        },
    },
};
</script>

<style lang="scss" scoped>
.static-html-wrapper {
    @apply flex;
}
</style>
