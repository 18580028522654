export const types = {
    SET_FETCHING_CUSTOMER_DATA_IN_PROGRESS: 'SET_FETCHING_CUSTOMER_DATA_IN_PROGRESS',
    SET_SHOULD_OPEN_MODAL_SHARE_LOYALTY_CLUB_FLAG: 'SET_SHOULD_OPEN_MODAL_SHARE_LOYALTY_CLUB_FLAG',
    SET_SHOULD_OPEN_MODAL_LOYALTY_CLUB_REGISTRATION_FLAG:
        'SET_SHOULD_OPEN_MODAL_LOYALTY_CLUB_REGISTRATION_FLAG',
    SET_SHOULD_OPEN_MODAL_AFTER_LOYALTY_CLUB_REGISTRATION_FLAG:
        'SET_SHOULD_OPEN_MODAL_AFTER_LOYALTY_CLUB_REGISTRATION_FLAG',
    SET_WAS_MODAL_AFTER_LOGIN_CLOSED: 'SET_WAS_MODAL_AFTER_LOGIN_CLOSED',
    SET_CUSTOMER_DATA: 'SET_CUSTOMER_DATA',
    ADD_TO_WISHLIST: 'ADD_TO_WISHLIST',
    REMOVE_FROM_WISHLIST: 'REMOVE_FROM_WISHLIST',
    SET_WISHLIST: 'SET_WISHLIST',
    INCREMENT_CUSTOMER_WISHLIST_COUNT: 'INCREMENT_CUSTOMER_WISHLIST_COUNT',
    DECREMENT_CUSTOMER_WISHLIST_COUNT: 'DECREMENT_CUSTOMER_WISHLIST_COUNT',
    SET_CUSTOMER_WISHLIST_COUNT: 'SET_CUSTOMER_WISHLIST_COUNT',
    UPDATE_CUSTOMER_DATA: 'UPDATE_CUSTOMER_DATA',
    SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
    SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
    SET_ACCESS_TOKEN_EXPIRATION_ISO: 'SET_ACCESS_TOKEN_EXPIRATION_ISO',
    SET_REFRESH_TOKEN_EXPIRATION_ISO: 'SET_REFRESH_TOKEN_EXPIRATION_ISO',
    SET_RESET_PASSWORD_EMAIL: 'SET_RESET_PASSWORD_EMAIL',
    SET_LOGIN_TOOLTIP_VISIBILITY: 'SET_LOGIN_TOOLTIP_VISIBILITY',
    SET_INIT_TOOLTIP_WITH_LOGIN: 'SET_INIT_TOOLTIP_WITH_LOGIN',
    SET_LOGIN_TOOLTIP_TIMEOUT_ID: 'SET_LOGIN_TOOLTIP_TIMEOUT_ID',
    SET_SHOULD_OPEN_LOYALTY_CLUB_REGISTRATION_ON_LOGIN_SUCCESS:
        'SET_SHOULD_OPEN_LOYALTY_CLUB_REGISTRATION_ON_LOGIN_SUCCESS',
    SET_IS_REGISTRATION_BY_SOCIAL_MEDIA: 'SET_IS_REGISTRATION_BY_SOCIAL_MEDIA',
    SET_IS_CUSTOMER_SUBSCRIBED_TO_NEWSLETTER: 'SET_IS_CUSTOMER_SUBSCRIBED_TO_NEWSLETTER',
    SET_SHOULD_OPEN_MODAL_NEWSLETTER_CONFIRMATION: 'SET_SHOULD_OPEN_MODAL_NEWSLETTER_CONFIRMATION',
    SET_SHOULD_SHOW_NEWSLETTER_CONFIRMATION_SECTION:
        'SET_SHOULD_SHOW_NEWSLETTER_CONFIRMATION_SECTION',
    SET_NEWSLETTER_CONFIRMATION_EMAIL: 'SET_NEWSLETTER_CONFIRMATION_EMAIL',
};

export default {
    [types.SET_FETCHING_CUSTOMER_DATA_IN_PROGRESS](state, fetchingInProgress) {
        state.fetchingCustomerInProgress = fetchingInProgress;
    },

    [types.SET_SHOULD_OPEN_MODAL_SHARE_LOYALTY_CLUB_FLAG](
        state,
        shouldOpenModalShareLoyaltyClubFlag
    ) {
        state.shouldOpenModalShareLoyaltyClubFlag = shouldOpenModalShareLoyaltyClubFlag;
    },

    [types.SET_SHOULD_OPEN_MODAL_LOYALTY_CLUB_REGISTRATION_FLAG](
        state,
        shouldOpenModalLoyaltyClubRegistration
    ) {
        state.shouldOpenModalLoyaltyClubRegistration = shouldOpenModalLoyaltyClubRegistration;
    },

    [types.SET_SHOULD_OPEN_MODAL_AFTER_LOYALTY_CLUB_REGISTRATION_FLAG](
        state,
        shouldOpenModalAfterLoyaltyClubRegistration
    ) {
        // eslint-disable-next-line max-len
        state.shouldOpenModalAfterLoyaltyClubRegistration = shouldOpenModalAfterLoyaltyClubRegistration;
    },

    [types.SET_WAS_MODAL_AFTER_LOGIN_CLOSED](state, wasModalAfterLoginClosed) {
        state.wasModalAfterLoginClosed = wasModalAfterLoginClosed;
    },

    [types.SET_CUSTOMER_DATA](state, customerData) {
        state.customerData = customerData;
    },

    [types.ADD_TO_WISHLIST](state, items = []) {
        state.wishlist = [...items, ...new Set(state.wishlist)];
    },
    [types.REMOVE_FROM_WISHLIST](state, sku) {
        state.wishlist = state.wishlist.filter(item => item !== sku);
    },

    [types.SET_WISHLIST](state, wishlist = []) {
        state.wishlist = [...new Set(wishlist)];
    },

    [types.INCREMENT_CUSTOMER_WISHLIST_COUNT](state) {
        state.customerData.paginatedWishlist.items_count += 1;
    },

    [types.DECREMENT_CUSTOMER_WISHLIST_COUNT](state) {
        state.customerData.paginatedWishlist.items_count -= 1;
    },

    [types.SET_CUSTOMER_WISHLIST_COUNT](state, count = 0) {
        state.customerData.paginatedWishlist.items_count = count;
    },

    [types.UPDATE_CUSTOMER_DATA](state, updatedData) {
        state.customerData = {
            ...state.customerData,
            ...updatedData,
        };
    },

    [types.SET_ACCESS_TOKEN](state, accessToken) {
        state.accessToken = accessToken;
    },

    [types.SET_REFRESH_TOKEN](state, refreshToken) {
        state.refreshToken = refreshToken;
    },

    [types.SET_ACCESS_TOKEN_EXPIRATION_ISO](state, expiration) {
        state.accessTokenExpirationISO = expiration;
    },

    [types.SET_REFRESH_TOKEN_EXPIRATION_ISO](state, expiration) {
        state.refreshTokenExpirationISO = expiration;
    },

    [types.SET_RESET_PASSWORD_EMAIL](state, email) {
        state.resetPasswordEmail = email;
    },

    [types.SET_LOGIN_TOOLTIP_VISIBILITY](state, isShow) {
        state.loginTooltipVisibility = isShow;
    },

    [types.SET_INIT_TOOLTIP_WITH_LOGIN](state, init) {
        state.initLoginTooltip = init;
    },

    [types.SET_LOGIN_TOOLTIP_TIMEOUT_ID](state, id) {
        state.loginTooltipTimeoutId = id;
    },

    [types.SET_SHOULD_OPEN_LOYALTY_CLUB_REGISTRATION_ON_LOGIN_SUCCESS](state, shouldOpen) {
        state.shouldOpenLoyaltyClubRegistrationOnLoginSuccess = shouldOpen;
    },

    [types.SET_IS_REGISTRATION_BY_SOCIAL_MEDIA](state, isRegistrationBySocialMedia) {
        state.isRegistrationBySocialMedia = isRegistrationBySocialMedia;
    },

    [types.SET_IS_CUSTOMER_SUBSCRIBED_TO_NEWSLETTER](state, isCustomerSubscribedToNewsletter) {
        state.isCustomerSubscribedToNewsletter = isCustomerSubscribedToNewsletter;
    },

    [types.SET_SHOULD_OPEN_MODAL_NEWSLETTER_CONFIRMATION](
        state,
        shouldOpenModalNewsletterConfirmation
    ) {
        state.shouldOpenModalNewsletterConfirmation = shouldOpenModalNewsletterConfirmation;
    },

    [types.SET_SHOULD_SHOW_NEWSLETTER_CONFIRMATION_SECTION](
        state,
        shouldShowNewsletterConfirmationSection
    ) {
        state.shouldShowNewsletterConfirmationSection = shouldShowNewsletterConfirmationSection;
    },

    [types.SET_NEWSLETTER_CONFIRMATION_EMAIL](state, newsletterConfirmationEmail) {
        state.newsletterConfirmationEmail = newsletterConfirmationEmail;
    },
};
