<template>
    <component :is="tag" :class="headingClass">
        <slot />
    </component>
</template>

<script>
import { VALID_HEADING_TYPES } from '@configs/headings';

const propsHeadingType = {
    type: String,
    default: 'strong',
    validator: value => VALID_HEADING_TYPES.includes(value),
};

export default {
    name: 'BaseHeading',

    props: {
        /**
         * Heading tag type.
         */
        tag: propsHeadingType,
        /**
         * Theme - class heading tag.
         */
        theme: propsHeadingType,
    },

    computed: {
        headingClass() {
            return `_heading-${this.theme}`;
        },
    },
};
</script>
