export default {
    staticBlockById: state => id => state?.[id],

    pageBuilderBlockById: state => id => {
        if (!state[id] || !state[id].content_json) {
            return [];
        }

        const rawData = JSON.parse(state[id].content_json);

        const [
            {
                slots: { default: CmsPage },
            },
        ] = rawData;

        return CmsPage;
    },

    getComponentFromPageBuilderBlock: (_, getters) => (pageBuilderId, componentId) => {
        const pageBuilderBlock = getters.pageBuilderBlockById(pageBuilderId);

        if (!pageBuilderBlock.length) {
            return {};
        }

        const component = pageBuilderBlock.filter(({ name }) => name === componentId);

        return component[0] || {};
    },
};
