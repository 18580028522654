import { reactive } from 'vue';
import { GENDER_COOKIE_NAME, SECONDS_IN_YEAR } from '@configs/storage';
import { FOUND_HTTP_CODE } from '@configs/redirect';

import { CATALOG_PAGE_NAME } from '@search/routing/names';
import { MAIN_CATEGORY_PAGE_NAME } from '@router/names';
import { MAIN_CATEGORY_PAGE_PREFIX, PAGE_PATH_SUFFIX } from '@router/config';

import { DEFAULT_NAVIGATION_CONTEXT_CODE, IS_MENU_CONTEXTUAL } from '@configs/x3/config-keys';

export default class NavigationContext {
    constructor({
        $cookies,
        $errorHandler,
        $configProvider,
        contexts = [],
        currentContext = undefined,
    }) {
        this.$cookies = $cookies;
        this.$errorHandler = $errorHandler;
        this.$configProvider = $configProvider;

        this.isMenuContextual = this.$configProvider.getConfig(IS_MENU_CONTEXTUAL);
        this.availableContexts = contexts;
        this.context = reactive({ id: '', slug: '', code: '', path: '', displayName: '' });
        this.subContexts = {};
        this.setContext(currentContext);
    }

    createSubContext(subContextId) {
        this.subContexts[subContextId] = reactive({
            id: '',
            slug: '',
            code: '',
        });

        this.setSubContext(subContextId, this.context.id);
    }

    setSubContext(subContextId, contextId) {
        if (!this.isMenuContextual || !this.availableContexts.length) {
            return;
        }

        const navigationContext = this.availableContexts.find(({ id }) => id === contextId);

        if (!navigationContext) {
            return;
        }

        const context = this.subContexts[subContextId];

        context.id = navigationContext.id;
        context.slug = navigationContext.slug;
        context.code = navigationContext.code;
    }

    getContextsTabs() {
        return this.availableContexts.map(({ id, displayName }) => ({ id, label: displayName }));
    }

    getAvailableContexts() {
        return this.availableContexts;
    }

    getContext() {
        return this.context;
    }

    getContextId(subContextId = '') {
        if (subContextId) {
            return this.subContexts[subContextId]?.id || '';
        }

        return this.context.id;
    }

    getContextSlug(subContextId) {
        if (subContextId) {
            return this.subContexts[subContextId]?.slug || '';
        }

        return this.context.slug;
    }

    getContextCode(subContextId) {
        if (subContextId) {
            return this.subContexts[subContextId]?.code || '';
        }

        return this.context.code;
    }

    getContextDisplayName() {
        return this.context.displayName;
    }

    getContextPath() {
        return this.context.path;
    }

    setContext({ id = '', slug = '', code = '', displayName = '', path = '/' } = {}) {
        this.context.id = id;
        this.context.slug = slug;
        this.context.code = code;
        this.context.displayName = displayName;
        this.context.path = path;
    }

    initNavigationContext(navigation) {
        if (!this.isMenuContextual) {
            return;
        }

        this.setAvailableContexts(navigation.children);

        const navigationContextSlugOrCode =
            this.$cookies.get(GENDER_COOKIE_NAME) ||
            this.$configProvider.getConfig(DEFAULT_NAVIGATION_CONTEXT_CODE);

        this.setNavigationContext(navigationContextSlugOrCode);
    }

    setAvailableContexts(categories) {
        this.availableContexts = categories
            .filter(({ pim }) => pim && !!pim.slug && !!pim.code)
            .map(({ pim, custom_url_path, url_path, display_name, id }) => ({
                id,
                slug: pim.slug,
                code: pim.code,
                displayName: display_name,
                path:
                    custom_url_path ||
                    `/${MAIN_CATEGORY_PAGE_PREFIX}/${url_path}${PAGE_PATH_SUFFIX}`,
            }));
    }

    setNavigationContext(navigationContextCodeOrSlug) {
        if (!this.isMenuContextual || !this.availableContexts.length) {
            return;
        }

        const navigationContext = this.availableContexts.find(
            ({ slug, code }) => [slug, code].indexOf(navigationContextCodeOrSlug) >= 0
        );

        if (!navigationContext?.slug) {
            return;
        }

        this.setContext(navigationContext);

        if (process.client) {
            this.$cookies.set(GENDER_COOKIE_NAME, this.getContextSlug(), {
                path: '/',
                maxAge: SECONDS_IN_YEAR,
            });
        }
    }

    redirectToSavedContextHomepage(redirect, query) {
        const mainCategorySlug = this.$cookies.get(GENDER_COOKIE_NAME);

        if (!mainCategorySlug || !this.isMenuContextual) {
            return;
        }

        if (mainCategorySlug === 'home-decor') {
            return redirect(FOUND_HTTP_CODE, {
                name: CATALOG_PAGE_NAME,
                params: {
                    url: mainCategorySlug,
                },
                query,
            });
        }

        return redirect(FOUND_HTTP_CODE, {
            name: MAIN_CATEGORY_PAGE_NAME,
            params: {
                slug: `${mainCategorySlug}${PAGE_PATH_SUFFIX}`,
            },
            query,
        });
    }
}
