var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-progress-bar"},[_c('ul',{staticClass:"steps"},_vm._l((_vm.steps),function(step,index){return _c('li',{key:`step-${index}`,class:[
                'step',
                {
                    active: _vm.isActive(index),
                    valid: _vm.isValid(index),
                },
            ]},[(_vm.isValid(index))?_c('span',{staticClass:"circle icon"},[_c('SvgIcon',{staticClass:"icon-svg",attrs:{"width":"24px","height":"24px"}},[_c('Check2')],1)],1):_c('span',{staticClass:"circle counter"},[_vm._v(_vm._s(index + 1))]),_vm._v(" "),_c('div',{staticClass:"label"},[(step.path)?_c('BaseLink',{staticClass:"link",attrs:{"path":step.path,"label":step.name,"title":step.name}}):_c('span',[_vm._v(_vm._s(step.name))])],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }