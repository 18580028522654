import { render, staticRenderFns } from "./HeaderSimple.vue?vue&type=template&id=405a0dfd&scoped=true"
import script from "./HeaderSimple.vue?vue&type=script&lang=js"
export * from "./HeaderSimple.vue?vue&type=script&lang=js"
import style0 from "./HeaderSimple.vue?vue&type=style&index=0&id=405a0dfd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "405a0dfd",
  null
  
)

export default component.exports