<template>
    <BaseButton
        :type="type"
        :to="to"
        :theme="theme"
        :size="size"
        class="button-with-icon"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <div v-if="isSlotFill && showLeftSlot" class="left">
            <!-- @slot Button left slot -->
            <slot name="left" />
        </div>
        <div v-if="$slots.default" class="content">
            <!-- @slot Button default slot -->
            <slot />
        </div>
        <div v-if="isSlotFill && showRightSlot" class="right">
            <!-- @slot Button right slot -->
            <slot name="right" />
        </div>
    </BaseButton>
</template>

<script>
import BaseButton from '@atoms/BaseButton/BaseButton';
import Button from '@mixins/Button';

export default {
    name: 'ButtonWithIcon',

    components: {
        BaseButton,
    },

    mixins: [Button],

    props: {
        showRightSlot: {
            type: Boolean,
            default: true,
        },

        showLeftSlot: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        isSlotFill() {
            const { right, left } = this.$slots;

            return !!right || !!left;
        },
    },
};
</script>

<style lang="scss" scoped>
.button-with-icon {
    @apply flex items-center justify-center px-0;

    .left,
    .right {
        @apply flex items-center justify-center;
    }

    &.big {
        @apply px-5;

        .left,
        .right {
            min-width: theme('spacing.5');
        }

        .left {
            @apply mr-5;
        }

        .right {
            @apply ml-5;
        }
    }

    &.normal {
        @apply px-3;

        .left,
        .right {
            min-width: theme('spacing.3');
        }

        .left {
            @apply mr-3;
        }

        .right {
            @apply ml-3;
        }
    }

    &.small {
        @apply px-2;

        .left,
        .right {
            min-width: theme('spacing.2');
        }

        .left {
            @apply mr-2;
        }

        .right {
            @apply ml-2;
        }
    }

    &.pure {
        .left,
        .right {
            min-width: 0;
        }

        .left {
            @apply mr-0;
        }

        .right {
            @apply ml-0;
        }
    }
}
</style>
