import { WEB_VIEW, WEB_VIEW_MOBILE_APP } from '@configs/web-view';
import { IS_MOBILE_WEB_VIEW } from '@configs/storage';

import cookies from '@services/cookies';

import { MOBILE_TOKEN_EXPIRED_PAGE_NAME } from '@router/names';

const REFRESH_MOBILE_TOKEN_TIMEOUT = 20000;
const SHOULD_HANDLE_ROUTING_TIMEOUT = 1000;

const getRefreshAndRedirectFunctions = app => {
    let refreshTokensPromise = null;

    const redirectToTokenExpiredPage = async () => {
        const { store, router } = app;

        await store.dispatch('customer/removeAuthTokens');
        await store.dispatch('customer/setCustomerData', null);

        await router.push({
            name: MOBILE_TOKEN_EXPIRED_PAGE_NAME,
            query: {
                redirect: router.currentRoute.fullPath,
            },
        });
    };

    const setCallbackForMobileApp = (resolve, timeoutId) => {
        window.nativeMobileAppRefreshTokenCallback = async (
            accessToken,
            accessTokenExpirationISO
        ) => {
            try {
                clearTimeout(timeoutId);

                const { $services, store } = app;

                if (!accessToken || !accessTokenExpirationISO) {
                    await redirectToTokenExpiredPage();

                    return resolve(false);
                }

                await $services.auth.saveMobileAuthTokens({
                    accessToken,
                    accessTokenExpirationISO,
                });

                await store.dispatch('customer/setAuthorizationData', {
                    accessToken,
                    accessTokenExpirationISO,
                });

                delete window.nativeMobileAppRefreshTokenCallback;

                return resolve(true);
            } catch {
                await redirectToTokenExpiredPage();

                resolve(false);
            }
        };
    };

    const refreshMobileTokens = async () => {
        if (!process.client) {
            return false;
        }

        if (refreshTokensPromise) {
            return refreshTokensPromise;
        }

        refreshTokensPromise = new Promise(resolve => {
            const timeoutId = setTimeout(() => {
                delete window.nativeMobileAppRefreshTokenCallback;

                redirectToTokenExpiredPage();

                return resolve(false);
            }, REFRESH_MOBILE_TOKEN_TIMEOUT);

            try {
                setCallbackForMobileApp(resolve, timeoutId);

                window.webkit.messageHandlers.modivoAppNamespace.postMessage('refreshToken');
            } catch {
                clearTimeout(timeoutId);

                delete window.nativeMobileAppRefreshTokenCallback;

                redirectToTokenExpiredPage();

                resolve(false);
            }
        });

        refreshTokensPromise.then(() => {
            refreshTokensPromise = null;
        });

        return refreshTokensPromise;
    };

    return {
        refreshMobileTokens,
        redirectToTokenExpiredPage,
    };
};

const getHandleRoutingByApp = app => {
    let handleRoutingByIOSAppPromise = null;

    const createHandleRoutingByIOSAppPromise = url => {
        if (handleRoutingByIOSAppPromise) {
            return handleRoutingByIOSAppPromise;
        }

        handleRoutingByIOSAppPromise = new Promise(resolve => {
            const timeoutId = setTimeout(() => {
                delete window.shouldOverrideUrlLoadingReturnMethod;

                return resolve(false);
            }, SHOULD_HANDLE_ROUTING_TIMEOUT);

            try {
                window.shouldOverrideUrlLoadingReturnMethod = result => {
                    clearTimeout(timeoutId);
                    delete window.shouldOverrideUrlLoadingReturnMethod;

                    return resolve(result);
                };

                // eslint-disable-next-line max-len
                window.webkit.messageHandlers.shouldOverrideUrlLoading.postMessage(url);
            } catch (err) {
                clearTimeout(timeoutId);
                delete window.shouldOverrideUrlLoadingReturnMethod;
                resolve(false);
            }
        });

        handleRoutingByIOSAppPromise.then(() => {
            handleRoutingByIOSAppPromise = null;
        });

        return handleRoutingByIOSAppPromise;
    };

    const handleRoutingByIOSApp = url => {
        return createHandleRoutingByIOSAppPromise(url);
    };

    const handleRoutingByAndroidApp = url => {
        return new Promise(resolve => {
            try {
                resolve(
                    window.webkit.messageHandlers.modivoAppNamespace.shouldOverrideUrlLoading(url)
                );
            } catch {
                resolve(false);
            }
        });
    };

    const handleRoutingByMobileApp = async url => {
        if (!process.client) {
            return false;
        }

        const shouldHandleRoutingByAndroidApp = await handleRoutingByAndroidApp(url);

        if (app.$abTests.getVariant('dev_mod_mobile_deeplink') !== 'on') {
            return shouldHandleRoutingByAndroidApp;
        }

        const shouldHandleRoutingByIOSApp = await handleRoutingByIOSApp(url);

        return shouldHandleRoutingByAndroidApp || shouldHandleRoutingByIOSApp;
    };

    return {
        handleRoutingByMobileApp,
    };
};

export default ({ app, route }, inject) => {
    const isMobileAppWebViewCookieName = cookies.createCookieWithPrefix(IS_MOBILE_WEB_VIEW);

    const cookieOptions = {
        path: '/',
    };

    const hasMobileAppWebViewCookie = !!app.$cookies.get(isMobileAppWebViewCookieName);

    const hasQueryStringWithMobileAppWebView = route.query?.[WEB_VIEW] === WEB_VIEW_MOBILE_APP;

    if (process.client && hasQueryStringWithMobileAppWebView) {
        app.$cookies.set(isMobileAppWebViewCookieName, true, cookieOptions);
    }

    const hasAuthorizationTokensFromMobileApp = !!app.$cookies.get(
        'has-authorization-tokens-from-mobile-app'
    );

    inject('mobileApp', {
        isWebView: hasMobileAppWebViewCookie || hasQueryStringWithMobileAppWebView,
        hasAuthorizationTokensFromMobileApp,
        ...getRefreshAndRedirectFunctions(app),
        ...getHandleRoutingByApp(app),
    });
};
