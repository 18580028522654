<template>
    <CellLink :to="path" :horizontal-padding-desktop="PADDING_DESKTOP" class="footer-link">
        {{ label }}
    </CellLink>
</template>

<script>
import { CellLink, CELL_LINK_PADDINGS } from '@modivo-ui/components/CellLink/v1';

export default {
    name: 'FooterLink',

    components: {
        CellLink,
    },

    props: {
        path: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            default: '',
        },
    },

    beforeCreate() {
        this.PADDING_DESKTOP = CELL_LINK_PADDINGS.PX_0;
    },
};
</script>
