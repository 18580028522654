import { PRODUCT_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { DEFAULT_SEARCH_SELECT_FIELDS } from '@product/configs/search';
import {
    MEGATRON_CLIENT_ENDPOINT_NAME_TRUSTMATE,
    MEGATRON_REVIEWS_GET_PRODUCT_REVIEWS_OPERATION_NAME,
    MEGATRON_SEARCH_GET_PRODUCTS_OPERATION_NAME,
    MEGATRON_SEARCH_GET_PRODUCT_OPERATION_NAME,
    MEGATRON_SEARCH_GET_SPONSORED_PRODUCTS_OPERATION_NAME,
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_PRODUCT_SEARCH,
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
} from '@configs/megatron-client';
import { NOT_FOUND_HTTP_CODE, TIMEOUT_OCCURED_HTTP_CODE } from '@configs/http-codes';
import { PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME } from '@configs/product-attribute-names';
import { SEARCH_API_BRAND_CHANNEL } from '@configs/x3/config-keys';

import { ERROR_ACTION_TAG_NAME, SKIPPED_ERROR_STATUSES } from '@types/Errors';

import { isTrustmateProxyTestOn } from '@assets/reviews';
import { getSearchClientAndEndpointParameter } from '@assets/get-megatron-endpoint-parameter-search';

import ProductNotFoundException from '@exceptions/services/product/ProductNotFoundException';

import { SearchQuery, ApiVersion, Endpoint } from '@eobuwie/search';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.product.${action}`,
});

const GET_BY_INDEXES_ERROR_TAGS = getErrorTags('getByIndexes');
const GET_COLOR_VARIANTS_ERROR_TAGS = getErrorTags('getColorVariants');
const GET_BY_URL_KEY_ERROR_TAGS = getErrorTags('getByUrlKey');
const GET_SPONSORED_BY_URL_KEY_ERROR_TAGS = getErrorTags('getSponsoredByUrlKey');
const GET_SPONSORED_PRODUCTS_ERROR_TAGS = getErrorTags('getSponsoredProducts');
const GET_HOMEPAGE_SPONSORED_PRODUCTS_ERROR_TAGS = getErrorTags('getHomepageSponsoredProducts');

const isNewSearchTestOn = abTests => abTests.getVariant('mod_all_pdp_NewSearch') === 'on';

const emptyGetByIndexesResponse = (page, limit) => ({
    items: [],
    total: 0,
    page,
    per_page: limit,
    attributes: {},
});

const getEmptyReviewsResponse = () => ({
    reviews: {},
    errors: [],
});

const getEmptyGetSponsoredProducts = () => ({
    products: [],
    sponsorshipDetails: {},
});

export default ({
    megatronClient,
    httpClient,
    normalizeMegatronResponse,
    $errorHandler,
    $configProvider,
    helpers: { abTests, $avatar },
}) => ({
    async getColorVariants({
        colorVariantsUrl,
        colorVariantValues,
        locale,
        currency,
        selectLocales = [],
        limit = 200,
    }) {
        try {
            const apiVersion = isNewSearchTestOn(abTests)
                ? ApiVersion.VERSION_1
                : ApiVersion.VERSION_5;

            const searchQuery = new SearchQuery({
                apiVersion,
                endpoint: Endpoint.PRODUCTS,
            });

            const colorVariantsFilter = isNewSearchTestOn(abTests)
                ? PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME
                : colorVariantsUrl;

            const searchQueryUrl = searchQuery
                .setChannel($configProvider.getConfig(SEARCH_API_BRAND_CHANNEL))
                .setCurrency(currency)
                .setLocale(locale)
                .setFilters({
                    [colorVariantsFilter]: colorVariantValues,
                })
                .setSelectLocales(selectLocales)
                .setSelectFields(DEFAULT_SEARCH_SELECT_FIELDS)
                .setLimit(limit)
                .setWithStocks(true)
                .build();

            let response = null;

            if (isNewSearchTestOn(abTests)) {
                response = await megatronClient.get(searchQueryUrl, {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_PRODUCT_SEARCH,
                });
            } else {
                const { client, endpointParameter } = getSearchClientAndEndpointParameter(
                    megatronClient,
                    httpClient,
                    abTests
                );

                response = await client.get(searchQueryUrl, {
                    ...endpointParameter,
                });
            }

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_SEARCH_GET_PRODUCTS_OPERATION_NAME
            );

            if (!error) {
                return data?.items || [];
            }

            if (!SKIPPED_ERROR_STATUSES.includes(error.status)) {
                $errorHandler.captureDomainError(
                    PRODUCT_ERROR_DOMAIN,
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    GET_COLOR_VARIANTS_ERROR_TAGS,
                    {
                        searchQueryUrl,
                        data,
                        error,
                        status,
                    }
                );
            }

            return [];
        } catch (err) {
            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                err,
                GET_COLOR_VARIANTS_ERROR_TAGS
            );

            return [];
        }
    },

    async getByIndexes({
        indexes = [],
        page = 1,
        limit = 10,
        locale,
        selectLocales = [],
        currency,
        withStocks = false,
    }) {
        try {
            const apiVersion = isNewSearchTestOn(abTests)
                ? ApiVersion.VERSION_1
                : ApiVersion.VERSION_5;

            const searchQuery = new SearchQuery({
                apiVersion,
                endpoint: Endpoint.PRODUCTS,
            });

            const searchQueryUrl = searchQuery
                .setChannel($configProvider.getConfig(SEARCH_API_BRAND_CHANNEL))
                .setCurrency(currency)
                .setLocale(locale)
                .setIndexes(indexes)
                .setSelectLocales(selectLocales)
                .setLimit(limit)
                .setPage(page)
                .setWithStocks(withStocks)
                .build();

            let response = null;

            if (isNewSearchTestOn(abTests)) {
                response = await megatronClient.get(searchQueryUrl, {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_PRODUCT_SEARCH,
                });
            } else {
                const { client, endpointParameter } = getSearchClientAndEndpointParameter(
                    megatronClient,
                    httpClient,
                    abTests
                );

                response = await client.get(searchQueryUrl, {
                    ...endpointParameter,
                });
            }

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_SEARCH_GET_PRODUCTS_OPERATION_NAME
            );

            if (!error) {
                return data;
            }

            if (!SKIPPED_ERROR_STATUSES.includes(error.status)) {
                $errorHandler.captureDomainError(
                    PRODUCT_ERROR_DOMAIN,
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    GET_BY_INDEXES_ERROR_TAGS,
                    {
                        searchQueryUrl,
                        data,
                        error,
                        status,
                    }
                );
            }

            return emptyGetByIndexesResponse(page, limit);
        } catch (err) {
            $errorHandler.captureDomainError(PRODUCT_ERROR_DOMAIN, err, GET_BY_INDEXES_ERROR_TAGS);

            return emptyGetByIndexesResponse(page, limit);
        }
    },

    async getByUrlKey({ urlKey, locale, currency, selectLocales = [] }) {
        try {
            const apiVersion = isNewSearchTestOn(abTests)
                ? ApiVersion.VERSION_1
                : ApiVersion.VERSION_5;
            const searchQuery = new SearchQuery({
                apiVersion,
                endpoint: Endpoint.PRODUCTS,
            });

            const searchQueryUrl = searchQuery
                .setChannel($configProvider.getConfig(SEARCH_API_BRAND_CHANNEL))
                .setCurrency(currency)
                .setLocale(locale)
                .setSelectLocales(selectLocales)
                .setUrlKey(urlKey)
                .build();

            let response = null;

            if (isNewSearchTestOn(abTests)) {
                response = await megatronClient.get(searchQueryUrl, {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_PRODUCT_SEARCH,
                });
            } else {
                const { client, endpointParameter } = getSearchClientAndEndpointParameter(
                    megatronClient,
                    httpClient,
                    abTests
                );

                response = await client.get(searchQueryUrl, {
                    ...endpointParameter,
                });
            }

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_SEARCH_GET_PRODUCT_OPERATION_NAME
            );

            if (data === null) {
                throw new ProductNotFoundException(urlKey);
            }

            if (!error) {
                return data;
            }

            if (!SKIPPED_ERROR_STATUSES.includes(error.status)) {
                $errorHandler.captureDomainError(
                    PRODUCT_ERROR_DOMAIN,
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    GET_BY_URL_KEY_ERROR_TAGS,
                    {
                        searchQueryUrl,
                        data,
                        error,
                        status,
                    }
                );
            }

            throw error;
        } catch (err) {
            if (!(err instanceof ProductNotFoundException)) {
                $errorHandler.captureDomainError(
                    PRODUCT_ERROR_DOMAIN,
                    err,
                    GET_BY_URL_KEY_ERROR_TAGS
                );
            }

            throw err;
        }
    },

    async getSponsoredProductsBase({
        indexes = [],
        urlKey,
        locale,
        currency,
        selectLocales = [],
        fields = [],
        category,
    }) {
        let ERROR_TAGS = GET_HOMEPAGE_SPONSORED_PRODUCTS_ERROR_TAGS;
        let searchEndpoint = Endpoint.HOMEPAGE;

        const indexesAvailable = indexes.length > 0;

        if (indexesAvailable) {
            ERROR_TAGS = GET_SPONSORED_PRODUCTS_ERROR_TAGS;
            searchEndpoint = Endpoint.BASKET;
        } else if (urlKey) {
            ERROR_TAGS = GET_SPONSORED_BY_URL_KEY_ERROR_TAGS;
            searchEndpoint = Endpoint.PRODUCTS;
        }

        try {
            const searchQuery = new SearchQuery({
                apiVersion: ApiVersion.VERSION_5,
                endpoint: searchEndpoint,
            });

            const searchQueryBuilder = searchQuery
                .setChannel($configProvider.getConfig(SEARCH_API_BRAND_CHANNEL))
                .setCurrency(currency)
                .setLocale(locale)
                .setSelectLocales(selectLocales)
                .setSelectFields(fields)
                .setSponsorship();

            if (indexesAvailable) {
                searchQueryBuilder.setIndexes(indexes);
            } else if (urlKey) {
                searchQueryBuilder.setUrlKey(urlKey);
            }

            if (category) {
                searchQueryBuilder.setCategories([category]);
            }

            const searchQueryUrl = searchQueryBuilder.build();

            const { client, endpointParameter } = getSearchClientAndEndpointParameter(
                megatronClient,
                httpClient,
                abTests
            );

            const response = await client.get(searchQueryUrl, {
                ...endpointParameter,
                headers: {
                    ...$avatar.getInstanceIdHeader(),
                },
            });

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_SEARCH_GET_SPONSORED_PRODUCTS_OPERATION_NAME
            );

            if (!error) {
                const { products = [], sponsorship_details: sponsorshipDetails = {} } = data || {};

                return { products, sponsorshipDetails };
            }

            if (!SKIPPED_ERROR_STATUSES.includes(error.status)) {
                $errorHandler.captureDomainError(
                    PRODUCT_ERROR_DOMAIN,
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    ERROR_TAGS,
                    {
                        searchQueryUrl,
                        data,
                        error,
                        status,
                    }
                );
            }
        } catch (err) {
            $errorHandler.captureDomainError(PRODUCT_ERROR_DOMAIN, err, ERROR_TAGS);
        }

        return getEmptyGetSponsoredProducts();
    },

    async getProductReviewsMagento(sku) {
        const { data } = await megatronClient.get('v1/productReviews', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                skus: [sku],
                currentPage: 1,
                pageSize: 100,
            },
        });

        const { reviews = [], errors = [] } = data?.productReviews || {};
        const itemReviews = reviews.find(item => item.sku === sku);

        return {
            errors,
            reviews: itemReviews,
        };
    },

    async getProductReviews({ sku, locale }) {
        const params = new URLSearchParams();

        let response;

        const [language, country] = locale.split('_').map(text => text.toLowerCase());

        params.set('language', language);

        if (isTrustmateProxyTestOn(abTests)) {
            const TRUSTMATE_STORE = 'modivo';

            params.set('sku', sku);
            params.set('store', country);

            const url = `${TRUSTMATE_STORE}?${params.toString()}`;

            response = await megatronClient.get(url, {
                endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_TRUSTMATE,
            });
        } else {
            const url = `product_reviews/${sku}`;

            params.set('includeProductTraits', '1');

            response = await megatronClient.get([url, params.toString()].join('?'), {
                endpoint: `${MEGATRON_CLIENT_ENDPOINT_NAME_TRUSTMATE}_${country}`,
            });
        }

        const { data, error, status } = await normalizeMegatronResponse(
            response,
            MEGATRON_REVIEWS_GET_PRODUCT_REVIEWS_OPERATION_NAME
        );

        const { errors = [] } = data || {};

        if (error) {
            if ([TIMEOUT_OCCURED_HTTP_CODE, NOT_FOUND_HTTP_CODE].includes(status)) {
                return getEmptyReviewsResponse();
            }

            if (!SKIPPED_ERROR_STATUSES.includes(error.status)) {
                $errorHandler.captureDomainError(
                    PRODUCT_ERROR_DOMAIN,
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    {
                        [ERROR_ACTION_TAG_NAME]: 'services.product.getProductReviews',
                    },
                    {
                        data,
                        error,
                        status,
                    }
                );
            }

            return getEmptyReviewsResponse();
        }

        return {
            ...data,
            errors,
        };
    },
});
