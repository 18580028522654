<template>
    <div>
        <HeaderSimpleWrapper />
        <main>
            <ContainerContent>
                <div class="error">
                    <SvgIcon class="error-icon" width="150px" height="150px">
                        <Icon503 />
                    </SvgIcon>
                    <BaseHeading tag="h1" theme="h5">
                        {{ $t('Sorry, an error occurred') }}
                    </BaseHeading>
                    <BaseParagraph>
                        {{
                            $t(
                                // eslint-disable-next-line max-len
                                'Our experts are already working on solving this problem! Please refresh the page or come back in a few minutes.'
                            )
                        }}
                    </BaseParagraph>
                </div>
            </ContainerContent>
        </main>
        <CopyrightFooter />
        <ChatWrapper />
    </div>
</template>

<script>
import Icon503 from '@static/icons/104px/503.svg?inline';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import HeaderSimpleWrapper from '@molecules/HeaderSimpleWrapper/HeaderSimpleWrapper';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import CopyrightFooter from '@organisms/CopyrightFooter/CopyrightFooter';

export default {
    name: 'ErrorPageDefault',

    components: {
        ChatWrapper,
        HeaderSimpleWrapper,
        CopyrightFooter,
        ContainerContent,
        BaseHeading,
        BaseParagraph,
        SvgIcon,
        Icon503,
    },
};
</script>

<style lang="scss" scoped>
.error {
    @apply text-center mb-6 pt-4 pb-5;

    &:deep() {
        .error-icon {
            @apply h-100p mx-auto mb-4;
        }

        ._heading-h5 {
            @apply pb-4;
        }

        .paragraph {
            @apply text-center;
        }

        .base-button {
            @apply mt-5;
        }
    }

    @screen lg {
        @apply pt-10 pb-7 mb-9;

        .error-icon {
            @apply h-auto mb-6;
        }

        ._heading-h5 {
            @apply text-xxl leading-xxl;
        }
    }
}
</style>
