import {
    PATH_SEGMENTS_SEPARATOR,
    PATH_FILTER_NAME_VALUE_SEPARATOR,
    PATH_FILTER_VALUES_SEPARATOR,
} from '@search/configs/query-keys/filters';

import { capitalizeFirstLetter } from '@assets/string-utility';

export const isAggregatedSizeFilterValues = (arrayValues = []) => {
    if (!arrayValues.length) {
        return false;
    }

    return arrayValues.every(value => typeof value === 'string' && value.includes(':'));
};

export const isAggregatedSizeFilterUrlPart = urlPart =>
    urlPart.indexOf(':') !== urlPart.lastIndexOf(':');

export const parseAggregatedSizeFilterValueArray = filterValueArray => {
    const BASIC_SIZE_PLACEHOLDER = '*';

    const categoryWithSelectedSizesObject = filterValueArray.reduce((acc, value) => {
        const [category, ...sizes] = value.split(':');
        const [basicSize, size = BASIC_SIZE_PLACEHOLDER] = sizes;

        acc[category] = acc[category] || {};
        acc[category][basicSize] = acc[category][basicSize] || [];
        acc[category][basicSize].push(size);

        return acc;
    }, {});

    return Object.entries(categoryWithSelectedSizesObject).map(
        ([category, selectedSizesObject]) => {
            const sizesString = Object.entries(selectedSizesObject)
                .reduce((acc, [basicSize, sizes = []]) => {
                    if (sizes.some(size => size === BASIC_SIZE_PLACEHOLDER)) {
                        acc.push(basicSize);
                    }

                    const sizesFiltered = sizes.filter(size => size !== BASIC_SIZE_PLACEHOLDER);

                    if (sizesFiltered.length) {
                        acc.push(`${basicSize}:${sizesFiltered.join(':')}`);
                    }

                    return acc;
                }, [])
                .join(',');

            return [category, sizesString].join(':');
        }
    );
};

export const filtersToParamArray = filters => {
    if (!filters) {
        return [];
    }

    return (
        Object.entries(filters)
            // todo SEARCH-2398 maybe we should handle it differently
            .filter(([filterName, filterValue]) => filterName && filterValue?.length)
            .map(([filterName, filterValues]) => {
                const filterValueArray = [filterValues].flat();

                if (isAggregatedSizeFilterValues(filterValueArray)) {
                    const filterValue = parseAggregatedSizeFilterValueArray(filterValueArray).join(
                        PATH_FILTER_VALUES_SEPARATOR
                    );

                    return [encodeURIComponent(filterName), filterValue].join(
                        PATH_FILTER_NAME_VALUE_SEPARATOR
                    );
                }

                const filterValue = filterValueArray.join(PATH_FILTER_VALUES_SEPARATOR);

                return [filterName, filterValue]
                    .map(encodeURIComponent)
                    .join(PATH_FILTER_NAME_VALUE_SEPARATOR);
            })
    );
};

export const parseCategoryUrl = url => {
    const result = {
        categoryUrl: [],
        filters: {},
    };

    if (!url) {
        result.categoryUrl = '';

        return result;
    }

    url.split(PATH_SEGMENTS_SEPARATOR).forEach(item => {
        const [paramName, ...paramValues] = item.split(PATH_FILTER_NAME_VALUE_SEPARATOR);

        if (!paramName) {
            return;
        }

        if (isAggregatedSizeFilterUrlPart(item)) {
            const paramValue = paramValues.join(':');

            result.filters[paramName] = paramValue
                .split(PATH_FILTER_VALUES_SEPARATOR)
                .flatMap(value => {
                    const [sizeCategory, ...sizes] = value.split(':');

                    return sizes
                        .join(':')
                        .split(',')
                        .flatMap(sizesArray => {
                            const [basicSize, ...detailSizes] = sizesArray.split(':');

                            if (detailSizes.length) {
                                return detailSizes.map(size =>
                                    [sizeCategory, basicSize, size].join(':')
                                );
                            }

                            return [sizeCategory, basicSize].join(':');
                        });
                });

            return;
        }

        if (Array.isArray(paramValues) && paramValues[0]) {
            result.filters[paramName] = paramValues.join('').split(PATH_FILTER_VALUES_SEPARATOR);

            return;
        }

        result.categoryUrl.push(paramName);
    });

    result.categoryUrl = result.categoryUrl.join(PATH_SEGMENTS_SEPARATOR);

    return result;
};

export const stringifyCategoryUrl = paramsObject => {
    const { categoryUrl, filters } = paramsObject;
    const params = filtersToParamArray(filters);

    params.unshift(categoryUrl);

    return params.join(PATH_SEGMENTS_SEPARATOR);
};

export const stringifySearchUrl = paramsObject => {
    const { query, filters } = paramsObject;
    const params = filtersToParamArray(filters);

    params.unshift(query);

    return params.join(PATH_SEGMENTS_SEPARATOR);
};

export const mapCategoryUrlToBreadcrumbsForAnalytics = url => {
    if (!url) {
        return '';
    }

    return url
        .replaceAll('-', ' ')
        .split('/')
        .filter(value => value)
        .map(value => capitalizeFirstLetter(value))
        .join('|');
};
