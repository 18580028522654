import { delay as delayFn } from '@assets/async';

export const withRetryHandling = (callback, { delay = 500, maxRetries = 3 } = {}) => {
    const retry = async (attempt = 1) => {
        const response = await callback();

        if (response?.error) {
            if (attempt >= maxRetries) {
                return response;
            }

            await delayFn(delay);

            return retry(attempt + 1);
        }

        return response;
    };

    return retry();
};
