import { registerMediaQueryWatcher } from '@assets/media-query-watcher';

import { CORE_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { lg as BREAKPOINT_LG } from '@theme/resources/variables/responsiveBreakpoints.json';

const DESKTOP_BREAKPOINT = `(min-width: ${BREAKPOINT_LG})`;

export default ({ app, store }) => {
    window.onNuxtReady(() => {
        try {
            registerMediaQueryWatcher(
                isDesktop => store.dispatch('setIsDesktop', isDesktop),
                DESKTOP_BREAKPOINT
            );
        } catch (err) {
            app.$errorHandler.captureDomainError(CORE_ERROR_DOMAIN, err, {
                [ERROR_ACTION_TAG_NAME]: 'plugin.is-desktop-watcher',
            });
        }
    });
};
