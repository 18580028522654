import { MEGATRON_CHECKOUT_GET_REPAYMENT_ORDER_DETAILS_OPERATION_NAME } from '@configs/megatron-client';

import normalizeThanksPageData from '@normalizers/models/thanksPageData';

const normalizer = response => {
    const {
        data: { thanksPageData },
        error,
    } = response;

    if (error || !thanksPageData) {
        return response;
    }

    response.data.thanksPageData = normalizeThanksPageData(thanksPageData);

    return response;
};

export const getRepaymentOrderDetails = {
    operationName: MEGATRON_CHECKOUT_GET_REPAYMENT_ORDER_DETAILS_OPERATION_NAME,
    normalizer,
};
