import { toHreflangLink } from '@assets/hreflang';

import { IS_LIVE_CHAT_ALLOWED } from '@localeConfig/keys';

export default {
    storeCode: state => state.storeView.code,

    locale: state => state.storeView.locale,

    countryCode: (_, getters) => getters.locale.split('_')[1],

    currency: state => state.storeView.base_currency_code,

    baseDomain: state => state.storeView.base_url,

    timezone: state => state.storeView.timezone,

    metaLinks: state =>
        state.storeViews.map(({ locale, base_url }) =>
            toHreflangLink(locale, `https://${base_url}`)
        ),

    localeConfigByKey: state => key => {
        return state.localeConfig[key] || undefined;
    },

    isMagicLinkEnabled: state => state.globalConfig.isMagicLinkEnabled,

    isZowieChatEnabled: state => state.globalConfig.isZowieChatEnabled,

    zowieChatEnabledPages: state => state.globalConfig.zowieChatEnabledPages,

    zowieScriptSrc: state => state.globalConfig.zowieScriptSrc,

    zowieChatInstanceId: state => state.globalConfig.zowieChatInstanceId,

    contactFormLink: state => state.helpCenterConfig?.contactFormLink,

    freeShippingTresholdInternal: state => state.globalConfig.freeShippingTresholdInternal,

    freeShippingTresholdPartner: state => state.globalConfig.freeShippingTresholdPartner,

    minimalShippingCostInternal: state => state.globalConfig.minimalShippingCostInternal,

    shippingTimeInfo: state => state.globalConfig.shippingTimeInfo,

    isLiveChatEnabled: (_, getters) =>
        getters.localeConfigByKey(IS_LIVE_CHAT_ALLOWED) && !getters.isZowieChatEnabled,

    isAvatarClientAddressEnabled: state => state.globalConfig.isAvatarClientAddressEnabled,

    isAccountVerificationEnabled: state => state.globalConfig.isAccountVerificationEnabled,

    freeDeliveryText: state => state.globalConfig.freeDeliveryText,

    synthroneScriptUrl: state => state.globalConfig.synthroneScriptUrl,

    synthroneBrands: state =>
        (state.globalConfig.synthroneBrands || '')
            .split(',')
            .map(brand => brand.trim())
            .filter(brand => !!brand),
};
