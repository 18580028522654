import ShippingMethod from '@models/ShippingMethod/ShippingMethod';

export default async function normalizeCart(cart) {
    if (!cart) {
        return cart;
    }

    const { extCart, ...cartData } = cart;

    if (!extCart || extCart.store_carts?.length === 0) {
        return cart;
    }

    return {
        ...cartData,
        extCart: {
            ...extCart,
            store_carts: extCart.store_carts.map(storeCart => ({
                ...storeCart,
                selected_shipping_method: new ShippingMethod(
                    storeCart.selected_shipping_method
                ).getModel(),
            })),
        },
    };
}
