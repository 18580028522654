import {
    INPOST_SHIPPING_METHOD_CARRIER_CODE,
    DPD_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    SPEEDY_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    STORE_PICKUP_SHIPPING_METHOD_CARRIER_CODE,
} from '@configs/checkout-shipping-methods';

export default class ShippingMethod {
    constructor(method) {
        this.method = method ?? {};
    }

    getModel() {
        const {
            method_code = '',
            module_map = false,
            shipping_option = null,
            options = [],
            id = '',
            price = '',
            active = false,
            logo = '',
            description = '',
            name = '',
            displayName = '',
            additional_data = {},
        } = this.method;

        const methodCode = method_code || name;
        const { point, store_pickup_place: storePickupPlace = null } = additional_data;

        let {
            inpost_point: inpostPoint = null,
            shipping_point: shippingPoint = null,
            dpd_parcel_shop_point: dpdParcelShopPoint = null,
            speedy_parcel_shop_point: speedyParcelShopPoint = null,
        } = additional_data;
        let storePickupPlaceId = null;

        if (point) {
            if (module_map) {
                shippingPoint = point;
            } else if (methodCode === INPOST_SHIPPING_METHOD_CARRIER_CODE) {
                inpostPoint = point;
            } else if (methodCode === STORE_PICKUP_SHIPPING_METHOD_CARRIER_CODE) {
                storePickupPlaceId = point;
            } else if (methodCode === DPD_POINTS_SHIPPING_METHOD_CARRIER_CODE) {
                dpdParcelShopPoint = point;
            } else if (methodCode === SPEEDY_POINTS_SHIPPING_METHOD_CARRIER_CODE) {
                speedyParcelShopPoint = point;
            }
        }

        return {
            id,
            price,
            isActive: active,
            isDefaultPickupPointMethod: module_map,
            logoUrl: logo,
            description,
            title: displayName,
            carrierCode: methodCode,
            methodCode,
            shippingPointId: shippingPoint?.shipping_point_id,
            shippingPoint,
            inpostPoint,
            selectedShippingOption: shipping_option,
            shippingOptions: options,
            dpdParcelShopPoint,
            speedyParcelShopPoint,
            storePickupPlaceId,
            storePickupPlace,
        };
    }
}
