import Deferred from '@core-assets/deferred';
import { isObject } from '@assets/object';

import { MODALS_MODULE_NAME } from '../store/modals/meta';

const getModalsModule = async () =>
    import(/* webpackChunkName: "store-async-modules-modals" */ '../store/modals');

export default class ModalsManager {
    static IS_OPEN_GETTER_NAME = ModalsManager.getNameWithNamespace('isOpen');
    static OPEN_ACTION_NAME = ModalsManager.getNameWithNamespace('open');
    static CLOSE_ACTION_NAME = ModalsManager.getNameWithNamespace('close');
    static SET_CONFIG_ACTION_NAME = ModalsManager.getNameWithNamespace('setConfig');

    static GET_CONFIG_GETTER_NAME = ModalsManager.getNameWithNamespace('getConfig');

    registerModuleDeferred = null;

    constructor(store, { isRegisteredGetterName, setRegisteredActionName }) {
        this.$store = store;
        this.isRegisteredGetterName = isRegisteredGetterName;
        this.setRegisteredActionName = setRegisteredActionName;
    }

    isOpen(modalName) {
        return this.isStoreModuleRegistered
            ? this.$store.getters[ModalsManager.IS_OPEN_GETTER_NAME](modalName)
            : false;
    }

    async open(modalName, config = null) {
        if (!this.isStoreModuleRegistered) {
            await this.registerModalsModule();
        }

        await this.setConfig(modalName, config);

        await this.$store.dispatch(ModalsManager.OPEN_ACTION_NAME, modalName);
    }

    async setConfig(modalName, config = null) {
        if (!isObject(config)) {
            return;
        }

        await this.$store.dispatch(ModalsManager.SET_CONFIG_ACTION_NAME, {
            modalName,
            ...config,
        });
    }

    getConfig(modalName) {
        if (!this.isStoreModuleRegistered) {
            return {};
        }

        return this.$store.getters[ModalsManager.GET_CONFIG_GETTER_NAME](modalName);
    }

    close(modalName) {
        this.$store.dispatch(ModalsManager.CLOSE_ACTION_NAME, modalName);
        this.$store.dispatch(ModalsManager.SET_CONFIG_ACTION_NAME, {
            modalName,
        });
    }

    async registerModalsModule() {
        if (this.registerModuleDeferred) {
            return this.registerModuleDeferred.promise;
        }

        this.registerModuleDeferred = new Deferred();

        const { default: modalsModule } = await getModalsModule();

        this.$store.registerModule(MODALS_MODULE_NAME, modalsModule);
        await this.$store.dispatch(this.setRegisteredActionName, MODALS_MODULE_NAME);

        this.registerModuleDeferred.resolve();
    }

    static getNameWithNamespace(name) {
        return `${MODALS_MODULE_NAME}/${name}`;
    }

    get isStoreModuleRegistered() {
        return this.$store.getters[this.isRegisteredGetterName](MODALS_MODULE_NAME);
    }
}
