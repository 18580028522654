<template>
    <div class="footer-menu">
        <FooterLinks
            v-for="(section, index) in menuSections"
            :key="`${section.sectionName}-${index}`"
            :heading="section.sectionName"
        >
            <FooterLink
                v-for="({ path, label }, key) in section.links"
                :key="`${label}-${key}`"
                :path="path"
                :label="label"
            />
        </FooterLinks>
    </div>
</template>

<script>
import FooterLink from '@atoms/FooterLink/FooterLink';

import FooterLinks from '@molecules/FooterLinks/FooterLinks';

export default {
    name: 'FooterMenu',

    components: {
        FooterLink,
        FooterLinks,
    },

    props: {
        menuSections: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-menu {
    @screen lg {
        @apply grid;
        @apply gap-ui-4;

        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
</style>
