export default async ({ $configProvider }) => {
    const brand = $configProvider.getConfig('brand');

    let serviceWorkerPath;

    if (brand === 'modivo') {
        serviceWorkerPath = '/sw.js';
    } else {
        serviceWorkerPath = `/sw.${brand}.js`;
    }

    const brands = JSON.parse('{"modivo":{"enabled":true},"eobuwie":{"enabled":true}}');

    if (!brands[brand].enabled) {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(registrations => {
                // eslint-disable-next-line no-restricted-syntax
                for (const registration of registrations) {
                    console.info('[x-pwa] [workbox] Unregistering service worker:', registration);
                    registration.unregister();
                }
            });
        }

        if ('caches' in window) {
            caches.keys().then(keys => {
                if (keys.length) {
                    console.info('[x-pwa] [workbox] Cleaning cache for:', keys.join(', '));

                    // eslint-disable-next-line no-restricted-syntax
                    for (const key of keys) {
                        caches.delete(key);
                    }
                }
            });
        }

        return;
    }

    async function register() {
        if (!('serviceWorker' in navigator)) {
            throw new Error('serviceWorker is not supported in current browser!');
        }

        const { Workbox } = await import('workbox-cdn/workbox/workbox-window.prod.es5.mjs');

        const workbox = new Workbox(serviceWorkerPath, {
            scope: '/',
        });

        await workbox.register();

        return workbox;
    }

    window.$workbox = register().catch(() => {});
};
