export const STORAGE_PREFIX = '__MODIVO__';
export const COOKIE_PREFIX = '__MODIVO__';
export const STORAGE_EXPIRES_KEY = 'expires';
export const STORAGE_EXPIRES_DATA_KEY = 'data';
export const SYNERISE_UUID_COOKIE_NAME = '_snrs_uuid';
export const COOKIES_MODAL_COOKIE_NAME = 'cookies_modal';
export const COOKIES_POLICY_COOKIE_NAME = 'cookies_policy';
export const COOKIES_PERSONAL_DATA_COOKIE_NAME = 'cookies_personal_data';
export const GOOGLE_ANALYTICS_CLIENT_ID_COOKIE_NAME = '_ga';
export const GENDER_COOKIE_NAME = 'gender';
export const COOKIES_IS_PROMOTION_WIDGET_ENABLED = 'cookies_is_promotion_widget_enabled';
export const SHOULD_DISPLAY_NEWSLETTER_CONFIRMATION_SECTION =
    'should_display_newsletter_confirmation_section';

export const RESET_PASSWORD_CUSTOMER_ID = 'reset_password_customer_id';
export const RESET_PASSWORD_TOKEN = 'reset_password_token';

export const SEARCH_HISTORY_KEY = 'search_history';
export const LOCAL_WISHLIST_KEY = 'local_wishlist';
export const LOCAL_WISHLIST_PRICES_KEY = 'local_wishlist_prices';
export const GUEST_CART_ID_KEY = 'guest_cart_id';
export const USER_CART_ID_KEY = 'user_cart_id';
export const USER_FIRST_VISIT_KEY = 'first_visit';
export const ACTIVE_MAIN_CATEGORY_KEY = 'active_main_category';
export const CHECKOUT_ORDER_KEY = 'order';
export const CHECKOUT_ORDER_SELECTED_PAYMENT_TYPE_KEY = 'order_selected_payment_type';
export const RECENTLY_VIEWED_PRODUCTS_KEY = 'recently_viewed_products';
export const CUSTOMER_ADDRESS_KEY = 'temp_customer_address';
export const CUSTOMER_EMAIL_KEY = 'temp_customer_email';
export const CUSTOMER_COMMUNICATION_AGREEMENT_KEY = 'temp_customer_communication_agreement';
export const PWA_INSTALLED_KEY = 'pwa_installed';
export const IS_PWA_INSTALL_BANNER_CLOSED = 'is_pwa_install_banner_closed';
export const LOYALTY_CLUB_MODAL_AFTER_LOGIN = 'loyalty-club-modal-after-login';
export const LOYALTY_CLUB_REFERENCE_ID = 'reference-id';
export const HIDE_MODAL_CONSENTS_KEY = 'hide_modal_consents';
export const GTM_CATEGORIES_STORAGE_KEY = 'categories_storage';
export const GTM_PROVIDERS_STORAGE_KEY = 'providers_storage';
export const GTM_CONSENTS_ACCEPTED_KEY = 'consents_accepted';
export const CONSENTS_ITEMS_KEY = 'items_consents_codes';
export const WEB_PUSH_SUBSCRIBE_STATUS = 'web-push-subscribe-status';
export const WEB_PUSH_SUBSCRIBED = 'subscribed';
export const WEB_PUSH_UNSUBSCRIBED = 'unsubscribed';
export const IS_UNPAID_ORDER_BANNER_CLOSED = 'is_unpaid_order_banner_closed';
export const LOGIN_METHOD = 'login-method';
export const ORIGINAL_REFERRER_KEY = 'original-referrer';
export const IS_NEW_AVATAR_ID = 'is-new-avatar-id';
export const AVATAR_ID = 'avatar-id';
export const FALLBACK_AVATAR_ID = 'fallback-avatar-id';
export const IS_LOGIN_TOOLTIP_SHOWN = 'is-login-tooltip-shown';
export const LOGIN_TOOLTIP_SHOWN_COUNTER = 'login-tooltip-shown-counter';
export const SESSION_CLIENT_ID = 'session-client-id';
export const CURRENT_PRODUCT_KEY = 'current_product';
export const PATH_TO_REDIRECT_FROM_LOGIN_AND_REGISTER_PAGE =
    'path-to-redirect-from-login-and-register-page';
export const REDIRECT_PATH_AFTER_AUTH = 'redirect-path-after-auth';
export const SESSION_KEY = 'session-key';
export const WISHLIST_PRODUCT_TO_ADD_AFTER_LOGIN = 'wishlist-product-to-add-after-login';
export const PAGE_VIEWS_KEY = 'page-views';
export const MODAL_NEWSLETTER_VIEWS_KEY = 'modal-newsletter-views';
export const MODAL_NEWSLETTER_LAST_VIEW_EXPIRATION_KEY = 'modal-newsletter-last-view-expiration';
export const REDIRECT_TO_CUSTOMER_PAGE_AFTER_LOGIN = 'redirect-to-customer-page-after-login';
export const NEWSLETTER_TOOLTIP_SHOWN_COUNTER = 'newsletter-tooltip-shown-counter';
export const IS_NEWSLETTER_TOOLTIP_DISABLED = 'is-newsletter-tooltip-disabled';
export const NEWSLETTER_TOOLTIP_LAST_VIEW_EXPIRATION_KEY =
    'newsletter-tooltip-last-view-expiration';
export const IS_ADD_TO_WISHLIST_CLICKED = 'is-add-to-wishlist-clicked';

export const IS_MOBILE_WEB_VIEW = 'is-mobile-web-view';

export const MILLISECONDS_IN_HOUR = 60 * 60 * 1000;
export const MILLISECONDS_IN_DAY = 24 * MILLISECONDS_IN_HOUR;
export const MILLISECONDS_IN_WEEK = MILLISECONDS_IN_DAY * 7;
export const SECONDS_IN_DAY = 60 * 60 * 24;
export const SECONDS_IN_YEAR = SECONDS_IN_DAY * 365;

export const STORAGE_TYPE_LOCAL = 'localStorage';
export const STORAGE_TYPE_SESSION = 'sessionStorage';
export const STORAGE_TYPE_DEFAULT = STORAGE_TYPE_LOCAL;

export const STORAGE_TYPES = {
    STORAGE_TYPE_LOCAL,
    STORAGE_TYPE_SESSION,
};

export const COOKIE_NAMES = [
    COOKIES_MODAL_COOKIE_NAME,
    COOKIES_POLICY_COOKIE_NAME,
    COOKIES_PERSONAL_DATA_COOKIE_NAME,
];
