export const NAME = 'adTech';

export const NOTIFY_BEACON = 'NOTIFY_BEACON';
export const GET_DISPLAY = 'GET_DISPLAY';
export const GET_DISPLAY_WITH_POSITIONS = 'GET_DISPLAY_WITH_POSITIONS';
export const GET_STATIC_BANNERS = 'GET_STATIC_BANNERS';
export const GET_COMPANY_INFORMATION = 'GET_COMPANY_INFORMATION';

export const ASYNC_AD_TECH_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        NOTIFY_BEACON,
        GET_DISPLAY,
        GET_DISPLAY_WITH_POSITIONS,
        GET_STATIC_BANNERS,
        GET_COMPANY_INFORMATION,
    },
};
