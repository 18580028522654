import { types } from './mutations';

export default {
    setStatusesData({ commit }, statusesData) {
        commit(types.SET_STATUSES_DATA, statusesData);
    },

    async getStatusesData({ dispatch }) {
        const statusesWithDiscountRates = await this.$services.loyaltyClub.getStatusesData();

        if (!statusesWithDiscountRates) {
            return false;
        }

        dispatch('setStatusesData', statusesWithDiscountRates);

        return true;
    },

    setLoyaltyClubSettings({ commit }, loyaltyClubSettings) {
        commit(types.SET_LOYALTY_CLUB_SETTINGS, loyaltyClubSettings);
    },

    async getLoyaltyClubSettings({ dispatch }) {
        const loyaltyClubSettings = await this.$services.loyaltyClub.getLoyaltyClubSettings();

        if (!loyaltyClubSettings) {
            return false;
        }

        dispatch('setLoyaltyClubSettings', loyaltyClubSettings);

        return true;
    },
};
