const { resolve } = require('path');

const { GENERAL_TRANSLATIONS_KEY } = require('../../../../server/trans/config');

const PRODUCT_I18N_NAMESPACE = '__product';

module.exports = {
    namespace: PRODUCT_I18N_NAMESPACE,

    generateFilePaths: lang => ({
        [GENERAL_TRANSLATIONS_KEY]: resolve(__dirname, `./translations/${lang}.json`),
    }),
};
