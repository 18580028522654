import { flatMap } from '@assets/async';

const normalizeFieldValue = async (
    fieldValue,
    { type },
    { dateHelper, timezone, dateFormat } = {}
) => {
    const defaultValue = null;

    if (typeof fieldValue === 'string' && type === Date && dateHelper) {
        const isValid = await dateHelper.isValid(fieldValue, dateFormat);

        if (!isValid) {
            return defaultValue;
        }

        return dateHelper?.createDateFromStringInTimezone(fieldValue, dateFormat, timezone);
    }

    if (typeof fieldValue === 'number' && type === Number) {
        const number = parseInt(fieldValue, 10);

        return Number.isNaN(number) ? defaultValue : number;
    }

    return fieldValue?.trim() || defaultValue;
};

export const getNormalizedAttributeModelValues = async (
    attributeModel,
    attributeValues,
    normalizerOptions,
    attributeValueCallback = null
) => {
    const mapped = await flatMap(Object.entries(attributeModel), async ([key, model]) => {
        let attributeValue = attributeValues[key];

        if (typeof attributeValueCallback === 'function') {
            attributeValue = attributeValueCallback(attributeValue, key, model, attributeValues);
        }

        const value = await normalizeFieldValue(attributeValue, model, normalizerOptions);

        const entries = [[key, value]];

        if (model.keepRaw === true) {
            entries.push([`${key}_raw`, attributeValue]);
        }

        return entries;
    });

    return Object.fromEntries(mapped);
};
