import {
    PSB_FOOTER_CONFIGURATION,
    FOOTER_SIMPLE_CMS_BLOCKS_IDS,
    COOKIES_CMS_BLOCKS_IDS,
} from '@configs/footer';
import { CMS_MARKETING_BAR_GLOBAL_ID } from '@configs/marketing-bar';
import { CMS_BLOCK_ID as SMART_BANNER_CMS_BLOCK_ID } from '@configs/smart-banner';
import { HEADER_CMS_BLOCKS_IDS } from '@configs/header';
import { SIDEBAR_MENU_BLOCKS_IDS } from '@configs/sidebar';
import { NEWSLETTER_CMS_BLOCKS_IDS } from '@configs/newsletter';
import { CMS_GLOBAL_CONFIG_ID } from '@configs/global-config';
import { PSB_SYNERISE_CONFIG_ID } from '@configs/synerise';
import { SESSION_KEY } from '@configs/storage';

import { HOME_PAGE_NAME } from '@router/names';

import { CORE_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { ERROR_ACTION_TAG_NAME, ERROR_STORE_MODULE_TAG_NAME } from '@types/Errors';

import { getSessionIdWithUUID } from '@assets/session';
import { isRouteUsingFetchInParallel } from '@assets/fetch-in-parallel';
import { saveCountryCodeForGTM } from '@assets/gtm-native-enhanced-conversion';

import registerHeaderStoreModule from '@header/store/helpers/registerHeaderStoreModule';

import {
    types,
    NAVIGATION,
    CMS_BLOCKS,
    MARKETING_BAR_CONFIG,
    LOYALTY_CLUB_STATUSES_DATA,
    LOYALTY_CLUB_SETTINGS,
    IS_STRICT_PHONE_VALIDATION_ENABLED_FLAG,
    PAGE_BUILDER_BLOCKS,
} from './mutations';

const MODULE_NAME = 'root';

const cmsBlocksToLoad = [
    ...COOKIES_CMS_BLOCKS_IDS,
    ...HEADER_CMS_BLOCKS_IDS,
    ...NEWSLETTER_CMS_BLOCKS_IDS,
];

const pageBuilderBlocksToLoad = new Set([
    CMS_GLOBAL_CONFIG_ID,
    PSB_SYNERISE_CONFIG_ID,
    PSB_FOOTER_CONFIGURATION,
    SMART_BANNER_CMS_BLOCK_ID,
    CMS_MARKETING_BAR_GLOBAL_ID,
]);

export default {
    async nuxtServerInit({ dispatch }, { app, store, route, redirect }) {
        const { name, query } = route;

        registerHeaderStoreModule({ store });

        if (name === HOME_PAGE_NAME) {
            app.$navigationContext.redirectToSavedContextHomepage(redirect, query);
        }

        if (!isRouteUsingFetchInParallel(route)) {
            await dispatch('getStoreData');
        }
    },

    nuxtClientInit({ dispatch, rootGetters }, { store }) {
        const { $errorHandler, $storage } = this.app;

        registerHeaderStoreModule({ store });
        getSessionIdWithUUID(this, SESSION_KEY);
        saveCountryCodeForGTM({
            countryCode: rootGetters['config/countryCode'],
            $errorHandler,
            $storage,
        });
        dispatch('addVisibilityChangeEventListenerForAuth');
        dispatch('synerise/setSyneriseConfig');
    },

    async getStoreFullData({ state, commit, dispatch }) {
        const { defaultCategoryId } = state;
        const {
            categoryList,
            cmsBlocks,
            pageBuilderBlocks,
            loyaltyClubSettings,
            statusesWithDiscountRates,
            isStrictPhoneValidationEnabled,
            storeConfig,
        } = await this.$services.global.getStoreData(
            cmsBlocksToLoad,
            Array.from(pageBuilderBlocksToLoad),
            defaultCategoryId
        );

        const cmsBlocksAreValid = Boolean(
            Array.isArray(cmsBlocks?.items) && cmsBlocks.items.length
        );

        if (cmsBlocksAreValid) {
            dispatch('cmsBlock/setCmsBlocks', cmsBlocks.items);
        }

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: CMS_BLOCKS,
            isValid: cmsBlocksAreValid,
        });

        const pageBuilderBlocksAreValid = Boolean(
            Array.isArray(pageBuilderBlocks?.items) && pageBuilderBlocks.items.length
        );

        if (pageBuilderBlocksAreValid) {
            dispatch('cmsBlock/setCmsBlocks', pageBuilderBlocks.items);
        }

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: MARKETING_BAR_CONFIG,
            isValid: pageBuilderBlocksAreValid,
        });

        const categoriesAreValid = Boolean(Array.isArray(categoryList) && categoryList.length);

        if (categoriesAreValid) {
            const mainCategoriesWrapper = categoryList[0];

            dispatch('header/navigation/setNavigationCategories', mainCategoriesWrapper);
            this.app.$navigationContext.initNavigationContext(mainCategoriesWrapper);
        }

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: NAVIGATION,
            isValid: categoriesAreValid,
        });

        const loyaltyClubSettingsAreValid = Boolean(Object.keys(loyaltyClubSettings || {}).length);

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: LOYALTY_CLUB_SETTINGS,
            isValid: loyaltyClubSettingsAreValid,
        });

        if (loyaltyClubSettingsAreValid) {
            dispatch('loyaltyClub/setLoyaltyClubSettings', loyaltyClubSettings);
        }

        const loyaltyClubStatusesDataAreValid = Boolean(
            Array.isArray(statusesWithDiscountRates) && statusesWithDiscountRates.length
        );

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: LOYALTY_CLUB_STATUSES_DATA,
            isValid: loyaltyClubStatusesDataAreValid,
        });

        if (loyaltyClubStatusesDataAreValid) {
            dispatch('loyaltyClub/setStatusesData', statusesWithDiscountRates);
        }

        const strictPhoneValidationEnabledIsValid =
            typeof isStrictPhoneValidationEnabled === 'boolean';

        if (strictPhoneValidationEnabledIsValid) {
            dispatch('validation/setStrictPhoneValidationEnabled', isStrictPhoneValidationEnabled);

            commit(types.SET_DATA_LOADED, {
                dataLoadedName: IS_STRICT_PHONE_VALIDATION_ENABLED_FLAG,
                isValid: true,
            });
        }

        const { stanley_prefix: stanleyPrefix = null } = storeConfig || {};

        if (stanleyPrefix) {
            commit(types.SET_STANLEY_PREFIX, stanleyPrefix);
        }

        if (pageBuilderBlocksAreValid) {
            await Promise.allSettled([
                dispatch('config/loadGlobalConfig'),
                dispatch('synerise/loadSyneriseConfig'),
            ]);
        }

        if (
            !cmsBlocksAreValid ||
            !categoriesAreValid ||
            !loyaltyClubSettingsAreValid ||
            !loyaltyClubStatusesDataAreValid ||
            !strictPhoneValidationEnabledIsValid ||
            !pageBuilderBlocksAreValid
        ) {
            throw new Error(
                // eslint-disable-next-line max-len
                `Error occured during fetching full store data. Data validation results - cmsBlocks: ${cmsBlocksAreValid}, categoryList: ${categoriesAreValid}, loyaltyClubSettings: ${loyaltyClubSettingsAreValid}, loyaltyClubStatusesDataAreValid: ${loyaltyClubStatusesDataAreValid}, isStrictPhoneValidationEnabled: ${strictPhoneValidationEnabledIsValid}, pageBuilderBlocks: ${pageBuilderBlocksAreValid}`
            );
        }
    },

    async loadNavigation({ commit, dispatch }) {
        const isValid = await dispatch('header/navigation/getNavCategoriesData');

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: NAVIGATION,
            isValid,
        });
    },

    async loadInitialCmsBlocks({ commit, dispatch }) {
        const isValid = await dispatch('cmsBlock/getCmsBlocks', {
            cmsBlocksToLoad,
        });

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: CMS_BLOCKS,
            isValid,
        });
    },

    async loadInitialPageBuilderBlocks({ commit, dispatch }) {
        const isValid = await dispatch('cmsBlock/getPageBuilderBlocks', {
            pageBuilderBlocksToLoad: Array.from(pageBuilderBlocksToLoad),
        });

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: PAGE_BUILDER_BLOCKS,
            isValid,
        });
    },

    async loadMarketingBarConfig({ commit, dispatch }) {
        const isValid = await dispatch('cmsBlock/getPageBuilderBlocks', {
            pageBuilderBlocksToLoad: [CMS_MARKETING_BAR_GLOBAL_ID],
        });

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: MARKETING_BAR_CONFIG,
            isValid,
        });
    },

    async loadLoyaltyClubStatusesData({ commit, dispatch }) {
        const isValid = await dispatch('loyaltyClub/getStatusesData');

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: LOYALTY_CLUB_STATUSES_DATA,
            isValid,
        });
    },

    async loadLoyaltyClubSettings({ commit, dispatch }) {
        const isValid = await dispatch('loyaltyClub/getLoyaltyClubSettings');

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: LOYALTY_CLUB_SETTINGS,
            isValid,
        });
    },

    async loadStrictPhoneValidationEnabled({ commit, dispatch }) {
        const isValid = await dispatch('validation/getStrictPhoneValidationEnabled');

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: IS_STRICT_PHONE_VALIDATION_ENABLED_FLAG,
            isValid,
        });
    },

    async getStoreData({ state, dispatch, rootGetters }) {
        const { dataLoaded } = state;
        const { isConfigValid } = rootGetters;

        if (!isConfigValid) {
            const {
                [NAVIGATION]: navigationLoadedFlag,
                [CMS_BLOCKS]: cmsLoadedFlag,
                [PAGE_BUILDER_BLOCKS]: pageBuilderBlocksLoadedFlag,
                [MARKETING_BAR_CONFIG]: marketingBarConfigFlag,
                [LOYALTY_CLUB_STATUSES_DATA]: cmsLoyaltyClubStatusesDataFlag,
                [LOYALTY_CLUB_SETTINGS]: cmsLoyaltyClubSettingsFlag,
                [IS_STRICT_PHONE_VALIDATION_ENABLED_FLAG]: isStrictPhoneValidationEnabledFlag,
            } = dataLoaded;

            try {
                const dataLoadHandlers = [
                    {
                        loadActionName: 'loadNavigation',
                        shouldLoad: !navigationLoadedFlag,
                    },
                    {
                        loadActionName: 'loadInitialCmsBlocks',
                        shouldLoad: !cmsLoadedFlag,
                    },
                    {
                        loadActionName: 'loadInitialPageBuilderBlocks',
                        shouldLoad: !pageBuilderBlocksLoadedFlag,
                    },
                    {
                        loadActionName: 'loadMarketingBarConfig',
                        shouldLoad: !marketingBarConfigFlag,
                    },
                    {
                        loadActionName: 'loadLoyaltyClubStatusesData',
                        shouldLoad: !cmsLoyaltyClubStatusesDataFlag,
                    },
                    {
                        loadActionName: 'loadLoyaltyClubSettings',
                        shouldLoad: !cmsLoyaltyClubSettingsFlag,
                    },
                    {
                        loadActionName: 'loadStrictPhoneValidationEnabled',
                        shouldLoad: !isStrictPhoneValidationEnabledFlag,
                    },
                ].filter(loadDataHandler => loadDataHandler.shouldLoad);

                if (dataLoadHandlers.length === Object.keys(dataLoaded).length) {
                    await dispatch('getStoreFullData');
                } else {
                    await Promise.all(
                        dataLoadHandlers.map(handler => dispatch(handler.loadActionName))
                    );
                }
            } catch (err) {
                this.app.$errorHandler.captureDomainError(CORE_ERROR_DOMAIN, err, {
                    [ERROR_ACTION_TAG_NAME]: 'getStoreData',
                    [ERROR_STORE_MODULE_TAG_NAME]: MODULE_NAME,
                });
            }
        }

        await dispatch('header/navigation/prepareNavigationData');
    },

    async getSidebarStoreData({ dispatch }) {
        await dispatch('cmsBlock/getCmsBlocks', {
            cmsBlocksToLoad: SIDEBAR_MENU_BLOCKS_IDS,
        });
    },

    async getFooterSimpleStoreData({ dispatch }) {
        await dispatch('cmsBlock/getCmsBlocks', {
            cmsBlocksToLoad: FOOTER_SIMPLE_CMS_BLOCKS_IDS,
        });
    },

    setIsMobile({ state, commit }, isMobile) {
        if (state.isMobile !== isMobile) {
            commit(types.SET_IS_MOBILE, isMobile);
        }

        if (!state.isMobileEvaluated) {
            commit(types.SET_IS_MOBILE_EVALUATED, true);
        }
    },

    setIsTabletOrDesktop({ state, commit }, isTabletOrDesktop) {
        if (state.isTabletOrDesktop !== isTabletOrDesktop) {
            commit(types.SET_IS_TABLET_OR_DESKTOP, isTabletOrDesktop);
        }

        if (!state.isTabletOrDesktopEvaluated) {
            commit(types.SET_IS_TABLET_OR_DESKTOP_EVALUATED, true);
        }
    },

    setIsDesktop({ state, commit }, isDesktop) {
        if (state.isDesktop !== isDesktop) {
            commit(types.SET_IS_DESKTOP, isDesktop);
        }

        if (!state.isDesktopEvaluated) {
            commit(types.SET_IS_DESKTOP_EVALUATED, true);
        }
    },

    setIsChatWidgetLoading({ commit }, isChatWidgetLoading) {
        commit(types.SET_IS_CHAT_WIDGET_LOADING, isChatWidgetLoading);
    },

    setIsChatWidgetLoaded({ commit }, isChatWidgetLoaded) {
        commit(types.SET_IS_CHAT_WIDGET_LOADED, isChatWidgetLoaded);
    },

    addPerformanceEntry({ commit }, entry) {
        commit(types.ADD_PERFORMANCE_ENTRY, entry);
    },

    setRouteRenderStart({ commit }, entry) {
        commit(types.SET_ROUTE_RENDER_START, entry);
    },

    setAsyncModuleRegistered({ commit }, moduleName) {
        commit(types.SET_ASYNC_MODULE_REGISTERED, moduleName);
    },

    async addVisibilityChangeEventListenerForAuth({ dispatch, state }) {
        const handleAuthTokensOnVisibleState = async () => {
            if (document.visibilityState !== 'visible') {
                return;
            }

            const previousAccessToken = state.customer.accessToken;

            await dispatch('customer/setAuthorizationDataFromCookies');

            const currentAccessToken = state.customer.accessToken;

            const isDifferentAccessToken = previousAccessToken !== currentAccessToken;

            if (currentAccessToken && (!previousAccessToken || isDifferentAccessToken)) {
                await dispatch('customer/getCustomerData');

                return;
            }

            const isAccessTokenDeletedFromAnotherTab = previousAccessToken && !currentAccessToken;

            if (isAccessTokenDeletedFromAnotherTab) {
                await dispatch('customer/handleExpiredSession', false);
            }
        };

        document.addEventListener('visibilitychange', handleAuthTokensOnVisibleState);
    },

    setPrintableContentOnly({ commit }, isPrintableContentOnly) {
        commit(types.SET_PRINTABLE_CONTENT_ONLY, isPrintableContentOnly);
    },
};
