export const DEFAULT_ZOWIE_CHAT_SRC =
    'https://modivo.chat.getzowie.com/web/live-chat/chatbotize-entrypoint.min.js';

export default function zowieChatInit({ instanceId, scriptSrc }, onLoadedCallback) {
    (function (d, s, id, cb) {
        const js = d.createElement(s);
        const n = d.createElement('div');

        n.id = id;

        if (js.readyState) {
            js.onreadystatechange = function () {
                if (js.readyState === 'loaded' || js.readyState === 'complete') {
                    js.onreadystatechange = null;
                    cb();
                }
            };
        } else {
            js.onload = function () {
                cb();
            };
        }

        js.src = scriptSrc;
        document.body.appendChild(js);
        document.body.appendChild(n);
    })(document, 'script', 'chatbotize', function () {
        // eslint-disable-next-line no-undef
        Chatbotize.init({
            instanceId,
            startOnOpen: true,
            headerMode: 'white',
            onLoaded: onLoadedCallback,
            position: {
                desktopBottom: 89,
                desktopRight: 4,
                mobileOffset: 500,
                mobileBottom: 89,
                mobileRight: 4,
            },
        });
    });
}
