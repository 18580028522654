import { createNamespacedHelpers } from 'vuex';
import { capitalizeFirstLetter } from '@assets/string-utility';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    computed: {
        ...mapConfigGetters(['baseDomain']),

        titleBaseDomain() {
            return capitalizeFirstLetter(this.baseDomain);
        },
    },
};
