import {
    ACCOUNT_PAGE,
    CHECKOUT_PAGE,
    CONTACT_PAGE,
    CUSTOMER_REGISTRATION_PAGE,
    NEWSLETTER_REGISTRATION,
    LOYALTY_CLUB,
    PRODUCT_ALERT_INFO,
    CUSTOMER_REGISTRATION_THROUGH_SOCIAL_POPUP,
    WISHLIST,
} from '@configs/gdpr';

import gdprMutations from './mutations';
import gdprActions from './actions';

export const state = () => ({
    [ACCOUNT_PAGE]: [],
    [CHECKOUT_PAGE]: [],
    [CONTACT_PAGE]: [],
    [CUSTOMER_REGISTRATION_PAGE]: [],
    [NEWSLETTER_REGISTRATION]: [],
    [LOYALTY_CLUB]: [],
    [PRODUCT_ALERT_INFO]: [],
    [CUSTOMER_REGISTRATION_THROUGH_SOCIAL_POPUP]: [],
    [WISHLIST]: [],
    isFetching: false,
});

export const mutations = gdprMutations;
export const actions = gdprActions;
