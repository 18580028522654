import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.newsletter.${action}`,
});

export default ({ megatronClient, $errorHandler }) => ({
    async confirmNewsletterSubscription({ id, code, confirmationSource }) {
        const ERROR_TAGS = getErrorTags('confirmNewsletterSubscription');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/confirmNewsletterSubscription',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        id,
                        code,
                        confirmationSource,
                    },
                }
            );

            if (!error) {
                return data?.confirmNewsletterSubscription?.success;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    id,
                    code,
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                id,
                code,
            });

            return false;
        }
    },

    async unsubscribeNewsletterSubscription({ id, code }) {
        const ERROR_TAGS = getErrorTags('unsubscribeNewsletterSubscription');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/unsubscribeNewsletterSubscription',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        id,
                        code,
                        denyNewsletterAgreementDecisions: true,
                    },
                }
            );

            if (!error) {
                return data?.unsubscribeNewsletterSubscription?.success;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    id,
                    code,
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                id,
                code,
            });

            return false;
        }
    },

    async unsubscribeNewsletterSubscriptionByCode({ code }) {
        const ERROR_TAGS = getErrorTags('unsubscribeNewsletterSubscriptionByCode');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/unsubscribeNewsletterSubscriptionByCode',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        code,
                        denyNewsletterAgreementDecisions: true,
                    },
                }
            );

            if (!error) {
                return data?.unsubscribeNewsletterSubscriptionByCode?.success;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    code,
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                code,
            });

            return false;
        }
    },

    async subscribeToNewsletter(email, gender) {
        const ERROR_TAGS = getErrorTags('subscribeToNewsletter');

        try {
            const { data, error, status } = await megatronClient.post('v1/subscribeToNewsletter', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    email,
                    gender,
                },
            });

            if (!error) {
                return data?.subscribeToNewsletter;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    email,
                    gender,
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                email,
                gender,
            });

            return false;
        }
    },
});
