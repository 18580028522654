import {
    DISCOUNT_TYPES,
    TYPES_FOR_INTERNAL_OFFERS_ONLY,
    TYPES_ORDER,
    TYPE_NEW,
    TYPE_OUTLET,
    TYPE_DISCOUNT,
} from '@types/ProductBadge';

export const filterOutDiscountBadges = badges =>
    badges.filter(({ type }) => !DISCOUNT_TYPES.includes(type));

export const filterDiscountOnlyBadges = badges =>
    badges.filter(({ type }) => DISCOUNT_TYPES.includes(type));

export const filterInternalOnlyBadges = (badges, isChosenOfferInternal) =>
    badges.filter(({ type, isInternal }) => {
        const isBadgeOnlyForInternalOffer = TYPES_FOR_INTERNAL_OFFERS_ONLY.includes(type);

        return !isBadgeOnlyForInternalOffer || isChosenOfferInternal === isInternal;
    });

export const filterBadgesPerLocale = (badges, forbiddenBadges) =>
    badges.filter(({ type }) => !forbiddenBadges.includes(type));

export const sortBadges = badges => {
    return [...badges].sort(
        ({ type: badgeType1 }, { type: badgeType2 }) =>
            TYPES_ORDER.indexOf(badgeType1) - TYPES_ORDER.indexOf(badgeType2)
    );
};

export const getBadgesToShow = badges => {
    if (!badges.length) {
        return badges;
    }

    const sortedBadges = sortBadges(badges);

    const [firstBadge] = sortedBadges;

    const returnedBadges = [firstBadge];

    const outletBadge = sortedBadges.find(({ type }) => type === TYPE_OUTLET);
    const discountBadge = sortedBadges.find(({ type }) => type === TYPE_DISCOUNT);

    const shouldShowDiscountBadge = outletBadge && discountBadge && firstBadge === outletBadge;

    if (shouldShowDiscountBadge) {
        returnedBadges.push(discountBadge);

        return returnedBadges;
    }

    const newBadge = sortedBadges.find(({ type }) => type === TYPE_NEW);

    const shouldShowNewBadge =
        newBadge &&
        firstBadge !== newBadge &&
        !TYPES_FOR_INTERNAL_OFFERS_ONLY.includes(firstBadge.type);

    if (shouldShowNewBadge) {
        returnedBadges.push(newBadge);
    }

    return returnedBadges;
};

export const checkIfOn = currentBadge => {
    const { content } = currentBadge;

    return `${content}#is_on:1/0`;
};
