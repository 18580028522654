export const LOGIN = 'login';
export const REGISTER = 'register';
export const FORGOT_PASSWORD = 'forgot-password';

export const LOGIN_COMPONENT_NAME = 'CustomerLoginPanel';
export const REGISTER_COMPONENT_NAME = 'CustomerRegisterPanel';
export const FORGOT_PASSWORD_COMPONENT_NAME = 'CustomerForgotPasswordPanel';

export const CREATE_ADDRESS_MODAL = 'create-address';
export const UPDATE_ADDRESS_MODAL = 'update-address';

export const AUTHORIZATION_FORMS_IDS = {
    LOGIN,
    REGISTER,
    FORGOT_PASSWORD,
};

export default {
    LOGIN,
    REGISTER,
    LOGIN_COMPONENT_NAME,
    REGISTER_COMPONENT_NAME,
    CREATE_ADDRESS_MODAL,
    UPDATE_ADDRESS_MODAL,
    FORGOT_PASSWORD_COMPONENT_NAME,
};
