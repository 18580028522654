import { ACCOUNT_VERIFICATION_STATES } from '@configs/account-verification';

export default {
    isAccountVerificationStatusLoaded: state => state.accountVerificationStatus !== null,

    isAccountVerified: state =>
        state.accountVerificationStatus === ACCOUNT_VERIFICATION_STATES.COMPLETE,

    isAccountVerificationPending: state =>
        state.accountVerificationStatus === ACCOUNT_VERIFICATION_STATES.PENDING,

    canVerifyAccount: (_, getters) =>
        getters.isAccountVerificationStatusLoaded && !getters.isAccountVerified,
};
