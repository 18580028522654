import {
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_PACKAGE_TRACKING,
} from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME, UNKNOWN_ERROR } from '@types/Errors';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.package-tracking.${action}`,
});

export default ({ megatronClient, $errorHandler }) => ({
    async getOrderTracking(trackingIdentifier) {
        const ERROR_TAGS = getErrorTags('getOrderTracking');
        const payload = { trackingIdentifier };

        try {
            const { data, error, status } = await megatronClient.get('v1/getOrderTracking', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload,
            });

            if (!error) {
                return data?.orderTracking || {};
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return {};
    },
    async getPackageTrackingDetailsList(orderNumber) {
        const { data } = await megatronClient.get(
            `rest/order-trackings-details?trackTraceId=${orderNumber}`,
            {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_PACKAGE_TRACKING,
            }
        );

        return data?.['hydra:member'] || [];
    },
});
