export const debounceAggregate = (func, timeoutMs) => {
    let timerId;
    let params = [];

    return (...args) => {
        clearTimeout(timerId);
        params.push(args);
        timerId = setTimeout(() => {
            func.apply(this, [params]);
            params = [];
        }, timeoutMs);
    };
};
