import { megatronNormalizersRegistry } from '@normalizers/requests/megatron-registry';

export const getMegatronNormalizer = (app, store) => {
    const {
        $createProductPath: createProductPath,
        $createCategoryPath: createCategoryPath,
        $stringifyCategoryUrl: stringifyCategoryUrl,
        $imaginator: imaginator,
        $getStaticFileUrlWithHash: getStaticFileUrlWithHash,
        $abTests: abTests,
        $dateHelper: dateHelper,
        $t: t,
        $configProvider: configProvider,
    } = app;

    const helpers = {
        imaginator,
        createProductPath,
        createCategoryPath,
        stringifyCategoryUrl,
        getStaticFileUrlWithHash,
        abTests,
        dateHelper,
        t,
        localeConfigByKey: store.getters['config/localeConfigByKey'],
        configProvider,
    };

    return (response, operationName) => {
        if (!operationName) {
            return response;
        }

        const normalizer = megatronNormalizersRegistry[operationName];

        if (!normalizer) {
            return response;
        }

        const {
            locale,
            base_currency_code: currency,
            timezone: storeViewTimezone,
        } = store.state.config.storeView;

        return normalizer(
            response,
            {
                locale,
                currency,
                storeViewTimezone,
            },
            helpers
        );
    };
};
