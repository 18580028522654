import { MEGATRON_CLIENT_ENDPOINT_NAME_TRUSTMATE } from '@configs/megatron-client';

export const MODIVO_SMAUG_SUFFIX_PATH = 'rest/smaug';

export const REST_SUFFIX_PATH = 'rest';
export const SYNERISE_SUFFIX_PATH = 'rest/synerise';
export const TRUSTMATE_SUFFIX_PATH = 'rest/trustmate_mod';
export const TRUSTMATE_PROXY_SUFFIX_PATH = 'rest/trustmate';

export const createMegatronEndpointUrl = (baseUrl, suffix = '') => `${[baseUrl, suffix].join('/')}`;

export const createMegatronTrustmateEndpoints = ({
    countries,
    megatronEndpoint,
    isNewProxyEnabled,
}) => {
    if (isNewProxyEnabled) {
        return {
            [MEGATRON_CLIENT_ENDPOINT_NAME_TRUSTMATE]: {
                uri: createMegatronEndpointUrl(megatronEndpoint, TRUSTMATE_PROXY_SUFFIX_PATH),
                useErrorCallback: false,
                context: {
                    useAuthorization: false,
                },
            },
        };
    }

    return countries.reduce((acc, country) => {
        acc[`${MEGATRON_CLIENT_ENDPOINT_NAME_TRUSTMATE}_${country}`] = {
            uri: createMegatronEndpointUrl(
                megatronEndpoint,
                `${TRUSTMATE_SUFFIX_PATH}_${country}_1_1`
            ),
            useErrorCallback: false,
            context: {
                useAuthorization: false,
            },
        };

        return acc;
    }, {});
};
