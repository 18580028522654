import { ECO_FRIENDLY_ATTRIBUTE_NAME as EcoFriendlyAttributeName } from '@configs/eco-friendly';

export const ACTIVE_ATTRIBUTE_NAME = 'product_active';
export const BADGES_ATTRIBUTE_NAME = 'product_badge';
export const BRAND_ATTRIBUTE_NAME = 'manufacturer';
export const BRAND_WITH_COLLECTION_ATTRIBUTE_NAME = 'manufacturer_with_collection';
export const CARE_TIPS_ENTITY_NAME = 'wskazowki_pielegnacyjne_entity';
export const COLOR_ATTRIBUTE_NAME = 'main_color';
export const SIZE_DESCRIPTION_ATTRIBUTE_NAME = 'size_description';
export const COLOR_ARRAY_ATTRIBUTE_NAME = 'kolor';
export const DISCOUNT_ATTRIBUTE_NAME = 'discount';
export const OMNIBUS_DISCOUNT_ATTRIBUTE_NAME = 'omnibus_discount';
export const FASON_ATTRIBUTE_NAME = 'fason';
export const FINAL_PRICE_ATTRIBUTE_NAME = 'final_price';
export const OMNIBUS_PRICE_ATTRIBUTE_NAME = 'omnibus_price';
export const IMAGES_ATTRIBUTE_NAME = 'images';
export const DOUBLE_SIZE_TYPE = 'rozmiar_karta_produktu';
export const PRODUCT_CARD_SIZE_TYPE_ATTRIBUTE_TEST_B = 'karta_produktu_typ_rozmiaru_b';
export const MAPPED_SIZE_TYPE = 'rozmiar_filtr';
export const MODEL_ATTRIBUTE_NAME = 'model';
export const NAME_ATTRIBUTE_NAME = 'nazwa_wyswietlana';
export const NAME_DISPLAY_ATTRIBUTE_NAME = 'nazwa_wyswietlana_front';
export const PRICE_ATTRIBUTE_NAME = 'price';
export const PRODUCER_ATTRIBUTE_NAME = 'producent';
export const PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME = 'product_group_associated';
export const PRODUCT_COLOR_VARIANTS_COUNT_ATTRIBUTE_NAME = 'product_color_variants_count';
export const PURPOSE_ATTRIBUTE_NAME = 'przeznaczenie';
export const SERIES_NAME_ATTRIBUTE_NAME = 'series_name';
export const SEX_NAME_ATTRIBUTE_NAME = 'sex';
export const SIZE_TYPE_NAME_ATTRIBUTE_NAME = 'size_type';
export const SIZE_TABLE_ATTRIBUTE_NAME = 'tabela_rozmiarow';
export const SKU_ATTRIBUTE_NAME = 'index';
export const STICKER_ATTRIBUTE_NAME = 'akcje_marketingowe';
export const STYLE_ATTRIBUTE_NAME = 'styl';
export const URL_KEY_ATTRIBUTE_NAME = 'url_key';
export const VIDEO_ATTRIBUTE_NAME = 'video_url';
export const OCCASION_ATTRIBUTE_NAME = 'okazja';
export const NEW_ATTRIBUTE_NAME = 'nowosc';
export const COLOR_ATTRIBUTES_NAMES = [COLOR_ATTRIBUTE_NAME, COLOR_ARRAY_ATTRIBUTE_NAME];
export const HOT_DEAL_ATTRIBUTE_NAME = 'hot_deal';
export const FOOTWEAR_SIZE_ATTRIBUTE_NAME = 'footwear_size';
export const CLOTHES_SIZE_ATTRIBUTE_NAME = 'clothes_size';
export const HEALTH_BEAUTY_SIZE_ATTRIBUTE_NAME = 'health_beauty_size';
export const HOME_DECOR_SIZE_ATTRIBUTE_NAME = 'home_decor_size';
export const LOYALTY_CLUB_ATTRIBUTE_NAME = 'modivo_fashion_club';
export const ECO_FRIENDLY_ATTRIBUTE_NAME = EcoFriendlyAttributeName;
export const PREMIUM_ATTRIBUTE_NAME = 'premium';
export const SIZE_SUGGESTION_ATTRIBUTE_NAME = 'size_suggestion';
export const SPECIAL_ACTION_ATTRIBUTE_NAME = 'action_label';
export const TECHNOLOGIES_ENTITY_NAME = 'technologie_entity';
export const CERTIFICATE_ENTITY_NAME = 'certyfikat';
export const ONLY_HERE_ATTRIBUTE_NAME = 'tylko_u_nas';
export const OUTLET_ATTRIBUTE_NAME = 'outlet';
export const WIDTH_OF_SHOE_MODEL_ATTRIBUTE_NAME = 'width_of_shoe_model';
export const COLLECTION_ATTRIBUTE_NAME = 'kolekcja';

export const SHORT_NAME_ATTRIBUTE_NAME = 'short_name';
