export default {
    bg_BG: {
        englishName: 'Bulgaria',
        localName: 'България',
    },
    cs_CZ: {
        englishName: 'Czech Republic',
        localName: 'Česká republika',
    },
    de_DE: {
        englishName: 'Germany',
        localName: 'Deutschland',
    },
    el_GR: {
        englishName: 'Greece',
        localName: 'Ελλάδα',
    },
    en_US: {
        englishName: 'Great Britain',
        localName: 'Great Britain',
    },
    fr_FR: {
        englishName: 'France',
        localName: 'France',
    },
    hu_HU: {
        englishName: 'Hungary',
        localName: 'Magyarország',
    },
    it_IT: {
        englishName: 'Italy',
        localName: 'Italia',
    },
    lt_LT: {
        englishName: 'Lithuania',
        localName: 'Lietuva',
    },
    pl_PL: {
        englishName: 'Poland',
        localName: 'Polska',
    },
    ro_RO: {
        englishName: 'Romania',
        localName: 'Rumunia',
    },
    sk_SK: {
        englishName: 'Slovakia',
        localName: 'Slovensko',
    },
    uk_UA: {
        englishName: 'Ukraine',
        localName: 'Україна',
    },
    ru_UA: {
        englishName: 'Ukraine',
        localName: 'Україна',
    },
    hr_HR: {
        englishName: 'Croatia',
        localName: 'Hrvatska',
    },
    lv_LV: {
        englishName: 'Latvia',
        localName: 'Latvija',
    },
    sl_SI: {
        englishName: 'Slovenia',
        localName: 'Slovenija',
    },
    de_AT: {
        englishName: 'Austria',
        localName: 'Österreich',
    },
    et_EE: {
        englishName: 'Estonia',
        localName: 'Eesti',
    },
    de_CH: {
        englishName: 'Switzerland',
        localName: 'Schweiz',
    },
    fr_CH: {
        englishName: 'Switzerland',
        localName: 'Suisse',
    },
    it_CH: {
        englishName: 'Switzerland',
        localName: 'Svizzera',
    },
    es_ES: {
        englishName: 'Spain',
        localName: 'Eesti',
    },
    sv_SE: {
        englishName: 'Sweden',
        localName: 'Sverige',
    },
    ru_RU: {
        englishName: 'Russia',
        localName: 'Россия',
    },
    en_GB: {
        englishName: 'Great Britain',
        localName: 'Great Britain',
    },
};
