<template>
    <div
        class="base-tooltip"
        v-on="listeners"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
    >
        <WithClickOutsideDetection :handler="tooltipVisibility ? 'closeTooltip' : null">
            <div class="base-tooltip-wrapper" :class="tooltipClass">
                <slot />
                <div
                    v-if="tooltipVisibility"
                    class="tooltip-container"
                    :class="[positionResponsive, tooltipContainerClass]"
                    :style="tooltipPadding"
                >
                    <div
                        v-show="hover || alwaysVisible"
                        class="tooltip-content"
                        :class="tooltipContentClass"
                    >
                        <slot
                            :is-visible="tooltipVisibility"
                            name="tooltipContent"
                            :close-tooltip="closeTooltip"
                        />
                    </div>
                    <ButtonIcon
                        v-if="isCloseButtonVisible && hover"
                        class="close-button"
                        @click="closeTooltip($event)"
                    >
                        <SvgIcon width="16" height="16" class="close-icon">
                            <Close1 />
                        </SvgIcon>
                    </ButtonIcon>
                </div>
            </div>
        </WithClickOutsideDetection>
    </div>
</template>

<script>
import { POSITIONS } from '@types/Tooltip';

import Close1 from '@static/icons/16px/close1.svg?inline';

import { checkIfExistsInValuesMap } from '@assets/props';

import ButtonIcon from '@atoms/ButtonIcon/ButtonIcon';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import WithClickOutsideDetection from '@molecules/WithClickOutsideDetection/WithClickOutsideDetection';

const TAG_LINK = 'a';

const PADDING_DIRECTION_MAP = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
    'bottom-left': 'top',
    'bottom-right': 'top',
    'top-left': 'bottom',
    'top-right': 'bottom',
};

export default {
    name: 'BaseTooltip',

    components: {
        WithClickOutsideDetection,
        SvgIcon,
        ButtonIcon,
        Close1,
    },

    props: {
        isMobile: {
            type: Boolean,
            required: true,
        },

        position: {
            type: String,
            default: POSITIONS.POSITION_TOP,
            validator: checkIfExistsInValuesMap(POSITIONS),
        },

        positionMobile: {
            type: String,
            default: '',
            validator: checkIfExistsInValuesMap(POSITIONS, true),
        },

        showTooltipOnMobile: {
            type: Boolean,
            default: true,
        },

        padding: {
            type: String,
            default: '25px',
        },

        isTooltipEnabled: {
            type: Boolean,
            default: true,
        },

        alwaysVisible: {
            type: Boolean,
            default: false,
        },

        tooltipClass: {
            type: String,
            default: '',
        },

        tooltipContentClass: {
            type: String,
            default: '',
        },

        tooltipContainerClass: {
            type: String,
            default: '',
        },

        isCloseButtonVisible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            tooltipVisibility: false,
            hover: false,
        };
    },

    computed: {
        listeners() {
            const listeners = {};
            const { isMobile, showTooltipOnMobile, showTooltip } = this;

            if (!isMobile) {
                listeners.mouseenter = showTooltip;
            } else if (showTooltipOnMobile) {
                listeners.click = event => {
                    event.stopPropagation();

                    if (this.tooltipVisibility) {
                        const clickTarget = event.target;

                        if (
                            clickTarget.tagName.toLowerCase() !== TAG_LINK &&
                            clickTarget.parentElement.tagName.toLowerCase() !== TAG_LINK
                        ) {
                            event.preventDefault();
                            this.closeTooltip();
                        }
                    } else {
                        event.preventDefault();
                        this.showTooltip();
                    }
                };
            }

            return listeners;
        },

        tooltipPadding() {
            const { positionResponsive, padding } = this;

            return `padding-${PADDING_DIRECTION_MAP[positionResponsive]}: ${padding}`;
        },

        positionResponsive() {
            return this.positionMobile !== '' && this.isMobile
                ? this.positionMobile
                : this.position;
        },
    },
    watch: {
        isTooltipEnabled(isEnabled) {
            if (isEnabled) {
                this.showTooltip();
            } else {
                this.closeTooltip();
            }
        },

        isMobile(flag) {
            if (flag && !this.showTooltipOnMobile) {
                this.closeTooltip();
            }
        },
    },

    mounted() {
        if (this.alwaysVisible) {
            this.showTooltip();
        }
    },

    methods: {
        showTooltip() {
            if (this.isTooltipEnabled && (!this.isMobile || this.showTooltipOnMobile)) {
                this.tooltipVisibility = true;
            }
        },

        closeTooltip(event) {
            event?.stopPropagation();

            this.tooltipVisibility = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.base-tooltip {
    .base-tooltip-wrapper {
        @apply relative flex items-center justify-center;

        .close-button {
            @apply absolute top-0 right-0 w-4 h-4 m-1;
        }

        .tooltip-container {
            @apply absolute block z-3;

            &.top,
            &.bottom {
                @apply left-1/2;
                transform: translateX(-50%);

                .tooltip-content {
                    &::before,
                    &::after {
                        left: calc(50% - 6px);
                    }
                }
            }

            &.left,
            &.right {
                @apply top-1/2;
                transform: translateY(-50%);

                .tooltip-content {
                    &::before,
                    &::after {
                        top: calc(50% - 6px);
                    }
                }
            }

            &.top {
                @apply bottom-full;

                .tooltip-content {
                    &::before {
                        @apply -bottom-6;
                    }

                    &::after {
                        @apply top-full -ml-3p;
                        border-top-color: theme('colors.light');
                    }
                }
            }

            &.bottom {
                @apply top-full;

                .tooltip-content {
                    &::before {
                        @apply -top-6;
                    }

                    &::after {
                        @apply bottom-full -ml-3p;
                        border-bottom-color: theme('colors.light');
                    }
                }
            }

            &.left {
                @apply right-full;

                .tooltip-content {
                    &::before {
                        @apply -right-6;
                        top: calc(50% - 6px);
                    }

                    &::after {
                        @apply left-full -mt-3p;
                        border-left-color: theme('colors.light');
                    }
                }

                .close-button {
                    @apply right-24;
                }
            }

            &.right {
                @apply left-full;

                .tooltip-content {
                    &::before {
                        @apply -left-6;
                    }

                    &::after {
                        @apply right-full -mt-3p;
                        border-right-color: theme('colors.light');
                    }
                }
            }

            &.bottom-left,
            &.bottom-right {
                @apply top-full;

                .tooltip-content {
                    &::before {
                        @apply -top-6;
                    }

                    &::after {
                        @apply bottom-full;
                        border-bottom-color: theme('colors.light');
                    }
                }
            }

            &.bottom,
            &.bottom-left,
            &.bottom-right {
                .close-button {
                    @apply top-24;
                }
            }

            &.top-left,
            &.top-right {
                @apply bottom-full;

                .tooltip-content {
                    &::before {
                        @apply -bottom-6;
                    }

                    &::after {
                        @apply top-full;
                        border-top-color: theme('colors.light');
                    }
                }
            }

            &.bottom-right,
            &.top-right {
                @apply -left-6;

                .tooltip-content {
                    &::before {
                        @apply left-12;
                    }

                    &::after {
                        @apply left-10;
                    }
                }
            }

            &.bottom-left,
            &.top-left {
                @apply -right-6;

                .tooltip-content {
                    &::before {
                        @apply right-12;
                    }

                    &::after {
                        @apply right-10;
                    }
                }
            }
        }

        .tooltip-content {
            @apply relative text-xs text-text leading-s bg-light shadow-4 p-4;

            &::before {
                @apply absolute z-hidden shadow-4 w-12p h-12p;

                content: '';
                transform: rotate(45deg);
            }

            &::after {
                @apply absolute w-0 h-0 border-solid border-transparent border-9 border-t-9;

                content: '';
            }
        }
    }
}
</style>
