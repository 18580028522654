<template>
    <div>
        <nuxt />
        <GlobalModals />
        <SmartBannerWrapper />
    </div>
</template>

<script>
import BaseLayout from '@layouts/mixins/BaseLayout';
import WithCustomer from '@layouts/mixins/WithCustomer';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import GlobalModals from '@organisms/GlobalModals/GlobalModals';

export default {
    name: 'EmptyLayout',

    components: {
        SmartBannerWrapper,
        GlobalModals,
    },

    mixins: [BaseLayout, WithCustomer],
};
</script>
