import { CATALOG_PAGE_NAME, SEARCH_RESULTS_PAGE_NAME } from '@search/routing/names';

import { normalizeQuery } from '@search/assets/search';
import { stringifyCategoryUrl } from '@search/assets/path';

export default ({ app }, inject) => {
    inject('createPagePath', routePageConfig => {
        return routePageConfig;
    });

    inject('stringifyCategoryUrl', paramObject => stringifyCategoryUrl(paramObject));

    inject('createCategoryPath', (url, query = {}) => {
        const {
            route: { fullPath },
        } = app.router.resolve({
            name: CATALOG_PAGE_NAME,
            params: {
                url,
            },
            query,
        });

        return fullPath.replace(/%2F/g, '/'); // @todo maybe there is a better way to do this;
    });

    inject('createSearchPath', (url, category) => {
        const [query, ...rest] = url.replace(/%2F/g, '/').split('/');
        const normalizedQuery = normalizeQuery(query);
        const normalizedUrl = [normalizedQuery, ...rest].join('/');

        const {
            route: { fullPath },
        } = app.router.resolve({
            name: SEARCH_RESULTS_PAGE_NAME,
            params: {
                url: normalizedUrl,
            },
            query: {
                q: normalizedQuery,
                category,
            },
        });

        return fullPath.replace(/%2F/g, '/'); // @todo maybe there is a better way to do this;;
    });
};
