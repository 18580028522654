var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"contentWithScroll",class:[
        _vm.isEnabled ? 'with-scroll' : '',
        _vm.size,
        _vm.theme,
        _vm.isHorizontal ? 'horizontal' : '',
        _vm.desktopOnly ? 'desktop-only' : '',
        _vm.hideScrollbar ? 'hidden-scrollbar' : '',
    ]},[_vm._t("default",null,{"autoscrollToActiveElement":_vm.autoscrollToActiveElement})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }