import { types } from './mutations';

export default {
    openAddProductToCartModal({ commit }, addedCartItemData) {
        commit(types.SET_OPEN_ADD_PRODUCT_TO_CART_MODAL, addedCartItemData);
    },

    resetAddProductToCartModal({ commit }) {
        commit(types.SET_RESET_ADD_PRODUCT_TO_CART_MODAL);
    },

    openAddProductToCartErrorModal({ commit }, cartItemData) {
        commit(types.SET_OPEN_ADD_PRODUCT_TO_CART_ERROR_MODAL, cartItemData);
    },

    resetAddProductToCartErrorModal({ commit }) {
        commit(types.SET_RESET_ADD_PRODUCT_TO_CART_ERROR_MODAL);
    },

    setIsSizeModalOpen({ commit }, isSizeModalOpen) {
        commit(types.SET_IS_SIZE_MODAL_OPEN, isSizeModalOpen);
    },
};
