import {
    TYPE_DISCOUNT,
    TYPE_ECO_FRIENDLY,
    TYPE_ONLY_HERE,
    TYPE_OCCASION,
    TYPE_NEW,
    TYPE_OUTLET,
} from '@types/ProductBadge';

import {
    sale,
    light,
    dark,
    primary,
    ecoFriendly,
    outlet,
    outletContent,
} from '@theme/resources/variables/colors.json';

const CLASS_MAP = {
    [TYPE_DISCOUNT]: 'discount-badge',
    [TYPE_OCCASION]: 'discount-badge',
    [TYPE_ECO_FRIENDLY]: 'eco-friendly-badge',
    [TYPE_ONLY_HERE]: 'only-here-badge',
    [TYPE_OUTLET]: 'outlet-badge',
};

const DEFAULT_CLASS = 'promo-badge';

const BACKGROUND_COLOR_MAP = {
    [TYPE_DISCOUNT]: sale,
    [TYPE_OCCASION]: sale,
    [TYPE_ECO_FRIENDLY]: ecoFriendly,
    [TYPE_ONLY_HERE]: primary,
    [TYPE_NEW]: light,
    [TYPE_OUTLET]: outlet,
};

const CONTENT_COLOR_MAP = {
    [TYPE_NEW]: dark,
    [TYPE_OUTLET]: outletContent,
};

export class ProductBadge {
    constructor({
        content = '',
        backgroundColor = '',
        type = '',
        isInternal,
        color = light,
        data = {},
    }) {
        this.backgroundColor = backgroundColor || BACKGROUND_COLOR_MAP[type] || dark;
        this.color = CONTENT_COLOR_MAP?.[type] || color;
        this.content = content;
        this.type = type;
        this.isInternal = isInternal;
        this.label = this.getLabel();
        this.data = data;
    }

    getLabel() {
        if (this.type === TYPE_ECO_FRIENDLY) {
            return `eco<span class="${this.getCssClass()}">-friendly</span>`;
        }

        return `<span class="${this.getCssClass()}">${this.content}</span>`;
    }

    getCssClass() {
        return CLASS_MAP[this.type] || DEFAULT_CLASS;
    }

    getPlainObject() {
        return {
            backgroundColor: this.backgroundColor,
            color: this.color,
            content: this.content,
            type: this.type,
            label: this.label,
            isInternal: this.isInternal,
            data: this.data,
        };
    }
}
