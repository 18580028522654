export class ApolloError extends Error {
    constructor({
        graphQLErrors = [],
        networkError = null,
        errorMessage = '',
        extraInfo = {},
    } = {}) {
        super(errorMessage);
        this.graphQLErrors = graphQLErrors;
        this.networkError = networkError;
        this.extraInfo = extraInfo;
        this.message = errorMessage;
    }
}
