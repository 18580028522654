export const types = {
    SET_NAVIGATION_CATEGORIES: 'SET_NAVIGATION_CATEGORIES',
    SET_USE_DISCLOSURES: 'SET_USE_DISCLOSURES',
};

export default {
    [types.SET_NAVIGATION_CATEGORIES](state, rootCategoryChildren) {
        state.categories = rootCategoryChildren;
    },

    [types.SET_USE_DISCLOSURES](state, useDisclosures) {
        state.useDisclosures = useDisclosures;
    },
};
