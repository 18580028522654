// todo to be improved in MOD-2935

export const DEVICE = {
    MOBILE: 'mobile',
    TABLET: 'tablet',
    DESKTOP: 'desktop',
};

export const DEVICE_STORE = {
    APP_STORE: 'appStore',
    APPGALLERY: 'appGallery',
    GOOGLE_PLAY: 'googlePlay',
};
