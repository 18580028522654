export const AVAILABLE = 'available';
export const UNAVAILABLE = 'unavailable';
export const AVAILABLE_FROM_ANOTHER_SELLER_AT_PARTNER = 'available_from_another_seller_at_partner';
export const AVAILABLE_FROM_ANOTHER_SELLER_AT_MODIVO = 'available_from_another_seller_at_modivo';

export const AVAILABILITY_STATUSES = {
    AVAILABLE,
    UNAVAILABLE,
    AVAILABLE_FROM_ANOTHER_SELLER_AT_PARTNER,
    AVAILABLE_FROM_ANOTHER_SELLER_AT_MODIVO,
};
