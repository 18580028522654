export default ({ app, $mobileApp }) => {
    if (!$mobileApp.isWebView) {
        return;
    }

    app.router.beforeEach(async (to, from, next) => {
        const isFirstRequest = from.path === '/' && from.name === null;

        if (isFirstRequest) {
            next();
        } else {
            const { protocol, host } = window.location;

            const url = `${protocol}//${host}${to.fullPath}`;
            const shouldContinueRouting = !(await $mobileApp.handleRoutingByMobileApp(url));

            if (shouldContinueRouting) {
                next();
            } else {
                next(false);
            }
        }
    });
};
