import { types } from './mutations';

export default {
    setBeforeInstallPromptEvent({ commit }, beforeInstallPromptEvent) {
        commit(types.SET_BEFORE_INSTALL_PROMPT_EVENT, beforeInstallPromptEvent);
    },

    setIsInstallationPending({ commit }, isInstallationPending) {
        commit(types.SET_IS_INSTALLATION_PENDING, isInstallationPending);
    },

    setWasInstallationSuccessful({ commit }, wasInstallationSuccessful) {
        commit(types.SET_WAS_INSTALLATION_SUCCESSFUL, wasInstallationSuccessful);
    },

    setDisplayMode({ state, commit }, displayMode) {
        if (state.displayMode !== displayMode) {
            commit(types.SET_DISPLAY_MODE, displayMode);
        }
    },
};
