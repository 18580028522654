import {
    IMAGE_FORMAT_JPG_EXTENSION,
    IMAGE_PLACEHOLDERS,
    IMAGE_TYPE_PRODUCT_CARD,
} from '@types/Image';

export const getPlaceholder = imageType => IMAGE_PLACEHOLDERS[imageType];

export const getImage = (images, imageType) => {
    const firstImage = images?.[0]?.[imageType];

    return firstImage || getPlaceholder(imageType);
};

export const getProductImageUrl = (productImage, alias, format = IMAGE_FORMAT_JPG_EXTENSION) => {
    return productImage?.[alias]?.sources[format]?.[0].src;
};

export const getSrcsetsFromSourcesConfig = sources => {
    return Object.fromEntries(
        Object.entries(sources).map(([type, images]) => [
            type,
            images.map(({ src, width }) => `${src} ${width}w`).join(', '),
        ])
    );
};

export const createDummyPictureLoader = ({ sources, url }, loadCallback, errorCallback) => {
    const pictureElement = document.createElement('picture');

    const srcsets = getSrcsetsFromSourcesConfig(sources);

    Object.entries(srcsets).forEach(([type, srcset]) => {
        const sourceEl = document.createElement('source');

        sourceEl.srcset = srcset;
        sourceEl.type = `image/${type}`;

        pictureElement.appendChild(sourceEl);
    });

    const img = document.createElement('img');

    img.addEventListener('load', loadCallback);

    img.addEventListener('error', errorCallback);

    pictureElement.appendChild(img);

    img.src = url;
};

export const mergeProductImages = (...productImages) =>
    productImages.reduce((result, productImage) => {
        if (!productImage || typeof productImage !== 'object') {
            return result;
        }

        if (!result) {
            return JSON.parse(JSON.stringify(productImage));
        }

        Object.entries(productImage.sources).forEach(([type, source]) => {
            result.sources[type] = result.sources[type].concat(source);
        });

        return result;
    }, null);

export const getProductImage = (
    product,
    index,
    imageDimensions,
    fallback = IMAGE_TYPE_PRODUCT_CARD
) => {
    const { images } = product;
    const image = images && images.length ? images[index] : null;

    if (
        image &&
        imageDimensions.reduce(
            (result, imageDimension) => (image[imageDimension] ? result : false),
            true
        )
    ) {
        const productImages = imageDimensions.map(productImage => image[productImage]);

        return mergeProductImages(...productImages);
    }

    return IMAGE_PLACEHOLDERS[fallback];
};
