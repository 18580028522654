import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.contact.${action}`,
});

export default (megatronClient, $errorHandler) => ({
    async getContactConfigData() {
        const ERROR_TAGS = getErrorTags('getContactConfigData');

        try {
            const { data, error, status } = await megatronClient.get('v1/contactConfig', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            });

            if (!error) {
                return data?.contactConfig?.subjects || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return [];
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return [];
        }
    },

    async publishContactData(formData) {
        const response = (errors, success) => ({
            errors: errors || [],
            success: success || false,
        });
        const ERROR_TAGS = getErrorTags('publishContactData');

        try {
            const { data, error, status } = await megatronClient.post('v1/publishContact', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    ...formData,
                },
            });

            if (!error) {
                const { errors, success } = data?.publishContact || {};

                return response(errors, success);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    ...formData,
                    data,
                    error,
                    status,
                }
            );

            return response();
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                ...formData,
            });

            return response();
        }
    },
});
