import ConsentsProvider from '@models/ConsentsData/ConsentsProvider';

export default class ConsentsCategory extends ConsentsProvider {
    constructor({ cms_description, cms_title, code, is_required, providers, user_can_modify }) {
        super({ cms_description, cms_title, code });

        this.isRequired = is_required;
        this.providers = Array.isArray(providers) ? providers : [];
        this.userCanModify = user_can_modify;
    }

    build() {
        return {
            ...super.build(),
            isRequired: this.isRequired,
            providers: this.buildProviders(),
            userCanModify: this.userCanModify,
        };
    }

    buildProviders() {
        return this.providers.map(provider => new ConsentsProvider(provider).build());
    }
}
