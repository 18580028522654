import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

export default megatronClient => ({
    async getIsStrictPhoneValidationEnabled() {
        const { data, error } = await megatronClient.get('v1/isStrictPhoneValidationEnabled', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
        });

        const errors = error?.data?.errors || [];

        if (errors.length) {
            throw new Error(errors.map(err => err.message).join('|'));
        }

        return data?.isStrictPhoneValidationEnabled || null;
    },

    async validatePhone(phoneNumber) {
        const { data, error } = await megatronClient.post('v1/validatePhone', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                phoneNumber,
            },
        });

        const errors = error?.data?.errors || [];

        if (errors.length) {
            throw new Error(errors.map(err => err.message).join('|'));
        }

        return data?.validatePhone || null;
    },
});
