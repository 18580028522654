export const types = {
    SET_VALIDATION_RULES: 'SET_VALIDATION_RULES',
    SET_STRICT_PHONE_VALIDATION_ENABLED: 'SET_STRICT_PHONE_VALIDATION_ENABLED',
};

export default {
    [types.SET_VALIDATION_RULES](state, rules) {
        state.validationRules = rules;
    },

    [types.SET_STRICT_PHONE_VALIDATION_ENABLED](state, isStrictPhoneValidationEnabled) {
        state.isStrictPhoneValidationEnabled = isStrictPhoneValidationEnabled;
    },
};
