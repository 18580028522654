<template>
    <div class="sidebar-menu">
        <ButtonLarge :variant="SECONDARY" :wide="true" class="open-btn" @click="open()">
            {{ $t('Menu') }}
        </ButtonLarge>
        <SideModal
            class="sticky-panels"
            :is-open="isOpened"
            :apply-only-on-mobile="true"
            :fixed-header="true"
            @close="close()"
        >
            <template #header>
                <div class="panel-header">
                    <div class="label">
                        <BaseHeading theme="h4">
                            {{ $t('Menu') }}
                        </BaseHeading>
                    </div>
                    <div class="close" @click="close()">
                        <SvgIcon :width="24" :height="24">
                            <Close2 />
                        </SvgIcon>
                    </div>
                </div>
            </template>

            <slot />

            <PWAInstallWrapper
                v-if="isOpened"
                #default="{ isOpen, isInstallationPending, install, close: closePWAInstallBanner }"
                class="pwa-install-banner"
            >
                <PWAInstallBanner
                    v-if="isOpen"
                    :is-installation-pending="isInstallationPending"
                    type="relative"
                    @install="install()"
                    @close="closePWAInstallBanner()"
                />
            </PWAInstallWrapper>
        </SideModal>
    </div>
</template>

<script>
import Close2 from '@static/icons/16px/close2.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import SideModal from '@molecules/SideModal/SideModal';
import PWAInstallWrapper from '@molecules/PWAInstallWrapper/PWAInstallWrapper';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'SidebarMenu',

    components: {
        PWAInstallWrapper,
        SvgIcon,
        BaseHeading,
        ButtonLarge,
        SideModal,
        Close2,
        PWAInstallBanner: () => ({
            component: import(
                /* webpackChunkName: "pwa-install" */
                '@molecules/PWAInstallBanner/PWAInstallBanner'
            ),
        }),
    },

    props: {
        isMobile: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            isOpened: false,
        };
    },

    watch: {
        isMobile(toValue, fromValue) {
            const changedFromMobileToDesktop = !!(fromValue && !toValue);

            if (changedFromMobileToDesktop && this.isOpened) {
                this.close();
            }
        },

        $route() {
            if (this.isOpened && this.isMobile) {
                this.close();
            }
        },
    },

    beforeCreate() {
        this.SECONDARY = BUTTON_LARGE_VARIANTS.SECONDARY;
    },

    methods: {
        close() {
            this.isOpened = false;
        },

        open() {
            this.isOpened = true;
        },
    },
};
</script>

<style lang="scss" scoped>
$modal-header-height: #{theme('customVariables.sideModal.headerHeight')};

.sidebar-menu {
    .pwa-install-banner {
        @apply mt-3;
    }

    .open-btn {
        @apply mb-4;
    }
    &:deep() {
        .panel-header {
            @apply flex items-center w-full p-3 border-border border-b-1;
            height: $modal-header-height;

            .label {
                @apply w-full;
            }
        }
    }
}

@screen lg {
    .sidebar-menu {
        .pwa-install-banner {
            @apply hidden;
        }

        &:deep() {
            .open-btn {
                @apply hidden;
            }

            .panel-header {
                @apply hidden;
            }
        }
    }
}
</style>
