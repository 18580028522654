<template>
    <div>
        <BannerMessageIcon
            :title="$t('Do you have questions?')"
            :description="
                $tRma('Check out the frequently asked questions in the Help Center or contact us')
            "
            :variant="BANNER_VARIANT_MOBILE"
            :desktop-variant="BANNER_VARIANT_DESKTOP"
            :padding="BANNER_PADDING"
        >
            <template #buttons>
                <component
                    :is="buttonComponent.component"
                    :variant="buttonComponent.variant"
                    :to="$tPath('help-center')"
                >
                    {{ $t('Help center') }}
                </component>
                <component
                    :is="buttonComponent.component"
                    :variant="variantSecondary"
                    :to="$tPath('contact')"
                >
                    {{ $t('Contact') }}
                </component>
            </template>
        </BannerMessageIcon>
    </div>
</template>

<script>
import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';
import { ButtonSmall, BUTTON_SMALL_VARIANTS } from '@modivo-ui/components/ButtonSmall/v1';
import {
    BannerMessageIcon,
    BANNER_MESSAGE_ICON_VARIANTS,
    BANNER_MESSAGE_ICONS_PADDINGS,
} from '@modivo-ui/components/BannerMessageIcon/v1';
import { mapState } from 'vuex';

export default {
    name: 'NewsletterBanner',

    components: {
        ButtonLarge,
        ButtonSmall,
        BannerMessageIcon,
    },

    computed: {
        ...mapState(['isMobile']),

        buttonComponent() {
            const { isMobile } = this;

            return {
                component: isMobile ? ButtonSmall : ButtonLarge,
                variant: isMobile ? BUTTON_SMALL_VARIANTS.PRIMARY : BUTTON_LARGE_VARIANTS.PRIMARY,
            };
        },

        variantSecondary() {
            return this.isMobile
                ? BUTTON_SMALL_VARIANTS.SECONDARY
                : BUTTON_LARGE_VARIANTS.SECONDARY;
        },
    },

    beforeCreate() {
        this.BANNER_VARIANT_MOBILE = BANNER_MESSAGE_ICON_VARIANTS.S;
        this.BANNER_VARIANT_DESKTOP = BANNER_MESSAGE_ICON_VARIANTS.M;
        this.BANNER_PADDING = BANNER_MESSAGE_ICONS_PADDINGS.P_16;
    },
};
</script>
