<template>
    <PWAInstallDataProvider v-if="shouldDisplay" #default="provider">
        <slot v-bind="provider" />
    </PWAInstallDataProvider>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { IS_PWA_INSTALL_BANNER_CLOSED, STORAGE_TYPE_SESSION } from '@configs/storage';

const { mapState: mapPWAState, mapGetters: mapPWAGetters } = createNamespacedHelpers('pwa');

export default {
    name: 'PWAInstallWrapper',

    components: {
        PWAInstallDataProvider: () => ({
            component: import(
                /* webpackChunkName: "pwa-install" */
                '@molecules/PWAInstallDataProvider/PWAInstallDataProvider'
            ),
        }),
    },

    computed: {
        ...mapPWAState(['beforeInstallPromptEvent', 'wasInstallationSuccessful']),
        ...mapPWAGetters(['isPWA']),

        shouldDisplay() {
            return (
                process.client &&
                !this.isPWA &&
                !this.$storage.getItem(IS_PWA_INSTALL_BANNER_CLOSED, STORAGE_TYPE_SESSION) &&
                !!this.beforeInstallPromptEvent &&
                !this.wasInstallationSuccessful
            );
        },
    },
};
</script>
