import { types } from './mutations';

export default {
    setUrlKey({ commit }, urlKey) {
        commit(types.SET_URL_KEY, urlKey);
    },

    setProductsReviewsTotalCount({ commit }, count) {
        commit(types.SET_PRODUCTS_REVIEWS_TOTAL_COUNT, count);
    },

    setProductsReviewsAverageRating({ commit }, rating) {
        commit(types.SET_PRODUCTS_REVIEWS_AVERAGE_RATING, rating);
    },

    async setRelatedProducts({ rootGetters, commit }, relatedIndexes) {
        const locale = rootGetters['config/locale'];
        const currency = rootGetters['config/currency'];

        const { items } = await this.app.$services.product.getByIndexes({
            indexes: relatedIndexes,
            limit: relatedIndexes.length,
            page: 1,
            locale,
            currency,
            withStocks: true,
        });

        commit(types.SET_RELATED_PRODUCTS, items);

        return items.length > 0;
    },

    resetRelatedProducts({ commit }) {
        commit(types.RESET_RELATED_PRODUCTS);
    },

    setColorVariants({ commit }, colorVariants) {
        commit(types.SET_COLOR_VARIANTS, colorVariants);
    },

    setListingCategoryId({ commit }, listingCategory) {
        commit(types.SET_LISTING_CATEGORY_ID, listingCategory);
    },

    setFitAnalyticsProductLoaded({ commit }, detail) {
        commit(types.SET_FIT_ANALYTICS_PRODUCT_LOADED, detail);
    },

    resetFitAnalyticsProductLoaded({ commit }) {
        commit(types.RESET_FIT_ANALYTICS_PRODUCT_LOADED);
    },

    setFitAnalyticsRecommendation({ commit }, size) {
        commit(types.SET_FIT_ANALYTICS_RECOMMENDATION, size);
    },

    resetFitAnalyticsRecommendation({ commit }) {
        commit(types.RESET_FIT_ANALYTICS_RECOMMENDATION);
    },

    setFitAnalyticsScriptLoaded({ commit }) {
        commit(types.SET_FIT_ANALYTICS_SCRIPT_LOADED);
    },
};
