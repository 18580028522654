import { HTTP_CLIENT_ENDPOINT_NAME_AUTH } from '@configs/http-client';

import { withRetryHandling } from '@assets/nuxt-http/helpers';

const ENDPOINT_PARAMETER = { endpoint: HTTP_CLIENT_ENDPOINT_NAME_AUTH };

export default httpClient => ({
    saveAuthTokens: async payload => {
        const { data } = await httpClient.post('/save-auth-tokens', {
            ...ENDPOINT_PARAMETER,
            payload,
        });

        return data || {};
    },

    saveMobileAuthTokens: async ({ accessToken, accessTokenExpirationISO }) =>
        httpClient.post('/save-auth-tokens-with-redirect', {
            ...ENDPOINT_PARAMETER,
            payload: {
                accessToken,
                accessTokenExpirationISO,
            },
        }),

    removeAuthTokens: async () => {
        const { data } = await httpClient.post('/delete-auth-tokens', {
            ...ENDPOINT_PARAMETER,
        });

        return data || {};
    },

    readAuthTokens: async () => {
        const { data } = await withRetryHandling(
            () =>
                httpClient.post('/read-auth-tokens', {
                    ...ENDPOINT_PARAMETER,
                }),
            {
                delay: 1000,
            }
        );

        return data || {};
    },
});
