import FilterManager from './managers/FilterManager.js';

var plugin = ({ app, store }, inject) => {
  const namespace = "";
  const esizemeOption = "";
  const esizemeEnabled = esizemeOption === "true";
  app.$filterManager = new FilterManager({
    store,
    namespace,
    esizemeEnabled
  });
  inject("filterManager", app.$filterManager);
};

export { plugin as default };
