<template>
    <div class="main">
        <MarketingBarDataProvider />
        <HeaderWrapper />
        <main>
            <nuxt />
        </main>
        <Messages />
        <BaseFooter />
        <ChatWrapper />
        <GlobalModals />
        <SmartBannerWrapper />
    </div>
</template>

<script>
import BaseLayout from '@layouts/mixins/BaseLayout';
import WithCustomer from '@layouts/mixins/WithCustomer';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import Messages from '@molecules/Messages/Messages';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import BaseFooter from '@organisms/BaseFooter/BaseFooter';
import GlobalModals from '@organisms/GlobalModals/GlobalModals';
import HeaderWrapper from '@header-organisms/HeaderWrapper/HeaderWrapper';

import MarketingBarDataProvider from '@marketing-bar-components/organisms/MarketingBarDataProvider/MarketingBarDataProvider';

export default {
    name: 'DefaultWithoutNewsletterLayout',

    components: {
        SmartBannerWrapper,
        ChatWrapper,
        Messages,
        BaseFooter,
        HeaderWrapper,
        GlobalModals,
        MarketingBarDataProvider,
    },

    mixins: [BaseLayout, WithCustomer],
};
</script>
