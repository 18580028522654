export const createGuestProcessAuthorizationHeaders = store => {
    if (!store.getters['customer/isLoggedIn'] && store.getters['rma/guest/hasToken']) {
        const guestToken = store.getters['rma/guest/tokenValue'];

        return {
            'X-Rma-Guest-Authorization': `Bearer ${guestToken}`,
        };
    }

    return null;
};
