<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :stoke-width="strokeWidth"
        role="presentation"
        class="svg"
    >
        <g :fill="fillColor" :stroke="strokeColor">
            <slot />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'SvgIcon',

    props: {
        width: {
            type: [Number, String],
            default: 24,
        },

        height: {
            type: [Number, String],
            default: 24,
        },

        fillColor: {
            type: String,
            default: 'currentColor',
        },

        strokeColor: {
            type: String,
            default: 'currentColor',
        },

        strokeWidth: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
.svg {
    color: theme('colors.tertiary');
}
</style>
