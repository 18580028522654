<template>
    <nuxt-link class="text-link" :to="path" :title="title" :event="disabled ? '' : 'click'">
        <slot>{{ label }}</slot>
    </nuxt-link>
</template>

<script>
export default {
    name: 'BaseLink',

    props: {
        path: {
            type: [String, Object],
            required: true,
        },

        label: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
