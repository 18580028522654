import orderBy from 'lodash.orderby';

import { PAYMENT_TYPE_OPTIONS } from '@configs/payment-types';
import {
    PAYMENT_METHOD_NOT_SUPPORTED_TEXT,
    PAYMENT_METHOD_NOT_AVAILABLE_FOR_SHIPPING_METHOD_TEXT,
    PAYMENT_METHOD_NOT_AVAILABLE_FOR_SHIPPING_METHODS_TEXT,
} from '@configs/checkout-payment-methods';
import {
    STORE_PICKUP_SHIPPING_METHOD_CARRIER_CODE,
    SHIPPING_METHOD_CODES_WITH_DEDICATED_COMPONENT,
} from '@configs/checkout-shipping-methods';

import { PAYMENT_TYPES } from '@types/PaymentTypes';

function getMethodNotAvailableText(paymentType) {
    const { methodNotAvailableText } = PAYMENT_TYPE_OPTIONS?.[paymentType] || {};

    return methodNotAvailableText;
}

function getDeliveryLimitReachedText(number) {
    return {
        key: 'Delivery method available for the maximum number of products: {maxProducts}',
        values: { maxProducts: number },
    };
}

function findMaxProductsLimit(methodPadOptions) {
    if (!methodPadOptions?.length) {
        return null;
    }

    return Math.max(
        ...methodPadOptions.map(({ maximumAmountOfProducts }) => maximumAmountOfProducts || 1000)
    );
}

function countCartItemsQuantity(storeCart) {
    return storeCart.items.reduce((acc, { quantity }) => acc + quantity, 0);
}

function getDeliveryMethodDisabilityReasons(methodPadOptions, paymentType, storeCart) {
    const reasons = [];

    if (!methodPadOptions.length) {
        reasons.push({
            key: getMethodNotAvailableText(paymentType),
        });
    }

    const productsLimit = findMaxProductsLimit(methodPadOptions);

    if (productsLimit && countCartItemsQuantity(storeCart) > productsLimit) {
        reasons.push(getDeliveryLimitReachedText(productsLimit));
    }

    return reasons;
}

function getOptionsByPaymentMethod(options, id) {
    return options?.filter(({ paymentMethodId }) => paymentMethodId === id) || [];
}

function getPaymentLimitReachedText(storeName, number) {
    return {
        key:
            // eslint-disable-next-line max-len
            '{storeName} - Payment method available for the maximum number of products: {maxProducts}',
        values: { storeName, maxProducts: number },
    };
}

function findStoreProductsLimit(methodOptions, storeCart, selectedDeliveryMethodId = null) {
    const storeOptions = methodOptions
        .filter(option => option.storeId === storeCart.store.id)
        .filter(
            option =>
                !selectedDeliveryMethodId || option.deliveryMethodId === selectedDeliveryMethodId
        );

    return findMaxProductsLimit(storeOptions);
}

function getPaymentMethodDisabilityReasons(
    method,
    methodOptions,
    paymentType,
    selectedPaymentType,
    isAnyShippingMethodSelected,
    selectedShippingMethodsObject,
    selectedShippingMethodsOptions,
    storeCarts
) {
    const { id: methodId, type: paymentMethodType } = method;
    const reasons = [];

    const isSameTypeAsPaymentType = paymentType === paymentMethodType;

    const isSameTypeAsSelectedPaymentType =
        selectedPaymentType && paymentType === selectedPaymentType;

    const everyStoreSupports = storeCarts.every(({ store: { id: storeId } }) =>
        methodOptions.some(({ storeId: optionStoreId }) => storeId === optionStoreId)
    );

    let existsAnyOptionForSelectedShippingMethods = true;

    if (!isSameTypeAsPaymentType) {
        const methodNotAvailableText = getMethodNotAvailableText(paymentType);

        reasons.push({ key: methodNotAvailableText });
    }

    if (!everyStoreSupports) {
        reasons.push({ key: PAYMENT_METHOD_NOT_SUPPORTED_TEXT });
    }

    if (isAnyShippingMethodSelected && isSameTypeAsSelectedPaymentType) {
        // eslint-disable-next-line max-len
        existsAnyOptionForSelectedShippingMethods = selectedShippingMethodsOptions.some(
            ({ paymentMethodId: optionPaymentMethodId }) => optionPaymentMethodId === methodId
        );
    }

    if (!existsAnyOptionForSelectedShippingMethods) {
        const isOneSeller = storeCarts.length === 1;
        const text = isOneSeller
            ? PAYMENT_METHOD_NOT_AVAILABLE_FOR_SHIPPING_METHOD_TEXT
            : PAYMENT_METHOD_NOT_AVAILABLE_FOR_SHIPPING_METHODS_TEXT;

        reasons.push({ key: text });
    }

    const limitReachedStores = storeCarts
        .filter(storeCart => {
            const storeLimit = findStoreProductsLimit(
                methodOptions,
                storeCart,
                selectedShippingMethodsObject[storeCart.store.id]?.id
            );

            return storeLimit && countCartItemsQuantity(storeCart) > storeLimit;
        })
        .map(storeCart =>
            getPaymentLimitReachedText(
                storeCart.store.name,
                findStoreProductsLimit(
                    methodOptions,
                    storeCart,
                    selectedShippingMethodsObject[storeCart.store.id]?.id
                )
            )
        );

    if (limitReachedStores.length) {
        reasons.push(...limitReachedStores);
    }

    return reasons;
}

function sortPaymentMethods(methods, selectedPaymentType) {
    return orderBy(
        methods,
        [
            ({ type }) => type === selectedPaymentType,
            ({ disabled }) => disabled,
            method => methods.indexOf(method),
        ],
        ['desc', 'asc', 'asc']
    );
}

export function getPaymentMethodsForPaymentType(
    paymentType,
    paymentMethods,
    allPadOptions,
    isAnyShippingMethodSelected,
    selectedShippingMethodsObject,
    selectedShippingMethodsOptions,
    selectedPaymentType,
    storeCarts
) {
    const methods = paymentMethods.map(method => {
        const options = getOptionsByPaymentMethod(allPadOptions, method.id);

        const disabilityReasons = getPaymentMethodDisabilityReasons(
            method,
            options,
            paymentType,
            selectedPaymentType,
            isAnyShippingMethodSelected,
            selectedShippingMethodsObject,
            selectedShippingMethodsOptions,
            storeCarts
        );

        return {
            ...method,
            options,
            disabilityReasons,
            disabled: disabilityReasons.length !== 0,
        };
    });

    return sortPaymentMethods(methods, selectedPaymentType);
}

export function hasEveryStoreAnyOptionWithPaymentType(options, storesIds, paymentType) {
    return storesIds.every(storeId =>
        options.some(
            ({ paymentType: optionPaymentType, storeId: optionStoreId }) =>
                optionPaymentType === paymentType && optionStoreId === storeId
        )
    );
}

export function getPaymentTypesWithAnyOptionForEveryStore(options, storesIds) {
    const paymentTypes = Object.values(PAYMENT_TYPES);

    return paymentTypes.filter(paymentType =>
        hasEveryStoreAnyOptionWithPaymentType(options, storesIds, paymentType)
    );
}

export function getOptionsForSelectedShippingAndPaymentMethods(
    selectedShippingMethodsIds,
    selectedPaymentMethodId,
    allPadOptions
) {
    return allPadOptions.filter(
        ({ deliveryMethodId, paymentMethodId }) =>
            selectedShippingMethodsIds.includes(deliveryMethodId) &&
            paymentMethodId === selectedPaymentMethodId
    );
}

export function getOptionsForSelectedShippingMethods(selectedShippingMethodsIds, allPadOptions) {
    return allPadOptions.filter(({ deliveryMethodId }) =>
        selectedShippingMethodsIds.includes(deliveryMethodId)
    );
}

export function getDeliveryMethodPrice(methodPadOptions, shippingOptions) {
    const selectedShippingOption = shippingOptions.find(({ isSelected }) => isSelected);

    const prices = selectedShippingOption
        ? [selectedShippingOption?.fee || 0]
        : [...new Set(methodPadOptions.map(({ price }) => price))];

    return { value: Math.min(...prices), isSingle: prices.length === 1 };
}

function setShippingOptionsSelection(
    shippingOptions,
    selectedMethodOptions,
    isOfSelectedPaymentType
) {
    return shippingOptions?.map(option => ({
        ...option,
        isSelected:
            isOfSelectedPaymentType &&
            !!selectedMethodOptions?.find(
                selectedMethodOption =>
                    selectedMethodOption.is_selected && selectedMethodOption.id === option.id
            ),
    }));
}

export const checkoutDeliveryMethodFromDeliveryMethod = (
    deliveryMethod,
    storeCart,
    paymentType,
    allPadOptions,
    selectedShippingMethod,
    selectedPaymentType
) => {
    const {
        id: methodId,
        shippingOptions: methodShippingOptions,
        carrierCode: methodCode,
    } = deliveryMethod;

    const padOptions = allPadOptions
        .filter(option => option.deliveryMethodId === methodId)
        .filter(option => option.storeId === storeCart.store.id)
        .filter(option => option.paymentType === paymentType);

    const shippingOptions = setShippingOptionsSelection(
        methodShippingOptions,
        selectedShippingMethod.shippingOptions,
        selectedPaymentType === paymentType
    );

    const price = getDeliveryMethodPrice(padOptions, shippingOptions);

    const isStorePickupMethod = methodCode === STORE_PICKUP_SHIPPING_METHOD_CARRIER_CODE;

    const disabilityReasons = getDeliveryMethodDisabilityReasons(
        padOptions,
        paymentType,
        storeCart
    );

    return {
        ...deliveryMethod,
        padOptions,
        shippingOptions,
        price,
        isStorePickupMethod,
        disabilityReasons,
        disabled: !!disabilityReasons.length,
    };
};

export const checkoutMethodFromCheckoutDeliveryMethod = (checkoutDeliveryMethod, pickupPlaces) => {
    const { carrierCode: code, disabled, isStorePickupMethod } = checkoutDeliveryMethod;

    return {
        ...checkoutDeliveryMethod,
        code,
        hasDedicatedComponent: Object.values(
            SHIPPING_METHOD_CODES_WITH_DEDICATED_COMPONENT
        ).includes(code),
        forceToShow: isStorePickupMethod,
        disabled:
            disabled ||
            (isStorePickupMethod && Array.isArray(pickupPlaces) && !pickupPlaces.length),
    };
};

export const sortDeliveryMethods = deliveryMethods => {
    return deliveryMethods.sort((method1, method2) => {
        if (method1.disabled === method2.disabled) {
            return 0;
        }

        return method1.disabled ? 1 : -1;
    });
};

export const hasStoreAvailableShippingMethod = (
    storeId,
    storeDeliveryMethodsGroups,
    { title: titleToCheck, id: idToCheck },
    paymentType
) => {
    const { disabled = true } =
        storeDeliveryMethodsGroups
            .find(({ store }) => store.id === storeId)
            ?.groups.find(group => group.paymentType === paymentType)
            ?.methods.find(({ id, title }) => title === titleToCheck || id === idToCheck) || {};

    return !disabled;
};

export const isCodPayment = paymentType => paymentType === PAYMENT_TYPES.COD;
