export const DEFAULT_STORE_CODE = 'default';
export const STORES_WITH_STATIONARY_SHOPS = [DEFAULT_STORE_CODE];

export const LOYALTY_CLUB_STATUS_1 = 'silver';
export const LOYALTY_CLUB_STATUS_2 = 'gold';
export const LOYALTY_CLUB_STATUS_3 = 'platinum';

export const LOYALTY_CLUB_STATUSES = [
    LOYALTY_CLUB_STATUS_1,
    LOYALTY_CLUB_STATUS_2,
    LOYALTY_CLUB_STATUS_3,
];

export const LOYALTY_CLUB_DISCOUNT_PERCENT_1 = 5;
export const LOYALTY_CLUB_DISCOUNT_PERCENT_2 = 10;
export const LOYALTY_CLUB_DISCOUNT_PERCENT_3 = 15;

export const LOYALTY_CLUB_DISCOUNTS = {
    [LOYALTY_CLUB_STATUS_1]: LOYALTY_CLUB_DISCOUNT_PERCENT_1,
    [LOYALTY_CLUB_STATUS_2]: LOYALTY_CLUB_DISCOUNT_PERCENT_2,
    [LOYALTY_CLUB_STATUS_3]: LOYALTY_CLUB_DISCOUNT_PERCENT_3,
};

export const LOYALTY_CLUB_POINT_RATE_1 = 0;
export const LOYALTY_CLUB_POINT_RATE_2 = 5000;
export const LOYALTY_CLUB_POINT_RATE_3 = 15000;

export const LOYALTY_CLUB_POINT_RATES = {
    [LOYALTY_CLUB_STATUS_1]: LOYALTY_CLUB_POINT_RATE_1,
    [LOYALTY_CLUB_STATUS_2]: LOYALTY_CLUB_POINT_RATE_2,
    [LOYALTY_CLUB_STATUS_3]: LOYALTY_CLUB_POINT_RATE_3,
};

export const SHARE_ID_PREFIX = 'shareid';

export const PSB_LOYALTY_CLUB_FAQ_1 = 'psb_loyalty_club_faq_1';
export const PSB_LOYALTY_CLUB_FAQ_2 = 'psb_loyalty_club_faq_2';
export const PSB_LOYALTY_CLUB_FAQ_3 = 'psb_loyalty_club_faq_3';
export const PSB_LOYALTY_CLUB_FAQ_4 = 'psb_loyalty_club_faq_4';
export const PSB_LOYALTY_CLUB_FAQ_5 = 'psb_loyalty_club_faq_5';
export const PSB_LOYALTY_CLUB_FAQ_6 = 'psb_loyalty_club_faq_6';
export const PSB_LOYALTY_CLUB_FAQ_7 = 'psb_loyalty_club_faq_7';
export const PSB_LOYALTY_CLUB_FAQ_8 = 'psb_loyalty_club_faq_8';
export const PSB_LOYALTY_CLUB_FAQ_9 = 'psb_loyalty_club_faq_9';
export const PSB_LOYALTY_CLUB_FAQ_10 = 'psb_loyalty_club_faq_10';

export const LOYALTY_CLUB_FAQ_BLOCKS_IDS = [
    PSB_LOYALTY_CLUB_FAQ_1,
    PSB_LOYALTY_CLUB_FAQ_2,
    PSB_LOYALTY_CLUB_FAQ_3,
    PSB_LOYALTY_CLUB_FAQ_4,
    PSB_LOYALTY_CLUB_FAQ_5,
    PSB_LOYALTY_CLUB_FAQ_6,
    PSB_LOYALTY_CLUB_FAQ_7,
    PSB_LOYALTY_CLUB_FAQ_8,
    PSB_LOYALTY_CLUB_FAQ_9,
    PSB_LOYALTY_CLUB_FAQ_10,
];

export const PSB_LOYALTY_CLUB_BENEFITS_1 = 'psb_loyalty_club_benefits_silver';
export const PSB_LOYALTY_CLUB_BENEFITS_2 = 'psb_loyalty_club_benefits_gold';
export const PSB_LOYALTY_CLUB_BENEFITS_3 = 'psb_loyalty_club_benefits_platinum';

export const LOYALTY_CLUB_BENEFITS_BLOCKS_IDS = [
    PSB_LOYALTY_CLUB_BENEFITS_1,
    PSB_LOYALTY_CLUB_BENEFITS_2,
    PSB_LOYALTY_CLUB_BENEFITS_3,
];

export const PSB_LOYALTY_CLUB_IMG_1 = 'psb_loyalty_club_img_1';
export const PSB_LOYALTY_CLUB_IMG_2 = 'psb_loyalty_club_img_2';

export const LOYALTY_CLUB_IMAGES_IDS = [PSB_LOYALTY_CLUB_IMG_1, PSB_LOYALTY_CLUB_IMG_2];

export const LOYALTY_CLUB_BLOCKS_IDS = [
    ...LOYALTY_CLUB_FAQ_BLOCKS_IDS,
    ...LOYALTY_CLUB_BENEFITS_BLOCKS_IDS,
    ...LOYALTY_CLUB_IMAGES_IDS,
];

export const PSB_SIDE_STICKY_BLOCK = 'psb_side_sticky_block';

export const LOYALTY_CLUB_PRODUCT_FALSE = 'nie';

export const LOYALTY_CLUB_PAGEBUILDER_IDENTIFIER = 'fashion-club';

export const LOYALTY_CLUB_DOWNLOAD_PLAY = 'https://modivoapp.onelink.me/bfv0/mfclpandroid';
export const LOYALTY_CLUB_DOWNLOAD_APP_STORE = 'https://modivoapp.onelink.me/bfv0/mfclpios';
export const LOYALTY_CLUB_DOWNLOAD_APP_GALLERY = 'https://modivoapp.onelink.me/bfv0/mfclphms';
