const { resolve } = require('path');

const { GENERAL_TRANSLATIONS_KEY } = require('../../../../server/trans/config');

const SEARCH_I18N_NAMESPACE = '__search';

module.exports = {
    namespace: SEARCH_I18N_NAMESPACE,

    generateFilePaths: lang => ({
        [GENERAL_TRANSLATIONS_KEY]: resolve(__dirname, `./translations/${lang}.json`),
    }),
};
