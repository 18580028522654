export const EMAIL = 'email';
export const COMMENT = 'comment';
export const SUBJECT = 'subject';
export const ORDER_NUMBER = 'orderNumber';

export const CONTACT_DATA_MODEL = {
    EMAIL,
    COMMENT,
    SUBJECT,
    ORDER_NUMBER,
};

export const PSB_CONTACT_TOP_FAQ_TEXT = 'psb_contact_top_faq_text';
export const PSB_CONTACT_COMPANY_DATA = 'psb_contact_company_data';
export const PSB_CONTACT_FORM_ADDITIONAL_INFO = 'psb_contact_form_additional_info';
export const PSB_CONTACT_US_BOTTOM = 'psb_contact_us_bottom';

const PSB_CONTACT_FAQ_TEXT_PREFIX = 'psb_contact_faq_text_';
const CONTACT_FAQ_LIMIT = 10;

export const PSB_CONTACT_PAGE = 'psb_contact_page';

export const CONTACT_FAQ_TEXT_BLOCKS_IDS = Array.from(
    { length: CONTACT_FAQ_LIMIT },
    (element, index) => `${PSB_CONTACT_FAQ_TEXT_PREFIX}${index + 1}`
);

export const CONTACT_PAGE_CMS_BLOCKS_IDS = [
    PSB_CONTACT_FORM_ADDITIONAL_INFO,
    PSB_CONTACT_TOP_FAQ_TEXT,
    PSB_CONTACT_COMPANY_DATA,
    PSB_CONTACT_US_BOTTOM,
    ...CONTACT_FAQ_TEXT_BLOCKS_IDS,
];

export default {
    CONTACT_PAGE_CMS_BLOCKS_IDS,
    CONTACT_FAQ_TEXT_BLOCKS_IDS,
    CONTACT_DATA_MODEL,
};
