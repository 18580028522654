import { CMS_GLOBAL_CONFIG_ID } from '@configs/global-config';
import { CMS_HELP_CENTER_CONFIG_ID } from '@configs/help-center-config';
import { CMS_GOOGLE_ANALYTICS_CONFIG } from '@configs/google-analytics';

import { CORE_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { types } from './mutations';

const MODULE_NAME = 'config';

const getPageBuilderBlockProps = pageBuilderBlock => {
    const content = JSON.parse(pageBuilderBlock.content_json)[0];

    return content?.slots.default[0].props || {};
};

export default {
    setStoreViews({ commit }, storeViews) {
        commit(types.SET_STORE_VIEWS, storeViews);
    },

    setStoreView({ commit }, storeView) {
        commit(types.SET_STORE_VIEW, storeView);
    },

    setBaseUrl({ commit }, baseUrl) {
        commit(types.SET_BASE_URL, baseUrl);
    },

    async setLocaleConfig({ commit }, localeConfig) {
        try {
            commit(types.SET_LOCALE_CONFIG, localeConfig);
        } catch (err) {
            this.app.$errorHandler.captureDomainError(CORE_ERROR_DOMAIN, err, {
                [ERROR_ACTION_TAG_NAME]: 'localeConfig',
            });
        }
    },

    async loadGlobalConfig({ rootGetters, commit }) {
        const configBlock = rootGetters['cmsBlock/staticBlockById'](CMS_GLOBAL_CONFIG_ID);

        if (!configBlock?.content_json) {
            return false;
        }

        try {
            const globalConfig = getPageBuilderBlockProps(configBlock);

            commit(types.SET_GLOBAL_CONFIG, globalConfig);

            return true;
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'loadGlobalConfig',
                },
                { configBlock }
            );

            return false;
        }
    },

    async loadHelpCenterConfig({ dispatch, rootGetters, commit }) {
        await dispatch(
            'cmsBlock/getPageBuilderBlocks',
            {
                pageBuilderBlocksToLoad: CMS_HELP_CENTER_CONFIG_ID,
            },
            { root: true }
        );

        const configBlock = rootGetters['cmsBlock/staticBlockById'](CMS_HELP_CENTER_CONFIG_ID);

        if (!configBlock?.content_json) {
            return false;
        }

        try {
            const helpCenterConfig = getPageBuilderBlockProps(configBlock);

            commit(types.SET_HELP_CENTER_CONFIG, helpCenterConfig);

            return true;
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'loadHelpCenterConfig',
                },
                { configBlock }
            );

            return false;
        }
    },

    async loadGoogleAnalyticsConfig({ dispatch, rootGetters, commit }) {
        await dispatch(
            'cmsBlock/getPageBuilderBlocks',
            {
                pageBuilderBlocksToLoad: CMS_GOOGLE_ANALYTICS_CONFIG,
            },
            { root: true }
        );

        const googleAnalyticsConfigBlock = rootGetters['cmsBlock/staticBlockById'](
            CMS_GOOGLE_ANALYTICS_CONFIG
        );

        if (!googleAnalyticsConfigBlock?.content_json) {
            return;
        }

        try {
            const googleAnalyticsConfig = getPageBuilderBlockProps(googleAnalyticsConfigBlock);

            commit(types.SET_GOOGLE_ANALYTICS_CONFIG, googleAnalyticsConfig);
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'loadGoogleAnalyticsConfig',
                },
                { googleAnalyticsConfigBlock }
            );
        }
    },
};
