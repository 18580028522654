<template>
    <div class="footer-links">
        <HeaderText :size="HEADER_SIZE">
            {{ heading }}
        </HeaderText>
        <ul class="links">
            <slot />
        </ul>
    </div>
</template>

<script>
import { HeaderText, HEADER_TEXT_SIZES } from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'FooterLinks',

    components: {
        HeaderText,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.HEADER_SIZE = HEADER_TEXT_SIZES.M;
    },
};
</script>

<style lang="scss" scoped>
.footer-links {
    .links {
        @apply flex flex-col;
        @apply mt-ui-4;
    }
}
</style>
