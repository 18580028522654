<template>
    <div v-if="isBlockContentExist" class="mobile-app-banner">
        <SideModal :is-open="isBannerOpen" :slide-from="SLIDE_FROM_BOTTOM" @close="closeBanner()">
            <div class="content">
                <SvgIcon width="60px" height="60px" class="modivo-icon">
                    <ModivoLetter />
                </SvgIcon>

                <StaticBlock
                    v-if="cmsBlock && cmsBlock.content"
                    :class="{ 'is-android': isAndroid }"
                    :content="cmsBlock.content"
                    class="content-text"
                />
            </div>
        </SideModal>
    </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';

import {
    PSB_MOBILE_APP_BANNER,
    ROUTES_TO_NOT_DISPLAY,
    BANNER_SHOW_DELAY,
    MOBILE_APP_BANNER_KEY,
    BANNER_EXPIRE_TIME,
} from '@configs/mobile-app-banner';

import { UI_OPEN_MOBILE_APP_BANNER } from '@analytics-types/Events';
import { SLIDE_FROM_BOTTOM } from '@types/SideModal';

import ModivoLetter from '@static/icons/custom/modivo-m-letter.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';

const { mapGetters: mapCmsBlockGetters, mapActions: mapCmsBlockActions } = createNamespacedHelpers(
    'cmsBlock'
);

export default {
    name: 'MobileAppBanner',

    components: {
        SvgIcon,
        ModivoLetter,
        StaticBlock,
        SideModal: () => ({
            component: import(
                /* webpackChunkName: "side-modal" */
                '@molecules/SideModal/SideModal'
            ),
        }),
    },

    data() {
        return {
            isBannerOpen: true,
            cmsBlock: {},
            timeoutHandler: null,
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapCmsBlockGetters(['staticBlockById']),

        isRouteAllowed() {
            return !ROUTES_TO_NOT_DISPLAY.includes(this.$route.name);
        },

        isBlockContentExist() {
            return !!this.cmsBlock?.content;
        },

        isAndroid() {
            return !!this.$ua?.isAndroid;
        },
    },

    watch: {
        $route() {
            this.showBannerAfterDelay();
        },

        isMobile(isMobile) {
            if (isMobile) {
                this.showBannerAfterDelay();
            } else {
                clearTimeout(this.timeoutHandler);
            }
        },
    },

    beforeMount() {
        this.showBannerAfterDelay();
    },

    beforeCreate() {
        this.SLIDE_FROM_BOTTOM = SLIDE_FROM_BOTTOM;
    },

    beforeDestroy() {
        clearTimeout(this.timeoutHandler);
    },

    methods: {
        ...mapCmsBlockActions(['getCmsBlocks']),

        showBannerAfterDelay() {
            const { isBot = false } = this.$ua || {};

            if (this.isMobile && this.isRouteAllowed && this.isLocalStorageExpired() && !isBot) {
                clearTimeout(this.timeoutHandler);

                this.timeoutHandler = setTimeout(async () => {
                    if (!this.isBlockContentExist) {
                        await this.getCmsBlocks({
                            cmsBlocksToLoad: [PSB_MOBILE_APP_BANNER],
                        });

                        this.cmsBlock = this.staticBlockById(PSB_MOBILE_APP_BANNER);

                        if (this.isBlockContentExist) {
                            window.requestAnimationFrame(() => {
                                this.$analytics.emit(UI_OPEN_MOBILE_APP_BANNER);
                            });
                        }
                    }
                }, BANNER_SHOW_DELAY);
            }
        },

        isLocalStorageExpired() {
            return !this.$storage.getItem(MOBILE_APP_BANNER_KEY);
        },

        closeBanner() {
            this.isBannerOpen = false;

            const expireDate = new Date().getTime() + BANNER_EXPIRE_TIME;

            this.$storage.setItem(MOBILE_APP_BANNER_KEY, 1, expireDate);
        },
    },
};
</script>

<style lang="scss" scoped>
.mobile-app-banner {
    @screen mobile-only {
        @apply text-center;

        .content {
            @apply px-3 pb-5 pt-3;
        }

        .modivo-icon {
            @apply rounded-10 border-1 border-border m-auto;
        }

        .content-text {
            @apply mt-3;
        }

        &:deep() {
            .side-modal {
                &.bottom {
                    @apply h-auto bottom-0;

                    transform: translateY(100%);

                    &.opened {
                        transform: translateY(0);
                    }
                }

                .header {
                    @apply h-auto;
                }

                .close {
                    @apply absolute top-16 right-16 z-1;
                }
            }

            .wrapper {
                @apply h-auto;
            }

            .title {
                @apply text-m font-semibold leading-m text-text2;
            }

            .paragraph {
                @apply text-text2 text-center mt-2;
            }

            .android {
                @apply hidden;
            }

            .is-android {
                .android {
                    @apply block;
                }

                .ios {
                    @apply hidden;
                }
            }

            @import './assets/scss/cms/components/cms-action';

            .cms-action {
                @apply mt-3 w-full justify-center;

                max-width: theme('customVariables.mobileAppBanner.buttonMaxWidth');
            }
        }
    }

    @screen lg {
        @apply hidden;
    }
}
</style>
