const { resolve } = require('path');

const {
    GENERAL_TRANSLATIONS_KEY,
    VALIDATION_TRANSLATIONS_KEY,
} = require('../../../../server/trans/config');

const RMA_I18N_NAMESPACE = '__rma';

module.exports = {
    namespace: RMA_I18N_NAMESPACE,

    generateFilePaths: lang => ({
        [GENERAL_TRANSLATIONS_KEY]: resolve(__dirname, `./translations/${lang}.json`),
        [VALIDATION_TRANSLATIONS_KEY]: resolve(
            __dirname,
            `./translations/validations/${lang}.json`
        ),
    }),
};
