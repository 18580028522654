<template>
    <ContainerContent
        :class="{
            _full: fullWidth,
            _nogutters: noGutters,
            'is-sidebar-visible-on-mobile': isSidebarVisibleOnMobile,
        }"
    >
        <div class="sidebar w-full lg:w-2/8 xl:w-1/6">
            <!-- @slot sidebar slot -->
            <slot name="sidebar" />
        </div>
        <div class="content w-full lg:w-6/8 xl:w-5/6">
            <!-- @slot content slot -->
            <slot></slot>
        </div>
    </ContainerContent>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'ContainerContentWithSidebar',
    components: {
        ContainerContent,
    },
    props: {
        /**
         * Changes max-width of element to 100% and apply 0 paddings.
         */
        fullWidth: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets negative margins to counteract containers padding.
         */
        noGutters: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets sidebar visibility on mobile.
         */
        isSidebarVisibleOnMobile: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.sidebar {
    @apply hidden;
}

.is-sidebar-visible-on-mobile {
    .sidebar {
        @apply block;
    }
}

@screen lg {
    .sidebar {
        @apply block;
    }
}
</style>
