export const TYPES = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
};

export const PARAMS = {
    DISPLAY_LIMIT: 5,
    DEFAULT_TIMEOUT_MS: 7000,
};

export default {
    TYPES,
    PARAMS,
};
