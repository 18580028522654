import { MEGATRON_SEARCH_GET_PRODUCTS_CATALOG_OPERATION_NAME } from '@configs/megatron-client';

import normalizeCatalogProduct from '@normalizers/models/catalogProduct';
import normalizeFilters from '@normalizers/models/filters';

const normalizer = async (response, config, helpers) => {
    const { data, error } = response;

    if (error || !data) {
        return response;
    }

    const { products = [], filters, attributes } = data || {};

    response.data.products = await Promise.all(
        products.map(product =>
            normalizeCatalogProduct({ ...product, attributes }, helpers, config)
        )
    );

    if (filters) {
        response.data.filters = normalizeFilters(data.filters, config);
    }

    return response;
};

export const getProductsCatalog = {
    operationName: MEGATRON_SEARCH_GET_PRODUCTS_CATALOG_OPERATION_NAME,
    normalizer,
};
