import { render, staticRenderFns } from "./MinicartTooltip.vue?vue&type=template&id=37742127&scoped=true"
import script from "./MinicartTooltip.vue?vue&type=script&lang=js"
export * from "./MinicartTooltip.vue?vue&type=script&lang=js"
import style0 from "./MinicartTooltip.vue?vue&type=style&index=0&id=37742127&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37742127",
  null
  
)

export default component.exports