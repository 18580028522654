var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isSomeModalOpen)?_c('LoyaltyClubProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ openLoyaltyClubRegistrationModal, shouldDisplayModalAfterLogin }){return [_c('div',[_c(_vm.authModal,{tag:"component",attrs:{"show":_vm.isCustomerAccountModalOpen},on:{"open-loyalty-club-registration-modal":function($event){return openLoyaltyClubRegistrationModal()},"close-customer-modal":function($event){return _vm.closeModal(_vm.MODAL_CUSTOMER_ACCOUNT_NAME)}}}),_vm._v(" "),(_vm.isMagicLinkEnabled && _vm.isMagicLinkAuthModalOpen)?_c('MagicLinkAuth',{on:{"close":function($event){return _vm.closeModal(_vm.MODAL_MAGIC_LINK_AUTH)}}}):_vm._e(),_vm._v(" "),(
                    _vm.isAccountVerificationModalOpen &&
                    !_vm.isCustomerAccountModalOpen &&
                    !_vm.shouldOpenModalLoyaltyClubRegistration &&
                    !_vm.shouldOpenModalAfterLoyaltyClubRegistration &&
                    !shouldDisplayModalAfterLogin &&
                    !_vm.isModalAfterRegistrationBySocialMediaOpen
                )?_c('AccountVerificationModal',{on:{"close":function($event){return _vm.closeModal(_vm.MODAL_ACCOUNT_VERIFICATION)}}}):_vm._e()],1)]}}],null,false,2047420976)}):_vm._e(),_vm._v(" "),(_vm.isModalAfterRegistrationBySocialMediaOpen)?_c('ModalAfterRegistrationBySocialMedia',{on:{"close-modal":function($event){return _vm.closeModal(_vm.MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA)}}}):_vm._e(),_vm._v(" "),(_vm.shouldOpenNewsletterTriggerTooltip)?_c('NewsletterTriggerTooltip'):_vm._e(),_vm._v(" "),(_vm.shouldOpenModalNewsletter)?_c('ModalNewsletter',{on:{"close":function($event){return _vm.closeModal(_vm.MODAL_NEWSLETTER_NAME)}}}):_vm._e(),_vm._v(" "),(_vm.isNewsletterTooltipOpenFromListing)?_c('NewsletterTooltip',{attrs:{"is-snackbar":true},on:{"close":function($event){return _vm.closeModal(_vm.NEWSLETTER_TOOLTIP)}}}):_vm._e(),_vm._v(" "),(_vm.isModalInformationOpen)?_c('InformationModal',{on:{"close":function($event){return _vm.closeModal(_vm.MODAL_ADVERTISEMENT_INFORMATION)}}}):_vm._e(),_vm._v(" "),_c('NewsletterConfirmationModal',{attrs:{"show":_vm.isModalNewsletterConfirmationOpen &&
            _vm.shouldOpenModalNewsletterConfirmation &&
            !_vm.shouldOpenModalLoyaltyClubRegistration},on:{"close":function($event){return _vm.closeModal(_vm.MODAL_NEWSLETTER_CONFIRMATION)}}}),_vm._v(" "),(_vm.isSimilarProductsVSModalOpen)?_c('SimilarProductsVSModal',{on:{"close":function($event){return _vm.closeModal(_vm.MODAL_SIMILAR_PRODUCTS_VS)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }