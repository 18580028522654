import { STORE_CONTEXT } from '@configs/context';
import { AVATAR_ADDRESS_TYPES } from '@configs/avatar';
import { ACCOUNT_VERIFICATION_STATES, CHANNEL_TYPE_EMAIL } from '@configs/account-verification';
import {
    BAD_REQUEST_HTTP_CODE,
    GONE_HTTP_CODE,
    NO_CONTENT_HTTP_CODE,
    NOT_FOUND_HTTP_CODE,
} from '@configs/http-codes';
import { MODAL_ACCOUNT_VERIFICATION, MODAL_ACCOUNT_VERIFICATION_TYPE } from '@configs/modals';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { mapAddressForAvatar } from '@assets/address';

import { types } from './mutations';

const MODULE_NAME = 'avatar';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `avatar.${action}`,
});

export default {
    async connectClient({ rootGetters, rootState, commit, state }, avatarId) {
        const ERROR_TAGS = getErrorTags('connectClient');

        try {
            if (state.isConnectingClient || !rootGetters['customer/isLoggedIn']) {
                return;
            }

            const { accessToken } = rootState.customer;

            if (!avatarId || !accessToken) {
                this.$errorHandler.captureStoreError(
                    MODULE_NAME,
                    new Error('Missing avatarId or accessToken during connectClient action'),
                    ERROR_TAGS,
                    {
                        hasAccessToken: Boolean(accessToken),
                        hasAvatarId: Boolean(avatarId),
                    }
                );

                return;
            }

            commit(types.SET_IS_CONNECTING_CLIENT, true);

            const { data, error } = await this.$services.avatar.connectClient(avatarId);

            commit(types.SET_IS_CONNECTING_CLIENT, false);

            const { status } = data || {};

            if (status !== 'success' || error) {
                this.$errorHandler.captureStoreError(
                    MODULE_NAME,
                    new Error('Avatar unsuccessful connect client'),
                    ERROR_TAGS,
                    {
                        status,
                        error: JSON.stringify(error),
                    }
                );

                return;
            }

            this.$avatar.onConnectClientSuccess();
        } catch (err) {
            this.$errorHandler.captureStoreError(MODULE_NAME, err, ERROR_TAGS, {
                error: JSON.stringify(err),
            });
        }
    },

    async sendMagicLink({ rootGetters }, email) {
        const ERROR_TAGS = getErrorTags('sendMagicLink');

        const storeCode = rootGetters['config/storeCode'];

        let result = true;

        try {
            const { error } = await this.$services.avatar.sendMagicLink({
                email,
                storeContext: STORE_CONTEXT,
                websiteContext: storeCode,
            });

            if (error) {
                result = false;

                this.app.$errorHandler.captureStoreError(MODULE_NAME, error, ERROR_TAGS, {
                    storeCode,
                    storeContext: STORE_CONTEXT,
                    error: JSON.stringify(error),
                });
            }
        } catch (err) {
            result = false;

            this.$errorHandler.captureStoreError(MODULE_NAME, err, ERROR_TAGS, {
                error: JSON.stringify(err),
            });
        }

        return result;
    },

    async readClientAddresses({ dispatch, commit }) {
        const ERROR_TAGS = getErrorTags('readClientAddresses');

        try {
            const { data, error } = await this.$services.avatar.readClientAddresses();

            if (error) {
                this.app.$errorHandler.captureStoreError(MODULE_NAME, error, ERROR_TAGS, {
                    error: JSON.stringify(error),
                });
                await dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t('Fetching data error occurred'),
                    },
                    { root: true }
                );

                return;
            }

            commit(types.SET_CLIENT_ADDRESSES, data);
        } catch (err) {
            this.$errorHandler.captureStoreError(MODULE_NAME, err, ERROR_TAGS, {
                error: JSON.stringify(err),
            });
        }
    },

    async createClientAddress({ dispatch, rootGetters }, address) {
        const ERROR_TAGS = getErrorTags('createClientAddress');

        try {
            const storeCode = rootGetters['config/storeCode'];
            const {
                isActive,
                isCompanyOrder,
                isBillingAddress,
                isDeliveryAddress,
                storeContext,
                title,
                city,
                street,
                websiteContext,
                phone,
                zipCode,
                houseNumber,
                isDefaultBillingAddress,
                isDefaultDeliveryAddress,
                flatNumber,
                vatNumber,
                companyName,
                firstName,
                lastName,
            } = mapAddressForAvatar(address, storeCode);

            const { data, error } = await this.$services.avatar.createClientAddress({
                isActive,
                isCompanyOrder,
                isBillingAddress,
                isDeliveryAddress,
                storeContext,
                title,
                city,
                street,
                websiteContext,
                phone,
                zipCode,
                houseNumber,
                isDefaultBillingAddress,
                isDefaultDeliveryAddress,
                flatNumber,
                vatNumber,
                companyName,
                firstName,
                lastName,
            });

            await dispatch('readClientAddresses');

            if (error || !data?.id) {
                this.app.$errorHandler.captureStoreError(
                    MODULE_NAME,
                    new Error(error?.message || 'Missing ID from createClientAddress', {
                        cause: error,
                    }),
                    ERROR_TAGS,
                    {
                        error: JSON.stringify(error),
                    }
                );

                await dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'Creating address failed. Please try again in a moment.'
                        ),
                    },
                    { root: true }
                );

                return;
            }

            await dispatch(
                'messages/addSuccessMessage',
                { text: this.app.i18n.t('Address has been added.') },
                { root: true }
            );
        } catch (err) {
            this.$errorHandler.captureStoreError(MODULE_NAME, err, ERROR_TAGS, {
                error: JSON.stringify(err),
            });
        }
    },

    async deleteClientAddress({ dispatch }, clientAddressId) {
        const ERROR_TAGS = getErrorTags('deleteClientAddress');

        try {
            const { error } = await this.$services.avatar.deleteClientAddress(clientAddressId);

            await dispatch('readClientAddresses');

            if (error) {
                this.app.$errorHandler.captureStoreError(MODULE_NAME, error, ERROR_TAGS, {
                    error: JSON.stringify(error),
                });

                await dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'Removing address failed. Please try again in a moment.'
                        ),
                    },
                    { root: true }
                );

                return;
            }

            await dispatch(
                'messages/addSuccessMessage',
                { text: this.app.i18n.t('Address has been removed.') },
                { root: true }
            );
        } catch (err) {
            this.$errorHandler.captureStoreError(MODULE_NAME, err, ERROR_TAGS, {
                error: JSON.stringify(err),
            });
        }
    },

    async updateClientAddress({ dispatch, rootGetters }, address) {
        const ERROR_TAGS = getErrorTags('updateClientAddress');

        try {
            const storeCode = rootGetters['config/storeCode'];
            const {
                isActive,
                isCompanyOrder,
                isBillingAddress,
                isDeliveryAddress,
                title,
                city,
                street,
                phone,
                zipCode,
                houseNumber,
                isDefaultBillingAddress,
                isDefaultDeliveryAddress,
                flatNumber,
                vatNumber,
                companyName,
                firstName,
                lastName,
                clientAddressId,
            } = mapAddressForAvatar(address, storeCode);

            const { data, error } = await this.$services.avatar.updateClientAddress(
                clientAddressId,
                {
                    isActive,
                    isCompanyOrder,
                    isBillingAddress,
                    isDeliveryAddress,
                    title,
                    city,
                    street,
                    phone,
                    zipCode,
                    houseNumber,
                    isDefaultBillingAddress,
                    isDefaultDeliveryAddress,
                    flatNumber,
                    vatNumber,
                    companyName,
                    firstName,
                    lastName,
                }
            );

            await dispatch('readClientAddresses');

            if (error || !data?.id) {
                this.app.$errorHandler.captureStoreError(
                    MODULE_NAME,
                    new Error(error?.message || 'Error with updateClientAddress', {
                        cause: error,
                    }),
                    ERROR_TAGS,
                    {
                        error: JSON.stringify(error),
                    }
                );

                await dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'Changing address failed. Please try again in a moment.'
                        ),
                    },
                    { root: true }
                );

                return;
            }

            await dispatch(
                'messages/addSuccessMessage',
                { text: this.app.i18n.t('Address has been changed.') },
                { root: true }
            );
        } catch (err) {
            this.$errorHandler.captureStoreError(MODULE_NAME, err, ERROR_TAGS, {
                error: JSON.stringify(err),
            });
        }
    },

    async setDefaultClientAddress({ dispatch }, { clientAddressId, addressType }) {
        const ERROR_TAGS = getErrorTags('setDefaultClientAddress');

        try {
            const { BILLING, DELIVERY } = AVATAR_ADDRESS_TYPES;

            if (![BILLING, DELIVERY].includes(addressType)) {
                return;
            }

            const {
                setDefaultBillingClientAddress,
                setDefaultDeliveryClientAddress,
            } = this.$services.avatar;
            const MAP_ACTIONS_BY_ADDRESS_TYPE = {
                [BILLING]: setDefaultBillingClientAddress,
                [DELIVERY]: setDefaultDeliveryClientAddress,
            };
            const { data, error } = await MAP_ACTIONS_BY_ADDRESS_TYPE[addressType](clientAddressId);

            await dispatch('readClientAddresses');

            if (error || !data?.id) {
                this.app.$errorHandler.captureStoreError(
                    MODULE_NAME,
                    new Error(error?.message || 'Error with setDefaultClientAddress', {
                        cause: error,
                    }),
                    ERROR_TAGS,
                    {
                        error: JSON.stringify(error),
                        addressType,
                    }
                );

                await dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'Changing address failed. Please try again in a moment.'
                        ),
                    },
                    { root: true }
                );

                return;
            }

            await dispatch(
                'messages/addSuccessMessage',
                { text: this.app.i18n.t('Address has been changed.') },
                { root: true }
            );
        } catch (err) {
            this.$errorHandler.captureStoreError(MODULE_NAME, err, ERROR_TAGS, {
                error: JSON.stringify(err),
            });
        }
    },

    async checkAccountVerificationStatus({ rootState, commit }, { callPlace = '' } = {}) {
        const ERROR_TAGS = getErrorTags('checkAccountVerificationStatus');
        const hasAccessToken = !!rootState.customer.accessToken;

        try {
            const { email = null } = rootState.customer.customerData || {};

            if (!email || !hasAccessToken) {
                commit(types.SET_ACCOUNT_VERIFICATION_STATUS, null);

                return null;
            }

            const { NOT_EXIST, COMPLETE, PENDING } = ACCOUNT_VERIFICATION_STATES;

            const payload = {
                channelType: CHANNEL_TYPE_EMAIL,
                channelId: email,
            };

            const {
                data,
                error,
                status,
            } = await this.$services.avatar.checkAccountVerificationStatus(payload);

            if (status === NOT_FOUND_HTTP_CODE) {
                commit(types.SET_ACCOUNT_VERIFICATION_STATUS, NOT_EXIST);

                return NOT_EXIST;
            }

            if (status >= BAD_REQUEST_HTTP_CODE) {
                this.app.$errorHandler.captureStoreError(
                    MODULE_NAME,
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    ERROR_TAGS,
                    {
                        error: JSON.stringify(error),
                        callPlace,
                        hasAccessToken,
                    }
                );
                commit(types.SET_ACCOUNT_VERIFICATION_STATUS, null);

                return null;
            }

            const verificationStatus = data?.confirmedAt !== null ? COMPLETE : PENDING;

            commit(types.SET_ACCOUNT_VERIFICATION_STATUS, verificationStatus);

            return verificationStatus;
        } catch (err) {
            this.$errorHandler.captureStoreError(MODULE_NAME, err, ERROR_TAGS, {
                error: JSON.stringify(err),
            });

            commit(types.SET_ACCOUNT_VERIFICATION_STATUS, null);

            return null;
        }
    },

    setAccountVerificationStatus({ commit }, status) {
        commit(types.SET_ACCOUNT_VERIFICATION_STATUS, status);
    },

    async sendAccountVerification({ rootGetters, rootState, dispatch, commit }) {
        const ERROR_TAGS = getErrorTags('sendAccountVerification');

        if (!rootGetters['customer/isLoggedIn']) {
            return;
        }

        try {
            const verificationStatus = await dispatch('checkAccountVerificationStatus', {
                callPlace: 'sendAccountVerification',
            });

            const { PENDING, COMPLETE, NOT_EXIST } = ACCOUNT_VERIFICATION_STATES;

            const showErrorMessage = () => {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'Something went wrong. Please try again in a moment.'
                        ),
                    },
                    { root: true }
                );
            };

            if (!verificationStatus) {
                showErrorMessage();

                return false;
            }

            if (verificationStatus === COMPLETE) {
                dispatch(
                    'messages/addSuccessMessage',
                    {
                        text: this.app.i18n.t('Your email address has already been confirmed.'),
                    },
                    { root: true }
                );

                return false;
            }

            const { email } = rootState.customer.customerData;

            const ACTIONS = {
                [NOT_EXIST]: payload => this.$services.avatar.createAccountVerification(payload),
                [PENDING]: payload => this.$services.avatar.resendAccountVerification(payload),
            };

            const chosenAccountVerificationAction = ACTIONS[verificationStatus];

            if (!chosenAccountVerificationAction) {
                return false;
            }

            const { error, status } = await chosenAccountVerificationAction({
                channelType: CHANNEL_TYPE_EMAIL,
                channelId: email,
            });

            if (!error) {
                commit(types.SET_ACCOUNT_VERIFICATION_STATUS, PENDING);

                return true;
            }

            showErrorMessage();

            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    error: JSON.stringify(error),
                    status,
                    verificationStatus,
                }
            );

            return false;
        } catch (err) {
            this.$errorHandler.captureStoreError(MODULE_NAME, err, ERROR_TAGS, {
                error: JSON.stringify(err),
            });

            return false;
        }
    },

    async confirmAccountVerification(_context, token) {
        const ERROR_TAGS = getErrorTags('confirmAccountVerification');
        const { COMPLETE, ERROR } = ACCOUNT_VERIFICATION_STATES;

        try {
            if (!token) {
                return ERROR;
            }

            const { error, status } = await this.$services.avatar.confirmAccountVerification(token);

            switch (status) {
                case NO_CONTENT_HTTP_CODE:
                    return COMPLETE;
                case GONE_HTTP_CODE:
                    return ERROR;
                default:
                    this.app.$errorHandler.captureStoreError(
                        MODULE_NAME,
                        new Error(error?.message || 'UNKNOWN_ERROR', {
                            cause: error,
                        }),
                        ERROR_TAGS,
                        {
                            error: JSON.stringify(error),
                            status,
                        }
                    );

                    return ERROR;
            }
        } catch (err) {
            this.$errorHandler.captureStoreError(MODULE_NAME, err, ERROR_TAGS, {
                error: JSON.stringify(err),
            });

            return ERROR;
        }
    },

    clearAccountVerificationData({ commit }) {
        commit(types.SET_ACCOUNT_VERIFICATION_STATUS, null);
    },

    async showAccountVerificationIfNeeded({ getters, rootGetters }) {
        if (!getters.canVerifyAccount || !rootGetters['config/isAccountVerificationEnabled']) {
            return false;
        }

        await this.$modals.open(MODAL_ACCOUNT_VERIFICATION, {
            [MODAL_ACCOUNT_VERIFICATION_TYPE]: ACCOUNT_VERIFICATION_STATES.SEND,
        });

        return true;
    },

    async checkEmailExist({ rootGetters }, email) {
        const ERROR_TAGS = getErrorTags('checkEmailExist');

        try {
            const storeCode = rootGetters['config/storeCode'];

            const { data, error } = await this.$services.avatar.checkEmailExist({
                websiteContext: storeCode,
                storeContext: STORE_CONTEXT,
                email,
            });

            if (!error) {
                return data?.exists || false;
            }

            this.$errorHandler.captureStoreError(
                MODULE_NAME,
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    error: JSON.stringify(error),
                }
            );

            return false;
        } catch (err) {
            this.$errorHandler.captureStoreError(MODULE_NAME, err, ERROR_TAGS, {
                error: JSON.stringify(err),
            });
        }
    },
};
