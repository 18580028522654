<template>
    <div>
        <ModalBeforeLoyaltyClubRegistration
            v-if="!isLoggedIn && shouldOpenModalLoyaltyClubRegistration"
            :is-open="shouldOpenModalLoyaltyClubRegistration"
            @show-customer-modal="onShowCustomerModal($event)"
            @close="closeLoyaltyClubRegistrationModal()"
        />
        <LoyaltyClubProvider
            #default="{ isSubscribed, closeModalAfterLoyaltyClubRegistration, status }"
        >
            <div>
                <ModalLoyaltyClubRegistration
                    v-if="!isSubscribed && isLoggedIn && shouldOpenModalLoyaltyClubRegistration"
                    :is-open="shouldOpenModalLoyaltyClubRegistration"
                    @close="closeLoyaltyClubRegistrationModal()"
                />
                <ModalLoyaltyClubStatus
                    v-if="isSubscribed && isLoggedIn && shouldOpenModalAfterLoyaltyClubRegistration"
                    :previous-status="status"
                    :status="status"
                    @close="closeModalAfterLoyaltyClubRegistration()"
                />
            </div>
        </LoyaltyClubProvider>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { MODAL_CUSTOMER_ACCOUNT_NAME, MODAL_TYPE } from '@configs/modals';

import LoyaltyClubProvider from '@functionals/LoyaltyClubProvider/LoyaltyClubProvider';

const {
    mapState: mapCustomerState,
    mapGetters: mapCustomerGetters,
    mapActions: mapCustomerActions,
} = createNamespacedHelpers('customer');

export default {
    name: 'LoyaltyClubRegistrationWrapper',

    components: {
        LoyaltyClubProvider,
        ModalBeforeLoyaltyClubRegistration: () => ({
            component: import(
                /* webpackChunkName: "modal-before-loyalty-club-registration" */
                '@molecules/ModalBeforeLoyaltyClubRegistration/ModalBeforeLoyaltyClubRegistration'
            ),
        }),
        ModalLoyaltyClubRegistration: () => ({
            component: import(
                /* webpackChunkName: "modal-loyalty-club-registration" */
                '@organisms/ModalLoyaltyClubRegistration/ModalLoyaltyClubRegistration'
            ),
        }),
        ModalLoyaltyClubStatus: () => ({
            component: import(
                /* webpackChunkName: "modal-loyalty-club-status" */
                '@organisms/ModalLoyaltyClubStatus/ModalLoyaltyClubStatus'
            ),
        }),
    },

    computed: {
        ...mapCustomerState([
            'shouldOpenModalLoyaltyClubRegistration',
            'shouldOpenModalAfterLoyaltyClubRegistration',
        ]),

        ...mapCustomerGetters(['isLoggedIn']),
    },

    methods: {
        ...mapCustomerActions(['setShouldOpenLoyaltyClubRegistrationOnLoginSuccess']),

        async onShowCustomerModal(type) {
            this.closeLoyaltyClubRegistrationModal();
            this.setShouldOpenLoyaltyClubRegistrationOnLoginSuccess(true);

            await this.$modals.open(MODAL_CUSTOMER_ACCOUNT_NAME, {
                [MODAL_TYPE]: type,
            });
        },

        closeLoyaltyClubRegistrationModal() {
            this.$emit('close-loyalty-club-registration-modal');
        },
    },
};
</script>
