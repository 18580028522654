<template>
    <div class="navigation-sl" :class="{ 'double-columns': sectionColumns.length === 2 }">
        <div
            v-for="(column, index) in sectionColumns"
            :key="`${section.id}-col-${index + 1}`"
            class="section-column"
        >
            <CellLink
                v-for="link in column.categoryLinks"
                :key="link.path"
                :to="link.path"
                :title="link.name"
                :label="link.name"
                :horizontal-padding-desktop="CELL_LINK_PADDING_DESKTOP"
                :data-test-id="NAVIGATION_NESTED_ITEM_LINK"
                class="nav-item"
            />

            <CellLink
                v-if="column.showAll"
                :variant="CELL_LINK_BOLD"
                :to="column.showAll.path"
                :label="$t('View all')"
                :horizontal-padding-desktop="CELL_LINK_PADDING_DESKTOP"
                :data-test-id="NAVIGATION_SHOW_ALL_ITEM"
            />
        </div>
    </div>
</template>

<script>
import { NAVIGATION_SHOW_ALL_ITEM, NAVIGATION_NESTED_ITEM_LINK } from '@types/AutomaticTestIDs';

import {
    CellLink,
    CELL_LINK_VARIANTS,
    CELL_LINK_PADDINGS,
} from '@modivo-ui/components/CellLink/v1';

export default {
    name: 'NavigationContent',

    components: {
        CellLink,
    },

    props: {
        section: {
            type: Object,
            required: true,
        },
    },

    computed: {
        sectionColumns() {
            const links = this.section.categoryLinks;

            if (links.length <= 10) {
                return [this.section];
            }

            let numberOfAllLinks = links.length;

            if (this.section.showAll) {
                numberOfAllLinks += 1;
            }

            const halfWayPosition = Math.ceil(numberOfAllLinks / 2);
            const firstHalfOfLinks = links.slice(0, halfWayPosition);

            const secondHalfOfLinks = links.slice(halfWayPosition, links.length);

            return [
                { categoryLinks: firstHalfOfLinks, showAll: null },
                {
                    ...this.section,
                    categoryLinks: secondHalfOfLinks,
                },
            ];
        },
    },

    beforeCreate() {
        this.NAVIGATION_SHOW_ALL_ITEM = NAVIGATION_SHOW_ALL_ITEM;
        this.NAVIGATION_NESTED_ITEM_LINK = NAVIGATION_NESTED_ITEM_LINK;
        this.CELL_LINK_PADDING_DESKTOP = CELL_LINK_PADDINGS.PX_0;
        this.CELL_LINK_BOLD = CELL_LINK_VARIANTS.BOLD;
    },
};
</script>

<style scoped lang="scss">
.navigation-sl {
    @screen ui-desktop {
        @apply flex;

        &.double-columns {
            .section-column {
                @apply w-1/2;
            }
        }
    }
}
</style>
