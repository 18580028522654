<template>
    <component
        :is="element"
        :key="isLoading"
        :type="to ? null : type"
        :class="['base-button', theme, size]"
        v-bind="attrs"
        v-on="$listeners"
    >
        <template v-if="isLoading">
            <span class="hidden-content">
                <slot />
            </span>
            <SvgIcon :width="iconSize" :height="iconSize" class="icon">
                <component :is="loaderIcon" />
            </SvgIcon>
        </template>
        <slot v-else />
    </component>
</template>

<script>
import { isHttpLink } from '@assets/link';

import { SIZE_SMALL } from '@types/Button';

import Button from '@mixins/Button';

import LoaderSmall from '@static/icons/16px/loader.svg?inline';
import LoaderBig from '@static/icons/24px/loader.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';

export default {
    name: 'BaseButton',

    components: { SvgIcon, LoaderSmall, LoaderBig },

    mixins: [Button],

    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        element() {
            if (!this.to) {
                return 'button';
            }

            return this.isHttpLink ? 'a' : 'nuxt-link';
        },

        isHttpLink() {
            return isHttpLink(this.to);
        },

        attrs() {
            const attrs = { ...this.$attrs };

            if (this.isHttpLink) {
                attrs.href = this.to;
            } else {
                attrs.to = this.to;
            }

            return attrs;
        },

        iconSize() {
            return this.size === SIZE_SMALL ? '16px' : '24px';
        },

        loaderIcon() {
            return this.size === SIZE_SMALL ? LoaderSmall : LoaderBig;
        },
    },
};
</script>

<style lang="scss" scoped>
.base-button {
    @apply relative inline-flex items-center justify-center rounded-8;
    transition: background-color 0.5s, color 0.5s, border-color 0.5s;

    .icon {
        @apply absolute;
        color: currentColor;
    }

    .hidden-content {
        @apply invisible;
    }

    &:focus {
        @apply outline-none shadow-7;
    }

    &:active {
        @apply outline-none;
    }

    &[disabled] {
        @apply pointer-events-none opacity-40;
    }

    &.primary,
    &.secondary,
    &.secondary-dashed,
    &.tertiary,
    &.tertiary-dashed,
    &.white,
    &.dark,
    &.transparent-white,
    &.transparent-white-dashed,
    &.light {
        @apply font-semibold px-5 border-2;
    }

    &.primary,
    &.secondary,
    &.tertiary,
    &.white,
    &.transparent-white {
        @apply border-solid;
    }

    &.secondary-dashed,
    &.transparent-white-dashed {
        @apply border-dashed;
    }

    &.primary {
        @apply border-primary text-light bg-primary;

        &:deep() .svg-icon {
            stroke: theme('colors.light');
        }

        &:hover {
            @apply bg-primaryOption border-primaryOption;
        }

        &:active {
            @apply bg-transparent text-primary;

            &:deep() .base-badge {
                @apply bg-dark text-light;
            }
            &:deep() .svg-icon {
                stroke: theme('colors.primary');
            }

            &:hover {
                &:deep() .svg-icon {
                    stroke: theme('colors.primary');
                }

                &:deep() .base-badge {
                    @apply bg-light text-primary;
                }
            }
        }
    }

    &.secondary,
    &.secondary-dashed {
        @apply border-gray1 text-gray1;

        &:deep() .base-badge {
            @apply bg-dark text-light;
        }

        &:hover {
            @apply bg-gray1 text-light;

            &:deep() .base-badge {
                @apply bg-light text-dark;
            }

            &:deep() .svg-icon {
                stroke: theme('colors.light');
            }
        }

        &:active {
            @apply bg-transparent border-primary text-gray1;

            &:deep() .base-badge {
                @apply bg-light text-gray1;
            }

            &:deep() .svg-icon {
                stroke: theme('colors.gray1');
            }

            &:hover {
                &:deep() .base-badge {
                    @apply bg-light text-gray1;
                }
            }
        }
    }

    &.tertiary {
        @apply border-border text-gray1;

        &:hover {
            @apply bg-gray4;
        }

        &:active {
            @apply bg-transparent border-primary text-gray1;
        }

        &[disabled] {
            @apply text-gray2;
        }
    }

    &.white {
        @apply border-light text-gray1 bg-light;

        &:deep() .svg-icon {
            stroke: theme('colors.gray1');
        }

        &:hover,
        &:focus {
            @apply bg-gray1 text-light;
        }

        &:active {
            @apply bg-light text-gray1;
        }

        &[disabled] {
            @apply opacity-60;
        }
    }

    &.transparent-white {
        @apply border-light text-light;

        &:deep() .svg-icon {
            stroke: theme('colors.light');
        }

        &:hover,
        &:focus {
            @apply bg-gray1;
        }

        &:active {
            @apply bg-light text-gray1;
        }

        &[disabled] {
            @apply opacity-60;
        }
    }

    &.transparent-white-dashed {
        @apply border-light text-light;

        &:deep() .svg-icon {
            stroke: theme('colors.light');
        }

        &:hover,
        &:active,
        &:focus {
            @apply bg-gray1;
        }

        &[disabled] {
            @apply opacity-60;
        }
    }

    &[class*='thin-'] {
        @apply px-7 border-1;

        &[disabled] {
            @apply opacity-60;
        }
    }

    &.thin-dark {
        @apply border-gray1 text-gray1 font-semibold;

        &:hover,
        &:active,
        &:focus {
            @apply bg-gray1 text-light;
        }
    }

    &.thin-gray {
        @apply border-border text-gray1;

        &:hover,
        &:active,
        &:focus {
            @apply bg-gray4;
        }
    }

    &.thin-light {
        @apply border-light text-light font-semibold;

        &:hover,
        &:active,
        &:focus {
            @apply bg-light text-gray1;
        }
    }

    &.big {
        @apply text-m leading-m min-h-xxxl;
    }

    &.normal {
        @apply text-r leading-r min-h-xxl;
    }

    &.small {
        @apply text-s leading-s min-h-xl;
    }

    &.pure {
        @apply font-semibold border-none min-h-m;

        &:focus {
            @apply outline-none shadow-none;
        }

        &:deep() .svg-icon {
            fill: none;
        }
    }

    &.dark {
        @apply font-semibold border-2 border-light text-light bg-tertiary;

        &:hover {
            @apply bg-dark-hover;
        }
    }

    &.light {
        @apply border-tertiary text-tertiary bg-light;

        &:hover {
            @apply bg-tertiary text-light;

            &:deep() .svg-icon {
                fill: theme('colors.light');
            }
        }
    }

    &.eob {
        @apply border-eob-green text-light bg-eob-green font-semibold py-14p px-5;

        &:active {
            @apply bg-transparent text-eob-green;
        }
    }

    &.only-icon {
        &:hover {
            &:deep() .svg-icon {
                fill: none;
                stroke: theme('colors.primary');
            }
        }
    }

    &.added {
        &:deep() .svg-icon {
            fill: theme('colors.primary');
            stroke: theme('colors.primary');
        }

        &.only-icon {
            &:hover {
                &:deep() .svg-icon {
                    fill: theme('colors.primary');
                    stroke: theme('colors.primary');
                }
            }
        }
    }

    &.tag {
        @apply text-gray1 font-book text-s bg-gray5
            rounded-50p uppercase px-3 min-h-l  whitespace-nowrap;

        &:hover,
        &:active {
            @apply bg-gray4;
        }

        &:focus {
            @apply outline-none shadow-none;
        }
    }
}
</style>
