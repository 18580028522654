import ShippingMethod from '@models/ShippingMethod/ShippingMethod';

export default ({ order_info: { store_orders, ...orderInfoRest }, ...thanksPageDataRest }) => ({
    ...thanksPageDataRest,
    order_info: {
        ...orderInfoRest,
        sellerOrders: store_orders.map(sellerOrder => ({
            ...sellerOrder,
            selected_shipping_method: new ShippingMethod(
                sellerOrder.selected_shipping_method
            ).getModel(),
        })),
    },
});
