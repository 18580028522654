export default class Cache {
    cache = {};

    get(key) {
        return this.cache[key];
    }

    set(key, value) {
        this.cache[key] = value;

        return value;
    }
}
