<template>
    <div class="footer-menu-mobile">
        <FooterLinksMobile
            v-for="(section, index) in menuSections"
            :key="`${section.sectionName}-${index}`"
            :heading="section.sectionName"
            class="links"
        >
            <FooterLink
                v-for="({ path, label }, key) in section.links"
                :key="`${label}-${key}`"
                :path="path"
                :label="label"
            />
        </FooterLinksMobile>
    </div>
</template>

<script>
import FooterLink from '@atoms/FooterLink/FooterLink';

import FooterLinksMobile from '@molecules/FooterLinksMobile/FooterLinksMobile';

export default {
    name: 'FooterMenuMobile',

    components: {
        FooterLink,
        FooterLinksMobile,
    },

    props: {
        menuSections: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-menu-mobile {
    @apply -mx-ui-4;
}
</style>
