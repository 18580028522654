export const types = {
    ADD_MESSAGE: 'ADD_MESSAGE',
    REMOVE_MESSAGE: 'REMOVE_MESSAGE',
    REMOVE_MESSAGES: 'REMOVE_MESSAGES',
};

export default {
    [types.ADD_MESSAGE](state, message) {
        state.messages = [...state.messages, message];
    },
    [types.REMOVE_MESSAGE](state, messageId) {
        state.messages = state.messages.filter(message => message.id !== messageId);
    },
    [types.REMOVE_MESSAGES](state) {
        state.messages = [];
    },
};
