<template>
    <div :align-left="true" class="footer-bottom">
        <Copyright class="footer-copyright" />
        <FooterBottomLinks :menu-sections="menuSections" />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { PSB_FOOTER_CONFIGURATION } from '@configs/footer';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { PRODUCT_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { CMS_FOOTER_CONFIGURATION } from '@modules/page-builder/page-builder.config';

import Copyright from '@molecules/Copyright/Copyright';
import FooterBottomLinks from '@organisms/FooterBottomLinks/FooterBottomLinks';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');

export default {
    name: 'FooterBottom',

    components: {
        Copyright,
        FooterBottomLinks,
    },

    props: {
        baseFooterSections: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            config: [],
        };
    },

    computed: {
        ...mapCmsBlockGetters(['pageBuilderBlockById']),

        footerDataCmsPage() {
            return this.pageBuilderBlockById(PSB_FOOTER_CONFIGURATION);
        },

        menuSections() {
            return this.baseFooterSections.length ? this.baseFooterSections : this.config;
        },
    },

    mounted() {
        if (!this.baseFooterSections.length) {
            this.getConfig();
        }
    },

    methods: {
        getConfig() {
            try {
                const cmsFooterConfiguration = this.footerDataCmsPage.find(
                    ({ name }) => name === CMS_FOOTER_CONFIGURATION
                );

                if (!cmsFooterConfiguration) {
                    return;
                }

                const {
                    slots: { default: rawFooterSections },
                } = cmsFooterConfiguration;

                const baseFooterSections = rawFooterSections.slice(3, 4).map(section => {
                    const {
                        slots: { default: baseFooterLinks },
                    } = section;

                    const links = baseFooterLinks.map(link => link.props);

                    return { links };
                });

                this.config = baseFooterSections;
            } catch (err) {
                this.$errorHandler.captureDomainError(PRODUCT_ERROR_DOMAIN, err, {
                    [ERROR_ACTION_TAG_NAME]: 'Footer.config',
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-bottom {
    @apply flex flex-col gap-y-ui-4 py-ui-7;

    @screen lg {
        @apply flex-row;

        .footer-copyright {
            @apply mr-ui-7;
        }
    }
}
</style>
