<script>
import { META_ROBOTS_INDEX_FOLLOW } from '@configs/seo';

import BasePage from '@pages/mixins/BasePage';

import ErrorPage404 from '@organisms/ErrorPage/ErrorPage404';
import ErrorPageDefault from '@organisms/ErrorPage/ErrorPageDefault';

const codeComponentMap = new Map();

codeComponentMap.set(404, 'ErrorPage404');

export default {
    name: 'Error',

    components: {
        ErrorPage404,
        ErrorPageDefault,
    },

    mixins: [BasePage({ sendPageEvent: false })],

    layout: () => 'empty',

    props: {
        error: {
            type: Object,
            default: () => ({
                statusCode: 404,
            }),
        },
    },

    data() {
        return {
            pageName: 'ErrorPage',
            meta: {
                robots: META_ROBOTS_INDEX_FOLLOW,
            },
        };
    },

    head() {
        return {
            meta: [{ hid: 'robots', name: 'robots', content: this.meta.robots }],
        };
    },

    async mounted() {
        await this.isCustomerFetchedDeferred.promise;

        this.emitPageEvent({
            route: {
                name: this.pageName,
                fullPath: this.$route.fullPath,
            },
        });
    },

    render(createElement) {
        const ErrorPage = codeComponentMap.get(this.error.statusCode) || 'ErrorPageDefault';

        return createElement(ErrorPage, {}, []);
    },
};
</script>
