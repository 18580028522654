<template>
    <div>
        <template v-if="withPageNavigation">
            <HeaderSimpleWrapper />
        </template>
        <main>
            <nuxt />
        </main>
        <Messages />
        <BaseFooter v-if="withPageNavigation" />
        <ChatWrapper />
        <GlobalModals />
        <SmartBannerWrapper />
    </div>
</template>

<script>
import { LOGIN_PAGE_NAME } from '@router/names';

import WebView from '@mixins/WebView';
import BaseLayout from '@layouts/mixins/BaseLayout';
import WithCustomer from '@layouts/mixins/WithCustomer';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import HeaderSimpleWrapper from '@molecules/HeaderSimpleWrapper/HeaderSimpleWrapper';
import Messages from '@molecules/Messages/Messages';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import BaseFooter from '@organisms/BaseFooter/BaseFooter';
import GlobalModals from '@organisms/GlobalModals/GlobalModals';

export default {
    name: 'WithSimpleHeaderLayout',

    components: {
        SmartBannerWrapper,
        ChatWrapper,
        HeaderSimpleWrapper,
        Messages,
        BaseFooter,
        GlobalModals,
    },

    mixins: [BaseLayout, WithCustomer, WebView],

    computed: {
        isLoginPage() {
            return this.$route.name === LOGIN_PAGE_NAME;
        },

        withPageNavigation() {
            return !(this.isWebViewMobile && this.isLoginPage);
        },
    },
};
</script>
