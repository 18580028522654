(() => {
    if (typeof HTMLElement.prototype.replaceChildren !== 'undefined') {
        return;
    }

    HTMLElement.prototype.replaceChildren = function (...addNodes) {
        while (this.lastChild) {
            this.removeChild(this.lastChild);
        }

        if (addNodes.length > 0) {
            this.append(...addNodes);
        }
    };
})();
