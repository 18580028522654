import { DISPLAY_MODE_BROWSER, DISPLAY_MODE_STANDALONE } from '@types/DisplayMode';

const getDisplayMode = isMediaQueryDisplayModeStandalone => {
    return isMediaQueryDisplayModeStandalone || (navigator && navigator.standalone)
        ? DISPLAY_MODE_STANDALONE
        : DISPLAY_MODE_BROWSER;
};

export default updateCallbackHandler => {
    if (typeof updateCallbackHandler !== 'function') {
        throw new Error('updateCallbackHandler needs to be function.');
    }

    const displayModeMediaQueryList = window.matchMedia('(display-mode: standalone)');

    updateCallbackHandler(getDisplayMode(displayModeMediaQueryList.matches));

    displayModeMediaQueryList.addListener(event => {
        updateCallbackHandler(getDisplayMode(event.matches));
    });
};
