import { FAMILY_MODEL, FAMILY_STYLIZATION, PICTURE } from '@types/Gallery';

export const REGEXP_YOUTUBE = /^.*(?:youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
export const REGEXP_VIMEO = /^.*vimeo\.com.*\/(\d+)/;

const YOUTUBE_VIDEO_ID_LENGTH = 11;
const VIMEO_VIDEO_MINIMUM_ID_LENGTH = 6;

export const VIMEO_PLAYER_URL = 'https://player.vimeo.com';

export const getYoutubeVideoUrl = url => {
    // eslint-disable-next-line no-unused-vars
    const [_, youtubeID] = url.match(REGEXP_YOUTUBE) || [];

    if (youtubeID?.length === YOUTUBE_VIDEO_ID_LENGTH) {
        return `https://www.youtube.com/embed/${youtubeID}?rel=0`;
    }

    return null;
};

export const getVimeoVideoUrl = url => {
    // eslint-disable-next-line no-unused-vars
    const [_, vimeoID] = url.match(REGEXP_VIMEO) || [];

    if (vimeoID?.length >= VIMEO_VIDEO_MINIMUM_ID_LENGTH) {
        // eslint-disable-next-line max-len
        return `${VIMEO_PLAYER_URL}/video/${vimeoID}?autoplay=1&loop=1&title=0&byline=0`;
    }

    return null;
};

export const getVideoPlaceholder = media => {
    const pictures = media.filter(({ type }) => type === PICTURE);

    if (!pictures.length) {
        return null;
    }

    const stylizationPicture = pictures.find(({ family }) => family === FAMILY_STYLIZATION);

    if (stylizationPicture) {
        return stylizationPicture;
    }

    const modelPicture = pictures.find(({ family }) => family === FAMILY_MODEL);

    if (modelPicture) {
        return modelPicture;
    }

    const [firstPicture, secondPicture] = pictures;

    return secondPicture || firstPicture;
};

export default {
    REGEXP_VIMEO,
    REGEXP_YOUTUBE,
    getYoutubeVideoUrl,
    getVimeoVideoUrl,
};
