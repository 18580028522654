<template>
    <div class="main">
        <MarketingBarDataProvider />
        <HeaderWrapper :is-web-view-mobile="isWebViewMobile" />
        <main>
            <ContainerContent class="breadcrumbs-wrapper" :align-left="true">
                <Breadcrumbs :breadcrumbs="breadcrumbs" />
            </ContainerContent>
            <ContainerContentWithSidebar :is-sidebar-visible-on-mobile="true" class="page">
                <template #sidebar>
                    <SidebarMenu :is-mobile="isMobile">
                        <StaticHtmlLinkHandler>
                            <StaticBlock
                                v-if="sidebarStaticBlocks.staticBlockSidebarMenu"
                                :content="sidebarStaticBlocks.staticBlockSidebarMenu.content"
                                class="sidebar-menu"
                            />
                        </StaticHtmlLinkHandler>
                    </SidebarMenu>
                </template>
                <div class="wrapper">
                    <nuxt />
                </div>
            </ContainerContentWithSidebar>
        </main>
        <template v-if="!isWebViewMobile">
            <BaseFooter />
            <MobileAppBanner />
        </template>
        <ChatWrapper />
        <Messages />
        <GlobalModals />
        <SmartBannerWrapper />
    </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';

import { CONTACT_PAGE_NAME, ADD_RETURN_PAGE_NAME } from '@router/names';

import { PSB_SIDEBAR_MENU } from '@configs/sidebar';

import WebView from '@mixins/WebView';
import BaseLayout from '@layouts/mixins/BaseLayout';
import WithCustomer from '@layouts/mixins/WithCustomer';

import StaticBlock from '@atoms/StaticBlock/StaticBlock';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import Messages from '@molecules/Messages/Messages';
import StaticHtmlLinkHandler from '@molecules/StaticHtmlLinkHandler/StaticHtmlLinkHandler';
import ContainerContentWithSidebar from '@molecules/ContainerContentWithSidebar/ContainerContentWithSidebar';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import BaseFooter from '@organisms/BaseFooter/BaseFooter';
import SidebarMenu from '@organisms/SidebarMenu/SidebarMenu';
import MobileAppBanner from '@organisms/MobileAppBanner/MobileAppBanner';
import GlobalModals from '@organisms/GlobalModals/GlobalModals';
import HeaderWrapper from '@header-organisms/HeaderWrapper/HeaderWrapper';

import MarketingBarDataProvider from '@marketing-bar-components/organisms/MarketingBarDataProvider/MarketingBarDataProvider';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');

export default {
    name: 'CmsLayout',

    components: {
        SmartBannerWrapper,
        ChatWrapper,
        BaseFooter,
        Messages,
        StaticHtmlLinkHandler,
        ContainerContentWithSidebar,
        ContainerContent,
        Breadcrumbs,
        SidebarMenu,
        StaticBlock,
        MobileAppBanner,
        HeaderWrapper,
        GlobalModals,
        MarketingBarDataProvider,
    },

    mixins: [BaseLayout, WithCustomer, WebView],

    data() {
        return {
            routeNameToTranslationMap: {
                [CONTACT_PAGE_NAME]: this.$t('Contact'),
                [ADD_RETURN_PAGE_NAME]: this.$t('Returns'),
            },
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapCmsBlockGetters(['staticBlockById']),

        sidebarStaticBlocks() {
            return {
                staticBlockSidebarMenu: this.staticBlockById(PSB_SIDEBAR_MENU),
            };
        },

        routeName() {
            return this.$route.name;
        },

        cmsBreadcrumb() {
            const breadcrumbTranslation = this.routeNameToTranslationMap[this.routeName];

            if (breadcrumbTranslation) {
                return {
                    name: breadcrumbTranslation,
                    path: this.$router.resolve({
                        name: this.routeName,
                    }).route.path,
                };
            }

            return null;
        },

        breadcrumbs() {
            return this.cmsBreadcrumb ? [this.cmsBreadcrumb] : [];
        },
    },
};
</script>

<style lang="scss" scoped>
.main {
    .page {
        &:deep() {
            .sidebar-menu {
                .static-pages-menu {
                    .menu-button {
                        @apply hidden;
                    }

                    .menu-content {
                        .title,
                        .close {
                            @apply hidden;
                        }
                        .subtitle {
                            @apply font-semibold text-tertiary text-m leading-m;
                        }

                        .links-list {
                            @apply py-3;

                            .menu-link {
                                @apply mb-2;

                                a {
                                    @apply text-text;
                                }
                            }
                        }
                    }
                }
                .paragraph {
                    @apply mb-3;
                }

                .text-link {
                    @apply text-text;
                }
            }
        }
    }

    @screen lg {
        .breadcrumbs-wrapper {
            @apply mt-2 mb-3;
        }
    }
}
</style>
