import validationMessages from 'vee-validate/dist/locale/en.json';
import en_US from '../translations/en_US.json';
import en_US_validations from '../translations/validations/en_US.json';
import en_US_paths from '../translations/paths/en_US.json';

export default () => {
    const validations = validationMessages.messages;

    return {
        __validations: {
            ...validations,
            ...en_US_validations,
        },
        __paths: {
            ...en_US_paths,
        },
        ...en_US,
    };
};
