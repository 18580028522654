import { DEFAULT_ZOWIE_CHAT_SRC } from '@assets/zowie-chat';

export const createDefaultGlobalConfig = () => ({
    isMagicLinkEnabled: false,
    isZowieChatEnabled: false,
    zowieChatEnabledPages: [],
    zowieScriptSrc: DEFAULT_ZOWIE_CHAT_SRC,
    zowieChatInstanceId: '',
    freeShippingTresholdInternal: null,
    freeShippingTresholdPartner: null,
    minimalShippingCostInternal: '',
    shippingTimeInfo: '',
    isAvatarClientAddressEnabled: false,
    isAccountVerificationEnabled: false,
    freeDeliveryText: '',
    synthroneScriptUrl: '',
    synthroneBrands: '',
    isFitAnalyticsWidgetEnabled: false,
});

export const createDefaultHelpCenterConfig = () => ({
    contactFormLink: '',
});

export default () => ({
    localeConfig: {},
    storeViews: [],
    storeView: {},
    baseUrl: '',
    globalConfig: {
        ...createDefaultGlobalConfig(),
    },
    helpCenterConfig: {
        ...createDefaultHelpCenterConfig(),
    },
    googleAnalyticsConfig: {},
});
