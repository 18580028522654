<template>
    <div class="base-alert-row" :class="type">
        <SvgIcon width="24px" height="24px" class="icon">
            <component :is="iconType" />
        </SvgIcon>
        <div class="content">
            <slot></slot>
        </div>

        <template v-if="hasAction">
            <button v-if="actionHandler" type="button" class="action" @click="actionHandler(id)">
                {{ actionText }}
            </button>
            <a v-else :href="actionUrl" class="action">
                {{ actionText }}
            </a>
        </template>
    </div>
</template>

<script>
import TYPES from '@types/Alerts';

import { checkIfExistsInValuesMap } from '@assets/props';

import Success from '@static/icons/24px/success.svg?inline';
import Info from '@static/icons/24px/info.svg?inline';
import Error from '@static/icons/24px/error.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';

const ICON_MAP = {
    success: Success,
    info: Info,
    warning: Error,
    error: Error,
};

export default {
    name: 'BaseAlertRow',

    components: {
        SvgIcon,
    },

    props: {
        type: {
            type: String,
            required: true,
            validator: checkIfExistsInValuesMap(TYPES),
        },

        id: {
            type: String,
            default: null,
        },

        actionHandler: {
            type: Function,
            default: null,
        },

        actionUrl: {
            type: String,
            default: '',
        },

        actionText: {
            type: String,
            default: '',
        },
    },

    computed: {
        iconType() {
            return ICON_MAP[this.type];
        },

        hasAction() {
            return this.actionText && (this.actionHandler || this.actionUrl);
        },
    },
};
</script>

<style lang="scss" scoped>
.base-alert-row {
    @apply relative flex items-center justify-end p-3 flex-wrap;
    @apply text-light text-left text-s font-semibold bg-dark leading-s;
    min-height: theme('customVariables.baseAlertRow.minHeight');
    padding-left: theme('customVariables.baseAlertRow.paddingLeft');

    &.error {
        @apply bg-error;
    }

    &.success {
        @apply bg-success;
    }

    &.warning {
        @apply bg-warning text-gray1;
    }

    &.info {
        @apply bg-information;
    }

    .icon {
        @apply absolute w-4 h-4 top-16 left-16 text-current;
    }

    .content {
        @apply flex-grow;
    }

    .action {
        @apply font-semibold ml-4 underline shrink-0;
    }
}

@screen lg {
    .base-alert-row {
        @apply py-12p min-h-xxl flex-nowrap;

        .icon {
            @apply top-12;
        }
    }
}
</style>
