import memoryCache from 'memory-cache';

import getMemoryCacheRedirectsKey from '@modules/redirector/get-memory-cache-redirects-key';

export default class Redirector {
    static getMemoryCacheRedirectsKeyForLocale(locale = '') {
        return getMemoryCacheRedirectsKey(locale);
    }

    static getRedirectsForLocale(locale = '') {
        return memoryCache.get(Redirector.getMemoryCacheRedirectsKeyForLocale(locale)) || {};
    }
}
