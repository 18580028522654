import { isObject } from '@assets/object';
import loadScript from '@assets/load-script';

export default class CfChallangeHandler {
    TURNSTILE_SDK_URL = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    OVERLAY_ELEMENT_ID = 'cf-challenge-overlay';

    constructor(cfSiteKey, wrapperElementId, textToShow) {
        this.cfSiteKey = cfSiteKey;
        this.wrapperElementId = wrapperElementId;
        this.textToShow = textToShow;

        this.overlayElement = this.initializeOverlayElement();
    }

    initializeOverlayElement() {
        const { textToShow, wrapperElementId, OVERLAY_ELEMENT_ID } = this;

        let overlayElement = document.getElementById(OVERLAY_ELEMENT_ID);

        if (overlayElement) {
            return overlayElement;
        }

        overlayElement = CfChallangeHandler.createOverlay(
            OVERLAY_ELEMENT_ID,
            wrapperElementId,
            textToShow
        );

        CfChallangeHandler.appendElementToBody(overlayElement);

        return overlayElement;
    }

    showOverlay() {
        if (!this.overlayElement) {
            return;
        }

        this.overlayElement.style.display = 'block';
    }

    hideOverlay() {
        if (!this.overlayElement) {
            return;
        }

        this.overlayElement.style.display = 'none';
    }

    loadCfTurnstileSDK() {
        return loadScript(this.TURNSTILE_SDK_URL);
    }

    static createOverlay(overlayElementId, elementId, textToShow) {
        const overlayElement = document.createElement('div');

        overlayElement.id = overlayElementId;
        overlayElement.style.position = 'fixed';
        overlayElement.style.top = '0';
        overlayElement.style.left = '0';
        overlayElement.style.right = '0';
        overlayElement.style.bottom = '0';
        overlayElement.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
        overlayElement.style.border = '1px solid grey';
        overlayElement.style.zIndex = '10000';
        overlayElement.style.display = 'none';

        overlayElement.innerHTML =
            // eslint-disable-next-line max-len
            `<p style="color: white; text-align: center; margin-top: 50vh; margin-bottom: 20px;">${textToShow}</p><div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;" id="${elementId}"></div>`;

        return overlayElement;
    }

    static appendElementToBody(element) {
        if (!window?.document?.body) {
            throw new Error('document.body is not available');
        }

        document.body.appendChild(element);
    }

    static checkForChallengeHeader(headers) {
        if (!isObject(headers)) {
            return false;
        }

        return headers['cf-mitigated'] === 'challenge';
    }

    async makeChallenge() {
        try {
            await this.loadCfTurnstileSDK();
        } catch (error) {
            throw new Error('Unable to load Turnstile SDK');
        }

        if (!window.turnstile) {
            throw new Error('Turnstile is not available');
        }

        let widgetId = null;

        return new Promise((resolve, reject) => {
            widgetId = window.turnstile.render(`#${this.wrapperElementId}`, {
                sitekey: this.cfSiteKey,

                callback: (token, preClearanceObtained) => {
                    this.hideOverlay();

                    window.turnstile.remove(widgetId);

                    if (preClearanceObtained) {
                        resolve(preClearanceObtained);
                    } else {
                        reject(new Error('Unable to obtain pre-clearance'));
                    }
                },

                'error-callback': error => {
                    this.hideOverlay();

                    window.turnstile.remove(widgetId);

                    reject(error);
                },

                'before-interactive-callback': () => {
                    this.showOverlay();
                },

                'after-interactive-callback': () => {
                    this.hideOverlay();
                },

                'unsupported-callback': () => {
                    this.hideOverlay();

                    window.turnstile.remove(widgetId);

                    reject(new Error('Turnstile - unsupported browser'));
                },
            });
        });
    }
}
